/* eslint-disable */
/*
 * @Description: 各种枚举,头文件等...
 * @Author: 梁平贤
 * @LastEditors: wuqi
 * @Date: 2019-07-09 16:55:50
 * @LastEditTime: 2021-07-28 15:00:18
 */

export const BusinessFieldType = {
  default: 0, // 区域
  inputCommon: 1, // 输入框不限制
  multiText: 2, // 多行文本,根据编辑权限看是否可以输入
  inputNumber: 3, // 输入框数字
  inputAmount: 4, // 输入框金额
  select: 5, // 左右展示 + ">" 单选
  associatedData: 6, // 关联数据
  date: 7, // 日期 字符串
  checkBox: 8, // 复选 唯一，标记完成
  attachment: 9, // 附件
  newProgress: 10, // 进度 唯一
  detailParent: 11, // 明细或者子表
  multiSelect: 15, // 多选
  pureText: 16, // 纯文本
  jianGang: 18, // 兼岗
  shouFu: 19, // 收付信息
  onlyViewAssociatedData: 20, // 关联数据，直接能查看 弃用
  basicDataControl: 28, // 数据建模控件
  address: 29, // 地址
  subItem: 30, // 添加子项控件 唯一
  line: 31, // 分割线 App展示g高度为8的空白行
  superior: 33, // 所属上级
  tabControl: 34, // 开票选项卡
  subSet: 35, // 子集（特殊的二级明细区）
  imageAttachment: 36, // 图片附件
  cashObject: 37, // 收付对象 唯一
  associatedObject: 38, // 关联对象控件
  timeAxis: 39, // 时间轴控件
  ocr: 40,
  sign: 41, // 签到控件
  outsideArea:111,//外勤套件
  area: 42, // 区域控件
  textComposition: 43, // 图文合成
  seeFlow: 44, // 配置的流程
  multiTab: 45, //页签
  mileStone: 46, //里程碑
  // 以为为自定义的类型 不要搞乱排序了,,后面的都是不校验的
  notifier: 8888, // 通知人
  flow: 8889, // 自由流
  comment: 8890, // 评论点赞
  approvalComment: 8891, // 审批概要
  log: 8892, // 日志
  noticeReceipt: 8893, // 回执
  approvalOpinion: 8894 // 审批意见
};

// 业务建模页面类型
export const BusinessDataOpenType = {
  add: "1", // 新增
  view: "2", // 查看 草稿能编辑 流程可能编辑
  readOnly: "3", // 只读
  edit: "4" // 点击了编辑按钮的编辑状态
};

// 业务建模页面打开类型
// @param pageType 1默认不全屏,能选择全屏或收起 2默认全屏,能关闭 3打开新页签,全屏 4弹窗类型 (外部基本用不到,内部每种功能提供出去已经定义好了), 10默认全屏, 能选择全屏或收起
export const BusinessDataPageType = {
  normal: 1,
  fullscreen: 2,
  newPage: 3,
  dialog: 4,
  toggleFullscreen: 10
}

// 赋值类型,这里还需要修改优化
const AssignmentOptions = {
  none: 0, // 其它
  loading: 1 << 1, // 加载中
  // 单个 值的格式3种
  manual: 1 << 2, // 手动
  server: 1 << 3, // 约定服务器数据，很多业务逻辑不会触发
  default: 1 << 4, // 默认值
  calculate: 1 << 5, // 计算公式自动计算
  associatedData: 1 << 6, // 关联数据带出值
  automaticGetValue: 1 << 7, // 单选带出
  summary: 1 << 8, // 汇总
  quote: 1 << 9, // 收藏引用
  automaticAssignment: 1 << 10, // 自动赋值
  subItemDefaultOut: 1 << 11, // 子项新增默认带出
  OCR: 1 << 12, // ocr赋值ls
  change: 1 << 13 // 其它的被动改变,手动改变请用manual
};
// 首次加载
export { AssignmentOptions };


// 类型
export const BusinessType = {
  basic: 1, // 数据建模
  business: 2, // 业务建模
  task: 3, // 任务
  dataObject: 4, // 数据对象
  resource: 5, // 资源
  pureBasic: 100 // 纯基础数据
};

// 接口类型
export const InterfaceType = {
  ocr: "1", // ocr
  skyEye: "2", // 企业征信
  other: "3" // 其它
};


// 区域类型
export const SectionType = {
  main: 1, // 主表区
  detail: 2, // 明细区
  subset: 3, // 子集区
  userDefined: 4 // 自定义
};

// 比较操作类型
export const ConditionOperatorType = {
  equal: 1, // 等于
  notEqual: 2, // 不等于
  contain: 3, // 包含
  notContain: 4, // 不包含
  greater: 5, // 大于
  greaterOrEqual: 6, // 大于或等于
  lessOrEqual: 7, // 小于或等于
  less: 8, // 小于
  notContainIn: 9, // 不包含于
  containIn: 10, // 包含于
  range: 11, // 区间
  notEmpty: 13, // 不为空
  empty: 14// 为空
};

export function nameOfConditionOperatorType(type) {
  switch (type) {
    case ConditionOperatorType.notEqual:
      return "不等于";
    case ConditionOperatorType.equal:
      return "等于";
    case ConditionOperatorType.less:
      return "小于";
    case ConditionOperatorType.range:
      return "区间";
    case ConditionOperatorType.contain:
      return "包含";
    case ConditionOperatorType.greater:
      return "大于";
    case ConditionOperatorType.containIn:
      return "包含于";
    case ConditionOperatorType.notContain:
      return "不包含";
    case ConditionOperatorType.lessOrEqual:
      return "小于等于";
    case ConditionOperatorType.notContainIn:
      return "不包含于";
    case ConditionOperatorType.greaterOrEqual:
      return "大于等于";
    case ConditionOperatorType.empty:
      return "为空";
    case ConditionOperatorType.notEmpty:
      return "不为空";
    default:
      break;
  }
}

export const BillStatus = {
  "000": "未提交",
  "001": "待处理",
  "002": "审批中",
  "003": "已结束",
  "004": "已终止",
  "005": "暂缓",
  "006": "已审批",
  "008": "已提交"
};

export const BaseStatus = {
  "000": "启用",
  "001": "停用",
  "002": ""
}

// 操作列表
export const OpertorList = [
  { id: "0", value: "0", op: "", name: "", text: "" },
  { id: "1", value: "1", op: " == ", name: "等于", text: "等于" },
  { id: "2", value: "2", op: " != ", name: "不等于", text: "不等于" },
  { id: "3", value: "3", op: " in ", name: "包含", text: "包含" },
  { id: "4", value: "4", op: " nin", name: "不包含", text: "不包含" },
  { id: "5", value: "5", op: " >  ", name: "大于", text: "大于" },
  { id: "6", value: "6", op: " >= ", name: "大于或等于", text: "大于或等于" },
  { id: "7", value: "7", op: " <= ", name: "小于或等于", text: "小于或等于" },
  { id: "8", value: "8", op: " <  ", name: "小于", text: "小于" },
  { id: "9", value: "9", op: "", name: "不包含于", text: "不包含于" },
  { id: "10", value: "10", op: "", name: "包含于", text: "包含于" },
  { id: "11", value: "11", op: "", name: "属于", text: "属于" },
  { id: "12", value: "12", op: "", name: "不属于", text: "不属于" },
  { id: "13", value: "13", op: "", name: "不为空", text: "不为空" },
  { id: "14", value: "14", op: "", name: "为空", text: "为空" },
  { id: "15", value: "15", op: "", name: "时间段", text: "时间段" }
];

// 关联类型
export const BusinessRelationType = {
  form: 1, //表单
  basic: 2, //基础数据
  businessObj: 3, //业务对象
  business: 4, //业务建模
  attend: 5, //考勤
  manageModel: 7 //关联模型
}