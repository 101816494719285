// 邮箱路由
const routes = [
  {
    path: "/mailbox",
    redirect: "/mailbox/list",
    component: () => import(/* webpackChunkName: "list" */ "@/views/mailBox/App"),
    meta: {
      keepAlive: true,
      fullScreen: false,
      fullPage: false
    },
    children: [
      {
        path: "list",
        name: "List",
        component: () => import(/* webpackChunkName: "list" */ "@/views/mailBox/views/List"),
        meta: {
          keepAlive: true,
          fullScreen: false,
          fullPage: false
        }
      },
      {
        path: "mailSend",
        name: "MailSend",
        component: () => import(/* webpackChunkName: "mailsend" */ "@/views/mailBox/views/MailSend"),
        meta: {
          fullScreen: true
        },
        props: (route) => ({
          mailId: route.query.mailId,
          mailType: route.query.mailType
        })
      },
      {
        path: "mailSetting",
        name: "MailSetting",
        component: () => import(/* webpackChunkName: "mailsetting" */ "@/views/mailBox/views/MailSetting"),
        meta: {
          fullScreen: true
        },
        children: [
          {
            path: "accountManage",
            name: "AccountManage",
            meta: {
              fullScreen: true
            },
            component: () => import(/* webpackChunkName: "mailsetting" */ "@/views/mailBox/views/MailSetting/AccountManage")
          },
          {
            path: "nameListSetting",
            name: "NameListSetting",
            meta: {
              keepAlive: true,
              fullScreen: true
            },
            component: () => import(/* webpackChunkName: "mailsetting" */ "@/views/mailBox/views/MailSetting/NameListSetting")
          },
          {
            path: "receiveSetting",
            name: "ReceiveSetting",
            meta: {
              fullScreen: true
            },
            component: () => import(/* webpackChunkName: "mailsetting" */ "@/views/mailBox/views/MailSetting/ReceiveSetting")
          },
          {
            path: "filterMail",
            name: "FilterMail",
            meta: {
              fullScreen: true
            },
            component: () => import(/* webpackChunkName: "filterMail" */ "@/views/mailBox/views/MailSetting/FilterMail")
          },
          {
            path: "autoGraphSetting",
            name: "AutoGraphSetting",
            meta: {
              fullScreen: true
            },
            component: () => import(/* webpackChunkName: "autoGraphSetting" */ "@/views/mailBox/views/MailSetting/AutoGraphSetting")
          }
        ]
      },
      {
        path: "addressBook",
        name: "AddressBook",
        component: () => import(/* webpackChunkName: "mailsend" */ "@/views/mailBox/views/AddressBook"),
        meta: {
          fullScreen: true
        }
      },
      {
        path: "search",
        name: "MailSearch",
        component: () => import(/* webpackChunkName: "mailsearch" */ "@/views/mailBox/views/MailSearch"),
        meta: {
          fullScreen: true
        }
      }
    ]
  }
];

export default routes;
