var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "select-item",
      class: _vm.style,
      style: _vm.isChildren
        ? ""
        : "border: 1px solid #E8ECF2;border-radius: 4px;",
    },
    [
      _c("div", { staticClass: "label" }, [
        _vm._v(" " + _vm._s(_vm.data.name) + " "),
      ]),
      _c("div", { staticClass: "right" }, [_vm._v(" 切换 ")]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }