/*
 * @Description:
 * @Author: 梁平贤
 * @Date: 2020/6/29 14:07
 */

import BusinessDetailService from "./detail";
import CommentService from "./comment";
import BtnService from "./btn";

import AttendanceModel from "./attendanceModel";
import CashObjectService from "./cashObject";
import TemplateDataHandle from "./templateDataHandle";
import FlowAndLog from "./flowAndLog";
import Resource from "./resource";
import Task from "./task";
import BusinessDetailListService from "./detailList";
import FileService from "./file";

export const businessService = new BusinessDetailService();
export const commentService = new CommentService();
export const btnService = new BtnService();

export const attendanceModel = new AttendanceModel();
export const cashObject = new CashObjectService();
export const templateDataHandle = new TemplateDataHandle();
export const flowAndLog = new FlowAndLog();
export const resource = new Resource();
export const task = new Task();
export const detailListService = new BusinessDetailListService();
export const fileService = new FileService();
