/*
 * @Author: zhulin
 * @Date: 2020-06-16 17:37:45
 * @LastEditors: zhulin
 * @LastEditTime: 2020-08-14 11:07:13
 * @Description: file content
 */
import BaseService from "../BaseService";

/**
 * login相关
 */
class CorporateService extends BaseService {
  pk = "";

  constructor(http) {
    super("", http);
  }

  /**
   * @description  查询天眼查接口
   * @param
   * tycType string 类型365
   * aginSel Int 0: 是1:否 是否是再次查询
   * reqTycData Map 第三方需要的参数
   */
  queryTYC(params) {
    const { http } = this;
    return http.post("queryTYC", params);
  }
}

export default CorporateService;
