/*
 * @Author: Fenngding
 * @Date: 2019-09-04 09:13:28
 * @LastEditors: pengyu
 * @LastEditTime: 2020-06-16 14:16:58
 * @Email: fenngding@live.com
 * @Description:
 */
const routes = [
  {
    path: "/chat",
    component: () => import(/* webpackChunkName: "chat" */"@/views/chat"),
    meta: {
      title: "沟通",
      keepAlive: true,
      fullPage: false
    }
  }
];

export default routes;
