/*
 * @Author: zhangjiaheng
 * @Date: 2020-06-19 16:07:53
 * @LastEditTime: 2021-06-22 13:42:44
 * @LastEditors: Please set LastEditors
 * @Description: 河洛api
 * @FilePath: \user\src\api\en\en.js
 */
import BaseService from "../BaseService";

class En extends BaseService {
  pk = ""

  constructor(http) {
    super("", http);
  }

  // 消息中心 start
  /**
   * 查询用户习惯
   * @param params 参数
   * @returns {*}
   */
  queryUserConfig(params) {
    const { http } = this;
    return http.post("queryUserConfig", params);
  }

  /**
   * 保存用户习惯
   * @param params 参数
   * @returns {*}
   */
  saveUserConfig(params) {
    const { http } = this;
    return http.post("saveUserConfig", params);
  }

  /**
   * 查询模板配置习惯
   * @param params 参数
   * @returns {*}
   */
  queryMdViewConfig(params) {
    const { http } = this;
    return http.post("queryMdViewConfig", params);
  }

  /**
   * 查询未读列表
   * @param params 参数
   * @returns {*}
   */
  Notice(params) {
    const { http } = this;
    return http.post("queryNoticeList", params);
  }

  /**
   * 查询标记已读
   * @param params 参数 isBatch: 0标记已读
   * @returns {*}
   */
  readNotice(param) {
    const { http } = this;
    return http.post("readNotice", param);
  }

  /**
   * 查询标记未读读
   * @param params 参数
   * @returns {*}
   */
  convertToBeRead(param) {
    const { http } = this;
    return http.post("convertToBeRead", param);
  }

  /**
   * 删除消息
   * @param params 参数
   * @returns {*}
   */
  deleteNotice(param) {
    const { http } = this;
    return http.post("deleteNotice", param);
  }

  /**
   * 查询019类型的系统公告
   * @param params 参数
   * @returns {*}
   */
  querySysNoticeInfo(param) {
    const { http } = this;
    return http.post("querySysNoticeInfo", param);
  }

  /**
   * 查询048类型的体检结果
   * @param params 参数
   * @returns {*}
   */
  getTaxCheckResultDetail(param) {
    const { http } = this;
    return http.post("getTaxCheckResultDetail", param);
  }

  /**
   * 稍后提醒
   * @param params 参数
   * @returns {*}
   */
  imAddMsgNotice(param) {
    const { http } = this;
    return http.post("imAddMsgNotice", param);
  }

  // 消息中心 end

  // 回执列表 start
  /**
   * 查询回执列表
   * @param params 参数
   * @returns {*}
   */
  queryReceiptList(param) {
    const { http } = this;
    return http.post("queryReceiptList", param);
  }

  /**
   * 查询回执操作
   * @param params 参数
   * @returns {*}
   */
  receiptOperate(param) {
    const { http } = this;
    return http.post("receiptOperate", param);
  }

  // 回执列表 end

  // 我的业务 start
  /**
   * 查询我的业务列表
   * @param params 参数
   * @returns {*}
   */
  queryMyMdBusinessList(param) {
    const { http } = this;
    return http.post("queryMyMdBusinessList", param);
  }
  // 我的业务 end

  // 已办列表 start
  /**
   * 查询已办列表 或者待办 参数不同
   * @param params 参数
   * @returns {*}
   */
  queryTaskList(param) {
    const { http } = this;
    return http.post("queryTaskList", param);
  }
  // 已办列表 end

  // 我的订单 start
  /**
   * 查询我的订单列表
   * @param params 参数
   * @returns {*}
   */
  queryMyOrderList(param) {
    const { http } = this;
    return http.post("queryMyOrderList", param);
  }
  // 我的订单 end

  // 我的任务 start
  /**
   * 查询我的订单列表
   * @param params 参数
   * @returns {*}
   */
  mdMyTaskList(param) {
    const { http } = this;
    return http.post("mdMyTaskList", param);
  }
  // 我的任务 end

  // 待办任务 start
  /**
   * 批量同意
   * @param params 参数
   * @returns {*}
   */
  batchApplyAgree(param) {
    const { http } = this;
    return http.post("batchApplyAgree", param);
  }

  /**
   * 同意
   * @param params 参数
   * @returns {*}
   */
  mdAgree(param) {
    const { http } = this;
    return http.post("mdAgree", param);
  }

  /**
   * 查询流程节点
   * @param params 参数
   * @returns {*}
   */
  queryFlowNodeInfo(param) {
    const { http } = this;
    return http.post("queryFlowNodeInfo", param);
  }

  /**
   * 退回 驳回（就是退回到首节点）
   * @param params 参数
   * @returns {*}
   */
  mdReject(param) {
    const { http } = this;
    return http.post("mdReject", param);
  }

  /**
   * 收回
   * @param params 参数
   * @returns {*}
   */
  mdTakeBack(param) {
    const { http } = this;
    return http.post("mdTakeBack", param);
  }

  /**
   * 终止
   * @param params 参数
   * @returns {*}
   */
  stop(param) {
    const { http } = this;
    return http.post("stop", param);
  }

  /**
   * 流程自定义按钮接口
   * @param params 参数
   * @returns {*}
   */
  mdCustom(param) {
    const { http } = this;
    return http.post("mdCustom", param);
  }
  // 待办任务 end

  /**
   * 加入主题
   * @param params 参数
   * @returns {*}
   */
  ptJoin(param) {
    const { http } = this;
    return http.post("ptJoin", param);
  }

  /**
   * 删除主题
   * @param params 参数
   * @returns {*}
   */
  ptOut(param) {
    const { http } = this;
    return http.post("ptOut", param);
  }

  /**
   * 查询偏好设置
   * @param params 参数
   * @returns {*}
   */
  queryPreferences(param) {
    const { http } = this;
    return http.post("queryPreferences", param);
  }

  /**
   * 查询全局变量
   * @param params 参数
   * @returns {*}
   */
  queryRbacGloableVariableList(param) {
    const { http } = this;
    return http.post("queryRbacGloableVariableList", param);
  }

  /**
   * 查询工资条信息
   * @param params 参数
   * @returns {*}
   */
  queryPaySlipsDetail(param) {
    const { http } = this;
    return http.post("queryPaySlipsDetail", param);
  }

   /**
   * 查询更新工资条
   * @param params 参数
   * @returns {*}
   */
  updatePaySlip(param) {
    const { http } = this;
    return http.post("updatePaySlip", param);
  }
}

export default En;
