/*
 * @Author: zhulin
 * @Date: 2020-06-16 17:37:45
 * @LastEditors: zhulin
 * @LastEditTime: 2020-07-31 08:53:18
 * @Description: file content
 */
import BaseService from "../BaseService";

/**
 * login相关
 */
class AuthorizeService extends BaseService {
  pk = "";

  constructor(http) {
    super("", http);
  }

  /**
   * @description  查询我的授权
   * @param params
   * @returns {*}
   */
  queryMyAuth(params) {
    const { http } = this;
    return http.post("syQueryMyAuth", params);
  }

  /**
   * @description  查询授权给我
   * @param params
   * @returns {*}
   */
  queryAuthMe(params) {
    const { http } = this;
    return http.post("syQueryAuthMe", params);
  }

  /**
   * @description  新增授权
   * @param params
   * @returns {*}
   */
  addAuth(params) {
    const { http } = this;
    return http.post("syAddAuth", params);
  }

  /**
   * @description  查询授权代理的待办任务提醒
   * @param params
   * @returns {*}
   */
  queryProxySwitch(params) {
    const { http } = this;
    return http.post("queryWfProxySwitch", params);
  }

  /**
   * @description  设置授权代理的待办任务提醒
   * @param params
   * @returns {*}
   */
  setProxySwitch(params) {
    const { http } = this;
    return http.post("setWfProxySwitch", params);
  }

  /**
   * @description  查询审批授权列表
   * @param params
   * @returns {*}
   */
  queryProxyList(params) {
    const { http } = this;
    return http.post("wfProxyRecordList", params);
  }

  /**
   * @description  查询授权登录日志
   * @param params
   * @returns {*}
   */
  queryAuthLog(params) {
    const { http } = this;
    return http.post("syQueryAuthLog", params);
  }

  /**
   * @description  新增登录授权
   * @param params
   * @returns {*}
   */
  loginAction(params) {
    const { http } = this;
    return http.post("loginAction", params);
  }

  /**
   * @description  终止登录授权
   * @param params
   * @returns {*}
   */
  stopAuthPassword(params) {
    const { http } = this;
    return http.post("syStopAuthPassword", params);
  }

  /**
   * @description  获取基础数据类型
   * @param params
   * @returns {*}
   */
  queryObjectTypeList(params) {
    const { http } = this;
    return http.post("wfQueryObjectTypeList", params);
  }

  /**
   * @description  保存审批授权
   * @param params
   * @returns {*}
   */
  addUpdateProxy(params) {
    const { http } = this;
    return http.post("addOrUpdateWfProxy", params);
  }

  /**
   * @description  终止审批授权
   * @param params
   * @returns {*}
   */
  enableProxyRecord(params) {
    const { http } = this;
    return http.post("enableWfProxyRecord", params);
  }

  /**
   * @description  删除审批授权
   * @param params
   * @returns {*}
   */
  deleteProxyRecord(params) {
    const { http } = this;
    return http.post("deleteWfProxyRecord", params);
  }

  /**
   * @description  查询业务建模
   * @param params
   * @returns {*}
   */
  queryDataResource(params) {
    const { http } = this;
    return http.post("queryDataResourceType", params);
  }
}

export default AuthorizeService;
