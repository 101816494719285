/*
 * @Author: pengyu
 * @Date: 2020-06-16 17:37:45
 * @LastEditors: pengyu
 * @LastEditTime: 2021-06-23 17:18:40
 * @Description: 凭证处理-接口
 */
import BaseService from "../BaseService";

class IntelligentService extends BaseService {
  pk = ""

  constructor(http) {
    super("", http);
  }

  /**
   * @description  查询账套
   * @param params 登录参数
   * @returns {*}
   */
  queryAccList(params = {}) {
    const { http } = this;
    return http.post("bdAcQueryAccList", params);
  }

  /**
   * @description  查询会计期间
   * @param params 参数
   */
  queryAccTime(params) {
    const { http } = this;
    return http.post("bdAcQueryAccTimes", params);
  }

  /**
 * @description: 查询记账主体
 * @param params  查询参数
 * @return:
 */
  queryEntity(params) {
    const { http } = this;
    return http.post("queryObjectTreeExt", params);
  }

  /**
 * @description: 查询账套列表
 * @param params 查询参数
 */
  queryVoucherList(params) {
    const { http } = this;
    return http.post("vcQueryVoucherListByBusi", params);
  }

  /**
 * @description: 操作凭证 （审核，取消审核，删除）
 * @param params 查询参数
 */
  operateVoucher(params) {
    const { http } = this;
    return http.post("vcOsperatorVoucherAudit", params);
  }

  /**
 * @description: 查询日志
 * @param params 查询参数
 */
  voucherLog(params) {
    const { http } = this;
    return http.post("vcQueryVoucherLog", params);
  }

  /**
 * @description: 保存账套 记账主体的选择
 * @param params 参数
 */
  saveAccHabit(params) {
    const { http } = this;
    return http.post("bdAcSaveAccHabit", params);
  }

  /**
 * @description: 查询指定账套下的所有科目
 * @param params 参数
 */
  queryAllSubject(params) {
    const { http } = this;
    return http.post("bdAcQueryAllSubject", params);
  }

  /**
 * @description: 查询指定账套下的所有辅助核算
 * @param params 参数
 */
  queryAllAssit(params) {
    const { http } = this;
    return http.post("bdAcQuerySubjectAllAssit", params);
  }

  /**
 * @description: 查询人员树
 * @param params 参数
 */
  getDeptUserTree(params) {
    const { http } = this;
    return http.post("getDeptUserTree", params);
  }

  /**
 * @description: 查询非人员基础数据
 * @param params 参数
 */
  queryObjectTreeExtAccess(params) {
    const { http } = this;
    return http.post("queryObjectTreeExtAccess", params);
  }

  /**
 * @description: 查询非人员基础数据
 * @param params 参数
 */
  queryBusTemplateByAccount(params) {
    const { http } = this;
    return http.post("queryBusTemplateByAccount", params);
  }

  /** @description: 查询凭证日志
 *   @param params 参数
 */
  queryVoucherLog(params) {
    const { http } = this;
    return http.post("vcQueryVoucherLog", params);
  }

  /**
 * @description: 校验会计期间是否已经断号整理
 * @param params 参数
 */
  validateBroken(params) {
    const { http } = this;
    return http.post("vcValidateBroken", params);
  }

  /**
 * @description: 校验会计期间是否已经断号整理
 * @param params 参数
 */
  brokenSign(params) {
    const { http } = this;
    return http.post("vcBrokenSign", params);
  }

  /**
   * @description: 提交凭证
   * @param params 参数
   */
  submitVoucher(params) {
    const { http } = this;
    return http.post("vcVoucherSubmit", params);
  }

  /**
   * @description: 查询凭证自定义按钮
   */
  queryBtnFunc() {
    const { http } = this;
    return http.post("vcQueryBtnFunc");
  }

  /**
   * @description: 触发自定义按钮
   */
  emitCustomBtn(params) {
    const { http } = this;
    return http.post("vcCustomBtn", params);
  }

  /**
   * @description: 查询业务建模分类模板（凭证筛选）
   */
  queryCommClassifyTemplate(params) {
    const { http } = this;
    return http.post("queryCommClassifyTemplate", params);
  }

  /**
   * @description: 查询凭证处理列表自定义列头
   */
  queryUserBehavior(params) {
    const { http } = this;
    return http.post("queryUserBehavior", params);
  }

  /**
   * @description: 保存凭证处理列表自定义列头
   */
  saveUserBehavior(params) {
    const { http } = this;
    return http.post("saveUserBehavior", params);
  }

  exportVoucher(params) {
    const { http } = this;
    params = { ...params, requestType: "stream" };
    return http.post("expVoucherListByBusi", params, { responseType: "arraybuffer" });
  }

  // 查询凭证操作日志 数量
  queryVoucherInterfaceCount(params) {
    const { http } = this;
    return http.post("queryVoucherInterfaceCount", params);
  }

  // 导出凭证日志
  exportVoucherInterface(params) {
    const { http } = this;
    return http.post("exportVoucherInterface", params);
  }
}

export default IntelligentService;
