var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    {
      staticClass: "el-menu-item",
      class: {
        "is-active-1": _vm.activeId == 1,
        "is-active-2": _vm.activeId == 2,
        "is-active-3": _vm.activeId == 3,
        "is-active-4": _vm.activeId == 4,
        "is-active-5": _vm.activeId == 5,
        "is-active-6": _vm.activeId == 6,
        "is-disabled": _vm.disabled,
        "is-active-custom": _vm.active,
      },
      style: [
        _vm.paddingStyle,
        _vm.itemStyle,
        { backgroundColor: _vm.backgroundColor, ..._vm.navStyle },
      ],
      attrs: { role: "menuitem", tabindex: "-1" },
      on: {
        click: _vm.handleClick,
        mouseenter: _vm.onMouseEnter,
        focus: _vm.onMouseEnter,
        blur: _vm.onMouseLeave,
        mouseleave: _vm.onMouseLeave,
      },
    },
    [
      _c(
        "div",
        { style: { itemsStyle: _vm.itemsStyle } },
        [
          _vm.parentMenu.$options.componentName === "ElMenu" &&
          _vm.rootMenu.collapse &&
          _vm.$slots.title
            ? _c(
                "el-tooltip",
                { attrs: { effect: "dark", placement: "right" } },
                [
                  _c(
                    "div",
                    { attrs: { slot: "content" }, slot: "content" },
                    [_vm._t("title")],
                    2
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        position: "absolute",
                        left: "0",
                        top: "0",
                        height: "100%",
                        width: "100%",
                        display: "inline-block",
                        "box-sizing": "border-box",
                        padding: "0 20px",
                      },
                    },
                    [_vm._t("default")],
                    2
                  ),
                ]
              )
            : [_vm._t("default"), _vm._t("title")],
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }