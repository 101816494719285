/*
 * @Author: pengyu
 * @Date: 2021-06-30 10:09:47
 * @LastEditors: pengyu
 * @LastEditTime: 2021-06-30 10:32:45
 * @Description: 附件处理接口
 */

import VueApp from "@/main.js";
import BaseService from "../BaseService";

class FileService extends BaseService {
  pk = ""

  constructor(http) {
    super("", http);
  }

  /**
 * @description: 替换我的文件夹fileCode
 * @param params 查询参数
 */
   replaceFileCode(params) {
    const { http } = this;
    console.log(params, "paramsparamsparamsparams");
    // https://static.enfry.cn/Storage-api/upload.api?token=C7DA7E006B2BDEBBE05551FFDA75155E&appKey=enfrytest&ts=1701915351512
    const urlParam = VueApp.$store.getters.urlParam;
    const url = `${urlParam.baseUrl}/replaceFileCode.api?appKey=${urlParam.appKey}&token=${urlParam.token}&ts=${urlParam.ts}`;
    return http.post(url, params);
  }
}

export default FileService;
