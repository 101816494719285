/*
 * @Author: zhangjiaheng
 * @Date: 2020-08-06 16:47:39
 * @LastEditors: zhulin
 * @LastEditTime: 2020-09-17 10:52:35
 * @Description: 自定义资源api
 * @FilePath: \user\src\api\businessModel\resource.js
 */
import BaseService from "../BaseService";

class Resource extends BaseService {
  pk = "";

  constructor(http) {
    super("", http);
  }

  /**
   * 查询自定义资源的 资源视图
   * @param params 查询参数
   * @returns {*}
   */
  mdQueryResourceReport(params) {
    return this.http.post("mdQueryResourceReport", params);
  }

  /**
   * 查询自定义资源的 日历视图
   * @param params 查询参数
   * @returns {*}
   */
  queryResourceCalendar(params) {
    return this.http.post("mdQueryResourceCalendar", params);
  }

  /**
   * 查询自定义资源的 会议室数据
   * @param params 查询参数
   * @returns {*}
   */
  queryResourceBaseData(params) {
    return this.http.post("mdQueryResourceBaseData", params);
  }
}

export default Resource;
