/*
 * @Author: zhulin
 * @Date: 2020-06-16 17:12:51
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-11-09 17:04:22
 * @Description: file content
 */
import SalesTrendsService from "./salesTrendsService";
import PlanApplyService from "./planApplyService";
import PlanDetailService from "./planDetailService";

export const salesTrendsService = new SalesTrendsService();
export const planApplyService = new PlanApplyService();
export const planDetailService = new PlanDetailService();
