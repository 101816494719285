const routes = [
  {
    path: "/payroll/check",
    name: "payrollCheck",
    component: () => import(/* webpackChunkName:"payroll" */ "@/views/payRoll/user/check/index"),
    meta: {
      title: "查看工资条",
      keepAlive: false,
      fullPage: true,
      noVerify: true// 是否需要密码验证
    }
  }
];

export default routes;
