export default {
  storedFileData(state) {
    return state.fileData;
  },
  storedSelect(state) {
    return state.select;
  },
  storedForm(state) {
    return state.form;
  },
  getterCheckData(state) {
    return state.checkData;
  },
  getterRefInfo(state) {
    return state.refInfo;
  }
};
