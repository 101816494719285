/*
 * @Author: wuqi
 * @Date: 2021-08-11 15:56:39
 * @LastEditors: wuqi
 * @LastEditTime: 2021-08-30 14:45:48
 * @Description:
 */
import request from "@/api/http";

export function httpQueryGroupList(params) {
  return request.post("queryGroupList", params);
}
