/**
 * project: user
 * fileName: msg
 * Created by 徐俊 on 2020/08/25
 * Copyright © 2020 Enfry Ltd. All rights reserved.
 */

import { Message } from "element-ui";
import Utils from "@/tools/chat";
import config from "../sdk/config";

export function onRoamingMsgs(store, obj) {
  const msgs = obj.msgs.map((msg) => Utils.formatMsg(store.state, msg));
  store.commit("updateMsgs", msgs);
}

export function onOfflineMsgs(store, obj) {
  const msgs = obj.msgs.map((msg) => Utils.formatMsg(store.state, msg));
  store.commit("updateMsgs", msgs);
}

export function onMsg(store, msg) {
  const { state } = store;
  msg = Utils.formatMsg(state, msg);
  store.commit("putMsg", msg);
  if (msg.sessionId === state.currSessionId) {
    store.commit("updateCurrSessionMsgs", { type: "put", msg });
  }
  if (msg.scene === "team" && msg.type === "notification") {
    store.dispatch("onTeamNotificationMsg", msg);
  }
}

function onSendMsgDone(store, error, msg) {
  if (error) {
    // 被拉黑
    if (error.code === 7101) {
      msg.status = "success";
    }

    Message({ message: error.message, type: "error" });
    // return; //放开重发吧
  }
  onMsg(store, msg);
}

// 消息撤回
export function onRevocateMsg(store, error, msg) {
  const nim = window.nim;
  if (error) {
    if (error.code === 508) {
      Message({ message: "发送时间超过2分钟的消息，不能被撤回", type: "warning" });
    } else {
      Message({ message: error.message, type: "error" });
    }
    return;
  }

  const { state } = store;
  let tip = "";
  if (msg.from === state.userId) {
    tip = "你撤回了一条消息";
  } else {
    const userInfo = state.userInfos[msg.from];
    if (userInfo) {
      tip = `${Utils.getFriendAlias(userInfo)}撤回了一条消息`;
    } else {
      tip = "对方撤回了一条消息";
    }
  }
  nim.sendTipMsg({
    isLocal: true,
    scene: msg.scene,
    to: msg.to,
    tip,
    time: msg.time,
    done: function sendTipMsgDone(error, tipMsg) {
      const idClient = msg.deletedIdClient || msg.idClient;
      store.commit("replaceMsg", { sessionId: msg.sessionId, idClient, msg: tipMsg });
      if (msg.sessionId === store.state.currSessionId) {
        store.commit("updateCurrSessionMsgs", { type: "replace", idClient, msg: tipMsg });
      }
    }
  });
}

export function revocateMsg(store, msg) {
  const nim = window.nim;
  const { idClient } = msg;
  msg = Object.assign(msg, store.state.msgsMap[idClient]);
  nim.deleteMsg({
    msg,
    done: function deleteMsgDone(error) {
      onRevocateMsg(store, error, msg);
    }
  });
}
export function updateLocalMsg(store, msg) {
  store.commit("updateCurrSessionMsgs", {
    type: "replace",
    idClient: msg.idClient,
    msg
  });
  window.nim.updateLocalMsg({
    idClient: msg.idClient,
    localCustom: msg.localCustom
  });
  store.commit("replaceMsg", {
    sessionId: msg.sessionId,
    idClient: msg.idClient,
    msg
  });
}

// 发送普通消息
export function sendMsg(store, obj) {
  const nim = window.nim;
  obj = obj || {};
  const type = obj.type || "";
  switch (type) {
    case "text":

      if (!obj.text) {
        Message({ message: "消息不能为空" });
        return;
      }

      if (obj.text.length > 1000) {
        Message({ message: "消息不要超过1000字" });
        return;
      }
      nim.sendText({
        scene: obj.scene,
        to: obj.to,
        text: obj.text,
        needMsgReceipt: false,
        done(error, msg) {
          onSendMsgDone(store, error, msg);
          if (obj.done) {
            obj.done(error, msg);
          }
        }
      });
      break;
    case "custom":
      nim.sendCustomMsg({
        scene: obj.scene,
        to: obj.to,
        pushContent: obj.pushContent,
        content: JSON.stringify(obj.content),
        done(error, msg) {
          onSendMsgDone(store, error, msg);
          if (obj.done) {
            obj.done(error, msg);
          }
        }
      });
  }
}

export function resendMsg(store, obj) {
  const nim = window.nim;
  let { msg } = obj;
  const { scene, to } = obj;
  const { idClient } = msg;
  msg = Object.assign(msg, store.state.msgsMap[idClient]);
  nim.deleteLocalMsg({
    msg,
    done: function deleteMsgDone(/* error */) {
      nim.forwardMsg({
        msg,
        scene,
        to,
        done(error, msg) {
          store.commit("replaceMsg", { sessionId: msg.sessionId, idClient, msg });

          // onSendMsgDone(store, error, msg);
          if (obj.done) {
            obj.done(error, msg);
          }
        }
      });
    }
  });
}

// 发送文件消息
export function sendFileMsg(store, obj) {
  const nim = window.nim;
  let { type } = obj;
  const { fileInput } = obj;
  if (!type && fileInput) {
    type = "file";
    if (/\.(png|jpg|bmp|jpeg|gif)$/i.test(fileInput.value)) {
      type = "image";
    } else if (/\.(mov|mp4|ogg|webm)$/i.test(fileInput.value)) {
      type = "video";
    }
  }
  const data = {
    type,
    uploadprogress(data) {
      console.log(data.percentageText);
    },
    uploaderror() {
      fileInput.value = "";
      console && console.log("上传失败");
    },
    uploaddone(error, file) {
      fileInput.value = "";
      console.log(error);
      console.log(file);
    },
    beforesend(msg) {
      console.log("正在发送消息, id=", msg);
      onSendMsgDone(store, null, msg);
    },
    // done(error, msg) { // 为啥不响应啊
    //   // alert(error);
    //   // onSendMsgDone(store, error, msg);
    //   // if (obj.done) {
    //   //   obj.done();
    //   // }
    // },
    ...obj
  };
  nim.sendFile(data);
}

// 发送机器人消息
export function sendRobotMsg(store, obj) {
  const nim = window.nim;
  let { scene } = obj;
  const {
    type, to, robotAccid, content, params, target, body
  } = obj;
  scene = scene || "p2p";
  if (type === "text") {
    nim.sendRobotMsg({
      scene,
      to,
      robotAccid: robotAccid || to,
      content: {
        type: "text",
        content
      },
      body,
      done: onSendMsgDone
    });
  } else if (type === "welcome") {
    nim.sendRobotMsg({
      scene,
      to,
      robotAccid: robotAccid || to,
      content: {
        type: "welcome"
      },
      body,
      done: onSendMsgDone
    });
  } else if (type === "link") {
    nim.sendRobotMsg({
      scene,
      to,
      robotAccid: robotAccid || to,
      content: {
        type: "link",
        params,
        target
      },
      body,
      done: onSendMsgDone
    });
  }
}

export function getHistoryMsgs(store, obj) {
  const { state, commit } = store;
  const nim = window.nim;
  if (nim) {
    const {
      scene, to, done, limit
    } = obj;
    let options = {
      scene,
      to,
      reverse: false,
      asc: true,
      limit: limit || config.localMsglimit || 20,
      done: function getHistoryMsgsDone(error, obj) {
        if (obj.msgs) {
          if (obj.msgs.length === 0) {
            commit("setNoMoreHistoryMsgs");
          } else {
            const msgs = obj.msgs.map((msg) => Utils.formatMsg(store.state, msg));
            commit("updateCurrSessionMsgs", {
              type: "concat",
              msgs
            });
          }
        }
        if (done) {
          done(error, obj);
        }
      }
    };
    if (state.currSessionLastMsg) {
      options = Object.assign(options, {
        lastMsgId: state.currSessionLastMsg.idServer,
        endTime: state.currSessionLastMsg.time
      });
    }
    nim.getHistoryMsgs(options);
  }
}

export function resetNoMoreHistoryMsgs({ commit }) {
  commit("resetNoMoreHistoryMsgs");
}
