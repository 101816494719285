import BaseService from "../BaseService";

class LawAngelService extends BaseService {
  constructor(http) {
    super("", http);
  }

  // 查询订单信息
  querySysPayOrderInfo(params) {
    return this.http.post("querySysPayOrderInfo", params);
  }

  // 查询支付状态
  querySysPayOrderStatus(params) {
    return this.http.post("querySysPayOrderStatus", params);
  }

  // 发起微信支付
  sysPayPostZjPayWeChatPay(params) {
    return this.http.post("sysPayPostZjPayWeChatPay", params);
  }

  // 发起支付宝支付
  sysPayPostZjPayAlipay(params) {
    return this.http.post("sysPayPostZjPayAlipay", params);
  }

  // 发起银联支付
  sysPayPostZjPayUnionPay(params) {
    return this.http.post("sysPayPostZjPayUnionPay", params);
  }
}

export default LawAngelService;
