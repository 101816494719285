import BaseService from "../BaseService";

class CashObjectService extends BaseService {
  /**
 * @description: 查询收付人员
 * @param params
 */
  paymentPerson(searchText, cashObjInfo) {
    const { http } = this;
    const params = {};
    if (cashObjInfo) {
      params.cashObjInfo = JSON.stringify(cashObjInfo);
    }
    params.name = searchText;
    return http.post("commQueryPaymentByName", params);
  }

  /**
  * @description: 查询历史记录
  * @param params
  */
  historyPaymentPerson(cashObjInfo) {
    const { http } = this;
    const params = {};
    if (cashObjInfo) {
      params.cashObjInfo = JSON.stringify(cashObjInfo);
    }
    return http.post("queryHistoryRecordAndBankPayment", params);
  }

  /**
  * @description: 查询详情
  * @param params
  */
  paymentPersonDetail(dataId, type) {
    const { http } = this;
    const params = {};
    params.id = dataId;
    params.type = type;
    return http.post("queryUserAndMerchantsPayment", params);
  }

  /**
  * @description: 查询基础数据详情
  * @param params
  */
  queryBasicDataDetail(dataId, objectType, objectTypeId) {
    const { http } = this;
    const params = {};
    params.objectTypeId = objectTypeId;
    params.id = dataId;
    params.objectType = objectType;
    return http.post("bdQueryBaseDataInfo", params);
  }

  /**
  * @description: 查询基础数据详情
  * @param params
  */
  saveBasicDataDetail(objectType, objectTypeId, mdData) {
    const { http } = this;
    const params = {};
    params.objectTypeId = objectTypeId;
    params.objectType = objectType;
    params.mdData = JSON.stringify(mdData);
    return http.post("addBdDataBankInfo", params);
  }

  /**
  * @description: 查询基础数据详情
  * @param params
  */
  queryOptionsData(field, bank, city) {
    const { http } = this;
    if (field === "bank") {
      return http.post("queryBankTypeList");
    }
    if (field === "city") {
      return http.post("queryBankCityList");
    }
    if (bank?.length === 0 || city?.length === 0) {
      return [];
    }
    const params = {};
    params.bankTypeCode = bank;
    params.cityCode = city;
    return http.post("queryBankInfoList", params);
  }
}

export default CashObjectService;
