var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "micro-menu-page-container",
      class: {
        [_vm.themeInfo.themeColor + "-theme"]: _vm.themeInfo.navStyle !== 3,
        [_vm.themeInfo.themeColor + "-theme-top-one"]:
          _vm.themeInfo.navStyle === 3 && _vm.themeInfo.RecommendedStyles === 5,
        [_vm.themeInfo.themeColor + "-theme-top-two"]:
          _vm.themeInfo.navStyle === 3 && _vm.themeInfo.RecommendedStyles === 6,
        [_vm.themeInfo.themeColor + "-theme-top-three"]:
          _vm.themeInfo.navStyle === 3 && _vm.themeInfo.RecommendedStyles === 7,
        "custom-nav-bg": _vm.themeInfo.openCustomColor,
      },
    },
    [
      _c(
        "div",
        { staticClass: "home-router" },
        [
          _vm.pageLayout <= 1 ? _c("top-nav-menu") : _vm._e(),
          _vm.pageLayout === 0 && _vm.themeInfo.navStyle == 3
            ? _c("top-menu", {
                style: _vm.navStyle,
                attrs: { activeId: _vm.themeInfo.themeStyle },
                on: { onChildData: _vm.onChildData },
              })
            : _vm._e(),
          _vm.pageLayout === 0 &&
          _vm.themeInfo.navStyle == 3 &&
          _vm.leftChildList.length > 0
            ? _c(
                "div",
                {
                  staticStyle: {
                    float: "left",
                    color: "#fff",
                    height: "100%",
                    width: "218px",
                  },
                },
                [
                  _c(
                    "el-menu",
                    {
                      staticClass: "menu2",
                      style: [_vm.navBorderStyle, _vm.navStyle],
                      attrs: {
                        "text-color": "#a9b5c6",
                        "active-text-color": "#fff",
                        "unique-opened": true,
                        "collapse-transition": "",
                      },
                      on: { open: _vm.handleOpen, close: _vm.handleClose },
                    },
                    [
                      _vm._l(_vm.leftChildList, function (item) {
                        return [
                          _c(
                            "el-menu-item",
                            {
                              attrs: {
                                index: item.index,
                                activeId: _vm.activeId,
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.clickMenuItem(item)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c("en-icon", {
                                    staticClass: "en-icon",
                                    attrs: { name: item.icon, size: "16" },
                                  }),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "en-icon-title",
                                      attrs: { slot: "title" },
                                      slot: "title",
                                    },
                                    [_vm._v(_vm._s(item.name))]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "home-content",
              style: { height: _vm.containerHeight },
            },
            [
              _c(
                "div",
                { staticClass: "menu-content", style: _vm.navStyle },
                [
                  _vm.pageLayout === 0 && _vm.themeInfo.navStyle !== 3
                    ? _c("left-nav-menu", {
                        attrs: {
                          navStyles: _vm.themeInfo.navStyle,
                          activeId: _vm.themeInfo.themeStyle,
                        },
                        on: { menuClick: _vm.handleMenuClick },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "right-area",
                  style: { width: _vm.containerWidth },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "router-show-area",
                      style: {
                        ..._vm.containerStyle,
                        padding: _vm.pageLayout === 2 ? "0" : "8px",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "loading-container",
                          style: {
                            borderRadius: _vm.pageLayout === 2 ? "0" : "5px",
                          },
                        },
                        [
                          _vm.themeInfo.openIndexTab &&
                          _vm.themeInfo.openRouterTab &&
                          _vm.editableTabs.length > 0 &&
                          !_vm.isHide
                            ? _c(
                                "el-tabs",
                                {
                                  attrs: {
                                    "tab-size": "8",
                                    type: "card",
                                    closable: "",
                                  },
                                  on: {
                                    "tab-click": _vm.handleTabClick,
                                    "tab-remove": _vm.removeTab,
                                  },
                                  model: {
                                    value: _vm.editableTabsValue,
                                    callback: function ($$v) {
                                      _vm.editableTabsValue = $$v
                                    },
                                    expression: "editableTabsValue",
                                  },
                                },
                                _vm._l(_vm.editableTabs, function (item) {
                                  return _c(
                                    "el-tab-pane",
                                    {
                                      key: item.name,
                                      attrs: {
                                        name: item.name,
                                        title: item.title,
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "item-tab",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [
                                          item.color.length
                                            ? _c("en-icon", {
                                                staticStyle: {
                                                  "margin-right": "2px",
                                                },
                                                style: { color: item.color },
                                                attrs: {
                                                  name: item.icon,
                                                  size: "15px",
                                                },
                                              })
                                            : _c(
                                                "div",
                                                {
                                                  staticClass: "icon-box",
                                                  style: { ...item.color },
                                                },
                                                [
                                                  _c("en-icon", {
                                                    staticStyle: {
                                                      "margin-right": "2px",
                                                    },
                                                    style: { color: "#fff" },
                                                    attrs: {
                                                      name: item.icon,
                                                      size: "15px",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.title.length > 10
                                                  ? item.title.slice(0, 9) +
                                                      "..."
                                                  : item.title
                                              )
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                }),
                                1
                              )
                            : _vm._e(),
                          _c(
                            "keep-alive",
                            [
                              _vm.$route.meta.keepAlive
                                ? _c("router-view", {
                                    staticClass: "full-page",
                                    on: { updateTabs: _vm.updateTabs },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          !_vm.$route.meta.keepAlive
                            ? _c("router-view", {
                                key: _vm.$route.fullPath,
                                staticClass: "full-page",
                                on: { updateTabs: _vm.updateTabs },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }