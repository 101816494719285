/*
 * @Author: pengyu
 * @Date: 2021-02-02 16:06:55
 * @LastEditors: pengyu
 * @LastEditTime: 2021-02-02 16:10:15
 * @Description: 查询全局变量
 */
import BaseService from "../BaseService";

class GlobalVariable extends BaseService {
  pk = ""

  constructor(http) {
    super("", http);
  }

  getVariable(params) {
    const { http } = this;
    return http.post("/queryRbacGloableVariableList", params);
  }
}

export default GlobalVariable;
