/*
 * @Author: pengyu
 * @Date: 2021-02-25 17:30:02
 * @LastEditors: pengyu
 * @LastEditTime: 2021-02-25 17:32:51
 * @Description: file content
 */
// 该模块主要用于存放异步action,一般来说不推荐使用,尽可能使用mutations完成数据修改

export default {
  LoginByUsername({ commit }, userInfo) {
    const username = userInfo.username.trim();
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line no-undef
      loginByUsername(username, userInfo.password).then((response) => {
        const data = response.data;
        commit("_MxChangeVuexState", { _token: data.token });
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  }
};
