/*
 * @Author: zhulin
 * @Date: 2020-06-16 17:37:45
 * @LastEditors: zhulin
 * @LastEditTime: 2020-09-07 10:51:45
 * @Description: file content
 */
import BaseService from "../BaseService";

/**
 * 主题日志视图相关
 */
class CalendarListService extends BaseService {
  pk = "";

  constructor(http) {
    super("", http);
  }

  /**
   * @description  查询视图
   * @param params
   * @returns {*}
   */
  queryTotalPicList(params) {
    const { http } = this;
    return http.post("mdQueryTotalPicList", params);
  }

  /**
   * @description  查询视图
   * @param params
   * @returns {*}
   */
  queryUserViewList(params) {
    const { http } = this;
    return http.post("ptQueryUserViewList", params);
  }

  /**
   * @description  查询日历视图配置
   * @param params
   * @returns {*}
   */
  queryCalendarConfig(params) {
    const { http } = this;
    return http.post("ptCalendarConfig", params);
  }

  /**
   * @description  查询日历视图数据
   * @param params
   * @returns {*}
   */
  queryCalendarList(params) {
    const { http } = this;
    return http.post("ptCalendarList", params);
  }

  /**
   * @description  删除
   * @param params
   * @returns {*}
   */
  delCalendarData(params) {
    const { http } = this;
    return http.post("ptOut", params);
  }

  /**
   * @description  完成
   * @param params
   * @returns {*}
   */
  finishCalendarData(params) {
    const { http } = this;
    return http.post("ptFinish", params);
  }

  /**
   * @description  私密
   * @param params
   * @returns {*}
   */
  secretCalendarData(params) {
    const { http } = this;
    return http.post("ptSecret", params);
  }

  /**
   * @description  查询分享用户
   * @param params
   * @returns {*}
   */
  queryShareUser(params) {
    const { http } = this;
    return http.post("ptQueryShareUser", params);
  }

  /**
   * @description  查询常用用户
   * @param params
   * @returns {*}
   */
  queryFrequent(params) {
    const { http } = this;
    return http.post("queryFrequent", params);
  }
}

export default CalendarListService;
