var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "menu-container", style: [_vm.navStyle] },
    [
      _vm.themeInfo.SubmenuStyles == 1 || _vm.themeInfo.SubmenuStyles == 2
        ? _c(
            "en-menu",
            {
              key: _vm.updateKey,
              staticClass: "menu",
              class: { collapse: _vm.isCollapse },
              style: [_vm.navBorderStyle, _vm.subNavStyle],
              attrs: {
                "default-active": _vm.activeIndex,
                "text-color": "#a9b5c6",
                "active-text-color": "#fff",
                "unique-opened": true,
                "collapse-transition": "",
                collapse: _vm.isCollapse,
                mode: "horizontal",
              },
              on: { open: _vm.handleOpen, close: _vm.handleClose },
            },
            [
              _vm._l(_vm.menuList, function (item) {
                return [
                  item.children && item.children.length > 0
                    ? _c(
                        "en-submenu",
                        {
                          staticClass: "en-submenu",
                          attrs: {
                            classList: item.data.classifyList,
                            menus: item.children,
                            activeId: _vm.activeId,
                            index: item.index,
                          },
                          nativeOn: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.clickMenuItem(item)
                            },
                          },
                        },
                        [
                          _c(
                            "template",
                            { slot: "title" },
                            [
                              _c("en-icon", {
                                staticClass: "en-icon",
                                attrs: {
                                  name: _vm.setChildrenIcon(item.icon),
                                  size: "16",
                                },
                              }),
                              _vm.isCollapse
                                ? _c("i", {
                                    staticClass: "el-icon-arrow-right icon",
                                  })
                                : _vm._e(),
                              _c("span", { staticClass: "en-icon-title" }, [
                                _vm._v(_vm._s(item.name)),
                              ]),
                            ],
                            1
                          ),
                          _vm._l(item.children, function (subItem) {
                            return _c(
                              "div",
                              { key: subItem.index, staticClass: "submenu" },
                              [
                                subItem.children && subItem.children.length > 0
                                  ? _c(
                                      "en-submenu",
                                      {
                                        attrs: {
                                          activeId: _vm.activeId,
                                          menus: subItem.children,
                                          index: subItem.index,
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.clickMenuItem(subItem)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "template",
                                          { slot: "title" },
                                          [
                                            _c("en-icon", {
                                              staticClass: "en-icon",
                                              attrs: {
                                                name: _vm.setChildrenIcon(
                                                  subItem.icon
                                                ),
                                                size: "16",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "en-icon-title" },
                                              [_vm._v(_vm._s(subItem.name))]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._l(
                                          subItem.children,
                                          function (lastItem) {
                                            return _c(
                                              "en-menu-item",
                                              {
                                                key: lastItem.index,
                                                attrs: {
                                                  activeId: _vm.activeId,
                                                  index: lastItem.index,
                                                },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.clickMenuItem(
                                                      lastItem
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("en-icon", {
                                                  staticClass: "en-icon",
                                                  attrs: {
                                                    name: _vm.setChildrenIcon(
                                                      lastItem.icon
                                                    ),
                                                    size: "16",
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "en-icon-title",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(lastItem.name)
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    )
                                  : _c(
                                      "en-menu-item",
                                      {
                                        attrs: {
                                          index: subItem.index,
                                          activeId: _vm.activeId,
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.clickMenuItem(subItem)
                                          },
                                        },
                                      },
                                      [
                                        _c("en-icon", {
                                          staticClass: "en-icon",
                                          attrs: {
                                            name: _vm.setChildrenIcon(
                                              subItem.icon
                                            ),
                                            size: "16",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          { staticClass: "en-icon-title" },
                                          [_vm._v(_vm._s(subItem.name))]
                                        ),
                                      ],
                                      1
                                    ),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      )
                    : _c(
                        "en-menu-item",
                        {
                          attrs: { index: item.index, activeId: _vm.activeId },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.clickMenuItem(item)
                            },
                          },
                        },
                        [
                          _c("en-icon", {
                            staticClass: "en-icon",
                            attrs: { name: item.icon, size: "16" },
                          }),
                          _c(
                            "span",
                            {
                              staticClass: "en-icon-title",
                              attrs: { slot: "title" },
                              slot: "title",
                            },
                            [_vm._v(_vm._s(item.name))]
                          ),
                        ],
                        1
                      ),
                ]
              }),
            ],
            2
          )
        : _c(
            "en-menu",
            {
              key: _vm.updateKey,
              staticClass: "menu",
              class: { collapse: _vm.isCollapse },
              style: [_vm.navBorderStyle, _vm.subNavStyle],
              attrs: {
                "default-active": _vm.activeIndex,
                "text-color": "#a9b5c6",
                "active-text-color": "#fff",
                "unique-opened": true,
                "collapse-transition": "",
                collapse: _vm.isCollapse,
                mode: "horizontal",
              },
              on: { open: _vm.handleOpen, close: _vm.handleClose },
            },
            [
              _vm._l(_vm.menuList, function (item) {
                return [
                  _c(
                    "en-menu-item",
                    {
                      attrs: { index: item.index, activeId: _vm.activeId },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.leftChild(item)
                        },
                      },
                    },
                    [
                      _c("en-icon", {
                        staticClass: "en-icon",
                        attrs: { name: item.icon, size: "16" },
                      }),
                      _c(
                        "span",
                        {
                          staticClass: "en-icon-title",
                          attrs: { slot: "title" },
                          slot: "title",
                        },
                        [_vm._v(_vm._s(item.name))]
                      ),
                    ],
                    1
                  ),
                ]
              }),
            ],
            2
          ),
      _c(
        "div",
        {
          staticClass: "custom-homepage",
          style: _vm.navBgStyle,
          on: { click: _vm.gotoCustomHomePage },
        },
        [
          _c("i", { staticClass: "el-icon-setting icon" }),
          !_vm.isCollapse ? _c("span", { staticClass: "desc" }) : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }