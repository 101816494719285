var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "select-group",
      class: _vm.hasChildren ? "has-item-children" : "",
    },
    [
      _vm.hasChildren
        ? [
            _c(
              "div",
              { staticClass: "item selected", on: { click: _vm.openHandle } },
              [
                _c("div", { staticClass: "label" }, [
                  _vm._v(" " + _vm._s(_vm.data.name) + " "),
                ]),
                _c("en-icon", {
                  class: _vm.open ? "arrowTransformReturn" : "arrowTransform",
                  attrs: { name: "xiala" },
                }),
              ],
              1
            ),
            _vm.open
              ? _vm._l(_vm.children, function (node, index) {
                  return _c("selectItem", {
                    key: index,
                    attrs: {
                      data: node,
                      "is-children": true,
                      selected: _vm.selectedDeptId === node.id,
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.click(node)
                      },
                    },
                  })
                })
              : _vm._e(),
          ]
        : [
            _c("selectItem", {
              attrs: {
                data: _vm.data,
                selected: _vm.selectedId === _vm.data.id,
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.click(_vm.data)
                },
              },
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }