import TripService from "./TripService";
import HotelService from "./HotelService";
import CarService from "./car";
import OrderService from "./orderService";
import FlightService from "./FlightService";
import ContactService from "./ContactService";

export const tripService = new TripService();
export const hotelService = new HotelService();
export const carService = new CarService();
export const orderService = new OrderService();
export const flightService = new FlightService();
export const contactService = new ContactService();
