import BaseService from "../BaseService";

class PayRollVerify extends BaseService {
  constructor(http) {
    super("", http);
  }

  /**
   * 新设置(重新设置)工资条的密码
   * @param {设置的密码} value
   */
  paySlipsPassword(params) {
    const { http } = this;
    return http.post("paySlipsPassword", params);
  }

  /**
   * 校验工资条密码
   * @param {密码} value
   */
  checkPaySlip(params) {
    const { http } = this;
    return http.post("checkPaySlip", params);
  }

  /**
   * 工资条锁定
   * @param {工资锁定id} value
   */
  updatePaySlip(value) {
    const { http } = this;
    const status = { lockStatus: 1 };
    return http.post("updatePaySlip", { id: value, updateData: JSON.stringify(status) });
  }

  /**
   * 查询用户工资条-年份月份
   * @param {0:管理工资条1我的工资条} value
   */
  queryPaySlipsYearList(value) {
    const { http } = this;
    return http.post("queryPaySlipsYearList", { type: value });
  }

  /**
   * 获得用户工资条详情
   * @param {模板id} id
   */
  queryPaySlipList(id, sortField, filter, type) {
    const { http } = this;
    let conditionFields = [{ nameVariable: "templateId", value: id }];
    if (filter && filter.length) {
      conditionFields = conditionFields.concat(filter);
    }
    return http.post("queryPaySlipList", {
            templateId: id, type, conditionFields: JSON.stringify(conditionFields), sortField
          });
  }

  /**
   * 获得用户工资条详情表头数据
   * @param {模板id} id
   */
  queryPaySlipsColList(id) {
    const { http } = this;
    return http.post("queryPaySlipsColList", { templateId: id });
  }

  /**
   * 保存工资条
   * @param {工资条id} pid
   * @param {模板id} tid
   * @param {上传的手签图片} signPicUrl
   */
  savePaySlip(pid, tid, signPicUrl) {
    const { http } = this;
    const map = { signUrl: signPicUrl, status: 5 };
    return http.post("updatePaySlip", { id: pid, templateId: tid, updateData: JSON.stringify(map) });
  }

  /**
   * 获得用户工资条详情
   * @param {工资条id} mId
   */
  queryPaySlipsDetail(mId) {
    const { http } = this;
    return http.post("queryPaySlipsDetail", { id: mId });
  }

  /**
   * 忘记密码
   * @param {手机号码} value
   */
  sendCode(value) {
    const { http } = this;
    return http.post("sendValidateCode", { mobileNo: value });
  }

  /**
   * 验证，验证码
   * @param {电话号码} mobile
   * @param {验证码} code
   */
  checkCode(mobile, code) {
    const { http } = this;
    return http.post("checkValidateCode", { validateCode: code, mobileNo: mobile });
  }

  /**
   *校验工资条
   */
  importPaySlipsCheck(refInfoD, colD, valD, countD) {
    const { http } = this;
    return http.post("importPaySlipsCheck", {
      refInfo: JSON.stringify(refInfoD),
      col: JSON.stringify(colD),
      val: JSON.stringify(valD),
      count: countD
    });
  }

  /**
   * @description: 改变工资条状态
   * @param {*} param
   * @return {*}
   */
  paySlipBtn(param) {
    const { http } = this;
    return http.post("paySlipBtn", param);
  }
  // 添加工资条获取 通知模型

  imQueryModel(param) {
    const { http } = this;
    return http.post("imQueryModel", param);
  }

  /**
   *校验工资条
   */
  addPaySlips(param) {
    const { http } = this;
    return http.post("addPaySlips", param);
  }

  // 导出工资条
  exportPaySlips(param) {
    const { http } = this;
    return http.post("exportPaySlips", { ...param, requestType: "stream" }, {
      responseType: "arraybuffer"
    });
  }

  /**
   * @description: 工资条表格数据
   * @param {*} param
   * @return {*}
   */
  queryPaySlipTotal(param) {
    const { http } = this;
    return http.post("queryPaySlipTotal", param);
  }

  /**
   * @description: 保存工资条
   * @param {*} param
   * @return {*}
   */
  updateEditPaySlip(param) {
    const { http } = this;
    return http.post("updatePaySlip", param);
  }
}
export default PayRollVerify;
