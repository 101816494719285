/*
 * @Author: pengyu
 * @Date: 2020-07-15 10:34:01
 * @LastEditors: pengyu
 * @LastEditTime: 2020-07-15 10:41:25
 * @Description: 考勤模板所需接口
 */

import BaseService from "../BaseService";

class AttendanceModel extends BaseService {
  pk = ""

  constructor(http) {
    super("", http);
  }

  /**
 * @description: 查询凭证生成列表
 * @param params
 */
  calculateTime(params) {
    const { http } = this;
    return http.post("attCalculateTime", params);
  }
}

export default AttendanceModel;
