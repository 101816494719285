/*
 * @Author: zoujp
 * @Date: 2020-06-19 14:07:13
 * @LastEditTime: 2020-06-19 14:55:28
 * @LastEditors: Please set LastEditors
 * @Description: 公司圈接口入口
 * @FilePath: \user\src\api\companyCircle\index.js
 */
import CompanyCircleService from "./CompanyCircleService";

export const companyCircleService = new CompanyCircleService();
