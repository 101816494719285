/*
 * @Author: zhulin
 * @Date: 2020-06-16 17:37:45
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-15 16:07:56
 * @Description: file content
 */
import BaseService from "../BaseService";

/**
 * 主题列表视图相关
 */
class DefaultListService extends BaseService {
  pk = "";

  constructor(http) {
    super("", http);
  }

  /**
   * @description  查询页签
   * @param params
   * @returns {*}
   */
  queryTabs(params) {
    const { http } = this;
    return http.post("ptUserQueryTabs", params);
  }

  /**
   * @description  查询视图配置
   * @param params
   * @returns {*}
   */
  queryListCol(params) {
    const { http } = this;
    return http.post("ptListCol", params);
  }

  /**
   * @description  查询视图配置
   * @param params
   * @returns {*}
   */
  newqueryListCol(params) {
    const { http } = this;
    return http.post("mdListCol", params);
  }

  /**
   * @description  查询视图数据
   * @param params
   * @returns {*}
   */
  queryDetailResult(params) {
    const { http } = this;
    return http.post("ptQueryDetailResult", params);
  }

  /**
   * @description  查询全局变量
   * @param params
   * @returns {*}
   */
  queryRbacGloableVariableList(params) {
    const { http } = this;
    return http.post("queryRbacGloableVariableList", params);
  }

  /**
   * @description: 删除主题
   * @param {*} params
   * @return {*}
   */
  ptOut(params) {
    const { http } = this;
    return http.post("ptOut", params);
  }

  /**
   * @description: 完成/取消完成
   * @param {*} params
   * @return {*}
   */
  ptFinish(params) {
    const { http } = this;
    return http.post("ptFinish", params);
  }

  /**
   * @description: 私密/取消私密
   * @param {*} params
   * @return {*}
   */
  ptSecret(params) {
    const { http } = this;
    return http.post("ptSecret", params);
  }

  /**
   * @description: 获取共享组列表数据集合
   * @param {*} params
   * @return {*}
   */
  ptQueryUserSharePower(params) {
    const { http } = this;
    return http.post("ptQueryUserSharePower", params);
  }

  /**
   * @description: 共享组停用启用
   * @param {*} params
   * @return {*}
   */
  ptEnableUserSharePower(params) {
    const { http } = this;
    return http.post("ptEnableUserSharePower", params);
  }

  /**
   * @description: 共享组删除
   * @param {*} params
   * @return {*}
   */
  ptDeleteUserSharePower(params) {
    const { http } = this;
    return http.post("ptDeleteUserSharePower", params);
  }

  /**
   * @description: 共享组数据范围
   * @param {*} params
   * @return {*}
   */
  ptQuerySource(params) {
    const { http } = this;
    return http.post("ptQuerySource", params);
  }

  /**
   * @description: 共享组分享类型
   * @param {*} params
   * @return {*}
   */
  ptQueryShareType(params) {
    const { http } = this;
    return http.post("ptQueryShareType", params);
  }

  /**
   * @description: 共享组保存
   * @param {*} params
   * @return {*}
   */
  ptSaveUserSharePower(params) {
    const { http } = this;
    return http.post("ptSaveUserSharePower", params);
  }

  /**
   * @description  对比视图获取字段列表
   * @param params
   * @returns {*}
   */
  ptFieldList(params) {
    const { http } = this;
    return http.post("ptFieldList", params);
  }

  /**
   * @description  获取默认的对比条件，获取默认的过滤条件
   * @param params
   * @returns {*}
   */
  queryDefaults(params) {
    const { http } = this;
    return http.post("queryDefaults", params);
  }

  /**
   * @description  获取对比内容
   * @param params
   * @returns {*}
   */
  ptCompareList(params) {
    const { http } = this;
    return http.post("ptCompareList", params);
  }

  /**
   * @description  设置默认条件
   * @param params
   * @returns {*}
   */
  setDefaults(params) {
    const { http } = this;
    return http.post("saveDefaults", params);
  }
}

export default DefaultListService;
