<template>
  <div class="set-password-dialog">

  <!-- <el-dialog class="set-password-dialog" title="" :visible.sync="showSetPassword" width="700px" :before-close="handleClose" :close-on-click-modal="false"> -->
    <div  class="header-title">
      <span class="prompt-title">设置工资条密码</span>
    </div>
    <div class="dialog_content">
      <el-form label-width="70px" :rules="rules" ref="ruleForm" :model="form">
        <div class="pwd_input">
          <el-form-item label="新密码" prop="newPwd">
            <el-input v-model="form.newPwd" placeholder="请输入密码" :type="inputtype"> </el-input>
            <en-icon :name="iconImg" size="small" style="color:#A8B6C6" @click.native="changeState"> </en-icon>
          </el-form-item>
        </div>
        <div class="pwd_input">
          <el-form-item label="确认密码" prop="confirmNewPwd">
            <el-input v-model="form.confirmNewPwd" placeholder="请输入密码" :type="inputtype"> </el-input>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <span  class="dialog-footer">
      <en-button type="primary" @click="confirm('ruleForm')">确 定</en-button>
    </span>
  <!-- </el-dialog> -->
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { payRollApi } from "@/api/payRoll";

export default {
  name: "SetPassword",
  props: ["showSetPassword"],
  data() {
    const newPwd = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else if (/^([a-zA-Z0-9\u4E00-\u9FA5\uf900-\ufa2d]){8,20}$/.test(this.form.newPwd)) {
        callback();
      } else {
        callback(new Error("密码由8—20位英文字母、数字组成！"));
      }
    };
    const confirmNewPwd = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入确认密码"));
      } else if (value !== this.form.newPwd) {
        callback(new Error("两次输入密码不一致!"));
      } else if (/^([a-zA-Z0-9\u4E00-\u9FA5\uf900-\ufa2d]){8,20}$/.test(this.form.newPwd)) {
        callback();
      } else {
        callback(new Error("密码由8—20位英文字母、数字组成！"));
      }
    };
    return {
      inputtype: "text",
      iconImg: "kai-denglu",
      input: "",
      rules: {
        newPwd: [
          {
            required: true,
            validator: newPwd,
            trigger: "blur"
          }
        ],
        confirmNewPwd: [
          {
            required: true,
            validator: confirmNewPwd,
            trigger: "blur"
          }
        ]
      },
      form: {
        newPwd: "",
        confirmNewPwd: ""
      }
    };
  },
  computed: {},
  methods: {
    ...mapMutations(["setUserInfoByKey"]),
    handleClose() {
      this.$emit("closeSetPassword");
    },
    confirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.setPassword();
        } else {
          console.log("error submit!!");
        }
      });
    },
    async setPassword() {
      await payRollApi.paySlipsPassword({ checkValue: this.form.newPwd });
      this.$emit("closeSetPassword", true);
      this.setUserInfoByKey({ key: "isPayPassword", value: "1" });
      this.$message({
        showClose: false,
        message: "密码设置成功，请重新输入密码登录",
        type: "success"
      });
    },
    changeState() {
      if (this.iconImg === "bi-denglu") {
        this.iconImg = "kai-denglu";
        this.inputtype = "text";
      } else {
        this.iconImg = "bi-denglu";
        this.inputtype = "password";
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.set-password-dialog  {
  text-align: left;
  padding:0 20px;
}
.el-form-item__error {
  line-height: 30px !important;
  z-index: 101;
}
</style>
<style lang="scss" scoped>
.dialog_content {
  overflow: hidden;
  padding-top: 20px;
}
.header-title {
  text-align: left;
  margin-top: 5px;
  .prompt-title {
    font-size: 16px;
  }
  .prompt-info {
    color: #a9b5c6;
  }
}
.pwd_input {
  position: relative;
  span {
    display: inline-block;
    width: 60px;
    text-align: right;
  }
  .el-input {
    width:100%;
   /deep/ .el-input__inner{
      width: 100%!important;
    }
  }
  .en-icon {
    cursor: pointer;
    position: absolute;
    top: 7px;
    right: 7px;
  }
}
// .pwd_input:nth-child(2) {
//   margin-bottom: 100px;
// }
.forgot_pwd {
  text-decoration: none;
  color: #3e90fe;
  float: right;
  margin: 20px 0 20px 0;
  overflow: hidden;
}
.dialog-footer{
  display: inline-block;
  width: 100%;
  text-align: right;
}
</style>
