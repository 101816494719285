/*
 * @Author: zhangjiaheng
 * @Date: 2020-08-06 16:47:39
 * @LastEditors: zhangjiaheng
 * @LastEditTime: 2020-09-23 11:19:02
 * @Description: 自定义资源api
 * @FilePath: \user\src\api\businessModel\task.js
 */
import BaseService from "../BaseService";

class Task extends BaseService {
  pk = "";

  constructor(http) {
    super("", http);
  }

  /**
   * 查询查询分组条件
   * @param params 查询参数
   * @returns {*}
   */
  mdSearchFieldList(params) {
    return this.http.post("mdSearchFieldList", params);
  }

  /**
   * 查询查询分组数据
   * @param params 查询参数
   * @returns {*}
   */
  mdTaskGroupColList(params) {
    return this.http.post("mdTaskGroupColList", params);
  }

  /**
   * 查询分组数据详细数据
   * @param params 查询参数
   * @returns {*}
   */
  mdTaskGroupDataList(params) {
    return this.http.post("mdTaskGroupDataList", params);
  }

  /**
   * 标记完成 或者未完成
   * @param params 查询参数
   * @returns {*}
   */
  mdTaskInfoEdit(params) {
    return this.http.post("mdTaskInfoEdit", params);
  }

  /**
   * 排序接口
   * @param params 查询参数
   * @returns {*}
   */
  mdCustomGroupSort(params) {
    return this.http.post("mdCustomGroupSort", params);
  }

  /**
   * 自定义分组任务 移动到别的分组
   * @param params 查询参数
   * @returns {*}
   */
  mdMoveCustomGroupData(params) {
    return this.http.post("mdMoveCustomGroupData", params);
  }

  /**
   * 自定义分组 新增分组
   * @param params 查询参数
   * @returns {*}
   */
  mdAddCustomGroup(params) {
    return this.http.post("mdAddCustomGroup", params);
  }

  /**
   * 自定义分组 修改分组
   * @param params 查询参数
   * @returns {*}
   */
  mdEditCustomGroup(params) {
    return this.http.post("mdEditCustomGroup", params);
  }

  /**
   * 自定义分组任务 删除分组
   * @param params 查询参数
   * @returns {*}
   */
  mdDeleteCustomGroup(params) {
    return this.http.post("mdDeleteCustomGroup", params);
  }
}

export default Task;
