import BaseService from "../BaseService";

class ReportDetailService extends BaseService {
  constructor(http) {
    super("", http);
  }

  queryConfig(params) {
    const { http } = this;
    return http.post("queryReportConfig", params);
  }

  queryTotal(params) {
    return this.http.post("queryDetailReportResult", params);
  }

  /**
   * 查询全局变量
   * @param params 参数
   * @returns {*}
   */
  queryRbacGloableVariableList(param) {
    const { http } = this;
    return http.post("queryRbacGloableVariableList", param);
  }

  queryControlDetailCol(params) {
    return this.http.post("queryControlDetailCol", params);
  }

  queryControlDetail(params) {
    return this.http.post("queryControlDetail", params);
  }

  /**
   * @description 报表管理-查询报表明细列表
   * @param {*} params
   */
  queryDetailReportResult(params = {}) {
    return this.http.post("queryDetailReportResult", params);
  }

  /**
   * @description 报表管理-查询报表明细配置
   * @param {*} params
   */
  queryReportConfig(params = {}) {
    return this.http.post("queryReportConfig", params);
  }

  // 查询汇总报表list 表头字段
  queryReportTotalList(params) {
    return this.http.post("queryReportTotalList", params);
  }

  // 查询汇总报表list 表格list
  queryReportTotalDataPage(params) {
    return this.http.post("queryReportTotalDataPage", params);
  }

  // 保存自定义表头
  saveUserReportBehavior(params) {
    return this.http.post("saveUserReportBehavior", params);
  }

  // 导出报表 默认
  exportData(params) {
    return this.http.post("/expReportFormField", params, { responseType: "arraybuffer" });
  }

  // 导出报表
  expReportTotal(params) {
    return this.http.post("/expReportTotal", params, { responseType: "arraybuffer" });
  }

  // 查询关联数据表头 type 17
  queryMdTemplateRelationCol(params) {
    return this.http.post("queryMdTemplateRelationCol", params);
  }

  // 查询关联数据内容 type 17
  mdSearchFieldDataInfoList(params) {
    return this.http.post("mdSearchFieldDataInfoList", params);
  }

  // 查询关联数据表头
  queryModelTemplateDetailCol(params) {
    return this.http.post("queryModelTemplateDetailCol", params);
  }

  // 查询关联数据内容
  queryModelTemplateDetail(params) {
    return this.http.post("queryModelTemplateDetail", params);
  }
}

export default ReportDetailService;
