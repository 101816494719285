<!--
 * @Author: liyu
 * @Date: 2020/7/20
 * @Description: 顶部导航切换面板，代码来源来admin端
 -->
<template>
  <!--  <el-collapse-transition>-->
  <div id="switch-role" @click.self="closeModelFn">
    <div class="wrap">
      <div class="lt">
        <!-- <en-tabs v-model="activeTab" :list="tabList" type="white" @change="changeTabs"> </en-tabs> -->
        <div class="switch-user">切换用户</div>
        <!-- 用户端 -->
        <div v-en-loading="roleList.length > 0 ? false : true" v-if="activeTab === 'user'" class="choose-area">
          <selectGroup v-for="(data, index) in roleList" :data="data" :selected-id="selectRoleId" :selected-dept-id="selectedDeptId" @change="switchDepartment" :key="index"></selectGroup>
        </div>
        <!-- 管理端 -->
        <!-- <div v-if="activeTab === 'manager'" class="choose-area">
          <selectItem v-for="(company, index) in managerList" :data="company" :key="index" :selected="selectCompany === company.id" @click.native="selectManager(company.id)"> </selectItem>
        </div> -->
      </div>
      <div class="rt">
        <div class="line" @click="onBasicSetting">
          <en-icon class="setting" name="iconshezhi" size="16"> </en-icon>
          个人设置
        </div>
        <div class="line" @click="onRecharge">
          <en-icon class="setting" name="iconbus-btn26_1" size="16"> </en-icon>
          充值中心
        </div>
        <div class="line" @click="onLogout">
          <en-icon class="log" name="iconyunhang" size="16"> </en-icon>
          退出登录
        </div>
      </div>
    </div>
  </div>
  <!--  </el-collapse-transition>-->
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import { switchService } from "@/api/setInfo";
import API from "@/api/homeMenu";

// import selectItem from "./select-item";
import selectGroup from "./select-goup";

export default {
  name: "SwitchRoleModel",
  components: {
    // selectItem,
    selectGroup
  },
  data() {
    return {
      tabList: [
        {
          id: "user",
          name: "切换用户端"
        }
        // , {
        //   id: "manager",
        //   name: "切换管理端"
        // }
      ],
      activeTab: "user",
      selectCompany: 1,
      managerList: [],
      selectRole: [],
      roleList: [],
      selectRoleId: "",
      selectedDeptId: ""
    };
  },
  mounted() {
    const userInfo = this.$store.getters.userInfo;
    const us = localStorage.getItem("USERINFO");
    const usobj = JSON.parse(us);
    this.selectRoleId = usobj.tenantId;
    this.selectedDeptId = userInfo.depatementId;
    if (userInfo.adminTenantList?.length > 0) {
      this.tabList = [
        {
          id: "user",
          name: "切换用户端"
        }
        // {
        //   id: "manager",
        //   name: "切换管理端"
        // }
      ];
      const adminTenantList = [];
      userInfo.adminTenantList.forEach((item) => {
        const data = { ...item };
        data.id = data.tenantId;
        adminTenantList.push(data);
      });
      this.managerList = adminTenantList;
    } else {
      this.tabList = [
        {
          id: "user",
          name: "切换用户端"
        }
      ];
    }
    switchService
      .queryUserTenantAndDeptInfo()
      .then((reponse) => {
        this.roleList = reponse;
      })
      .catch(() => {});
  },
  methods: {
    ...mapActions(["logout", "updateUserInfo"]),
    ...mapMutations("main", ["setMenuData"]),
    switchDepartment(item) {
      switchService
        .switchUserInfoByAccAndTenantId(item)
        .then((reponse) => {
          reponse.appSessionId = this.$store.getters.userInfo.appSessionId;
          this.updateUserInfo(reponse);
          this.selectRoleId = reponse.tenantId;
          localStorage.setItem("tenantId", reponse.tenantId || "");
          localStorage.setItem("USERINFO", JSON.stringify(reponse));
          this.selectedDeptId = reponse.depatementId;
          this.$emit("change", reponse);
          this.closeModelFn();
          this.updateMenuData();
        })
        .catch(() => {});
    },
    async updateMenuData() {
      const menuData = await API.getMenu();
      // this.$router.replace("/home/index");
      this.setMenuData(menuData);
        window.location.href = "/user/home/index";
    },
    changeTabs(e) {
      console.log(e);
    },
    onBasicSetting() {
      this.$router.push("/setting/basic");
      this.closeModelFn();
    },
    // selectManager(e) {
    // selectManager() {
    //   window.open("https://en.enfry.com", "_blank");
    //   // this.$store.state._permission = "admin";
    //   // this.selectCompany = e;
    //   this.closeModelFn();
    // },
    closeModelFn() {
      this.$emit("closeModelFn");
    },
    /**
     * 退出登录
     */
    onLogout() {
      try {
        this.logout();
        // 清空标签页
        sessionStorage.removeItem("tableTabs");
        window.open("/user/login", "_self");
      } catch (e) {
        console.warn(e);
      }
    },
    onRecharge() {
      this.$router.push("/recharge");
      this.closeModelFn();
    }
  }
};
</script>

<style lang="scss" scoped>
#switch-role {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(10, 10, 10, 0.3);
  z-index: 8888;
}

.wrap {
  position: absolute;
  top: 59px;
  right: 11px;
  background: #fff;
  width: 483px;
  height: 243px;
  border-radius: 5px;
  border: 1px solid rgba(232, 236, 242, 1);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);

  > .lt {
    width: 321px;
    height: 100%;
    float: left;
  }

  /deep/ .en-tabs-list {
    width: 100%;
    justify-content: space-between;
    height: 37px;
    .en-tabs-item {
      min-width: 50%;
      max-width: 50%;
      height: 37px;
      line-height: 37px;
      padding: 0 !important;
      text-align: center;
      span {
        display: block;
        margin: 0 auto;
      }
    }
  }

  > .rt {
    width: 160px;
    height: 100%;
    float: right;
    border-left: 1px #e8ecf2 solid;
  }

  .line {
    position: relative;
    height: 36px;
    line-height: 36px;
    font-size: 12px;
    font-weight: 400;
    color: rgba(99, 108, 120, 1);
    vertical-align: middle;
    padding: 0 20px;
    display: flex;
    align-items: center;

    &:hover {
      background: #f5f9fd;
      cursor: pointer;
    }
  }

  .en-icon {
    margin-right: 5px;
  }

  .setting {
    color: #3E90FE;
  }

  .log {
    color: #f77474;
  }

  .choose-area {
    width: 100%;
    height: 204px;
    padding: 20px 20px 0 20px;
    overflow-y: auto;
  }

  /deep/ .el-input {
    width: 280px;
    height: 36px;
    line-height: 36px;
    margin-bottom: 12px;
  }
}

.switch-user{
  line-height: 40px;
  text-align: center;
  border-bottom:1px solid rgb(232, 236, 242);
  color:#636C78;
}
</style>
