/*
 * @Author: 周晴龙
 * @Date: 2020-08-11 15:45:07
 * @LastEditors: 周晴龙
 * @LastEditTime: 2020-08-24 15:47:01
 * @FilePath: \user\src\api\businessTravel\orderService.js
 * @Description:
 */
import BaseService from "../BaseService";

/**
 * login相关
 */
class OrderService extends BaseService {
  pk = ""

  constructor(http) {
    super("", http);
  }

  /**
   * 查询酒店数据
   * @param params 参数
   */
  queryCarList(params) {
    // const { http } = this;
    console.log(params);
    return this.http.post("queryCarOrder", params);
  }
}

export default OrderService;
