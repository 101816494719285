<template>
  <div class="micro-menu-page-container">
    <div class="home-router">
      <top-nav-menu v-if="pageLayout<=1"></top-nav-menu>
      <top-menu v-if="pageLayout===0" />
      <div
        class="home-content"
        :style="{height:containerHeight}"
      >
        <div
          class="right-area"
          :style="{width:containerWidth}"
        >
          <div
            class="router-show-area"
            :style="{padding:pageLayout === 2?'0':'8px'}"
          >
            <div
              class="loading-container"
              :style="{borderRadius:pageLayout === 2?'0':'5px'}"
            >
              <keep-alive>
                <router-view v-if="$route.meta.keepAlive" class="full-page"></router-view>
              </keep-alive>
              <router-view v-if="!$route.meta.keepAlive" :key="$route.fullPath" class="full-page"></router-view>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import timewait from "timewait";
import topMenu from "@/components/top-menu";
import topNavMenu from "@/components/top-nav-menu";

export default {
  name: "MicroMenuPage",
  components: { topMenu, topNavMenu },
  props: {
    loading: Boolean, // 微服务的加载动画
    content: [String, Boolean], // 微服务的加载内容
    showMicro: Boolean, // 是否显示微服务模板
    pageLayout: Number
  },
  data() {
    return {
      loadingConfig: {
        type: "loading",
        subTitle: "微模块加载中,请稍等..."
      },
      loadingFlag: ""
    };
  },
  computed: {
    containerHeight() {
      // 0 显示菜单和导航; 1显示导航; 2显示全屏
      return this.pageLayout === 2 ? "100%" : "calc(100% - 61px)";
    },
    containerWidth() {
      return this.pageLayout ? "100%" : "calc(100% - 188px)";
    }
  },
  watch: {
    loading: {
      handler(val) { // 根据微服务的加载状态切换动画
        console.log("加载状态：", val);
        this.loadingConfig.type = val ? "loading" : null;
        if (val) {
          this.loadingFlag = new Date().getTime();
          this.countDown(this.loadingFlag);
        }
      }
    }
  },
  methods: {
    async countDown(flag) {
      await timewait(30000);
      if (this.loading && flag === this.loadingFlag) {
        this.loadingConfig.type = "BrokenNet";
        this.loadingConfig.subTitle = "网络好慢哟...";
      }
    }
  }
};
</script>
<style scoped lang="scss">
.micro-menu-page-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  .container {
    height: 100%;
    width: 100%;
  }
  .home-router {
    height: 100%;
    width: 100%;
    .home-content {
      height: calc(100% - 61px);
      display: flex;
      .menu-content {
        height: 100%;
        flex-grow: 0;
        vertical-align: top;
        padding-top: 10px;
        background-color: #232c3d;
        overflow: hidden;
      }
      .right-area {
        width: calc(100% - 188px);
        flex-grow: 1;
        height: 100%;
        .router-show-area {
          height: 100%;
          width: 100%;
          padding: 8px;
          background-color: #1d2532;
          overflow: hidden;
          .loading-container {
            overflow: hidden;
            border-radius: 5px;
            height: 100%;
            width: 100%;
            .full-page {
              position: relative;
              width: 100%;
              height: 100%;
            }
            .container {
              background: white;
              overflow: hidden;
              height: 100%;
              width: 100%;
              /*/deep/ {*/
              /*  & > div {*/
              /*    position: relative;*/
              /*    width: 100%;*/
              /*    height: 100%;*/
              /*  }*/
              /*}*/
            }
          }
        }
      }
    }
  }
}
</style>
