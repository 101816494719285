/**
 * @Author 李煜
 * @Date 2020/8/18
 * @Description action
 */
export default {
  setFlightInfo: ({ commit }, data) => {
    commit({
      type: "setFlightInfo",
      data
    });
  },
  setFlightType: ({ commit }, data) => {
    commit({
      type: "setFlightType",
      data
    });
  },
  setSingleData: ({ commit }, data) => {
    commit({
      type: "setSingleData",
      data
    });
  },
  setRoundData: ({ commit }, data) => {
    commit({
      type: "setRoundData",
      data
    });
  },
  setHotelInfo: ({ commit }, data) => {
    commit({
      type: "setHotelInfo",
      data
    });
  }
};
