// 基础设置路由
const routes = [
  {
    path: "/flight",
    name: "flightTravel",
    component: () => import(/* webpackChunkName: "business-travel-flight" */ "@/views/businessTravel/flight"),
    meta: {
      title: "机票预订",
      fullPage: false,
      keepAlive: false
    }
  },
  {
    path: "/flight/list",
    name: "flightList",
    component: () => import(/* webpackChunkName: "business-travel-flight" */ "@/views/businessTravel/flight/list"),
    meta: {
      title: "机票预订-列表界面",
      fullPage: false,
      keepAlive: false
    }
  },
  {
    path: "/flight/order",
    name: "flightOrder",
    component: () => import(/* webpackChunkName: "business-travel-flight" */ "@/views/businessTravel/flight/order"),
    meta: {
      title: "机票预订-订单界面",
      fullPage: false,
      keepAlive: false
    }
  }
];
export default routes;
