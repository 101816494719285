var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      attrs: { id: "switch-role" },
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.closeModelFn.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "wrap" }, [
        _c("div", { staticClass: "lt" }, [
          _c("div", { staticClass: "switch-user" }, [_vm._v("切换用户")]),
          _vm.activeTab === "user"
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "en-loading",
                      rawName: "v-en-loading",
                      value: _vm.roleList.length > 0 ? false : true,
                      expression: "roleList.length > 0 ? false : true",
                    },
                  ],
                  staticClass: "choose-area",
                },
                _vm._l(_vm.roleList, function (data, index) {
                  return _c("selectGroup", {
                    key: index,
                    attrs: {
                      data: data,
                      "selected-id": _vm.selectRoleId,
                      "selected-dept-id": _vm.selectedDeptId,
                    },
                    on: { change: _vm.switchDepartment },
                  })
                }),
                1
              )
            : _vm._e(),
        ]),
        _c("div", { staticClass: "rt" }, [
          _c(
            "div",
            { staticClass: "line", on: { click: _vm.onBasicSetting } },
            [
              _c("en-icon", {
                staticClass: "setting",
                attrs: { name: "iconshezhi", size: "16" },
              }),
              _vm._v(" 个人设置 "),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "line", on: { click: _vm.onRecharge } },
            [
              _c("en-icon", {
                staticClass: "setting",
                attrs: { name: "iconbus-btn26_1", size: "16" },
              }),
              _vm._v(" 充值中心 "),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "line", on: { click: _vm.onLogout } },
            [
              _c("en-icon", {
                staticClass: "log",
                attrs: { name: "iconyunhang", size: "16" },
              }),
              _vm._v(" 退出登录 "),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }