/*
 * @Author: zhulin
 * @Date: 2020-06-16 17:37:45
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-12-15 10:27:03
 * @Description: file content
 */
import BaseService from "../BaseService";

/**
 * 主题分组视图相关
 */
class GroupListService extends BaseService {
  pk = "";

  constructor(http) {
    super("", http);
  }

  /**
   * @description  查询分组视图配置
   * @param params
   * @returns {*}
   */
  queryGroupConfig(params) {
    const { http } = this;
    return http.post("ptGroupConfig", params);
  }

  /**
   * @description  查询分组视图数据
   * @param params
   * @returns {*}
   */
  queryGroupList(params) {
    const { http } = this;
    return http.post("ptGroupList", params);
  }

  /**
   * @description  查询分组内分页
   * @param params
   * @returns {*}
   */
  ptGroupDataList(params) {
    const { http } = this;
    return http.post("ptGroupDataList", params);
  }
}

export default GroupListService;
