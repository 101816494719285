var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "msgbox-fade" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.visible,
            expression: "visible",
          },
        ],
        staticClass: "el-message-box__wrapper message-box__wrapper",
        attrs: {
          tabindex: "-1",
          role: "dialog",
          "aria-modal": "true",
          "aria-label": _vm.title || "dialog",
        },
        on: {
          click: function ($event) {
            if ($event.target !== $event.currentTarget) return null
            return _vm.handleWrapperClick.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "div",
          {
            staticClass:
              "el-message-box el-message-box--center pop-password-wrap",
          },
          [
            _c("div", { staticClass: "el-message-box__header close-wrap" }, [
              _vm.showClose
                ? _c(
                    "button",
                    {
                      staticClass: "el-message-box__headerbtn",
                      attrs: { type: "button", "aria-label": "Close" },
                      on: {
                        click: function ($event) {
                          return _vm.handleAction("close")
                        },
                        keydown: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.handleAction("close")
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-message-box__close el-icon-close",
                      }),
                    ]
                  )
                : _vm._e(),
            ]),
            _vm.showPrompt
              ? _c("prompt", {
                  attrs: {
                    "show-prompt": _vm.showPrompt,
                    payRollId: _vm.payRollId,
                    userInfo: _vm.userInfo,
                  },
                  on: {
                    closePrompt: _vm.getClosePrompt,
                    promptCallBack: _vm.payRollVerfyCallBack,
                  },
                })
              : _vm._e(),
            _vm.showSetPassword
              ? _c("setPassword", {
                  attrs: {
                    "show-set-password": _vm.showSetPassword,
                    userInfo: _vm.userInfo,
                  },
                  on: { closeSetPassword: _vm.getCloseSetPassword },
                })
              : _vm._e(),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }