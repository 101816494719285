/*
 * @Description: 基础数据 & 业务建模的路由
 * @Author: 梁平贤
 * @Date: 2020-06-16 09:07:16
 * @LastEditors: wuqi
 * @LastEditTime: 2021-03-24 14:51:17
 */
import detail from "./detail";// 业务建模详情
import list from "./list";// 业务建模列表
import resource from "./resource";// 业务建模资源类型 资源视图 日历视图
import task from "./task";// 业务建模任务类型 分组视图 日历视图
import board from "./board";// 业务建模分类菜单
// 业务建模任务类型 分组视图 日历视图
const routes = [
  ...detail,
  ...list,
  ...resource,
  ...task,
  ...board
];

export default routes;
