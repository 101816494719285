/**
 * @Author 李煜
 * @Date 2020/6/5
 * @Description 需要持久化的key
 */
export default [
  "loginAccount",
  "userInfo",
  "urlParam",
  "businessTravel",
  "main",
  "chat",
  "menu",
  "businessModel.skyEyeBusinessData",
  "componyMeta",
  "intelligentAccounting.saMxSubjectlist"
];
