/*
 * @Author: pengyu
 * @Date: 2020-11-18 17:19:48
 * @LastEditors: pengyu
 * @LastEditTime: 2020-11-18 17:22:50
 * @Description: 现金流量-明细
 */
import BaseService from "../BaseService";

class CashFlowService extends BaseService {
  pk = ""

  constructor(http) {
    super("", http);
  }

  /**
   * @description: 查询现金流量明细表
   * @param {*} params
   */
  queryCashFlowDetail(params) {
    const { http } = this;
    return http.post("queryCashFlowDetail", params);
  }

  /**
   * @description: 修改现金流量项目
   * @param {*} params
   */
  modifyCashFlowDetail(params) {
    const { http } = this;
    return http.post("modifyCashFlowDetail", params);
  }
}

export default CashFlowService;
