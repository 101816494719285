const routes = [
  {
    path: "/payroll/promptHr",
    name: "promptHr",
    component: () => import(/* webpackChunkName:"payroll" */ "@/views/payRoll/hr/prompt/index"),
    meta: {
      title: "hr-提示",
      keepAlive: true,
      fullPage: false
    }
  }
];

export default routes;
