/*
 * @Author: czm
 * @Date: 2020-11-04 15:30:12
 * @LastEditors:
 * @LastEditTime:
 * @Description: 报表建模vuex
 */
export default {
  variableList: []
};
