/* eslint-disable */
import Vue from "vue";
import merge from "element-ui/src/utils/merge";
import { isVNode } from "element-ui/src/utils/vdom";
import msgboxVue from "./main.vue";

const defaults = {
  userInfo: {
    mobileNo: ""
  },
  uid: 1,
  title: undefined,
  message: "",
  customClass: "",
  action: "",
  callback: null,
  focusAfterClosed: null,
  showPrompt: false,
  showSetPassword: false,
  isPayPassword: "0"
};

const MessageBoxConstructor = Vue.extend(msgboxVue);

let currentMsg;
let instance;
let msgQueue = [];

const defaultCallback = (action,ctx,data) => {
  if (currentMsg) {
    const callback = currentMsg.callback;
    if (typeof callback === "function") {
      callback({action,data});
    }
    if (currentMsg.resolve) {
      if (action === "confirm") {
        currentMsg.resolve({action,data});
      } else if (currentMsg.reject && (action === "cancel" || action === "close")) {
        currentMsg.reject({action,data});
      }
    }
  }
};

const initInstance = () => {
  instance = new MessageBoxConstructor({
    el: document.createElement("div")
  });

  instance.callback = defaultCallback;
};

const showNextMsg = () => {
  if (!instance) {
    initInstance();
  }
  instance.action = "";

  if (!instance.visible || instance.closeTimer) {
    if (msgQueue.length > 0) {
      currentMsg = msgQueue.shift();

      const options = currentMsg.options;
      for (const prop in options) {
        if (options.hasOwnProperty(prop)) {
          instance[prop] = options[prop];
        }
      }
      if (!options.callback) {
        instance.callback = defaultCallback;
      }

      const oldCb = instance.callback;
      instance.callback = (action, instance,data) => {
        oldCb(action, instance,data);
        showNextMsg();
      };
      if (isVNode(instance.message)) {
        instance.$slots.default = [instance.message];
        instance.message = null;
      } else {
        delete instance.$slots.default;
      }
      ["modal", "showClose", "closeOnClickModal","closeOnPressEscape","closeOnHashChange"].forEach((prop) => {
        if (instance[prop] === undefined) {
          instance[prop] = true;
        }
      });
      document.body.appendChild(instance.$el);

      Vue.nextTick(() => {
        instance.visible = true;
      });
    }
  }
};

const MessageBox = function(options, callback) {
  if (Vue.prototype.$isServer) return;
  if (options.callback && !callback) {
    callback = options.callback;
  }

  if (typeof Promise !== "undefined") {
    return new Promise((resolve, reject) => { // eslint-disable-line
      msgQueue.push({
        options: merge({}, defaults, MessageBox.defaults, options),
        callback,
        resolve,
        reject
      });

      showNextMsg();
    });
  }
  msgQueue.push({
    options: merge({}, defaults, MessageBox.defaults, options),
    callback
  });

  showNextMsg();
};

MessageBox.close = () => {
  instance.doClose();
  instance.visible = false;
  msgQueue = [];
  currentMsg = null;
};

export default MessageBox;
export { MessageBox };
