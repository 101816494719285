<template>
  <div>
    <div class="conta">
      <en-icon name="icontubiaoyanse-shuxing" size="15px" style="color:#A9B5C6;margin-right:3px;"></en-icon>
      <div class="box_xxx" :style="colorCubstyle" @click="toggleShow"></div>
    <div v-show="selectBoxShow" class="color-dialog">
      <div class="div1" v-if="typeSelections === 2">
        <el-radio-group v-model="changeColorType" @change="colorType">
          <el-radio-button label="1" >纯色</el-radio-button>
          <el-radio-button label="2" >渐变</el-radio-button>
        </el-radio-group>
        <!-- <div class="div2">
          <el-button class="cbutton" :style="pureStyle" @click="toggleTypePure">纯色</el-button>
          <el-button class="cbutton" :style="gradientStyle" @click="toggleTypeGradient">渐变</el-button>
        </div> -->
      </div>
      <ColorPicker :type="isLinear ? 'linear' : 'gradient'" @changeColor="changeColor" @onClose="onClosePicker"
        :pDeg="pDeg" :pColor="pColor" :pColors="pColors" :showClose="false" :closeOnClickBody="false" />
    </div>
  </div>
  </div>
</template>

<script>
import ColorPicker from "./components/vue2-color-picker.vue";

export default {
  name: "MyColorPicker",
  components: {
    ColorPicker
  },
  model: {
    prop: "value",
    event: "input"
  },
  props: {
    value: {
      type: String,
      default: ""
    },
    // 1 只有纯色 2纯色+渐变色
    typeSelections: {
      type: Number,
      default: 2
    }
  },
  data() {
    return {
      changeColorType: "1",
      colorCubstyle: "",
      pureStyle: this.value.indexOf("gradient") < 0 ? "background:#fff;font-weight: bold;" : "",
      gradientStyle: this.value.indexOf("gradient") < 0 ? "" : "background:#fff;font-weight: bold;",
      selectBoxShow: false,
      fillbackFlag: false,
      isLinear: this.typeSelections === 2 ? this.value.indexOf("gradient") < 0 : true,
      pDeg: 90,
      pColor: {
        color: "rgba(0,0,0,1)"
      },
      pColors: [
        {
          color: "rgba(255, 255, 255, 1)",
          pst: 100
        },
        {
          color: "rgba(0, 0, 0, 1)",
          pst: 0
        }
      ]
    };
  },
  watch: {
      value(newVal, oldVal) {
        if (newVal === oldVal) return;
      const selectedColorStr = this.set16ToRgba(this.value);
      // linear-gradient(90deg, rgba(231, 218, 54, 1) 0%,rgba(171, 39, 39, 1) 100%)
      if (selectedColorStr) {
        this.colorCubstyle = `background: ${selectedColorStr}`;
        this.fillbackFlag = true;
        if (selectedColorStr.indexOf("gradient") > -1) {
          // this.isLinear = false
          const extractedStr = selectedColorStr.substring(16, selectedColorStr.length - 1);
          const dcarr = extractedStr.split("deg, ");

          const colorarr = dcarr[1].split(",").reduce((result, segment) => {
            if (!isNaN(segment.split(" ")[0])) {
              result[result.length - 1] += `,${segment}`;
            } else {
              result.push(segment);
            }
            return result;
          }, []);

          const newGradientColors = [];
          colorarr.forEach((item) => {
            newGradientColors.push({
              color: item.substring(0, item.indexOf(")") + 1),
              // eslint-disable-next-line radix
              pst: parseInt(item.substring(item.indexOf(")") + 2, item.length))
            });
          });
          // eslint-disable-next-line radix
          this.pDeg = parseInt(dcarr[0]);
          this.pColors = newGradientColors;
        } else {
          // this.isLinear = true
          this.pColor = {
            color: selectedColorStr
          };
          this.$emit("input", selectedColorStr);
        }
        this.fillbackFlag = false;
      }
      }
  },
  methods: {
  // 十六进制转RGBA
  set16ToRgba(str) {
      const reg = /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/;
      if (!reg.test(str)) { return; }
      // eslint-disable-next-line no-useless-escape
      let newStr = (str.toLowerCase()).replace(/\#/g, "");
      const len = newStr.length;
      // eslint-disable-next-line eqeqeq
      if (len == 3) {
          let t = "";
          // eslint-disable-next-line vars-on-top, no-var, block-scoped-var
          for (var i = 0; i < len; i++) {
              // eslint-disable-next-line block-scoped-var
              t += newStr.slice(i, i + 1).concat(newStr.slice(i, i + 1));
          }
          newStr = t;
      }
      const arr = []; // 将字符串分隔，两个两个的分隔
      // eslint-disable-next-line vars-on-top, no-var, block-scoped-var, no-redeclare
      for (var i = 0; i < 6; i += 2) {
          // eslint-disable-next-line block-scoped-var
          const s = newStr.slice(i, i + 2);
          // eslint-disable-next-line radix
          arr.push(parseInt(`0x${ s}`));
      }
      // eslint-disable-next-line consistent-return
      return `rgb(${ arr.join(",") })`;
    },
    colorType(data) {
      // console.log(data);
      // eslint-disable-next-line eqeqeq
      if (data == "1") {
        this.toggleTypePure();
      } else {
        this.toggleTypeGradient();
      }
      // <el-button class="cbutton" :style="pureStyle" @click="toggleTypePure">纯色</el-button>
      //     <el-button class="cbutton" :style="gradientStyle" @click="toggleTypeGradient">渐变</el-button>
    },
    toggleShow() {
      this.selectBoxShow = !this.selectBoxShow;
    },
    toggleTypePure() {
      this.isLinear = true;
      this.pureStyle = "background:#fff;font-weight: bold;";
      this.gradientStyle = "";
    },
    toggleTypeGradient() {
      this.isLinear = false;
      this.gradientStyle = "background:#fff;font-weight: bold;";
      this.pureStyle = "";
    },
    changeColor({
      style, colors, deg, color
    }) {
      if (!this.fillbackFlag) {
        let selectedColor = "";
        if (this.isLinear) {
          selectedColor = color.color;
        } else {
          selectedColor = style;
        }
        this.colorCubstyle = `background: ${selectedColor}`;
        this.$emit("input", selectedColor);
      }
    },
    onClosePicker() {
      this.selectBoxShow = false;
    }
  },
  mounted() {
    const selectedColorStr = this.set16ToRgba(this.value);
    if (!selectedColorStr) return;
    // linear-gradient(90deg, rgba(231, 218, 54, 1) 0%,rgba(171, 39, 39, 1) 100%)
    if (selectedColorStr) {
      this.colorCubstyle = `background: ${selectedColorStr}`;
      this.fillbackFlag = true;
      if (selectedColorStr.indexOf("gradient") > -1) {
        // this.isLinear = false
        const extractedStr = selectedColorStr.substring(16, selectedColorStr.length - 1);
        const dcarr = extractedStr.split("deg, ");

        const colorarr = dcarr[1].split(",").reduce((result, segment) => {
          if (!isNaN(segment.split(" ")[0])) {
            result[result.length - 1] += `,${segment}`;
          } else {
            result.push(segment);
          }
          return result;
        }, []);

        const newGradientColors = [];
        colorarr.forEach((item) => {
          newGradientColors.push({
            color: item.substring(0, item.indexOf(")") + 1),
            // eslint-disable-next-line radix
            pst: parseInt(item.substring(item.indexOf(")") + 2, item.length))
          });
        });
        // eslint-disable-next-line radix
        this.pDeg = parseInt(dcarr[0]);
        this.pColors = newGradientColors;
      } else {
        // this.isLinear = true
        this.pColor = {
          color: selectedColorStr
        };
        this.$emit("input", selectedColorStr);
      }
      this.fillbackFlag = false;
    }
  }
};
</script>
<style lang="scss">
.conta {
  display: flex;
  flex-direction: row;
}

.box_xxx {
  width: 22px;
  height: 22px;
  border: 3px solid #E8E8E8;
  border-radius: 3px;

}

.color-dialog {
 float: left;
 position: absolute;
 margin-top:30px;
}

.div1 {
  width: 245px;
  height: 58px;
  padding: 16px 10px;
  border-radius: 4px 4px 0 0;
  background: #fff;
}

.div2 {
  height: 26px;
  width: 225px;
  background-color: #d7d9dc;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cbutton {
  padding: 1px;
  height: 24px;
  width: 112px;
  border-radius: 4px;
  border: 0px;
  background: #d7d9dc;
  font-size: 14px;
  text-align: center;

}
</style>
