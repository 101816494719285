/*
 * @Author: pengyu
 * @Date: 2021-06-16 17:12:42
 * @LastEditors: pengyu
 * @LastEditTime: 2021-06-17 11:20:53
 * @Description: 法律知识库路由
 */
const routes = [
  {
    path: "/lawAngel",
    redirect: "/lawAngel/list",
    component: () => import(/* webpackChunkName:"setting" */ "@/views/lawAngel"),
    children: [
      {
        path: "/lawAngel/list",
        component: () => import(/* webpackChunkName:"law-angel" */ "@/views/lawAngel/list"),
        meta: {
          title: "法律知识库列表",
          keepAlive: false,
          pageLayout: 1,
          fullPage: true
        }
      }
    ]
  },
  {
    path: "/lawAngel/detail",
    component: () => import(/* webpackChunkName:"law-angel" */ "@/views/lawAngel/detail"),
    meta: {
      title: "法律知识库详情",
      keepAlive: false,
      fullPage: true
    }
  }

];

export default routes;
