import Qs from "qs";
import http from "./http";

class BaseService {
  // 处理get请求的参数序列化配置
  serializerConfig = {
    arrayFormat: "indices",
    allowDots: true
  }

  // 一个模块的公共路径
  module = ""

  /**
   * 构造函数
   * @param module 模块名称，比如：用户模块的增删改查 增加、修改：/user/add,查询/user/list,，删除/user/del。那么module=“user”
   * @param customHttp 自定义的http，可以扩展多个不同http。默认使用http.js种的http对象
   */
  constructor(module, customHttp) {
    this.module = module;
    this.http = customHttp || http;
  }

  /**
   * get参数序列化配置
   * @param params
   * @param config
   * @returns {string}
   */
  serializer(params, config = {}) {
    config = { ...this.serializerConfig, ...config };
    return Qs.stringify(params, config);
  }

  /**
   * Get请求封装，用于带参数的Get请求
   * @param url 请求url
   * @param params 请求参数
   * @config 序列化配置，可以替换serializer配置，一般情况不用使用
   * @returns {*}
   */
  query(url, params, config) {
    const { http } = this;
    return http.get(url, {
      params,
      paramsSerializer: (params) => this.serializer(params, config)
    });
  }

  /* 通用方法 */
  post(method, params) {
    return this.http.post(`/${method}`, params);
  }

  put(method, params, config) {
    console.log("put", config);

    return this.http.put(`/${method}`, params, config);
  }

  // 如果后台路径命名有一定标准，可以扩展标准化命令请求，比如查询列表，查询单个资源，增加/修改单个资源，删除单个资源
}

export default BaseService;
