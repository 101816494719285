/**
 * project: user
 * fileName: index
 * Created by 徐俊 on 2020/06/28
 * Copyright © 2020 Enfry Ltd. All rights reserved.
 */

const routes = [
  {
    path: "/toBusinessList", // 总入口(自动识别)
    name: "toBusinessList",
    component: () => import(/* webpackChunkName: "user-basic-business-list" */ "@/views/businessModel/list/entry.vue"),
    meta: {
      title: "业务建模列表",
      fullPage: false,
      keepAlive: false
    }
  },
  {
    path: "/businessModel/list",
    name: "businessList",
    component: () => import(/* webpackChunkName: "user-basic-business-list" */ "@/views/businessModel/list/index.vue"),
    meta: {
      title: "业务建模列表",
      fullPage: false,
      keepAlive: false
    }
  },
  {
    path: "/businessModel/importData",
    name: "importData",
    component: () => import(/* webpackChunkName: "user-basic-business-list" */ "@/views/businessModel/list/views/importData"),
    meta: {
      title: "业务建模导入",
      fullPage: false,
      keepAlive: false
    }
  },
  {
    path: "/businessModel/userLog",
    name: "userLog",
    component: () => import(/* webpackChunkName: "user-basic-business-list" */ "@/views/businessModel/list/views/businessLog"),
    meta: {
      title: "业务建模日志",
      fullPage: true,
      keepAlive: false,
      pageLayout: 2
    },
    props: (route) => ({
      id: route.query.templateId
    })
  },
  {
    path: "/businessModel/importList",
    name: "businessList",
    component: () => import(/* webpackChunkName: "user-basic-business-list" */ "@/views/businessModel/list/views/importList.vue"),
    meta: {
      title: "导入列表",
      fullPage: false,
      keepAlive: false
    }
  }
];

export default routes;
