/**
 * project: user
 * fileName: list
 * Created by 徐俊 on 2020/06/29
 * Copyright © 2020 Enfry Ltd. All rights reserved.
 */

import { cloneDeep } from "lodash";
import { BusinessFieldType } from "@/components/businessDetail/data/BusinessCommonHeader";
import BaseService from "../BaseService";

class BusinessModelList extends BaseService {
  /**
   * 查询列表表头
   * @param params 查询参数
   * @returns {*}
   */
  async queryHeadList(params) {
    const res = await this.http.post("mdListCol", params);
    if (res.tabSetList && res.tabSetList.length && !params.tabId) {
      params.tabId = res.tabSetList[0].id;
    }
    const { fieldSort } = await this.http.post("queryUserMdBehavior", params);
    let headList;// fieldSort
    let colList;
    // eslint-disable-next-line no-unused-vars
    let isUserSet = false;
    if (res.tabSetList && res.tabSetList.length) {
      isUserSet = true;
      const tabId = res.tabId ? res.tabId : res.tabSetList[0].id;
      const selectTab = res.tabSetList.find((item) => item.id === tabId);
      headList = JSON.parse(selectTab.tabHtmlJson);
      colList = headList.map((item) => res.colList.find((col) => col.field === item.field));
      res.tabId = tabId;
      res.tabList = res.tabSetList;
      delete res.tabSetList;

      if (res.colList && res.colList.length > 0 && headList) {
        headList.forEach((col) => { // 实在搞不明白, 为啥不返回完整数据
          if (!col.name) {
            const myCol = res.colList.find((tmp) => tmp.field === col.field);
            col.name = myCol?.name;
          }
        });
      }
    } else if (res.pageStyleData && typeof res.pageStyleData === "string") { // 管理员设置过模板布局了
      isUserSet = true;
      headList = JSON.parse(res.pageStyleData);
      // colList = cloneDeep(res.colList);
      colList = cloneDeep(headList);
      // res.pageStyleData = undefined;
    } else {
      headList = cloneDeep(res.colList);
      colList = cloneDeep(res.colList);
      // delete res.colList;
    }
    colList = colList.filter((item) => item);
    if (fieldSort.length) {
      // 第一次 先查列头 再查用户习惯
      headList = cloneDeep(fieldSort);
      colList?.forEach((item) => {
        if (!fieldSort.find((field) => item.field === field.field)) {
          headList.push(item);
        } else {
          const obj = headList.find((field) => item.field === field.field);
          // Object.assign(obj, item);
          obj.fieldType = item.fieldType;
          obj.align = item.align;
          obj.fieldLengthType = item.fieldLengthType;
        }
      });
    }
    const newHeadList = [];
    headList.forEach((col) => {
      const fieldType = Number(col.fieldType);
      const {
        newProgress, pureText, subItem, line, tabControl, ocr, multiTab, mileStone
      } = BusinessFieldType;
      if ([pureText, subItem, line, tabControl, ocr, multiTab, mileStone].indexOf(fieldType) >= 0) {
        return;
      }
      const excludeFields = ["createId", "createName", "createTime", "billStatus", "enable", "modifyTime", "createDepId", "createOrgId", "outsideArea", "version", "dataSources", "mSummary"];
      if (!isUserSet && excludeFields.indexOf(col.field) >= 0) {
        return;
      }
      if (!res.colList.find((item) => item.field === col.field)) {
        return;
      }
      if (col.pageHide === 0) {
        return;
      }
      if (fieldType === newProgress) { // 进度字段 关联了日期 后面加两列
        if (col.relatedDate) {
          const col0 = {
            id: col.field, name: "实际进度", field: col.field, colType: "3", fieldType: col.fieldType, area: "main", show: true
          };
          const col1 = {
            id: `${col.field}_planProgress`, field: `${col.field}_planProgress`, name: "计划进度", colType: "3", fieldType: col.fieldType, area: "main", show: true
          };
          const col2 = {
            id: `${col.field}_progressStatus`, field: `${col.field}_progressStatus`, name: "状态", colType: "1", fieldType: col.fieldType, area: "main", show: true
          };
          newHeadList.push(col0, col1, col2);
        } else {
          // 处理colType
          // if (utils.contains(["1", "3", "4", "5", "7", "15"], cf.fieldType)) {
          //   itm.colType = cf.fieldType;
          // } else if (utils.contains(['createId', 'createOrgId', 'createDepId'], cf.field)) {
          //   itm.colType = "5";
          // } else {
          //   itm.colType = "1";
          // }

          const col0 = {
            id: col.field, name: col.name || "", colType: "3", fieldType: col.fieldType, area: "main"
          };
          if (fieldSort.find((field) => field.field === col0.field)) {
            col0.show = true;
          }
          newHeadList.push(col0);
        }
      } else {
        const supportSearchFieldList = res?.publicParams?.supportSearchFieldList;
        if (!supportSearchFieldList || supportSearchFieldList.length === 0) {
          if (col.noSort === undefined) { // 没有返回字段
            col.noSort = "1";
          }
          if (col.noSearch === undefined) { // 没有返回字段
            col.noSearch = "1";
          }
        }
        const fieldObj = res.colList.find((field) => field.field === col.field);
        if (fieldObj) {
          if (fieldSort.find((field) => field.field === fieldObj.field) || !fieldSort.length) {
            col.show = true;
          }
          Object.assign(col, fieldObj);
        }
        newHeadList.push(col);
      }
    });
    if (fieldSort.length) {
      res.headList = cloneDeep(newHeadList);
      res.hideFields = cloneDeep(newHeadList.filter((item) => !item.show));
    } else {
      res.headList = newHeadList.length ? newHeadList : headList;
    }
    // 过滤掉签到
    res.headList = res.headList.filter((item) => item.fieldType !== BusinessFieldType.sign);
    // 金额表头带单位 2.16
    res.headList.forEach((item, index) => {
      if (Number(item.fieldType) === 4) {
        item.name = Number(item.suffix) === 2 ? (`${item.name}（万元）`) : (`${item.name}（元）`);
      }
      // 设置固定列
      if (index < res.fixedColumnNum) {
        item.fixed = "left";
      }
    });

    return res;
  }

  /**
   * 查询列表数据
   * @param params 查询参数
   * @param colList 列数据
   * @returns {*}
   */
  queryDataList(params, colList) {
    if (colList) {
      const userShowFields = colList.map((col) => ({
        nameVariable: col.field,
        fieldType: col.fieldType
      }));
      params.userShowFields = JSON.stringify(userShowFields);
    }
    // return this.http.post("mdTaskListData", params); // 后端要求修改接口 不然获取不到日历的数据
    return this.http.post("mdQueryListData", params);
  }

  /**
   * 查询明细表表头
   * @param params 查询参数
   * @returns {*}
   */
  queryDetailTableHeadList(params) {
    return this.http.post("mdQueryRefDataDetail", params);
  }

  /**
   * 查询业务建模表头
   * @param params 查询参数
   * @returns {*}
   */
   mdListCol(params) {
    return this.http.post("mdListCol", params);
  }

  /**
   * 查询明细表数据
   * @param params 查询参数
   * @returns {*}
   */
  queryDetailTableDataList(params) {
    return this.http.post("mdSearchFieldDataInfoList", params);
  }

  /**
   * 查询收付对象明细
   * @param params 查询参数
   * @returns {*}
   */
  queryPayObjectTableDataList(params) {
    return this.http.post("mdSearchFieldDataInfoList", params);
  }

  /**
   * 查询收付对象表头设置
   * @param params 查询参数
   * @returns {*}
   */
  queryUserMdBehavior(params) {
    return this.http.post("queryUserMdBehavior", params);
  }

  /**
   * 设置收付对象表头
   * @param params 设置参数
   * @returns {*}
   */
  saveUserBehavior(params) {
    return this.http.post("saveUserBehavior", params);
  }

  /**
   * 按钮jc数据
   * @param params 设置参数
   * @returns {*}
   */
  mdCustomBtn(params) {
    return this.http.post("mdCustomBtn", params);
  }

  /**
   * 删除数据
   * @param params 设置参数
   * @returns {*}
   */
  mdDeleteObject(params) {
    return this.http.post("mdDeleteObject", params);
  }

  /**
   * 点赞
   * @param params 设置参数
   * @returns {*}
   */
  mdLikeTheme(params) {
    return this.http.post("mdLikeTheme", params);
  }

  /**
   * 发票
   * @param params 设置参数
   * @returns {*}
   */
  mdInvoiceAutoResolve(params) {
    return this.http.post("mdInvoiceAutoResolve", params);
  }

  /**
   * 更新模板
   * @param params 设置参数
   * @returns {*}
   */
  mdBatchUpdateData(params) {
    return this.http.post("mdBatchUpdateData", params);
  }

  /**
   * 高级按钮
   * @param params 设置参数
   * @returns {*}
   */
  mdTopBtn(params) {
    return this.http.post("mdTopBtn", params);
  }

  /**
   * 获取日志
   * @param params 设置参数
   * @returns {*}
   */
  mdLogList(params) {
    return this.http.post("mdLogList", params);
  }

  /**
   * 获取基础日志
   * @param params 设置参数
   * @returns {*}
   */
  queryBaseOpLogList(params) {
    return this.http.post("queryBaseOpLogList", params);
  }

  /**
   * 获取用户日志
   * @param params 设置参数
   * @returns {*}
   */
  getArRuleUserLog(params) {
    return this.http.post("arRuleUserLog", params);
  }

  /**
   * 分享
   * @param params 设置参数
   * @returns {*}
   */
  mdDataShare(params) {
    return this.http.post("mdDataShare", params);
  }

  /**
   * 检查是否有可下载的文件
   * @param params 设置参数
   * @returns {*}
   */
  mdBatchDownCheckHasFile(params) {
    return this.http.post("mdBatchDownCheckHasFile", params);
  }

  /**
   * 批量下载文件
   * @param params 设置参数
   * @returns {*}
   */
  mdBatchDownFileListByParams(params) {
    return this.http.post("mdBatchDownFileListByParams", params);
  }

  /**
   * 分享二维码
   * @param params 设置参数
   * @returns {*}
   */
  mdShareTempInfoByCondition(params) {
    return this.http.post("mdShareTempInfoByCondition", params);
  }

  /**
   * 停用启用
   * @param params 设置参数
   * @returns {*}
   */
  mdEnableBaseData(params) {
    return this.http.post("mdEnableBaseData", params);
  }

  /**
   * 检查是否能编辑
   * @param params 设置参数
   * @returns {*}
   */
  mdEditObjectCheck(params) {
    return this.http.post("mdEditObjectCheck", params);
  }

  /**
 * @description: 二维码标签判断
 * @param params 查询参数
 */
  mdBtnRangeCheck(params) {
    return this.http.post("mdBtnRangeCheck", params);
  }

  /**
   * 获取编辑字段
   * @param params 设置参数
   * @returns {*}
   */
  mdQueryEditField(params) {
    return this.http.post("mdQueryEditField", params);
  }

  /**
   * 批量设置编辑字段
   * @param params 设置参数
   * @returns {*}
   */
  mdBatchEditDataField(params) {
    return this.http.post("mdBatchEditDataField", params);
  }

  /**
   * 查询模板
   * @param params 设置参数
   * @returns {*}
   */
  queryMdTemplate(params) {
    return this.http.post("queryMdTemplate", params);
  }

  /**
   * 查询导出是否有高级设置
   * @param params 设置参数
   * @returns {*}
   */
  mdQueryTemplateAreaInfo(params) {
    return this.http.post("mdQueryTemplateAreaInfo", params);
  }

  /**
   * 检查导出数量
   * @param params 设置参数
   * @returns {*}
   */
  queryExportMdDataCount(params) {
    return this.http.post("queryExportMdDataCount", params);
  }

  /**
   * 查询导出模板
   * @param params 设置参数
   * @returns {*}
   */
   queryExportTemplate(params) {
    return this.http.post("queryExportTemplate", params);
  }

  /**
   * 导出数据
   * @param params 设置参数
   * @returns {*}
   */
  mdExportTemplateEmptyOrDataList(params) {
    return this.http.post("/mdExportTemplateEmptyOrDataList", params, { responseType: "arraybuffer" });
  }

  // 导出模板
  mdExportEmptyTempInfoByCondition(params) {
    return this.http.post("/mdExportEmptyTempInfoByCondition", params, { responseType: "arraybuffer" });
  }

  // 导出模板(基础数据)
  bdExportEmptyTempInfoByCondition(params) {
    return this.http.post("/bdExportEmptyTempInfoByCondition", params, { responseType: "arraybuffer" });
  }

  // 业务建模基础数据导出(明细)
  exportSubBasicData(params) {
    return this.http.post("exportSubBasicData", params, { responseType: "arraybuffer" });
  }

  // 业务建模基础数据导出
  exportBasicData(params) {
    return this.http.post("exportBasicData", params, { responseType: "arraybuffer" });
  }

  // 导入模板列表
  queryImportTemplate(params) {
    return this.http.post("queryImportTemplate", params);
  }

  // 导入模板
  mdImportData(params) {
    return this.http.post("mdImportData", params);
  }

  // 导入模板(基础数据)
  bdImportData(params) {
    return this.http.post("bdImportData", params);
  }

  // 检查导入数据
  mdImportDataCheck(params) {
    return this.http.post("mdImportDataCheck", params);
  }

  // 检查导入数据(基础数据)
  bdImportDataCheck(params) {
    return this.http.post("bdImportDataCheck", params);
  }

  // 导出错误日志
  importExcelResult(params) {
    return this.http.post("importExcelResult", params, { responseType: "arraybuffer" });
  }

  // 导入表格至业务建模
  mdImportDataInTable(params) {
    return this.http.post("mdImportDataInTable", params);
  }

  // 导入表格至业务建模(基础数据)
  bdImportDataInTable(params) {
    return this.http.post("bdImportDataInTable", params);
  }

  /**
   * 设置用户查询
   * @param params 设置参数
   * @returns {*}
   */
  mdSaveUserSearchParams(params) {
    return this.http.post("/mdSaveUserSearchParams", params);
  }

  /**
   * 查看流程
   * @param params 设置参数
   * @returns {*}
   */
  wfQueryFlowDetailPage(params) {
    return this.http.post("/wfQueryFlowDetailPage", params);
  }

  /**
   * 查看可设置的分享字段
   * @param params 设置参数
   * @returns {*}
   */
  mdQueryUserFieldPower(params) {
    return this.http.post("/mdQueryUserFieldPower", params);
  }

  /**
   * 查看分享字段的时效
   * @param params 设置参数
   * @returns {*}
   */
  queryMdShareExpTime(params) {
    return this.http.post("/queryMdShareExpTime", params);
  }

  /**
   * 保存分享字段的时效
   * @param params 设置参数
   * @returns {*}
   */
  saveMdShareExpTime(params) {
    return this.http.post("/saveMdShareExpTime", params);
  }

  /**
   * 保存可设置的分享字段
   * @param params 设置参数
   * @returns {*}
   */
  mdSaveUserFieldPower(params) {
    return this.http.post("/mdSaveUserFieldPower", params);
  }

  /**
   * 按钮二维码标签
   * @param params 设置参数
   * @returns {*}
   */
  doMdRuleByBtnKey(params) {
    return this.http.post("/doMdRuleByBtnKey", params);
  }

  /**
   * 下载按钮二维码标签
   * @param params 设置参数
   * @returns {*}
   */
  downloadQRCodeTag(params) {
    return this.http.post("/downloadQRCodeTag", params);
  }

  /**
   * 天眼查
   * @param params 设置参数
   * @returns {*}
   */
  tlUserAccessButton(params) {
    return this.http.post("/tlUserAccessButton", params);
  }

  /**
   * 查询全局变量
   * @param params 参数
   * @returns {*}
   */
  queryRbacGloableVariableList(param) {
    return this.http.post("queryRbacGloableVariableList", param);
  }

  // 恢复
  mdDataRecovery(params) {
    return this.http.post("mdDataRecovery", params);
  }

  // 判断能否跳转邮件
  checkGenerateMailCustomBtnValid(params) {
    return this.http.post("checkGenerateMailCustomBtnValid", params);
  }

  // 处理异常
  mdHandleBtnRuleException(params) {
    return this.http.post("mdHandleBtnRuleException", params);
  }

  // 基础数据导出
  queryObjTypeInfoExistSub(params) {
    return this.http.post("queryObjTypeInfoExistSub", params);
  }

  bdSearchSubAttrInfo(params) {
    return this.http.post("bdSearchSubAttrInfo", params);
  }

  mdCancel(params) {
    return this.http.post("mdCancel", params);
  }

  /**
   * 任务日历数据
   * @param params 查询参数
   * @returns {*}
   */
  mdTaskCalendarsList(params) {
    return this.http.post("mdTaskCalendarsList", params);
  }
}

const businessModelList = new BusinessModelList();
export default businessModelList;
