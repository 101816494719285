/*
 * @Author: Zhouql
 * @Date: 2020-06-22 18:18:36
 * @LastEditTime: 2020-12-03 10:59:33
 * @LastEditors: wuqi
 * @Description: In User Settings Edit
 * @FilePath: \user\src\router\myForder\index.js
 */
const routes = [
  {
    path: "/my-folder/index",
    component: () => import("@/views/myFolder/index"),
    meta: {
      title: "我的文件夹",
      keepAlive: true,
      fullPage: false
    }
  },
  {
    path: "/my-folder/syncRule",
    component: () => import("@/views/myFolder/syncRule"),
    meta: {
      title: "同步规则",
      keepAlive: false,
      fullPage: false
    }
  }

];

export default routes;
