var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "conta" },
      [
        _c("en-icon", {
          staticStyle: { color: "#A9B5C6", "margin-right": "3px" },
          attrs: { name: "icontubiaoyanse-shuxing", size: "15px" },
        }),
        _c("div", {
          staticClass: "box_xxx",
          style: _vm.colorCubstyle,
          on: { click: _vm.toggleShow },
        }),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.selectBoxShow,
                expression: "selectBoxShow",
              },
            ],
            staticClass: "color-dialog",
          },
          [
            _vm.typeSelections === 2
              ? _c(
                  "div",
                  { staticClass: "div1" },
                  [
                    _c(
                      "el-radio-group",
                      {
                        on: { change: _vm.colorType },
                        model: {
                          value: _vm.changeColorType,
                          callback: function ($$v) {
                            _vm.changeColorType = $$v
                          },
                          expression: "changeColorType",
                        },
                      },
                      [
                        _c("el-radio-button", { attrs: { label: "1" } }, [
                          _vm._v("纯色"),
                        ]),
                        _c("el-radio-button", { attrs: { label: "2" } }, [
                          _vm._v("渐变"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c("ColorPicker", {
              attrs: {
                type: _vm.isLinear ? "linear" : "gradient",
                pDeg: _vm.pDeg,
                pColor: _vm.pColor,
                pColors: _vm.pColors,
                showClose: false,
                closeOnClickBody: false,
              },
              on: { changeColor: _vm.changeColor, onClose: _vm.onClosePicker },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }