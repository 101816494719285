const routes = [
  {
    path: "/payroll/paystubHr",
    name: "paystubHr",
    component: () => import(/* webpackChunkName:"payroll" */ "@/views/payRoll/hr/paystubHr/paystubHr"),
    meta: {
      title: "hr-工资条",
      keepAlive: false,
      fullPage: false
    }
  }
];

export default routes;
