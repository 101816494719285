export default {
  /**
   * @description 保存航班数据信息
   * @param state
   * @param data
   */
  setFlightInfo(state, { data }) {
    state.flightInfo = data;
  },
  /**
   * @description 设置航班类型
   * @param state
   * @param data
   */
  setFlightType(state, { data }) {
    state.isRound = data;
  },
  /**
   * @description 设置单向数据
   * @param state
   * @param data
   */
  setSingleData(state, { data }) {
    state.singleData = data;
  },
  /**
   * @description 设置返程数据
   * @param state
   * @param data
   */
  setRoundData(state, { data }) {
    state.roundData = data;
  },
  /**
   * @description 保存酒店数据信息
   * @param state
   * @param data
   */
  setHotelInfo(state, { data }) {
    state.hotelInfo = data;
  }
};
