/*
 * @Author: your name
 * @Date: 2020-06-10 16:56:02
 * @LastEditTime: 2024-03-05 09:40:58
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \user\src\router\home\index.js
 */
const routes = [
  {
    path: "/home",
    redirect: "/home/index"
  },
  {
    path: "/home/index",
    component: () => import(/* webpackChunkName: "home-main" */ "@/views/home/normal"),
    meta: {
      title: "常规首页",
      fullPage: false,
      keepAlive: false
    }
  },
  {
    path: "/home/define",
    component: () => import(/* webpackChunkName: "home-main" */ "@/views/home/homeDefine"),
    meta: {
      title: "自定义首页",
      fullPage: true,
      pageLayout: 2,
      keepAlive: false
    }
  },
  {
    path: "/home/myEn",
    component: () => import(/* webpackChunkName: "home-main" */ "@/views/home/myEn"),
    meta: {
      title: "我的河洛",
      fullPage: false,
      keepAlive: false
    }
  },
  {
    path: "/home/myEn/myTask",
    component: () => import(/* webpackChunkName: "home-main" */ "@/views/home/myEn/myTask"),
    meta: {
      title: "我的任务",
      fullPage: false,
      keepAlive: false
    }
  },
  {
    path: "/home/myEn/myBusiness",
    component: () => import(/* webpackChunkName: "home-main" */ "@/views/home/myEn/myBusiness"),
    meta: {
      title: "我的业务",
      fullPage: false,
      keepAlive: false
    }
  },
  {
    path: "/home/myEn/myOrder",
    component: () => import(/* webpackChunkName: "home-main" */ "@/views/home/myEn/myOrder"),
    meta: {
      title: "我的订单",
      fullPage: false,
      keepAlive: false
    }
  },
  {
    path: "/home/myEn/myNotice",
    component: () => import(/* webpackChunkName: "home-main" */ "@/views/home/myEn/myNotice"),
    meta: {
      title: "我的消息",
      fullPage: false,
      keepAlive: false
    }
  },
  {
    path: "/home/myEn/myTaskList",
    component: () => import(/* webpackChunkName: "home-main" */ "@/views/home/myEn/myTaskList"),
    meta: {
      title: "已办事项",
      fullPage: false,
      keepAlive: false
    }
  },
  {
    path: "/home/myEn/datePicker",
    component: () => import(/* webpackChunkName: "home-main" */ "@/views/home/myEn/datePicker"),
    meta: {
      title: "日历",
      fullPage: false,
      keepAlive: false
    }
  },
  {
    path: "/home/calendar",
    component: () => import(/* webpackChunkName: "home-main" */ "@/views/home/components/calendar-edit.vue"),
    meta: {
      title: "日历",
      fullPage: false,
      keepAlive: false
    }
  },
  {
    path: "/home/other",
    component: () => import("@/views/other/index.vue"),
    meta: {
      title: "other",
      keepAlive: false,
      fullPage: false
    }
  }
];

export default routes;
