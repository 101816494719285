const routes = [
  {
    path: "/payroll/paystubEmpty",
    name: "paystubEmpty",
    component: () => import(/* webpackChunkName:"payroll" */ "@/views/payRoll/hr/paystubEmpty/index"),
    meta: {
      title: "工资条-未导入",
      keepAlive: false,
      fullPage: false
    }
  }
];

export default routes;
