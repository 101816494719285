const routes = [
  {
    path: "/payroll/checkData",
    name: "checkData",
    component: () => import(/* webpackChunkName:"payroll" */ "@/views/payRoll/hr/importPayroll/checkData"),
    meta: {
      title: "导入工资条-校验数据",
      keepAlive: true,
      fullPage: false
    }
  }
];

export default routes;
