/*
 * @Author: pengyu
 * @Date: 2021-02-25 17:30:02
 * @LastEditors: pengyu
 * @LastEditTime: 2021-03-05 10:57:26
 * @Description: file content
 */
export default {
  isAdmin: (state) => state.permission === "admin",
  userInfo: (state) => state.userInfo,
  appSessionId: (state) => state.userInfo?.sessionId || "",
  tenantId: (state) => state.userInfo?.tenantId || "",
  mailAccount: (state) => state.mailAccount || "",
  uploadAction: (state) => state.uploadAction,
  userMailConfig: (state) => state.userMailConfig
};
