/*
 * @Author: pengyu
 * @Date: 2021-07-05 09:40:05
 * @LastEditors: pengyu
 * @LastEditTime: 2021-07-05 10:25:12
 * @Description: file content
 */
const routes = [
  {
    path: "/zjPayCashier",
    name: "Payment",
    component: () => import(/* webpackChunkName: "payment" */ "@/views/payment"),
    meta: {
      fullScreen: false
    }
  },
  {
    path: "/sysPayment",
    name: "sysPayment",
    component: () => import(/* webpackChunkName: "list" */ "@/views/payment/SysPayment"),
    meta: {
      fullScreen: false
    }
  }
];

export default routes;
