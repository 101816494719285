/**
 * @Author 李煜
 * @Date 2020/6/28
 * @Description user工程全局mixins，只封装使用频率非常高,非常高,非常高的mixin。
 */
/* eslint-disable */
 import { fmoney } from "@/tools/util.js";

export default {
  computed: {
  },
  method: {
  },
  filters: {
    fmoney
  }
};
