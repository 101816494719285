export default {
  isAdmin: (state) => state._permission === "admin",
  isSuperAdmin: (state) => state._permission === "superAdmin",
  isUser: (state) => state._permission === "user",
  getHotelDataInfo: (state) => state.hotelInfo,
  themeInfo: (state) => state.themeInfo,
  menuList: (state) => state.menuList,
  // 我的文件夹权限
  hasFolderPermission: (state) => state.menuList.some((menu) => (menu.dataList || []).some((item) => item.code === "062"))
};
