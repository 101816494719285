/*
 * @Author: Fenngding
 * @Date: 2019-08-28 15:30:12
 * @LastEditors: Fengding
 * @LastEditTime: 2019-09-26 23:21:11
 * @Email: fenngding@live.com
 * @Description:
 */
export default {
  _token: "928c8d97-421e-4154-8176-7cee17c563b9",
  _permission: "admin",
  _userInfo: {},
  themeInfo: {
    theme: "default", // blue/green/brown/white/default/define(自定义)
    menuColor: "default", // 颜色值用十六进制，不要用RGB
    contentColor: "", // 颜色值用十六进制，不要用RGB
    topMenuColor: "" // 颜色值用十六进制，不要用RGB
  },
  menuList: []
};
