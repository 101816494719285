/*
 * @Author: pengyu
 * @Date: 2021-06-16 17:09:39
 * @LastEditors: pengyu
 * @LastEditTime: 2021-06-16 17:10:23
 * @Description: file content
 */
import LawAngelService from "./lawAngelService";

export const lawService = new LawAngelService();
