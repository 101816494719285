/**
 * project: user
 * fileName: utils
 * Created by 徐俊 on 2020/08/24
 * Copyright © 2020 Enfry Ltd. All rights reserved.
 */
import Vue from "vue";
import config from "@/store/modules/chat/sdk/config";
import Private from "./private";

// if (!Function.prototype.bind) {
//   Function.prototype.bind = function() {
//     const fn = this; const args = Array.prototype.slice.call(arguments); const
//       object = args.shift();
//     return function() {
//       return fn.apply(object, args.concat(Array.prototype.slice.call(arguments)));
//     };
//   };
// }

const Utils = {

  formatSession(state, session) {
    // 格式化名称 图标
    Private.parseNameIcon(state, session);
    session.text = Private.parseMsgText(state, session.lastMsg);
    session.showDate = this.formatDate(session.updateTime, true);
  },
  findIndexByKey(arr, key, value) {
    let index = -1;
    if (!arr || !key || !value) {
        return index;
    }
    let i;
    for (i = 0; i < arr.length; i++) {
        if (arr[i][key] === value) {
            index = i;
            break;
        }
    }
    if (i === arr.length) {
        index = -1;
    }
    return index;
  },
  formatMsg(state, msg) {
    if (!msg) {
      return {};
    }
    // 格式化text
    Private.parseMsgText(state, msg);
    // 格式化时间
    msg.showDate = this.formatDate(msg.time || msg.userUpdateTime, false);
    msg.fromAvatar = state.userInfos[msg.from]?.avatar;
    return msg;
  },

  formatUserInfo(obj) {
    const nim = window.nim;
    let gender = "";

    obj = { ...obj };// store浏览器报错
    switch (obj.gender) {
      case "male":
        gender = "男";
        break;
      case "female":
        gender = "女";
        break;
      case "unknown":
        gender = "";
        break;
    }

    let custom = obj.custom || "";
    try {
      custom = JSON.parse(custom);
    } catch (e) {
      custom = {
        data: custom
      };
    }

    if (obj.avatar) {
      obj.avatar = nim.viewImageSync({
        url: obj.avatar, // 必填
        thumbnail: { // 生成缩略图， 可选填
          width: 40,
          height: 40,
          mode: "cover"
        }
      });
      // obj.avatar += '?imageView&thumbnail=40x40&quality=85'
    } else {
      obj.avatar = config.defaultUserIcon;
    }

    return Object.assign(obj, {
      account: obj.account,
      nick: obj.nick || "",
      avatar: obj.avatar || config.defaultUserIcon,
      birth: obj.birth || "",
      email: obj.email || "",
      tel: obj.tel || "",
      gender,
      sign: obj.sign || "",
      custom,
      createTime: obj.createTime || (new Date()).getTime(),
      updateTime: obj.updateTime || (new Date()).getTime()
    });
  },

  encode(_map, _content) {
    _content = `${_content}`;
    if (!_map || !_content) {
      return _content || "";
    }
    return _content.replace(_map.r, ($1) => {
      const _result = _map[!_map.i ? $1.toLowerCase() : $1];
      return _result != null ? _result : $1;
    });
  },
  encodePs(password) {
    password = password.replace(/\+/g, "%2B");
    password = password.replace(/\//g, "%2F");
    return password;
  },

  object2query(obj) {
    const keys = Object.keys(obj);
    const queryArray = keys.map((item) => `${item}=${encodeURIComponent(obj[item])}`);
    return queryArray.join("&");
  },

  // https://cn.vuejs.org/v2/guide/reactivity.html
  // Vue 不能检测到对象属性的添加或删除。然而它可以使用 Vue.set(object, key, value) 方法将响应属性添加到嵌套的对象上
  mergeObject(dest, src) {
    if (typeof dest !== "object" || dest === null) {
      dest = Object.create(null);
    }
    dest = Object.assign(Object.create(null), dest, src);
    return dest;
  },

  mergeVueObject(dest, src) {
    const keys = Object.keys(src);
    keys.forEach((item) => {
      if (typeof src[item] !== "undefined") {
        Vue.set(dest, item, src[item]);
      }
    });
    return dest;
  },

  // 消息类型列表
  mapMsgType(msg) {
    const map = {
      text: "文本消息",
      image: "图片消息",
      file: "文件消息",
      audio: "语音消息",
      video: "视频消息",
      geo: "地理位置消息",
      tip: "提醒消息",
      custom: "自定义消息",
      notification: "系统通知",
      robot: "机器人消息"
    };
    const type = msg.type;
    return map[type] || "未知消息类型";
  },

  stringifyDate(datetime, simple = false) {
    // let weekMap = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    const weekMap = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];
    datetime = new Date(datetime);
    const year = datetime.getFullYear();
    const simpleYear = datetime.getYear() - 100;
    let month = datetime.getMonth() + 1;
    month = month > 9 ? month : `0${month}`;
    let day = datetime.getDate();
    day = day > 9 ? day : `0${day}`;
    let hour = datetime.getHours();
    hour = hour > 9 ? hour : `0${hour}`;
    let min = datetime.getMinutes();
    min = min > 9 ? min : `0${min}`;
    let week = datetime.getDay();
    week = weekMap[week];
    const thatDay = (new Date(year, month - 1, day, 0, 0, 0)).getTime();

    if (simple) {
      return {
        withYear: `${day}/${month}/${simpleYear}`,
        withMonth: `${month}-${day}`,
        withDay: `${week}`,
        withLastDay: "昨天",
        withHour: `${hour}:${min}`,
        thatDay
      };
    }
    return {
      withYear: `${year}-${month}-${day} ${hour}:${min}`,
      withMonth: `${month}-${day} ${hour}:${min}`,
      withDay: `${week} ${hour}:${min}`,
      withLastDay: `昨天 ${hour}:${min}`,
      withHour: `${hour}:${min}`,
      thatDay
    };
  },

  /* 格式化日期 */
  formatDate(datetime, simple = false) {
    if (!datetime) {
      return "";
    }
    const tempDate = (new Date()).getTime();
    const result = this.stringifyDate(datetime, simple);
    const thatDay = result.thatDay;
    const deltaTime = (tempDate - thatDay) / 1000;

    if (deltaTime <= 3600 * 24) {
      return result.withHour;
    } if (deltaTime < 3600 * 24 * 2) {
      return result.withLastDay;
    } if (deltaTime <= 3600 * 24 * 7) {
      return result.withMonth;
    } if (deltaTime <= 3600 * 24 * 30) {
      return result.withMonth;
    }
    return result.withYear;
  },

  parseSession(sessionId) {
    if (/^p2p-/.test(sessionId)) {
      return {
        scene: "p2p",
        to: sessionId.replace(/^p2p-/, "")
      };
    }
    if (/^team-/.test(sessionId)) {
      return {
        scene: "team",
        to: sessionId.replace(/^team-/, "")
      };
    }
    return undefined;
  },

  /* 获得有效的备注名 */
  getFriendAlias(userInfo) {
    Private.getFriendAlias(userInfo);
  },

  // 获得合理的sessionId
  getSessionId(curImUserId, sessionId) {
    if (sessionId.indexOf("_") >= 0 || sessionId.indexOf("p2p") === 0) {
      sessionId = sessionId.replace(/^p2p-/, "");
      sessionId = sessionId.replace("_", "");
      sessionId = sessionId.replace("[\"", "");
      sessionId = sessionId.replace("\"]", "");
      sessionId = sessionId.replace(curImUserId, "");
    } else {
      sessionId = sessionId.replace(/^team-/, "");
    }

    return sessionId;
  }
};

Utils.teamConfigMap = {
  joinMode: {
    noVerify: "不需要验证",
    needVerify: "需要验证",
    rejectAll: "禁止任何人加入"
  },
  beInviteMode: {
    needVerify: "需要验证",
    noVerify: "不需要验证"
  },
  inviteMode: {
    manager: "管理员邀请",
    all: "所有人邀请"
  },
  updateTeamMode: {
    manager: "管理员修改",
    all: "所有人修改"
  },
  memberType: {
    manager: "管理员",
    normal: "普通成员"
  }
};

// eslint-disable-next-line func-names
Utils.escape = (function() {
  const _reg = /<br\/?>$/;
  const _map = {
    // eslint-disable-next-line no-useless-escape
    r: /\<|\>|\&|\r|\n|\s|\'|\"/g,
    "<": "&lt;",
    ">": "&gt;",
    "&": "&amp;",
    " ": "&nbsp;",
    "\"": "&quot;",
    "'": "&#39;",
    "\n": "<br/>",
    "\r": ""
  };
  // eslint-disable-next-line func-names
  return function(_content) {
    _content = Utils.encode(_map, _content);
    return _content.replace(_reg, "<br/>");
  };
}());

export default Utils;
