var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-menu-page-container" }, [
    _c(
      "div",
      { attrs: { id: "home-router" } },
      [
        _c("en-top-menu", {
          attrs: {
            "need-back": true,
            "menu-name": "系统设置",
            "icon-name": "xitongshezhi-guanliduan",
            "back-url": "/entry",
            divider: true,
          },
          scopedSlots: _vm._u([
            {
              key: "right",
              fn: function () {
                return undefined
              },
              proxy: true,
            },
          ]),
        }),
        _c("div", { staticClass: "home-content" }, [
          _c("div", { staticClass: "menu-content" }, [_c("left-nav-menu")], 1),
          _c("div", { staticClass: "left-area inline" }, [
            _c("div", { staticClass: "router-show-area" }, [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "keep-alive",
                    [
                      _vm.$route.meta.keepAlive
                        ? _c("router-view", {
                            key: _vm.$route.fullPath,
                            staticClass: "container",
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  !_vm.$route.meta.keepAlive
                    ? _c("router-view", {
                        key: _vm.$route.fullPath,
                        staticClass: "container",
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }