/*
 * @Author: pengyu
 * @Date: 2020-06-28 17:39:40
 * @LastEditors: pengyu
 * @LastEditTime: 2021-06-11 14:51:05
 * @Description: file content
 */
import BaseService from "../BaseService";

class VoucherDetail extends BaseService {
  pk = ""

  constructor(http) {
    super("", http);
  }

  /**
 * @description: 查询凭证是否需要借贷平衡
 */
  queryComConfig() {
    const { http } = this;
    return http.post("queryComConfig", { key_: "VOUCHER_RULE_LENDING_BALANCE" });
  }

  /**
   * @description: 回填会计期间
   * @param params
   */
  queryProduceDate(params) {
    const { http } = this;
    return http.post("initProduceDate", params);
  }

  /**
   * @description: 查询凭证详情
   * @param params
   */
  queryVoucherDetail(params) {
    const { http } = this;
    return http.post("vcQueryVoucherDetail", params);
  }

  /**
   * @description: 查询年度下的所有科目
   * @param {params}
   */
  querySubjectNoParent(params) {
    const { http } = this;
    return http.post("bdAcQuerySubjectNoPrarent", params);
  }

  /**
   * @description: 根据记账日期查询会计期间
   * @param {params}
   */
  queryAccTimeDetByTime(params) {
    const { http } = this;
    return http.post("bdAcAccTimeDetByTime", params);
  }

  /**
   * @description: 查询辅助核算对应的基础数据
   * @param {params}
   */
  queryObjectTypeByIds(params) {
    const { http } = this;
    return http.post("queryObjectTypeByIds", params);
  }

  /**
 * @description: 查询记账主体
 * @param params  查询参数
 */
  queryObjectTreeExt(params) {
    const { http } = this;
    return http.post("queryObjectTreeExt", params);
  }

  /**
 * @description: 查询摘要信息
 * @param params  查询参数
 */
  queryMark(params) {
    const { http } = this;
    return http.post("searchItemRemark", params);
  }

  /**
 * @description: 收藏凭证模板
 * @param params  查询参数
 */
  saveCollectVoucher(params) {
    const { http } = this;
    return http.post("vcSaveCollectVoucher", params);
  }

  /**
 * @description: 查询收藏模板
 * @param params  查询参数
 */
  queryCollectList(params) {
    const { http } = this;
    return http.post("vcQueryCollectList", params);
  }

  /**
   * @description: 查询收藏模板
   * @param params  查询参数
   */
  deleteCollectVoucher(params) {
    const { http } = this;
    return http.post("vcDeleteCollectVoucher", params);
  }

  /**
 * @description: 新增或编辑凭证
 * @param params
 */
  saveOrUpdateVoucher(params) {
    const { http } = this;
    return http.post("vcSaveOrUpdateVoucher", params);
  }

  /**
   * @description: 冲销凭证
   * @param {*}
   * @return {*}
   */
  chargeVoucher(params) {
    const { http } = this;
    return http.post("vcCharge", params);
  }

  /**
 * @description: 查询 基础数据--现金流量科目
 * @param params
 */
  queryObjectTree(params) {
    const { http } = this;
    return http.post("bdQueryObjectTree", params);
  }

  /**
 * @description: 查询业务单据-列头
 * @param params
 */
  queryReportTotalList(params) {
    const { http } = this;
    return http.post("queryReportTotalList", params);
  }

  /**
 * @description: 查询业务单据-数据
 * @param params
 */
  queryReportTotalDataPage(params) {
    const { http } = this;
    return http.post("queryReportTotalDataPage", params);
  }

  /**
 * @description: 查询汇率
 * @param params
 */
  queryMonthRate(params) {
    const { http } = this;
    return http.post("bdAcQueryMonthRate", params);
  }
}

export default VoucherDetail;
