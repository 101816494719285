/*
 * @Author: Fenngding
 * @Date: 2019-09-04 09:13:28
 * @LastEditors: wuqi
 * @LastEditTime: 2020-12-14 18:47:32
 * @Email: fenngding@live.com
 * @Description:
 */
import checkData from "./hr/checkData";
import graph from "./hr/graph";
import importPayroll from "./hr/importPayroll";
import payroll from "./hr/payroll";
import paystubEmpty from "./hr/paystubEmpty";
import paystubHr from "./hr/paystubHr";
import paystubHrSign from "./hr/paystubHrSign";
import sendNote from "./hr/sendNote";

import check from "./user/check";
import paystub from "./user/paystub";
import paystubNav from "./user/paystubNav";

// 模块路由
const routes = [
  {
    path: "/payroll",
    redirect: "/payroll/payroll",
    component: () => import(/* webpackChunkName:"payroll" */ "@/views/payRoll/user/prompt/index")
  },
  ...checkData,
  ...graph,
  ...importPayroll,
  ...payroll,
  ...paystubEmpty,
  ...paystubHr,
  ...paystubHrSign,
  ...sendNote,
  ...check,
  ...paystub,
  ...paystubNav
];

export default routes;
