/*
 * @Author: your name
 * @Date: 2020-07-06 16:52:37
 * @LastEditTime: 2022-05-23 10:08:38
 * @LastEditors: zhangjiaheng zhangjiaheng@enfry.com
 * @Description: 看板接口
 * @FilePath: \user\src\api\businessChart\chart.js
 */
import BaseService from "../BaseService";

class Chart extends BaseService {
  pk = "";

  constructor(http) {
    super("", http);
  }

  /**
   * 存用户习惯
   * @param params 参数
   * @returns {*}
   */
  saveUserConfig(param) {
    const { http } = this;
    return http.post("saveUserConfig", param);
  }

  /**
   * 查询户习惯
   * @param params 参数
   * @returns {*}
   */
  queryComConfig(param) {
    const { http } = this;
    return http.post("queryComConfig", param);
  }

   /**
   * 查询户习惯
   * @param params 参数
   * @returns {*}
   */
  saveComConfig(param) {
    const { http } = this;
    return http.post("saveComConfig", param);
  }

  /**
   * 查询户习惯
   * @param params 参数
   * @returns {*}
   */
  queryUserConfig(param) {
    const { http } = this;
    return http.post("queryUserConfig", param);
  }

  /**
   * 查询用户看板顺序
   * @param params 参数
   * @returns {*}
   */
  mdQueryUserPicCoordinate(param) {
    const { http } = this;
    return http.post("mdQueryUserPicCoordinate", param);
  }

  /**
   * 保存用户看板顺序
   * @param params 参数
   * @returns {*}
   */
  mdSaveUserPicCoordinate(param) {
    const { http } = this;
    return http.post("mdSaveUserPicCoordinate", param);
  }

  /**
   * 查询存在的所有图表
   * @param params 参数
   * refType: 1分类 2看板
   * refId: id
   * @returns {*}
   */
  mdQueryTotalPicList(param) {
    const { http } = this;
    return http.post("mdQueryTotalPicList", param);
  }

  /**
   * 查询图表的详细数据
   * @param params 参数
   * dataType: 11分类 2看板
   * id: 模板id
   * picId: 图表id
   * @returns {*}
   */
  mdQueryTotalPicData(param) {
    const { http } = this;
    return http.post("mdQueryTotalPicData", param);
  }

  /**
   * 查询当前模板设置的 查询条件
   * @param params 参数
   * @returns {*}
   */
  mdQueryCondition(param) {
    const { http } = this;
    return http.post("mdQueryCondition", param);
  }

  /**
   * 查询全局变量
   * @param params 参数
   * @returns {*}
   */
  queryRbacGloableVariableList(param) {
    const { http } = this;
    return http.post("queryRbacGloableVariableList", param);
  }

  /**
   * 查询业务建模分类以及模板
   * @param params 参数
   * @returns {*}
   */
  mdUserQueryTemplateClassify(param) {
    const { http } = this;
    return http.post("mdUserQueryTemplateClassify", param);
  }

  /**
   * 发送websocket消息
   * @param params 参数
   * @returns {*}
   */
  // sendWebSocketMessage
  sendWebSocketMessage(param) {
    const { http } = this;
    return http.post("sendWebSocketMessage", param);
  }
}

export default Chart;
