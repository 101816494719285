import BaseService from "../BaseService";

export default class CarService extends BaseService {
  /**
   * 查询航班信息
   * @param date
   * @param keywords
   * @returns {*}
   */
  queryflightInformation(date, keywords) {
    const { http } = this;
    return http.post("searchFlysByParams", { keyword: keywords, flydate: date });
  }

  /**
   * 查询历史联系人
   * @returns {*}
   */
  queryHistoryPerson() {
    const { http } = this;
    return http.post("tripHisContacts");
  }

  /**
   * 查询人员
   * @param keyword 关键字
   * @returns {*}
   */
  queryPerson(keyword) {
    const { http } = this;
    return http.post("tripQueryContacts", { name: keyword });
  }

  /**
   * 查询价格
   * @param param
   * @returns {*}
   */
  queryPriceInfo(param) {
    const { http } = this;
    return http.post("getPriceCouponInfo", param);
  }

  /**
   * 校验数据
   * @param data
   * @returns {*}
   */
  checkCarData(data) {
    const param = { addTripNodeList: JSON.stringify([data]) };
    return this.http.post("checkDdCarInfoByContion", param);
  }
}
