/*
 * @Author: 陈炯明
 * @Date: 2020-06-16 17:37:45
 * @LastEditors: wuqi
 * @LastEditTime: 2021-01-20 16:32:54
 * @Description: 报表-接口
 */
import BaseService from "../BaseService";

class ReportService extends BaseService {
  constructor(http) {
    super("", http);
  }

  /**
   * @description 报表管理-查询列表
   * @param {*} params
   */
  queryReportClassify(params = {}) {
    return this.http.post("queryReportClassify", params);
  }

  /**
   * @description  流程报表-查询表格数据
   * @param params
   * @returns {*}
   */
  queryFlowReport(params = {}) {
    return this.http.post("queryFlowReport", params);
  }

  /**
   * 流程报表
   * 查询业务建模模板 树数据
   */
  queryMdFormList(params = {}) {
    return this.http.post("queryMdFormList", params);
  }

  /**
   * 明细报表
   * 查询单据模板 树数据
   */
  queryFormList(params = {}) {
    return this.http.post("queryFormList", params);
  }

  /**
   * 明细报表
   * 查询单据模板字段
   */
  queryFormField(params = {}) {
    return this.http.post("queryFormField", params);
  }

  queryBillReportMainId(params = {}) {
    return this.http.post("queryBillReportMainId", params);
  }

  /**
   * 明细报表
   * 查询单据列表数据
   */
  queryBillDetail(params = {}) {
    return this.http.post("queryBillDetail", params);
  }

  /**
   * 合同统计表
   * 查询单据列表数据
   */
  queryContractReport(params = {}) {
    return this.http.post("queryContractReport", params);
  }

  exportBillDetail(params) {
    return this.http.post("exportBillDetail", params, { responseType: "arraybuffer" });
  }

  // 流程统计表导出
  exportTotalFlowReport(params) {
    return this.http.post("exportTotalFlowReport", params, { responseType: "arraybuffer" });
  }

  /* --------- 预算执行 -----------  */
  // 预算方案
  queryBudgetReportPlan(params) {
    return this.http.post("queryBudgetReportPlan", params);
  }

  // 查询条件
  queryPlanCondition(params) {
    return this.http.post("queryPlanCondition", params);
  }

  // 查询预算报表
  queryBudgetExecuteReport(params) {
    return this.http.post("queryBudgetExecuteReport", params);
  }
}

export default ReportService;
