import utils from "@/tools/chat";

/**
 * project: user
 * fileName: search
 * Created by 徐俊 on 2020/08/25
 * Copyright © 2020 Enfry Ltd. All rights reserved.
 */

export function resetSearchResult({ commit }) {
  commit("updateSearchlist", { type: "user", list: [] });
  commit("updateSearchlist", { type: "team", list: [] });
}

export function searchUsers({ state, commit }, obj) {
  let { accounts } = obj;
  const { done } = obj;
  const nim = window.nim;
  if (!Array.isArray(accounts)) {
    accounts = [accounts];
  }
  nim.getUsers({
    accounts,
    done: function searchUsersDone(error, users) {
      if (error) {
        console.debug(error);
        return;
      }
      commit("updateSearchlist", {
        type: "user",
        list: users
      });
      let updateUsers = users.filter((item) => {
        const account = item.account;
        if (item.account === state.userId) {
          return false;
        }
        const userInfo = state.userInfos[account] || {};
        return !userInfo.isFriend;
      });
      updateUsers = updateUsers.map((item) => utils.formatUserInfo(item));
      commit("updateUserInfo", updateUsers);
      if (done instanceof Function) {
        done(users);
      }
    }
  });
}

export function searchTeam({ commit }, obj) {
  const { teamId, done } = obj;
  const nim = window.nim;
  nim.getTeam({
    teamId,
    done: function searchTeamDone(error, teams) {
      if (error) {
        if (error.code === 803) {
          // 群不存在或未发生变化
          teams = [];
        } else {
          // alert(error);
          return;
        }
      }
      if (!Array.isArray(teams)) {
        teams = [teams];
      }
      teams.forEach((team) => {
        if (team.avatar && team.avatar.indexOf("chat.nosdn.127") > 0 && team.avatar.indexOf("?imageView") === -1) {
          team.avatar += "?imageView&thumbnail=300y300";
        }
      });
      commit("updateSearchlist", {
        type: "team",
        list: teams
      });
      if (done instanceof Function) {
        done(teams);
      }
    }
  });
}
