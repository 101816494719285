/*
 * @Author: zhulin
 * @Date: 2020-06-16 17:37:45
 * @LastEditors: wuqi
 * @LastEditTime: 2021-08-13 11:48:13
 * @Description: file content
 */
import BaseService from "../BaseService";

/**
 * login相关
 */
class SettingService extends BaseService {
  pk = "";

  constructor(http) {
    super("", http);
  }

  /**
   * @description  查询基本信息
   * @param params
   * @returns {*}
   */
  queryInfoSet(params) {
    const { http } = this;
    return http.post("queryInfoSet", params);
  }

  /**
   * @description  获取验证码
   * @param params
   * @returns {*}
   */
  sendValidateCode(params) {
    const { http } = this;
    return http.post("sendValidateCode", params);
  }

  /**
   * @description  验证验证码
   * @param params
   * @returns {*}
   */
  checkValidateCode(params) {
    const { http } = this;
    return http.post("checkValidateCode", params);
  }

  /**
   * @description  修改绑定手机号
   * @param params
   * @returns {*}
   */
  forgetMobile(params) {
    const { http } = this;
    return http.post("forgetMobile", params);
  }

  /**
   * @description  修改密码
   * @param params
   * @returns {*}
   */
  forgetPassword(params) {
    const { http } = this;
    return http.post("forgetPassword", params);
  }

  /**
   * @description  保存基本信息
   * @param params
   * @returns {*}
   */
  setUserInfo(params) {
    const { http } = this;
    return http.post("setUserInfo", params);
  }

  /**
   * @description  查询支行
   * @param params
   * @returns {*}
   */
  queryBankInfoList(params) {
    const { http } = this;
    return http.post("queryBankInfoList", params);
  }

  /**
   * @description  添加帐号信息
   * @param params
   * @returns {*}
   */
  setBankInfo(params) {
    const { http } = this;
    return http.post("setBankInfo", params);
  }

  /**
   * @description  删除帐号信息
   * @param params
   * @returns {*}
   */
  delBankInfo(params) {
    const { http } = this;
    return http.post("delBankInfo", params);
  }

  /**
   * @description: 查询人员树
   * @param params 参数
   */
  queryDeptUserTree(params) {
    const { http } = this;
    return new Promise((resolve, reject) => {
      http.post("queryDeptUserTree", params).then((resp) => {
        const result = resp?.filter((el) => el.enable === "000") || [];
        resolve(result);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  /**
   * @description: 查询组织机构
   * @param params 参数
   */
  queryDeptAndOrgInfo(params) {
    const { http } = this;
    return http.post("bdQueryDeptAndOrgInfo", params);
  }

  /**
   * @description: 查询人员
   * @param params 参数
   */
  queryPersonInfoByParams(params) {
    const { http } = this;
    return http.post("bdQueryPersonInfoByParams", params);
  }

  /**
   * @description: 查询企业
   * @param params 参数
   */
  queryUserTenantAndDeptInfo(params) {
    const { http } = this;
    return http.post("queryUserTenantAndDeptInfo", params);
  }
}

export default SettingService;
