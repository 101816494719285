/*
 * @Author: pengyu
 * @Date: 2020-11-19 11:17:47
 * @LastEditors: pengyu
 * @LastEditTime: 2020-11-19 15:48:11
 * @Description: 结转-接口
 */
import BaseService from "../BaseService";

class CarryOverService extends BaseService {
  pk = ""

  constructor(http) {
    super("", http);
  }

  /**
   * @description: 查询结转 数据
   * @param {*} params
   */
  queryFinalCarryOverList(params) {
    const { http } = this;
    return http.post("vcQueryFinalCarryOverList", params);
  }

  /**
   * @description: 生成凭证
   * @param {*} params
   */
  generateVoucherFinalCarryOver(params) {
    const { http } = this;
    return http.post("vcGenerateVoucherFinalCarryOver", params);
  }

  /**
   * @description: 测算金额
   * @param {*} params
   */
  evaluateFinalCarryOver(params) {
    const { http } = this;
    return http.post("vcEvaluateFinalCarryOver", params);
  }

  /**
   * @description: 结转查询会计期间
   * @param {*} params
   */
  queryCloseAccountAccTime(params) {
    const { http } = this;
    return http.post("closeAccountAcQueryAccTime", params);
  }

  /**
   * @description: 结转日志
   * @param {*} params
   */
  queryOperateLog(params) {
    const { http } = this;
    return http.post("queryOperateLog", params);
  }
}

export default CarryOverService;
