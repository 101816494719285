/**
 * project: user
 * fileName: session
 * Created by 徐俊 on 2020/08/25
 * Copyright © 2020 Enfry Ltd. All rights reserved.
 */
import { Message } from "element-ui";
import { httpQueryGroupList } from "@/api/chat/chatList";
import Utils from "@/tools/chat";
// 创建使用
export function insertLocalSession({ dispatch }, obj) {
  window.nim.insertLocalSession({
    scene: obj.scene || "team",
    to: obj.sessionId,
    done(error, obj) {
      if (error) {
        // Message({ message: error.message, type: "error" });
        console.log("insertLocalSessionc插入已有会话", error.event.session.id);
        dispatch("setCurrSession", error.event.session.id);
        return;
      }

      dispatch("setCurrSession", obj.session.id);
    }
  });
}

// 如果会话对象不是好友，需要更新好友名片
function updateSessionAccount(store, sessions) {
  const accountsNeedSearch = [];
  sessions.forEach((item) => {
    if (item.scene === "p2p") {
      // 如果不存在缓存资料
      if (!store.state.userInfos[item.to]) {
        accountsNeedSearch.push(item.to);
      }
    }
  });
  if (accountsNeedSearch.length > 0) {
    store.dispatch("searchUsers", {
      accounts: accountsNeedSearch
    });
  }
}

// onSessions只在初始化完成后回调
export function onSessions(store, sessions) {
  updateSessionAccount(store, sessions);
  store.dispatch("setFilterSessions", sessions);
}

export function onUpdateSession(store, session) {
  const sessions = [session];
  updateSessionAccount(store, sessions);
  store.commit("updateSessions", sessions);
}

// 删除会话
export function deleteSession({ commit }, sessionId) {
  const nim = window.nim;
  sessionId = sessionId || "";
  let scene = null;
  let account = null;
  if (/^p2p-/.test(sessionId)) {
    scene = "p2p";
    account = sessionId.replace(/^p2p-/, "");
  } else if (/^team-/.test(sessionId)) {
    scene = "team";
    account = sessionId.replace(/^team-/, "");
  }
  if (account && scene) {
    nim.deleteSession({
      scene,
      to: account,
      done: function deleteServerSessionDone(error) {
        if (error) {
          Message({ message: error.message, type: "error" });
          return;
        }

        nim.deleteLocalSession({
          id: sessionId,
          done: function deleteLocalSessionDone(error) {
            if (error) {
              Message({ message: error.message, type: "error" });
              return;
            }
            commit("deleteSessions", [sessionId]);
          }
        });
      }
    });
  }
}

export function setCurrSession({ commit }, sessionId) {
  const nim = window.nim;
  if (sessionId) {
    commit("updateCurrSessionId", {
      type: "init",
      sessionId
    });
    if (nim) {
      // 如果在聊天页面刷新，此时还没有nim实例，需要在onSessions里同步
      nim.setCurrSession(sessionId);
      commit("updateCurrSessionMsgs", {
        type: "init",
        sessionId
      });
    }
  }
}
// 后台过滤后的会话列表
export async function setFilterSessions(store, sessions) {
  const ids = [];
  for (let m = 0; m < sessions.length; m++) {
    if ((sessions[m].id.indexOf("team-") > -1)) {
        const id = sessions[m].id.replace("team-", "");
        if (!ids.includes(id)) {
            ids.push(id);
        }
    }
  }
  if (ids.length) {
    const data = await httpQueryGroupList({ tIds: ids.join() });
    for (let i = 0; i < data.length; i++) {
        const _id = data[i].sessionId; const stat = data[i].displayStatus;
        const _ind = Utils.findIndexByKey(sessions, "id", `team-${ _id}`);
        if (stat === "003") {
            if (_ind !== -1) {
                sessions.splice(_ind, 1);
            }
        } else {
            // 剩下的都是显示的
            sessions[_ind].displayStatus = data[i].displayStatus;
            sessions[_ind].isApproval = data[i].isApproval;
            sessions[_ind].name = data[i].title;
        }
      }
  }
  store.commit("updateSessions", sessions);
}
export function resetCurrSession({ commit }) {
  const nim = window.nim;
  nim.resetCurrSession();
  commit("updateCurrSessionMsgs", {
    type: "destroy"
  });
}
