import axios from "axios";
import BaseService from "../BaseService";

class TotalReportService extends BaseService {
  constructor(http) {
    super("", http);
  }

  queryConfig(params) {
    const { http } = this;
    return http.post("queryReportTotalConfig", params);
  }

  queryTotal(params) {
    const { CancelToken } = axios;
    return this.http.post("queryReportTotal", params, {
      cancelToken: new CancelToken(((c) => {
        this.cancel = c;
        // 这个参数 c 就是CancelToken构造函数里面自带的取消请求的函数，这里把该函数当参数用
      }))
    });
  }

  queryReportTemplateByColumnGroupingViewModelId(params) {
    return this.http.post("queryReportTemplateByColumnGroupingViewModelId", params);
  }

  exportTotalData(params) {
    return this.http.post("expReportTotalData", params, { responseType: "arraybuffer" });
    // return this.http.post("expReportTotalDetail", params);
  }

  /**
   * 查询全局变量
   * @param params 参数
   * @returns {*}
   */
  queryRbacGloableVariableList(param) {
    const { http } = this;
    return http.post("queryRbacGloableVariableList", param);
  }

  queryControlDetailCol(params) {
    return this.http.post("queryControlDetailCol", params);
  }

  queryControlDetail(params) {
    return this.http.post("queryControlDetail", params);
  }

  queryCustomReportDataNew(params) {
    return this.http.post("queryCustomReportDataNew", params);
  }
}

export default TotalReportService;
