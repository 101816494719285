/*
 * @Author: pengyu
 * @Date: 2021-07-05 09:34:23
 * @LastEditors: pengyu
 * @LastEditTime: 2021-07-05 09:41:39
 * @Description: file content
 */
import PaymentService from "./paymentServeice";
import SysPaymentService from "./sysPaymentServeice";

export const paymentServeice = new PaymentService();
export const sysPaymentService = new SysPaymentService();
