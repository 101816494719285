/**
 * @Author czm
 * @Date 2020/11/4
 * @Description mainvuex
 */
import state from "./state";
import mutations from "./mutations";
import actions from "./action";
import getters from "./getters";

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
