/*
 * @Author: pengyu
 * @Date: 2021-02-25 17:30:02
 * @LastEditors: 梁平贤
 * @LastEditTime: 2021-03-31 09:54:44
 * @Description: file content
 */
export default {
  token: "",
  tokenExpires: 1563493063812,
  permission: "",
  mailAccount: "",
  userInfo: {
    sessionId: "fdde7406-1e71-4dbe-9f66-11856b058f09",
    userId: "227053059725852672",
    tenantId: "225062062644002816",
    tenantName: "测试公司1",
    isSysManager: "000",
    isSysSuperManager: "000"
  },
  userMailConfig: {
    mailNeedReceipt: "0",
    mailReceiptType: "3"
  },
  uploadAction: ""
};
