// 基础设置路由
// import { replacePath, replaceRedirect } from "../../util";

const routes = [
  {
    path: "/hotel/entry",
    name: "hotelEntry",
    component: () => import(/* webpackChunkName: "business-travel-hotel" */ "@/views/businessTravel/hotel/entry"),
    meta: {
      title: "预定酒店入口",
      fullPage: false,
      keepAlive: true
    }
  },
  {
    path: "/hotel/query",
    name: "hotelquery",
    component: () => import(/* webpackChunkName: "business-travel-hotel" */ "@/views/businessTravel/hotel/query"),
    meta: {
      title: "酒店摘选",
      fullPage: false,
      keepAlive: true
    }
  },
  {
    path: "/hotel/map-query",
    name: "mapQuery",
    component: () => import(/* webpackChunkName: "business-travel-hotel" */ "@/views/businessTravel/hotel/map-query"),
    meta: {
      title: "酒店摘选",
      fullPage: false,
      keepAlive: true
    }
  },
  {
    path: "/hotel/booking",
    name: "booking",
    component: () => import(/* webpackChunkName: "business-travel-hotel" */ "@/views/businessTravel/hotel/booking"),
    meta: {
      title: "酒店预订",
      fullPage: false,
      keepAlive: true
    }
  },
  {
    path: "/hotel/test",
    name: "hoteltest",
    component: () => import(/* webpackChunkName: "business-travel-hotel" */ "@/views/businessTravel/hotel/test"),
    meta: {
      title: "酒店摘选",
      fullPage: false,
      keepAlive: true
    }
  }

];

// const PRE_URL = "/business-travel";
//
export default routes;
//   .map((v) => replacePath(PRE_URL, v))
//   .map((v) => replaceRedirect(PRE_URL, v));
