import Store from "@/store";
import BaseService from "../BaseService";

/**
 * login相关
 */
class ContactService extends BaseService {
  pk = ""

  constructor(http) {
    super("", http);
  }

  /**
   * 查询历史人员
   * @param params 参数
   */
  queryTripHisContacts() {
    return this.http.post("tripHisContacts.app", { userId: Store.getters.userInfo.userId });
  }

  /**
   * 获取联系人
   * @param params 查询条件
   * @returns {*}
   */
  tripHisContacts(params) {
    const { http } = this;
    return http.post("tripHisContacts", params);
  }

  /**
   * 搜索联系人
   * @param params 查询条件
   * @returns {*}
   */
  tripQueryContacts(params) {
    const { http } = this;
    return http.post("tripQueryContacts", params);
  }

  /**
   * 保存联系人
   * @param params
   * @example
   * name=liyu&idType=000&idNumber=430923198809110117&mobile=19900000002&flag=000&birthDay=&submitToken=092a5cbc-fbd8-45a6-b127-319f3d907ca3&tenantId=225062062644002816&schemeVersionId=
   * return {
   * "birthDay":"",
   * "flag":"000",
   * "idNumber":"430923198809110117",
   * "idType":"000",
   * "mobile":"19900000002",
   * "msgType":"N",
   * "name":"liyu",
   * "rspCode":"000000",
   * "rspData":
   * {"birthDay":"","flag":"000",
   *  "id":"324444024700698624",
   *  "idNumber":"430923198809110117",
   *  "idType":"000",
   *  "loginId":"227053059725852672",
   *  "mobile":"19900000002",
   *  "name":"liyu",
   *  "tenantId":"225062062644002816",
   *  "userId":"324444024700698624",
   *  "userLogo":""
   *  },
   *  "rspMsg":"","schemeVersionId":"",
   *  "submitToken":"092a5cbc-fbd8-45a6-b127-319f3d907ca3","tenantId":"225062062644002816"}
   */
  tripAddContacts(params) {
    const { http } = this;
    return http.post("tripAddContacts", params); // 不使用module
  }

  /**
   * 修改联系人
   * @param params
   * @returns {*}
   */
  tripUpdateContacts(params) {
    const { http } = this;
    return http.post("tripUpdateContacts", params); // 不使用module
  }
}

export default ContactService;
