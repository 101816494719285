export default {
  fileData: null,
  select: null,
  checkData: null,
  refInfo: null,
  form: {
    type: "",
    value: "",
    value1: "",
    desc: "工资条"
  }
};
