var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "micro-menu-page-container" }, [
    _c(
      "div",
      { staticClass: "home-router" },
      [
        _vm.pageLayout <= 1 ? _c("top-nav-menu") : _vm._e(),
        _vm.pageLayout === 0 ? _c("top-menu") : _vm._e(),
        _c(
          "div",
          {
            staticClass: "home-content",
            style: { height: _vm.containerHeight },
          },
          [
            _c(
              "div",
              {
                staticClass: "right-area",
                style: { width: _vm.containerWidth },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "router-show-area",
                    style: { padding: _vm.pageLayout === 2 ? "0" : "8px" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "loading-container",
                        style: {
                          borderRadius: _vm.pageLayout === 2 ? "0" : "5px",
                        },
                      },
                      [
                        _c(
                          "keep-alive",
                          [
                            _vm.$route.meta.keepAlive
                              ? _c("router-view", { staticClass: "full-page" })
                              : _vm._e(),
                          ],
                          1
                        ),
                        !_vm.$route.meta.keepAlive
                          ? _c("router-view", {
                              key: _vm.$route.fullPath,
                              staticClass: "full-page",
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }