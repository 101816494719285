/*
 * @Author: your name
 * @Date: 2020-07-06 16:57:05
 * @LastEditTime: 2022-08-05 12:01:09
 * @LastEditors: zhangjiaheng zhangjiaheng@enfry.com
 * @Description: In User Settings Edit
 * @FilePath: \user\src\router\businessChart\index.js
 */
const routes = [
  {
    path: "/toModelChartUser",
    component: () => import(/* webpackChunkName: "business-chart" */ "@/views/businessChart/index"),
    meta: {
      title: "业务看板",
      fullPage: false,
      keepAlive: false
    }
  },
  {
    path: "/toModelChartUserFull",
    component: () => import(/* webpackChunkName: "business-chart" */ "@/views/businessChart/chartFull"),
    meta: {
      title: "业务看板",
      fullPage: true,
      keepAlive: false
    }
  },
  {
    path: "/indexFull",
    component: () => import(/* webpackChunkName: "business-chart" */ "@/views/fullView/index"),
    meta: {
      title: "首页全屏",
      fullPage: true,
      keepAlive: true
    }
  },
  {
    path: "/indexVice",
    component: () => import(/* webpackChunkName: "business-chart" */ "@/views/fullView/indexVice"),
    meta: {
      title: "首页全屏",
      fullPage: true,
      keepAlive: true
    }
  },
  {
    path: "/sheet",
    component: () => import(/* webpackChunkName: "business-chart" */ "@/views/fullView/sheet"),
    meta: {
      title: "sheet",
      fullPage: false,
      keepAlive: true
    }
  }
];

export default routes;
