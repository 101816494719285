<!--
 * @Author: Fenngding
 * @Date: 2019-09-19 16:46:17
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-02-27 13:18:14
 * @Email: fenngding@live.com
 * @Description:
 -->
<template>
  <div class="main-menu-page-container">
    <div id="home-router">
      <en-top-menu
        :need-back="true"
        menu-name="系统设置"
        icon-name="xitongshezhi-guanliduan"
        back-url="/entry"
        :divider="true"
      >
        <template v-slot:right>
        </template>
      </en-top-menu>

      <div class="home-content">
        <div class="menu-content">
          <left-nav-menu />
        </div>
        <div class="left-area inline">
          <div class="router-show-area">
            <div class="container">
              <keep-alive>
                <router-view
                  v-if="$route.meta.keepAlive"
                  :key="$route.fullPath"
                  class="container"
                />
              </keep-alive>
              <router-view
                v-if="!$route.meta.keepAlive"
                :key="$route.fullPath"
                class="container"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import leftNavMenu from "@/components/left-nav-menu";

export default {
  name: "MainMenuPage",
  components: { leftNavMenu },
  props: {},
  data() {
    return {};
  },
  computed: {},
  methods: {}
};
</script>
<style scoped lang="scss">
.main-menu-page-container {
  height: 100%;
  width: 100%;
  #home-router {
    height: 100%;
    width: 100%;
    .home-content {
      height: calc(100% - 61px);
      .menu-content {
        width: 188px;
        display: inline-block;
        height: 100%;
        vertical-align: top;
        padding-top: 10px;
        background-color: #232c3d;
      }
      .inline {
        display: inline-block;
      }
      .left-area.inline {
        width: calc(100% - 188px);
        height: 100%;
        .router-show-area {
          height: 100%;
          width: 100%;
          padding: 8px;
          background-color: #1d2532;
          overflow: hidden;
          .container {
            border-radius: 5px;
            background-color: #1d2532;
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
