/*
 * @Author: pengyu
 * @Date: 2020-07-13 19:34:31
 * @LastEditors: wuqi
 * @LastEditTime: 2021-08-19 14:17:49
 * @Description: 获取图片,文件影像地址
 */
import Store from "@/store";
/**
 * @description: 获取下载地址
 * @param fileCode
 * @param type
 * @param filename
 * @return:fileUrl
 */
export function getFileUrl(fileCode, type, filename) {
  const urlParam = Store.getters.urlParam;
  console.log(urlParam, "urlParamurlParam");
  if (urlParam) {
    let fileUrl = fileCode; // 文件地址
    if (!fileCode) {
      return "";
    }
    if (fileCode.indexOf("Storage-api") > -1) {
      return fileCode;
    }
    const name = (filename && encodeURIComponent(filename)) || "";
    if (type === "000" || type === 0) {
      fileUrl = `${urlParam.showImageServer}?token=${urlParam.showImageToken}&ts=${urlParam.imgTs}&fileCode=${fileCode}&appKey=${urlParam.appKey}&attachmentName=${name}`;
    } else {
      fileUrl = `${urlParam.getFileServer}?token=${urlParam.getFileToken}&ts=${urlParam.ts}&fileCode=${fileCode}&appKey=${urlParam.appKey}&attachmentName=${name}`;
    }
    return fileUrl;
  }
    return "";
}
