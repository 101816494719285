/*
 * @Author: zhulin
 * @Date: 2020-06-16 17:37:45
 * @LastEditors: zhulin
 * @LastEditTime: 2020-08-02 17:21:40
 * @Description: file content
 */
import BaseService from "../BaseService";

/**
 * login相关
 */
class PreferenceService extends BaseService {
  pk = "";

  constructor(http) {
    super("", http);
  }

  /**
   * @description  查询偏好设置
   * @param params
   * @returns {*}
   */
  queryRemindList(params) {
    const { http } = this;
    return http.post("queryRemindConfigList", params);
  }

  /**
   * @description  设置提醒
   * @param params
   * @returns {*}
   */
  addRemindConfig(params) {
    const { http } = this;
    return http.post("addRemindConfig", params);
  }

  /**
   * @description  删除提醒
   * @param params
   * @returns {*}
   */
  deleteRemindConfig(params) {
    const { http } = this;
    return http.post("deleteRemindConfig", params);
  }

  /**
   * @description  停用提醒
   * @param params
   * @returns {*}
   */
  enableRemindConfig(params) {
    const { http } = this;
    return http.post("enableRemindConfig", params);
  }

  /**
   * @description  查询短信接收设置
   * @param params
   * @returns {*}
   */
  querySmsSet(params) {
    const { http } = this;
    return http.post("querySmsSet", params);
  }

  /**
   * @description  保存短信接收设置
   * @param params
   * @returns {*}
   */
  saveSmsSet(params) {
    const { http } = this;
    return http.post("saveSmsSet", params);
  }

  /**
   * @description  查询首页是否显示设置
   * @param params
   * @returns {*}
   */
  queryPreferences(params) {
    const { http } = this;
    return http.post("queryPreferences", params);
  }

  /**
   * @description  首页批量按钮设置
   * @param params
   * @returns {*}
   */
  savePreferences(params) {
    const { http } = this;
    return http.post("savePreferences", params);
  }
}

export default PreferenceService;
