// 司法风险

const routes = [
  {
    path: "property",
    name: "property",
    component: () => import(/* webpackChunkName: "corporate-credit" */"@/views/corporateCredit/property"),
    meta: {
      title: "司法风险",
      keepAlive: true,
      fullPage: false
    }
  }
];

export default routes;
