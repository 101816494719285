<script>
import emitter from "element-ui/src/mixins/emitter";
import Migrating from "element-ui/src/mixins/migrating";
import Menubar from "element-ui/src/utils/menu/aria-menubar";
import { addClass, removeClass, hasClass } from "element-ui/src/utils/dom";
import {
  createNamespacedHelpers
} from "vuex";

const { mapState: mapStateTheme } = createNamespacedHelpers("main");

export default {
  name: "ElMenu",
  components: {
    "el-menu-collapse-transition": {
      functional: true,
      render(createElement, context) {
        const data = {
          props: { mode: "out-in" },
          on: {
            beforeEnter(el) {
              el.style.opacity = 0.2;
            },

            enter(el) {
              addClass(el, "el-opacity-transition");
              el.style.opacity = 1;
            },

            afterEnter(el) {
              removeClass(el, "el-opacity-transition");
              el.style.opacity = "";
            },

            beforeLeave(el) {
              if (!el.dataset) {
                el.dataset = {};
              }
              if (hasClass(el, "el-menu--collapse")) {
                removeClass(el, "el-menu--collapse");
                el.dataset.oldOverflow = el.style.overflow;
                el.dataset.scrollWidth = el.clientWidth;
                addClass(el, "el-menu--collapse");
              } else {
                addClass(el, "el-menu--collapse");
                el.dataset.oldOverflow = el.style.overflow;
                el.dataset.scrollWidth = el.clientWidth;
                removeClass(el, "el-menu--collapse");
              }
              el.style.width = `${el.scrollWidth}px`;
              el.style.overflow = "hidden";
            },
            leave(el) {
              addClass(el, "horizontal-collapse-transition");
              el.style.width = `${el.dataset.scrollWidth}px`;
            }
          }
        };
        return createElement("transition", data, context.children);
      }
    }
  },

  mixins: [emitter, Migrating],

  props: {
    mode: {
      type: String,
      default: "vertical"
    },
    defaultActive: {
      type: String,
      default: ""
    },
    defaultOpeneds: Array,
    uniqueOpened: Boolean,
    router: Boolean,
    menuTrigger: {
      type: String,
      default: "hover"
    },
    collapse: Boolean,
    backgroundColor: String,
    textColor: String,
    activeTextColor: String,
    collapseTransition: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      activeIndex: this.defaultActive,
      openedMenus: (this.defaultOpeneds && !this.collapse) ? this.defaultOpeneds.slice(0) : [],
      popedMenus: [],
      items: {},
      submenus: {},
      navOffset: 0
    };
  },
  computed: {
    ...mapStateTheme(["themeInfo"]),
    hoverBackground() {
      // return this.backgroundColor ? this.mixColor(this.backgroundColor, 0.2) : "";
      return "#2A3345";
    },
    isMenuPopup() {
      return this.mode === "horizontal" || (this.mode === "vertical");
    },
    navBgStyle() {
      const {
 openCustomColor, navColor, HaverColor, NoHaverColor, SelectLabelColor, themeStyle
} = this.themeInfo;
        console.log("navColor", navColor, this.themeInfo);
        if (this.themeInfo.RecommendedStyles === 5) {
          if (this.themeInfo.themeColor === "dark") {
            return {
            background: navColor
          };
          }
          return {
            background: "#FFFFFF !important"
          };
        } if (this.themeInfo.RecommendedStyles === 6) {
          if (this.themeInfo.themeColor === "dark") {
            return {
            // background: navColor
          };
          }
          return {
            // background: "#FFFFFF !important"
          };
        } if (this.themeInfo.RecommendedStyles === 6) {
          if (this.themeInfo.themeColor === "dark") {
            return {
            // background: navColor
          };
          }
          return {
            // background: "#FFFFFF !important"
          };
        } if (this.themeInfo.RecommendedStyles === 6) {
          if (this.themeInfo.themeColor === "dark") {
            return {
            // background: navColor
          };
          }
          return {
            // background: "#FFFFFF !important"
          };
        }
        return {
          // backgroundColor: `${navColor}!important`,
          // color: this.active ? `${HaverColor} !important` : `${NoHaverColor }!important`
        };
    },
    navStyle() {
        const dir = "X";
        return {
          transform: `translate${dir}(-${this.navOffset}px)`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "50px"
        };
      }
  },

  watch: {
    defaultActive(value) {
      if (!this.items[value]) {
        this.activeIndex = null;
      }
      this.updateActiveIndex(value);
    },

    defaultOpeneds(value) {
      if (!this.collapse) {
        this.openedMenus = value;
        this.popedMenus = value;
      }
    },

    collapse(value) {
      if (value) {
        this.openedMenus = [];
        this.popedMenus = [];
      }
      this.broadcast("ElSubmenu", "toggle-collapse", value);
    }
  },
  mounted() {
    this.initOpenedMenu();
    this.$on("item-click", this.handleItemClick);
    this.$on("submenu-click", this.handleSubmenuClick);
    if (this.mode === "horizontal") {
        new Menubar(this.$el); // eslint-disable-line
    }
    // console.log(this.menuTrigger, "menuTrigger");
    this.$watch("items", this.updateActiveIndex);
  },
  methods: {
    updateActiveIndex(val) {
      const item = this.items[val] || this.items[this.activeIndex] || this.items[this.defaultActive];
      if (item) {
        this.activeIndex = item.index;
        this.initOpenedMenu();
      } else {
        this.activeIndex = null;
      }
    },
    scrollPrev() {
        const containerSize = this.$refs.navScroll.offsetWidth;
        const currentOffset = this.navOffset;

        if (!currentOffset) return;

        const newOffset = currentOffset > containerSize
          ? currentOffset - containerSize
          : 0;

        this.navOffset = newOffset;
      },
    scrollNext() {
      const navSize = this.$refs.nav.offsetWidth;
      const containerSize = this.$refs.navScroll.offsetWidth;
      const currentOffset = this.navOffset;

      if (navSize - currentOffset <= containerSize) return;

      const newOffset = navSize - currentOffset > containerSize * 2
        ? currentOffset + containerSize
        : (navSize - containerSize);

      this.navOffset = newOffset;
    },
    getMigratingConfig() {
      return { props: { theme: "theme is removed." } };
    },
    getColorChannels(color) {
      color = color.replace("#", "");
      if (/^[0-9a-fA-F]{3}$/.test(color)) {
        color = color.split("");
        for (let i = 2; i >= 0; i--) {
          color.splice(i, 0, color[i]);
        }
        color = color.join("");
      }
      if (/^[0-9a-fA-F]{6}$/.test(color)) {
        return {
          red: parseInt(color.slice(0, 2), 16),
          green: parseInt(color.slice(2, 4), 16),
          blue: parseInt(color.slice(4, 6), 16)
        };
      }
      return {
        red: 255,
        green: 255,
        blue: 255
      };
    },
    mixColor(color, percent) {
      let { red, green, blue } = this.getColorChannels(color);
      if (percent > 0) { // shade given color
        red *= 1 - percent;
        green *= 1 - percent;
        blue *= 1 - percent;
      } else { // tint given color
        red += (255 - red) * percent;
        green += (255 - green) * percent;
        blue += (255 - blue) * percent;
      }
      return `rgb(${Math.round(red)}, ${Math.round(green)}, ${Math.round(blue)})`;
    },
    addItem(item) {
      this.$set(this.items, item.index, item);
    },
    removeItem(item) {
      delete this.items[item.index];
    },
    addSubmenu(item) {
      this.$set(this.submenus, item.index, item);
    },
    removeSubmenu(item) {
      delete this.submenus[item.index];
    },
    openMenu(index, indexPath) {
      const { openedMenus } = this;
      if (openedMenus.indexOf(index) !== -1) {
        return;
      }
      // 将不在该菜单路径下的其余菜单收起
      // collapse all menu that are not under current menu item
      if (this.uniqueOpened) {
        this.openedMenus = openedMenus.filter((i) => indexPath.indexOf(i) !== -1);
      }
      this.openedMenus.push(index);
    },
    closeMenu(index) {
      const i = this.openedMenus.indexOf(index);
      if (i !== -1) {
        this.openedMenus.splice(i, 1);
      }
    },
    popMenu(index, indexPath) {
      const { popedMenus } = this;
      if (popedMenus.indexOf(index) !== -1) {
        return;
      }
      // 将不在该菜单路径下的其余菜单收起
      // collapse all menu that are not under current menu item
      if (this.uniqueOpened) {
        this.popedMenus = popedMenus.filter((i) => indexPath.indexOf(i) !== -1);
      }
      this.popedMenus.push(index);
    },
    closePopMenu(index) {
      const i = this.popedMenus.indexOf(index);
      if (i !== -1) {
        this.popedMenus.splice(i, 1);
      }
    },
    handleSubmenuClick(submenu) {
      const { index, indexPath } = submenu;
      const isOpened = this.openedMenus.indexOf(index) !== -1;

      if (isOpened) {
        this.popMenu(index);
        this.$emit("close", index, indexPath);
      } else {
        this.popMenu(index, indexPath);
        this.$emit("open", index, indexPath);
      }
    },
    handleItemClick(item) {
      const { index, indexPath } = item;
      const oldActiveIndex = this.activeIndex;
      const hasIndex = item.index !== null;

      if (hasIndex) {
        this.activeIndex = item.index;
      }

      this.$emit("select", index, indexPath, item);

      if (this.mode === "horizontal" || this.collapse) {
        this.openedMenus = [];
      }

      if (this.router && hasIndex) {
        this.routeToItem(item, (error) => {
          this.activeIndex = oldActiveIndex;
          if (error) {
            console.error(error);
          }
        });
      }
    },
    // arrowMove(type) {
    //   if (type == "left") {
    //     curIndex -= 5;
    //     if (curIndex < 0) {
    //       curIndex = 1;
    //     }
    //     carouselPane.value.childNodes[curIndex].scrollIntoView({
    //       behavior: "smooth"
    //     });
    //   } else {
    //     curIndex += 5;
    //     if (curIndex > carouselPane.value.childNodes.length - 2) {
    //       curIndex = carouselPane.value.childNodes.length - 2;
    //     }
    //     carouselPane.value.childNodes[curIndex].scrollIntoView({
    //       behavior: "smooth"
    //     });
    //   }
    // },
    // 初始化展开菜单
    // initialize opened menu
    initOpenedMenu() {
      const index = this.activeIndex;
      const activeItem = this.items[index];
      if (!activeItem || this.mode === "horizontal" || this.collapse) {
        return;
      }

      const { indexPath } = activeItem;

      // 展开该菜单项的路径上所有子菜单
      // expand all submenus of the menu item
      indexPath.forEach((i) => {
        const submenu = this.submenus[i];
        submenu && this.openMenu(i, submenu.indexPath);
      });
    },
    routeToItem(item, onError) {
      const route = item.route || item.index;
      try {
        this.$router.push(route, () => {}, onError);
      } catch (e) {
        console.error(e);
      }
    },
    open(index) {
      const { indexPath } = this.submenus[index.toString()];
      indexPath.forEach((i) => this.openMenu(i, indexPath));
    },
    close(index) {
      this.closeMenu(index);
    }
  },

  render() {
    const component = (
      <div >
        <span class='scroll-icon' style={this.navBgStyle} on-click={this.scrollPrev}><i class="el-icon-caret-left"></i></span>
        <div ref="navScroll" class='nav-wrap'><ul
          role='menubar'
          key={ +this.collapse }
          ref="nav"
          style={{ backgroundColor: this.backgroundColor || "", ...this.navStyle, ...this.navBgStyle }}
          class={{
            "el-menu--horizontal": this.mode === "horizontal",
            "el-menu--collapse": this.collapse,
            "el-menu": true
          }}
        >
          { this.$slots.default }
        </ul></div>
       <span class='scroll-icon' style={this.navBgStyle} on-click={this.scrollNext}> <i class="el-icon-caret-right"></i></span>
      </div>

    );
    // console.log(this.collapseTransition ,"collapseTransition" );
    if (this.collapseTransition) {
      return (
        <el-menu-collapse-transition>
          { component }
        </el-menu-collapse-transition>
      );
    }
    return component;
  },

  componentName: "ElMenu",

  provide() {
    return { rootMenu: this };
  }
};
</script>
<style lang="scss" scoped>
.el-menu-item,
.el-submenu__title {
  height: 50px !important;
  line-height: 50px !important;
  position: relative;
  white-space: nowrap;
  list-style: none;
  float:none;
  display: inline-block;
  margin-left: -1px;
}
.nav-wrap{
  overflow:hidden;
  height:50px;
}
.scroll-icon{
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 2px;
  font-size: 14px;
  color: #fff;
}
.el-menu--horizontal{
  float: left;
  transition: transform .3s;
  white-space: nowrap;
  border: none;
  background: transparent;
  li{
    display: inline-block!important;
    float: none;
    margin-left: -1px!important;
  }
}
</style>
