const routes = [
  {
    path: "/payroll/paystubNav",
    name: "paystubNav",
    component: () => import(/* webpackChunkName:"payroll" */ "@/views/payRoll/user/paystubNav/index"),
    meta: {
      title: "工资条(title)",
      keepAlive: false,
      fullPage: true,
      noVerify: true // 是否需要密码验证
    }
  }
];

export default routes;
