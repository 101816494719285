<!--
 * @Author: Fenngding
 * @Date: 2019-09-19 16:45:24
 * @LastEditors: Fengding
 * @LastEditTime: 2019-09-19 16:52:30
 * @Email: fenngding@live.com
 * @Description:
 -->
<template>
  <div class="main-full-page-container">
    <keep-alive>
      <router-view
        v-if="$route.meta.keepAlive"
        :key="$route.fullPath"
        class="full-page"
      />
    </keep-alive>
    <router-view
      v-if="!$route.meta.keepAlive"
      :key="$route.fullPath"
      class="full-page"
    />
  </div>
</template>
<script>
export default {
  name: "MainFullPage",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  methods: {}
};
</script>
<style scoped lang="scss">
.main-full-page-container {
  height: 100%;
  width: 100%;
  .full-page {
    position: relative;
    height: 100%;
    width: 100%;
  }
}
</style>
