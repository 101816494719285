/*
 * @Author: zhulin
 * @Date: 2020-06-16 17:12:51
 * @LastEditors: zhulin
 * @LastEditTime: 2020-07-02 16:37:21
 * @Description: file content
 */
import SettingService from "./settingService";
import PreferenceService from "./preferenceService";
import AuthorizeService from "./authorizeService";
import SwitchService from "./switchService";
import RechargeService from "./recharge";

export const settingService = new SettingService();
export const preferenceService = new PreferenceService();
export const authorizeService = new AuthorizeService();
export const switchService = new SwitchService();
export const rechargeService = new RechargeService();
