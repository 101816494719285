/* eslint-disable no-bitwise */
/*
 * @Author: 李煜
 * @Date: 2020/8/27
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-02-29 13:18:52
 * @Description: 公共方法
 */
import dayjs from "dayjs";
import { customAlphabet } from "nanoid";
import { isObject, isArray } from "lodash";
import { globalVarible } from "@/api/common";
// eslint-disable-next-line import/no-cycle
import VueApp from "@/main.js";
/**
 * @description 生成nanoid
 */
export const generateNanoid = customAlphabet("1234567890abcdef", 10);
export const getAgeFromIdNumber = (idNumber) => {
  const l = idNumber.length;
  let strBirthday = "";
  if (l === 18) {
    strBirthday = `${idNumber.substr(6, 4)}/${idNumber.substr(10, 2)}/${idNumber.substr(12, 2)}`;
  } else if (l === 15) {
    strBirthday = `19${idNumber.substr(6, 2)}/${idNumber.substr(8, 2)}/${idNumber.substr(10, 2)}`;
  }
  const birthDate = dayjs(strBirthday).toDate();
  const nowDateTime = dayjs().toDate();
  let age = nowDateTime.getFullYear() - birthDate.getFullYear();
  if (nowDateTime.getMonth() < birthDate.getMonth() || (nowDateTime.getMonth() === birthDate.getMonth() && nowDateTime.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const getAgeFromDate = (value) => {
  if (!value) {
    return null;
  }
  const date = dayjs(value);
  const nowDate = dayjs();
  let age = nowDate.year() - date.year();
  if (nowDate.month() < date.month()
    || (nowDate.month() === date.month() && nowDate.date() < date.date())
  ) {
    age--;
  }
  return age;
};

export const randomNum = (minNum, maxNum) => parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
export const fmoney = (num, n = 2) => {
  // n = n > 0 && n <= 20 ? n : 2;
  const ss = Number(num).toFixed(n).toString();
  const l = ss.split(".")[0].split("").reverse();
  const r = ss.split(".")[1] || "";
  let t = "";
  for (let i = 0; i < l.length; i++) {
    t += l[i] + ((i + 1) % 3 === 0 && (i + 1) !== l.length ? "," : "");
  }
  return (t.split("").reverse().join("") + (r ? `.${r}` : "")).replace("-,", "-");
};
export const renameKeys = (keysMap, obj) => Object.keys(obj).reduce(
  (acc, key) => ({
    ...acc,
    ...{ [keysMap[key] || key]: obj[key] }
  }),
  {}
);
export function downFile(blob, fileName) {
  if (window.navigator.msSaveOrOpenBlob) {
    navigator.msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
    window.URL.revokeObjectURL(link.href);
  }
}
export function dealStreamResp(httpResp) {
  const fileName = httpResp.headers["content-disposition"] && httpResp.headers["content-disposition"].split("filename=").pop();
  const contentType = httpResp.headers["content-type"];
  const blob = new Blob([httpResp.data], { type: httpResp.headers["content-type"] });
  if (contentType.indexOf("application/json") > -1) {
    return { cType: "json", ...httpResp };
  }
    return { blob, fileName, cType: "stream" };
}
/**
 * @description: 获取数据类型
 * @param {*} obj
 * @return string|number|boolean|object|array|undefined|null|function|date|regexp
 */
export const getObjectType = (obj) => {
  let str = Object.prototype.toString.call(obj);
  str = str.replace(/\[|]/g, "");
  return str.split(" ")[1].toLowerCase();
};

/**
 * @description: 查询全局变量
 * @param fieldType
 * @param type  001机构  002人员  3时间区间  4时间点
 */
export const queryGlobalVarible = async(fieldType, type) => {
  let tp;
  if (fieldType === 5 || fieldType === 15) { // 单选多选 人员机构
    tp = type === "001" ? 2 : type === "002" ? 1 : "";
  } else if (fieldType === 7) {
    tp = type;
  }

  if (!tp) {
    return [];
  }

  const res = await globalVarible.getVariable({ type: tp });
  const arr = [];

  res.forEach((itm) => {
    // 筛选 用户端使用  启用状态的变量
    if (itm.enable === 0 && itm.isUser === 0 && Number(itm.id) !== 0) {
      arr.push(itm);
    }
  });

  return arr;
};

/**
 * @description 打开office-online v4.9.7
 * @param openType 打开类型 1查看 2编辑
 * @param extension 文件类型 eg: doc docx xlsx pdf
 * @param fileCode 文件code
*/
export const openOfficeOnline = (openType, extension, fileCode) => {
  const url = `/office-online?openType=${openType}&extension=${extension}&fileCode=${fileCode}`;

  window.open(url, "_blank");
};

/**
 * @description 解析地址中的参数
 * @param url 地址
 * @param key key
*/
export const getUrlParamByKey = (url, key) => {
  const locString = url || location.search;
  const reg = new RegExp(`(\\?|\\&)${ key }=([^\\&]*)(\\&?)`, "i").exec(locString);
  if (reg != null) {
      return decodeURI(RegExp.$2);
  }
  return "";
};
/**
     * 字符串编码
     * @param password rsa后的密码
     * @returns {*} 编码后的字符串
     */
export const encode = (password) => {
  password = password.replace(/\+/g, "%2B");
  password = password.replace(/\//g, "%2F");
  return password;
};

/**
 * @description: base64加密解密
 * @param {*}
 * @return {*}
 */
const base64EncodeChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
const base64DecodeChars = [
     -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
     -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
     -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 62, -1, -1, -1, 63,
     52, 53, 54, 55, 56, 57, 58, 59, 60, 61, -1, -1, -1, -1, -1, -1,
     -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,
     15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, -1, -1, -1, -1, -1,
     -1, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
     41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, -1, -1, -1, -1, -1
 ];
function base64encode(str) {
  let out = "";
  let i = 0;
  let c1;
  let c2;
  let c3;
  const len = str.length;
  while (i < len) {
      c1 = str.charCodeAt(i++) & 0xff;
      if (i === len) {
          out += base64EncodeChars.charAt(c1 >> 2);
          out += base64EncodeChars.charAt((c1 & 0x3) << 4);
          out += "==";
          break;
      }
      c2 = str.charCodeAt(i++);
      if (i === len) {
          out += base64EncodeChars.charAt(c1 >> 2);
          out += base64EncodeChars.charAt(((c1 & 0x3) << 4) | ((c2 & 0xF0) >> 4));
          out += base64EncodeChars.charAt((c2 & 0xF) << 2);
          out += "=";
          break;
      }
      c3 = str.charCodeAt(i++);
      out += base64EncodeChars.charAt(c1 >> 2);
      out += base64EncodeChars.charAt(((c1 & 0x3) << 4) | ((c2 & 0xF0) >> 4));
      out += base64EncodeChars.charAt(((c2 & 0xF) << 2) | ((c3 & 0xC0) >> 6));
      out += base64EncodeChars.charAt(c3 & 0x3F);
  }
  return out;
}

function base64decode(str) {
  str = str.replace(/\s+/g, "+");
  let c1;
  let c2;
  let c3;
  let c4;
  let i = 0;
  let out = "";
  const len = str.length;
  while (i < len) {
      /* c1 */
      do {
          c1 = base64DecodeChars[str.charCodeAt(i++) & 0xff];
      } while (i < len && c1 === -1);
      if (c1 === -1) { break; }
      /* c2 */
      do {
          c2 = base64DecodeChars[str.charCodeAt(i++) & 0xff];
      } while (i < len && c2 === -1);
      if (c2 === -1) { break; }
      out += String.fromCharCode((c1 << 2) | ((c2 & 0x30) >> 4));
      /* c3 */
      do {
          c3 = str.charCodeAt(i++) & 0xff;
          if (c3 === 61) { return out; }
          c3 = base64DecodeChars[c3];
      } while (i < len && c3 === -1);
      if (c3 === -1) { break; }
      out += String.fromCharCode(((c2 & 0XF) << 4) | ((c3 & 0x3C) >> 2));
      /* c4 */
      do {
          c4 = str.charCodeAt(i++) & 0xff;
          if (c4 === 61) { return out; }
          c4 = base64DecodeChars[c4];
      } while (i < len && c4 === -1);
      if (c4 === -1) { break; }
      out += String.fromCharCode(((c3 & 0x03) << 6) | c4);
  }
  return out;
}

function utf16to8(str) {
  let i;
  let c;
  let out = "";
  const len = str.length;
  for (i = 0; i < len; i++) {
      c = str.charCodeAt(i);
      if ((c >= 0x0001) && (c <= 0x007F)) {
          out += str.charAt(i);
      } else if (c > 0x07FF) {
          out += String.fromCharCode(0xE0 | ((c >> 12) & 0x0F));
          out += String.fromCharCode(0x80 | ((c >> 6) & 0x3F));
          out += String.fromCharCode(0x80 | ((c >> 0) & 0x3F));
      } else {
          out += String.fromCharCode(0xC0 | ((c >> 6) & 0x1F));
          out += String.fromCharCode(0x80 | ((c >> 0) & 0x3F));
      }
  }
  return out;
}

function utf8to16(str) {
  let i;
  let c;
  let char2;
  let char3;
  let out = "";
  const len = str.length;
  i = 0;
  while (i < len) {
      c = str.charCodeAt(i++);
      switch (c >> 4) {
          case 0:
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
          case 6:
          case 7:
              // 0xxxxxxx
              out += str.charAt(i - 1);
              break;
          case 12:
          case 13:
              // eslint-disable-next-line no-irregular-whitespace
              // 110x xxxx　 10xx xxxx
              char2 = str.charCodeAt(i++);
              out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
              break;
          case 14:
              // eslint-disable-next-line no-irregular-whitespace
              // 1110 xxxx　10xx xxxx　10xx xxxx
              char2 = str.charCodeAt(i++);
              char3 = str.charCodeAt(i++);
              out += String.fromCharCode(((c & 0x0F) << 12)
                  | ((char2 & 0x3F) << 6)
                  | ((char3 & 0x3F) << 0));
              break;
      }
  }
  return out;
}

function base64Encode(str) {
  return base64encode(utf16to8(str));
}

function base64Decode(str) {
  return utf8to16(base64decode(str));
}
export {
  base64Encode,
  base64Decode
};
export function xhrDownload(url, errorCb) {
  const xhr = new XMLHttpRequest();
  xhr.open("GET", url, true);
  xhr.responseType = "blob";
  let fileName;
  xhr.onload = function dd() {
      if (this.status === 200) {
          const blob = this.response;

          // console.log(this);
          if (navigator.msSaveBlob == null) {
              const a = document.createElement("a");
              // const headerName = xhr.getResponseHeader("Content-disposition");
              const fileName = getUrlParamByKey(url, "attachmentName");
              a.download = fileName;
              a.href = URL.createObjectURL(blob);
              document.body.appendChild(a); // 修复firefox中无法触发click
              a.click();
              URL.revokeObjectURL(a.href);
              document.body.removeChild(a);
          } else {
              navigator.msSaveBlob(blob, fileName);
          }
      } else {
        this.onerror(`${this.status}:找不到对应文件`);
      }
  };
  xhr.onabort = function d() {
    console.log("** 请求被中止");
  };
  xhr.onerror = (error) => {
    console.log(error);
    errorCb && errorCb(error);
  };
  try {
    xhr.send();
  } catch (error) {
    errorCb && errorCb(error);
  }
}
export function verifyMenuPower(menuList = [], code) {
  let has = false;
  // eslint-disable-next-line consistent-return
  menuList.forEach((item) => {
    // eslint-disable-next-line consistent-return
    item?.dataList?.forEach((item2) => {
      if (item2.code === code) {
          has = true;
          return false;
      }
    });
    if (has) {
      return false;
    }
  });
  return has;
}
export function orderASCII(str1, str2) {
      if (!str1 && !str2) {
          return 0;
      } if (!str1) {
          return -1;
      } if (!str2) {
          return 1;
      }
      const code1 = str1.charAt(0).charCodeAt();
      const code2 = str2.charAt(0).charCodeAt();
      if (code2 > code1) {
          return -1;
      } if (code2 < code1) {
          return 1;
      }
      return orderASCII(str1.substring(1, str1.length), str2.substring(1, str2.length));
}
export function arrSort(arr, field, type, order) {
  return arr.sort((a, b) => {
      let v = 0;
      if (type === "1" || type === "2") {
          v = orderASCII(a[field], b[field]);
      } else if (type === "3" || type === "4") {
          if (b[field] > a[field]) {
              v = -1;
          } else if (b[field] < a[field]) {
              v = 1;
          }
      } else if (type === "5") {
          if (!a[field] && !b[field]) {
              v = 0;
          } else if (!a[field]) {
              v = -1;
          } else if (!b[field]) {
              v = 1;
          } else {
              v = orderASCII(a[field][0].name, b[field][0].name);
          }
      } else if (type === "7") {
          if (!b[field]) {
              v = 1;
          } else if (!a[field]) {
              v = -1;
          } else {
              const c = new Date(a[field].split(",")[0]).getTime();
              const d = new Date(b[field].split(",")[0]).getTime();
              if (d > c) {
                  v = -1;
              } else if (d < c) {
                  v = 1;
              } else if (a[field].split(",")[1] && b[field].split(",")[1]) {
                const c1 = new Date(a[field].split(",")[1]).getTime();
                const d1 = new Date(b[field].split(",")[1]).getTime();
                if (d1 > c1) {
                    v = -1;
                } else if (d1 < c1) {
                    v = 1;
                }
            }
          }
      } else if (type === "9") {
          if (!a[field] && !b[field]) {
              v = 0;
          } else if (!a[field]) {
              v = -1;
          } else if (!b[field]) {
              v = 1;
          } else {
              v = orderASCII(a[field][0].name, b[field][0].name);
          }
      } else {
          v = 0;
      }
      if (order) { // 1 降序
          v *= (-1);
      } else { // 0 升序
      }
      return v;
  });
}
export function customEach(objOrArr = [], callback) {
  if (isArray(objOrArr)) {
    objOrArr.forEach((v, k) => {
      callback(k, v);
    });
  } else if (isObject(objOrArr)) {
    Object.keys(objOrArr).forEach((v) => {
      callback(v, objOrArr[v]);
    });
  }
}
/* eslint-disable */
function getError(action, option, xhr) {
  let msg;
  if (xhr.response) {
    msg = `${xhr.response.error || xhr.response}`;
  } else if (xhr.responseText) {
    msg = `${xhr.responseText}`;
  } else {
    msg = `fail to post ${action} ${xhr.status}`;
  }

  const err = new Error(msg);
  err.status = xhr.status;
  err.method = "post";
  err.url = action;
  return err;
}

function getBody(xhr) {
  const text = xhr.responseText || xhr.response;
  if (!text) {
    return text;
  }

  try {
    return JSON.parse(text);
  } catch (e) {
    return text;
  }
}

export function rgbaToHex(rgba) {
  const rgb = rgba.split(",");
  const r = parseInt(rgb[0].split("(")[1], 10);
  const g = parseInt(rgb[1], 10);
  const b = parseInt(rgb[2].split(")")[0], 10);
  console.log("r", r, g, b);
  // eslint-disable-next-line no-bitwise
  const hex = `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
  console.log("hex", hex);
  return hex;
}

export function upload(option) {
  return new Promise((resolve) => {
    if (typeof XMLHttpRequest === "undefined") {
      return;
    }

    const xhr = new XMLHttpRequest();
    const action = option.action;

    if (xhr.upload) {
      xhr.upload.onprogress = function progress(e) {
        if (e.total > 0) {
          e.percent = e.loaded / e.total * 100;
        }
        if (option.onProgress) {
          option.onProgress(e);
        }
      };
    }

    const formData = new FormData();

    if (option.data) {
      Object.keys(option.data).forEach((key) => {
        formData.append(key, option.data[key]);
      });
    }

    formData.append(option.filename, option.file, option.file.name);
    xhr.onerror = function error(e) {
      option.onError(e);
    };

    xhr.onload = function onload() {
      if (xhr.status < 200 || xhr.status >= 300) {
        return option.onError(getError(action, option, xhr));
      }
      if (option.onSuccess) {
        option.onSuccess(getBody(xhr));
      }
      resolve(getBody(xhr));
    };

    xhr.open("post", action, true);

    if (option.withCredentials && "withCredentials" in xhr) {
      xhr.withCredentials = true;
    }

    const headers = option.headers || {};

    for (const item in headers) {
      if (headers.hasOwnProperty(item) && headers[item] !== null) {
        xhr.setRequestHeader(item, headers[item]);
      }
    }
    xhr.send(formData);
    return xhr;
  });
}

