import BaseService from "../BaseService";
// import Store from "@/store";

/**
 * login相关
 */
class TripService extends BaseService {
  pk = "";

  constructor(http) {
    super("", http);
  }

  /**
   * 查询我的行程列表
   * @param params 参数
   */
  queryMyTrip(params) {
    return this.http.post("queryMyTrip", params);
  }

  /**
   * 查询行程单详情
   * @param params 参数
   */
  queryTripDetail(params) {
    return this.http.post("queryTripDetail", params);
  }

  /**
   * 查询行程单配置数据
   * @param params 参数
   */
  queryComConfig(params) {
    return this.http.post("queryComConfig", params);
  }

  /**
   * 查询审批流程
   * @param params 参数
   */
  queryWfQueryFlowDetail(params) {
    return this.http.post("wfQueryFlowDetail", params);
  }

  /**
   * 查询业务建模数据
   * @param params 参数
   */
  queryMdSearchDataInfo(params) {
    return this.http.post("mdSearchDataInfo", params);
  }

  /**
   * 新增节点
   * @param params 参数
   */
  queryDeleteTripNode(params) {
    // const { http } = this;
    return this.http.post("deleteTripNode", params);
  }

  /**
   * 删除节点
   * @param params 参数
   */
  querySaveTripNode(params) {
    // const { http } = this;
    return this.http.post("saveTripNode", params);
  }

  /**
   * 编辑节点
   * @param params 参数
   */
  queryEditTripNode(params) {
    // const { http } = this;
    return this.http.post("updateTripNode", params);
  }

  /**
   * 取消节点
   * @param params 参数
   */
  queryCancelTripNode(params) {
    // const { http } = this;
    return this.http.post("cancelTripNode", params);
  }

  /**
   * 转移节点
   * @param params 参数
   */
  queryTransferTravelNode(params) {
    // const { http } = this;
    return this.http.post("transferTravelNode", params);
  }

  /**
   * 查询节点详情
   * @param params 参数
   */
  queryTripNodeDetail(params) {
    // const { http } = this;
    return this.http.post("queryTripNodeDetail", params);
  }

  /**
   * 查询新的行程节点信息
   * @param params 参数
   */
  queryTryNewTripNode(params) {
    // const { http } = this;
    return this.http.post("trQueryNewTripNode", params);
  }

  /**
   * 退票
   * @param params 参数
   */
  queryApplyReturnTicketApp(params) {
    return this.http.post("applyReturnTicketApp", params);
  }

  /**
   * 改签
   * @param params 参数
   */
  queryValidateIsChangeApp(params) {
    return this.http.post("validateIsChangeApp", params);
  }

  /**
   * 查询机票退改规则
   * @param params 参数
   */
  queryFlightTicketRule(params) {
    // const { http } = this;
    return this.http.post("getTicketRule", params);
  }

  /**
   * 检测超标
   * @param params 参数
   */
  queryCheckSupplement(params) {
    // const { http } = this;
    return this.http.post("checkSupplement", params);
  }

  /**
   * 保存行程
   * @param params 参数
   */
  querySaveTrip(params) {
    return this.http.post("saveTrip", params);
  }

  /**
   * 提交行程
   * @param params 参数
   */
  querySubmitTrip(params) {
    return this.http.post("submitTrip", params);
  }

  /**
   * 删除行程
   * @param params 参数
   */
  queryDeleteTrip(params) {
    return this.http.post("deleteTrip", params);
  }

  /**
   * 结束行程
   * @param params 参数
   */
  queryEndTrip(params) {
    return this.http.post("endTrip", params);
  }

  /**
   * 终止行程
   * @param params 参数
   */
  queryCloseTrip(params) {
    return this.http.post("closeTrip", params);
  }

  /**
   * 共享行程
   * @param params 参数
   */
  queryShareTrip(params) {
    return this.http.post("tripShare", params);
  }

  /**
   * 查询可转移的行程单列表
   * @param params 参数
   */
  queryTransferableTravelList(params) {
    return this.http.post("queryTransferableTravelList", params);
  }

  /**
   * 查询可关联的行程单列表
   * @param params 参数
   */
  queryTripForRelation() {
    return this.http.post("queryTripForRelation", {});
  }

  /**
   * 更新差补金额
   * @param params 参数
   */
  queryUpdateSubsidyAmount(params) {
    return this.http.post("updateSubsidyAmount", params);
  }

  /**
   * 报销校验
   * @param params 参数
   */
  queryTripExpense(params) {
    return this.http.post("tripExpense", params);
  }

  /**
   * 历史常用联系人
   * @param params 参数
   */
  queryTripHistoryContacts() {
    return this.http.post("tripHisContacts", {});
  }

  /**
   * 新增联系人
   * @param params 参数
   */
  queryTripAddContacts(params) {
    return this.http.post("tripAddContacts", params);
  }

  /**
   * 修改联系人信息
   * @param params 参数
   */
  queryTripUpdateContacts(params) {
    return this.http.post("tripUpdateContacts", params);
  }

  /**
   * 搜索联系人
   * @param params 参数
   */
  queryTripSearchContacts(params) {
    return this.http.post("tripQueryContacts", params);
  }

  /**
   * 保存行程单
   * @param params
   * @returns {*}
   */
  savetripnode(params) {
    const { http } = this;
    return http.post("savetripnode", params); // 不使用module
  }

  /**
   * 查询
   * @param params
   * @returns {*}
   */
  trQueryNewTripNode(params) {
    const { http } = this;
    return http.post("trQueryNewTripNode", params); // 不使用module
  }

  /**
   * 保存行程
   * @param params
   * @returns {*}
   */
  saveTripNode(params) {
    const { http } = this;
    return http.post("saveTripNode", params);
  }

  /**
   * 重新预定保存行程
   * @param params
   * @returns {*}
   */
  tripAddNewTripNode(params) {
    const { http } = this;
    return http.post("tripAddNewTripNode", params);
  }

  /**
   * 请求选择共享人
   * @param params
   * @returns {*}
   */
  requestBdQueryPersonDeptTree(params) {
    const { http } = this;
    return http.post("bdQueryPersonDeptTree", params);
  }

  /**
   * 校验行程期间是否有重复
   * @param params
   * @returns {*}
   */
  checkRepeatTripDate(params) {
    const { http } = this;
    return http.post("checkRepeatTripDate", params);
  }

  /**
   * @param params
   * @returns {*}
   */
   tripResourceSetSearch(params) {
    const { http } = this;
    return http.post("tripResourceSetSearch", params);
  }
}

export default TripService;
