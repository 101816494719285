/**
 * @Author en-cli v0.1.1
 * @Date 2020/05/28 14:41
 * @Description 工程入口
 */
/* eslint-disable */
import state from "./state";
import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

export default {
  namespaced: true,
  strict: false,
  state,
  getters,
  actions,
  mutations
};
