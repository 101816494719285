/*
 * @Author: pengyu
 * @Date: 2020-06-24 14:55:07
 * @LastEditors: pengyu
 * @LastEditTime: 2020-07-02 19:08:13
 * @Description: 凭证生成-接口
 */
import BaseService from "../BaseService";

class CredentialService extends BaseService {
  pk = ""

  constructor(http) {
    super("", http);
  }

  /**
 * @description: 查询凭证生成列表
 * @param params
 */
  queryVoucherGeneration(params) {
    const { http } = this;
    return http.post("vcQueryVoucherGeneration", params);
  }

  /**
 * @description: 生成凭证
 * @param params
 */
  generateVoucher(params) {
    const { http } = this;
    return http.post("vcOperatorVoucherGenerate", params);
  }

  /**
 * @description: 删除凭证
 * @param params
 */
  deleteVoucher(params) {
    const { http } = this;
    return http.post("vcDeleteVoucher", params);
  }
}

export default CredentialService;
