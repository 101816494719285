import BaseService from "../BaseService";

/**
 * login相关
 */
class SignInService extends BaseService {
  pk = ""

  constructor(http) {
    super("", http);
  }

  /**
   * 考勤配置信息
   * @param params 参数
   */
  queryUserAttConfig() {
    // const { http } = this;
    return this.http.post("queryUserAttConfig", {});
  }

  /**
   * 查询考勤记录
   * @param params 参数
   */
  queryInfoRecords(params) {
    // const { http } = this;
    return this.http.post("attQueryInfoRecords", params);
  }

  /**
   * 签到
   * @param params 签到参数
   * @returns {*}
   */
  signIn(params) {
    // const { http } = this;
    return this.http.post("attSignIn", params);
  }

  /**
   * 签到信息修改
   * @param params 签到参数
   * @returns {*}
   */
  updateSignIn(params) {
    // const { http } = this;
    return this.http.post("updateSignIn", params);
  }

  /**
   * 查询个人考勤汇总数据
   * @param params 参数
   */
  queryUserAttData(params) {
    // const { http } = this;
    return this.http.post("queryUserAtt", params);
  }

  /**
   * 查询个人考勤明细数据
   * @param params 参数
   */
  queryUserAttDetail(params) {
    // const { http } = this;
    return this.http.post("queryUserAttDetail", params);
  }

  /**
   * 考勤配置项可选数据查询
   * @param params 参数
   */
  queryObjectTreeExtAccess(params) {
    // const { http } = this;
    return this.http.post("queryObjectTreeExt", params);
  }
}

export default SignInService;
