<script>
import ElCollapseTransition from "element-ui/src/transitions/collapse-transition";
import Emitter from "element-ui/src/mixins/emitter";
import Popper from "element-ui/src/utils/vue-popper";
import { cloneDeep } from "lodash";
import {
  createNamespacedHelpers
} from "vuex";
import menuMixin from "./menu-mixin";

const { mapState: mapStateTheme } = createNamespacedHelpers("main");

const poperMixins = {
  props: {
    transformOrigin: {
      type: [Boolean, String],
      default: false
    },
    offset: Popper.props.offset,
    boundariesPadding: Popper.props.boundariesPadding,
    popperOptions: Popper.props.popperOptions
  },
  data: Popper.data,
  methods: Popper.methods,
  beforeDestroy: Popper.beforeDestroy,
  deactivated: Popper.deactivated
};
export default {
  name: "ElSubmenu",

  componentName: "ElSubmenu",

  components: { ElCollapseTransition },

  mixins: [
    menuMixin,
    Emitter,
    poperMixins
  ],

  props: {
    index: {
      type: String,
      required: true
    },
    activeId: {
      type: Number,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: ""
    },
    showTimeout: {
      type: Number,
      default: 300
    },
    navStyles: {
      type: Number,
      default: 1
    },
    hideTimeout: {
      type: Number,
      default: 300
    },
    classList: {
      type: Array,
      default() {
        return [];
      }
    },
    menus: {
      type: Array
    },
    popperClass: String,
    disabled: Boolean,
    popperAppendToBody: {
      type: Boolean,
      default: undefined
    },
    popperStyle: String
  },

  data() {
    return {
      hoverTimer: null,
      popperJS: null,
      timeout: null,
      items: {},
      submenus: {},
      mouseInChild: false,
      showPopMenu: false

    };
  },
  computed: {
    ...mapStateTheme(["themeInfo"]),
    itemStyle() {
      if (this.active) return;
      if (this.activeId === 2 || this.activeId === 3 || this.activeId === 4) {
        // eslint-disable-next-line consistent-return
        return {
          margin: "0 6px"
        };
      } if (this.activeId === 5 || this.activeId === 6) {
        // eslint-disable-next-line consistent-return
        return {
          marginLeft: "8px"
        };
      }
      // eslint-disable-next-line consistent-return
      return { margin: "0 4px" };
    },
    navStyle() {
      const {
 openCustomColor, SelectLabelColor, HaverColor, NoHaverColor, themeStyle, navColor, isCollapse
} = this.themeInfo;
      if (openCustomColor) {
        return {
          // background: this.active ? themeStyle === 1 ? `rgba($color: ${SelectLabelColor}, $alpha: 0.2) !important` : `${SelectLabelColor } !important` : `${navColor}!important`,
          // color: this.active ? `${HaverColor} !important` : `${NoHaverColor }!important`,
          borderLeftColor: this.active ? `${SelectLabelColor } !important` : "#FFFFFF",
          // eslint-disable-next-line no-dupe-keys
          lineHeight: this.navStyles === 2 ? isCollapse ? "20px" : "40px" : "40px",
          height: this.navStyles === 2 ? "64px" : "40px",
          textAlign: isCollapse ? "center" : ""
        };
      }
      return {
          // background: this.active ? themeStyle === 1 ? `rgba($color: ${SelectLabelColor}, $alpha: 0.2) !important` : `${SelectLabelColor } !important` : `${navColor}!important`,
          // color: this.active ? `${HaverColor} !important` : `${NoHaverColor }!important`,
          borderLeftColor: this.active ? `${SelectLabelColor } !important` : "#FFFFFF",
          // eslint-disable-next-line no-dupe-keys
          lineHeight: this.navStyles === 2 ? isCollapse ? "20px" : "40px" : "40px",
          height: this.navStyles === 2 ? "64px" : "40px",
          textAlign: isCollapse ? "center" : ""
        };
    },
    SubnavStyle() {
      const {
 openCustomColor, SelectLabelColor, HaverColor, NoHaverColor, themeStyle, navColor, isCollapse, themeColor
} = this.themeInfo;
      if (openCustomColor) {
        return {
          background: themeColor === "white" ? "#FFFFFF !important" : "#232C3D!important",
          color: "#a9b5c6!important",
          borderLeftColor: this.active ? `${SelectLabelColor } !important` : "#FFFFFF",
          // eslint-disable-next-line no-dupe-keys
          lineHeight: this.navStyles === 2 ? isCollapse ? "48px" : "48px" : "48px"
        };
      }
      return {};
    },
    // popper option
    appendToBody() {
      return this.popperAppendToBody === undefined
        ? this.isFirstLevel
        : this.popperAppendToBody;
    },
    // 处理菜单数据
    subsetList() {
      if (this.classList.length === 0) {
        return [];
      }
        // 根据 存储的 二级分类
        const classifyList = cloneDeep(this.classList || []);
        // {name:"",id:"def",color:"",showIcon:"",refDataIds,refDataList:[]}
        const clonedataList = cloneDeep(this.menus);
        // console.log(this.menus, "clonedataList");
        // 循环 二级分类 处理数据
        classifyList.forEach((v) => {
            v.dataList = [];
            // 字符串变 数字
            v.refDataList = v.refDataIds.split(",");
            v.refDataList.forEach((vId) => {
             const classItem = clonedataList.find((item, ind) => item.data.refId === vId);
             if (classItem && classItem.data && !classItem.data.referji) {
               v.dataList.push(classItem.data);
               classItem.data.referji = true;
             }
            });
        });

        const def = { name: "默认分组", id: "def", dataList: [] };
        def.dataList = clonedataList.filter((v) => !v.data?.referji).map((item) => item.data);

        classifyList.unshift(def);
        return classifyList;
    },
    menuTransitionName() {
      return this.rootMenu.collapse ? "el-zoom-in-left" : "el-zoom-in-top";
    },
    opened() {
      return this.rootMenu.openedMenus.indexOf(this.index) > -1;
    },
    poped() {
      return this.rootMenu.popedMenus.indexOf(this.index) > -1;
    },
    active() {
      let isActive = false;
      const { submenus } = this;
      const { items } = this;
      Object.keys(items).forEach((index) => {
        if (items[index].active) {
          isActive = true;
        }
      });
      Object.keys(submenus).forEach((index) => {
        if (submenus[index].active) {
          isActive = true;
        }
      });

      return isActive;
    },
    hasThreeLevel() {
     return this.classList?.length > 0;
    },
    hoverBackground() {
      return this.rootMenu.hoverBackground;
    },
    backgroundColor() {
      return this.rootMenu.backgroundColor || "";
      // return "#2A3345";
    },
    activeTextColor() {
      return this.rootMenu.activeTextColor || "";
    },
    textColor() {
      return this.rootMenu.textColor || "";
    },
    mode() {
      return this.rootMenu.mode;
    },
    isMenuPopup() {
      console.log(this.rootMenu.isMenuPopup);
      return this.rootMenu.isMenuPopup;
    },
    titleStyle() {
      if (this.mode !== "horizontal") {
        return { color: this.textColor };
      }
      return {
        borderBottomColor: this.active
          ? (this.rootMenu.activeTextColor ? this.activeTextColor : "")
          : "transparent",
        color: this.active
          ? this.activeTextColor
          : this.textColor
      };
    },
    isFirstLevel() {
      let isFirstLevel = true;
      let parent = this.$parent;
      while (parent && parent !== this.rootMenu) {
        if (["ElSubmenu", "ElMenuItemGroup"].indexOf(parent.$options.componentName) > -1) {
          isFirstLevel = false;
          break;
        } else {
          parent = parent.$parent;
        }
      }
      return isFirstLevel;
    }
  },
  watch: {

    poped() {
      console.log("111", this.rootMenu.collapse);
      if (!this.rootMenu.collapse) {
        this.$nextTick(() => {
          this.updatePopper();
        });
      } else {
        this.$nextTick(() => {
          this.updatePopper();
        });
      }
    }
  },
  created() {
    this.$on("toggle-collapse", this.handleCollapseToggle);
    this.$on("mouse-enter-child", () => {
      this.mouseInChild = true;
      clearTimeout(this.timeout);
    });
    this.$on("mouse-leave-child", () => {
      this.mouseInChild = false;
      clearTimeout(this.timeout);
    });
  },
  mounted() {
    this.parentMenu.addSubmenu(this);
    this.rootMenu.addSubmenu(this);
    this.initPopper();
  },
  beforeDestroy() {
    this.parentMenu.removeSubmenu(this);
    this.rootMenu.removeSubmenu(this);
  },
  methods: {
    handleCollapseToggle(value) {
      if (value) {
        this.initPopper();
      } else {
        this.doDestroy();
      }
    },
    addItem(item) {
      this.$set(this.items, item.index, item);
    },
    removeItem(item) {
      delete this.items[item.index];
    },
    // 设置图标
    setChildrenIcon(icon) {
      if (icon.indexOf("bus-board-icon") > -1 || icon.indexOf("bus-ywmb-icon") > -1) {
        return `${icon}_1`;
      }
      return icon;
    },
    setClassIcon(icon) {
      if (icon) {
        return `erjifenlei${icon}_1`;
      }
        return "bus-ywmb-icon_1";
    },
    addSubmenu(item) {
      this.$set(this.submenus, item.index, item);
    },
    removeSubmenu(item) {
      delete this.submenus[item.index];
    },
    handleClick() {
      const { rootMenu, disabled } = this;
      if (
        (rootMenu.menuTrigger === "hover" && rootMenu.mode === "horizontal")
          || (rootMenu.collapse && rootMenu.mode === "vertical")
          || disabled
      ) {
        return;
      }
      this.dispatch("ElMenu", "submenu-click", this);
    },
    handleMouseenter(event, showTimeout = this.showTimeout) {
      if (!("ActiveXObject" in window) && event.type === "focus" && !event.relatedTarget) {
        return;
      }
      const { rootMenu, disabled } = this;
      const that = this;
      if (
        (rootMenu.menuTrigger === "click" && rootMenu.mode === "horizontal")
          || (!rootMenu.collapse && rootMenu.mode === "vertical")
          || disabled
      ) {
        if ((!rootMenu.collapse && rootMenu.mode === "vertical")) {
          this.dispatch("ElSubmenu", "mouse-enter-child");
          clearTimeout(that.hoverTimer);
          clearTimeout(that.timeout);
          that.hoverTimer = setTimeout(() => {
            if (that.hoverTimer) {
              that.handleMouseenterCallBack(showTimeout);
            }
          }, 500);
        }
        return;
      }
      this.dispatch("ElSubmenu", "mouse-enter-child");
      this.handleMouseenterCallBack(showTimeout);
    },
    handleMouseenterCallBack(showTimeout) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.rootMenu.popMenu(this.index, this.indexPath);
      }, showTimeout);

      if (this.appendToBody) {
        this.$parent.$el.dispatchEvent(new MouseEvent("mouseenter"));
      }
    },
    handleMouseleave(deepDispatch = false) {
      const { rootMenu } = this;
      if (
        (rootMenu.menuTrigger === "click" && rootMenu.mode === "horizontal")
          // || (!rootMenu.collapse && rootMenu.mode === "vertical")
      ) {
        return;
      }
      this.dispatch("ElSubmenu", "mouse-leave-child");
      clearTimeout(this.hoverTimer);
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        !this.mouseInChild && this.rootMenu.closePopMenu(this.index);
      }, this.hideTimeout);

      if (this.appendToBody && deepDispatch) {
        if (this.$parent.$options.name === "ElSubmenu") {
          this.$parent.handleMouseleave(true);
        }
      }
    },

    exterHandleScroll() {
      console.log(this.$refs.menuList);
      this.$refs.menuHeader.scrollLeft = this.$refs.menuListWrap.scrollLeft;
      this.$refs.menuList.scrollTop = this.$refs.menuListWrap.scrollTop;
    },
    clickMenuItem(item) {
      this.rootMenu.$parent.clickMenuItem(item);
    },
    handleTitleMouseenter() {
      console.log("handleTitleMouseenter", this.themeInfo.isCollapse);
      if ((this.mode === "horizontal" && !this.rootMenu.backgroundColor) || this.themeInfo.openCustomColor) {
        return;
      }
      const title = this.$refs["submenu-title"];
      title && (title.style.backgroundColor = this.rootMenu.hoverBackground);
    },
    handleTitleMouseleave() {
      if ((this.mode === "horizontal" && !this.rootMenu.backgroundColor) || this.themeInfo.openCustomColor) {
        return;
      }
      const title = this.$refs["submenu-title"];
      title && (title.style.backgroundColor = this.rootMenu.backgroundColor || "");
    },
    updatePlacement() {
      this.currentPlacement = this.mode === "horizontal" && this.isFirstLevel
        ? "bottom-start"
        : "right-start";
    },
    initPopper() {
      this.referenceElm = this.$el;
      this.popperElm = this.$refs.menu;
      this.updatePlacement();
    }
  },
  render() {
    const {
      active,
      activeId,
      opened,
      poped,
      paddingStyle,
      titleStyle,
      navStyle,
      itemStyle,
      SubnavStyle,
      themeInfo,
      // backgroundColor,
      rootMenu,
      currentPlacement,
      menuTransitionName,
      mode,
      disabled,
      popperClass,
      $slots,
      menus,
      subsetList,
      setChildrenIcon,
      setClassIcon,
      isFirstLevel,
      hasThreeLevel,
      popperStyle
    } = this;
    // console.log(popperStyle, "popperStyle");
    const MenuHeaders = (
            <div class='header' ref='menuHeader' on-scroll={() => { this.sysHandleScroll(); }} >
                {subsetList.map((item) => <div class='header-item' on-click={() => { this.clickMenuItem(item); }}>
                             <en-icon class="icon" name={setClassIcon(item.showIcon)} size="16" style={{ color: item.color }} />
                             <span class='lable' >{item.name}</span>
                        </div>)
                }
              </div>
        );
    const MenuList = (
                    <div class='sub-menus' ref='menuListWrap' on-scroll={() => { this.exterHandleScroll(); }}>{
                        subsetList.map((item) => <ul class='list' ref='menuList'>
                          {
                            item?.dataList?.length
                            ? item?.dataList.map((sub) => <li class='item' on-click={() => { this.clickMenuItem(sub); }}>
                                    <en-icon class="icon" name={setChildrenIcon(sub.customIcon)} size="16" color={sub.customColor} /><span class='lable'>{sub.name}</span>
                                   </li>)
                            : ""
                          }
                        </ul>)
                        }
                    </div>
                );
                /* eslint-disable */
    const MenuSecList = (
                    <div class='sub-sec-menus' style={{ ...SubnavStyle }} ref='menuListWrap' on-scroll={() => { this.exterHandleScroll(); }}>{
                        menus.map((item) => <div class='item' ref='menuList' on-click={() => { this.clickMenuItem(item); }}>
                              {
                                <span >
                                    <en-icon class="icon" name={setChildrenIcon(item.icon)} size="16" style={{ color: item.iconColor }} />
                                    <el-tooltip open-delay={1000} disabled={ item.name.length < 6} effect="light" content={item.name} placement="right-end">
                                       <span class='lable'>{ item.name.length > 6 ? item?.name.slice(0, 5) + "..." : item.name }</span>
                                    </el-tooltip>
                                </span>
                              }
                            </div>)
                        }
                    </div>
            );
            /* eslint-enable */
    const popupMenu = (
      <transition name={menuTransitionName}>
        <div
          ref='menu'
          v-show={poped}
          class={[`el-menu--${mode}`, popperClass]}
          on-mouseenter={($event) => this.handleMouseenter($event, 100)}
          on-mouseleave={() => this.handleMouseleave(true)}
          on-focus={($event) => this.handleMouseenter($event, 100)}
          style={popperStyle}
          >
          <div
            role='menu'
          class={["el-menu el-menu--popup", `el-menu--popup-${currentPlacement}`, "popup-menus", `${themeInfo.themeColor}-popup-menus`]}
            style={{ backgroundColor: rootMenu.backgroundColor || "" }}>
            {
              !hasThreeLevel ? (<div>{MenuSecList}</div>) : ([MenuHeaders, MenuList])
            }
          </div>
        </div>
      </transition>
    );
    // const inlineMenu = (
    //   <el-collapse-transition>
    //     <ul
    //       role='menu'
    //       class='el-menu el-menu--inline'
    //       v-show={ opened }
    //       style={{ backgroundColor: rootMenu.backgroundColor || "" }}>
    //       {$slots.default}
    //     </ul>
    //   </el-collapse-transition>
    // );

    let submenuTitleIcon = (
      (rootMenu.mode === "horizontal" && isFirstLevel)
        || (rootMenu.mode === "vertical" && !rootMenu.collapse)
    ) ? "el-icon-arrow-down" : "el-icon-arrow-right";
    if (rootMenu.mode === "vertical") {
      submenuTitleIcon = opened ? "el-icon-arrow-down" : "el-icon-arrow-right";
      if (rootMenu.collapse) {
        submenuTitleIcon = opened ? "el-icon-arrow-right" : "el-icon-arrow-right";
      }
    }
    return (
      <li
        class={{
          "el-submenu": true,
          "is-active": active,
          "is-opened": opened,
          "is-disabled": disabled
        }}
        role='menuitem'
        aria-haspopup='true'
        aria-expanded={opened}
        on-mouseenter={this.handleMouseenter}
        on-mouseleave={() => this.handleMouseleave(false)}
        on-focus={this.handleMouseenter}
      >
        <div
          ref='submenu-title'
          on-click={this.handleClick}
          class={{
          "el-submenu__title": true,
          "is-1": activeId === 1,
          "is-2": activeId === 2,
          "is-3": activeId === 3,
          "is-4": activeId === 4,
          "is-collapseStyle": themeInfo.isCollapse
          }}
          on-mouseenter={this.handleTitleMouseenter}
          on-mouseleave={this.handleTitleMouseleave}
          style={[paddingStyle, titleStyle, navStyle, itemStyle]}
        >
          {$slots.title}
          <i class={["el-submenu__icon-arrow", submenuTitleIcon]} v-show={!themeInfo.isCollapse}></i>
        </div>
        { popupMenu }

      </li>
    );
  }
};
</script>
<style lang="scss" scoped>
.el-menu-item,
.el-submenu__title {
  height: 40px;
  position: relative;
  white-space: nowrap;
  list-style: none;
  /deep/ .el-submenu__icon-arrow {
    transform: none !important;
  }
}
.is-collapseStyle{
  display:flex;
  justify-content: center;
  align-items: center;
}
.arrow-left{
  background: #232c3d !important;
  width: 10px;
  height: 10px;
  display: inline-block;
  position: absolute;
  top: 18px;
  transform:rotate(45deg)
}
.popup-menus{
  min-width: 190px;
  max-width: 70vw;
  overflow: hidden;
  white-space: nowrap;
  padding: 0 0 2px 0;
  color: #333333;
  font-size: 14px;
  background: $whiteThemeBg;
  border-radius: 4px;
  .header{
    height: 48px;
    line-height: 48px;
    overflow-x: auto;
    border-bottom: 1px solid #E6EAF0;
    writing-mode: horizontal-tb;
    &::-webkit-scrollbar{
      width: 0px;
      height: 0px;
    }
    .header-item{
      cursor: pointer;
      writing-mode:horizontal-tb;
      display: inline-block;
      align-items: center;
      align-content: center;
      padding-left: 20px;
      width:190px;
      color: #333333;
    }
  }
  .sub-menus{
    background: $whiteThemeBg;
    display: inline-block;
    color: #333333;
    padding: 0;
    margin: 0;
    vertical-align: top;
    flex: 0 0 190px;
    display: flex;
    align-items: stretch;
    max-height: 70vh;
    overflow-y:hidden;
    .list{
      padding: 0;
      margin: 0;
      flex:0 0 190px;
      overflow-y: auto;
      .item{
        height: 48px;
        line-height: 48px;
        width: 198px;
        padding-left:20px;
        cursor: pointer;
        &:hover{
          border-radius: 4px;
          background: #F5F8FC !important;
        }
      }
      &::-webkit-scrollbar{
        width: 0;
        height: 0;
      }
    }

  }
  .sub-sec-menus{
    max-height: 504px;
    max-width: 70vw;
    writing-mode: vertical-lr;
    white-space: initial;
    padding-bottom: 2px;
    background: $whiteThemeBg;
    .item{
      cursor: pointer;
      writing-mode:horizontal-tb;
      display: inline-block;
      color: #333333!important;
      height: 48px;
      line-height: 48px;
      width: 198px;
      padding-left:20px;
      &:hover{
          border-radius: 4px;
          background: #F5F8FC !important;
      }
    }
  }
  .lable,.icon {
      display: inline-block;
      vertical-align: middle !important;
    }
    .lable{
      width: 150px;
      margin-left: 10px;
      overflow: hidden;
      white-space: nowrap; /* 防止文字换行 */
      text-overflow: ellipsis;
      overflow: hidden;
      display: inline-block;
      max-width: 160px;
    }
  &::-webkit-scrollbar{
    background: #172237;
  }
}
.dark-popup-menus {
  background: rgb(23, 34 ,55)!important;
  color: #FFFFFF !important;
  .sub-sec-menus {
    background: rgb(23, 34 ,55)!important;
    .item{
      color: #FFFFFF !important;
      cursor: pointer;
      writing-mode:horizontal-tb;
      display: inline-block;
      height: 48px;
      line-height: 48px;
      width: 198px;
      padding-left:20px;
      &:hover{
          border-radius: 4px;
          background: rgba($color: #fff, $alpha: 0.15) !important;
      }
    }
  }
}
.dark-popup-menus {
  .header{
    background: rgba($color: #172237, $alpha: 1) !important;
    height: 48px;
    line-height: 48px;
    overflow-x: auto;
    border-bottom: 1px solid rgba($color: #75839a, $alpha: 0.2);
    writing-mode: horizontal-tb;
    &::-webkit-scrollbar{
      width: 0px;
      height: 0px;
    }
    .header-item{
      cursor: pointer;
      writing-mode:horizontal-tb;
      display: inline-block;
      align-items: center;
      align-content: center;
      padding-left: 20px;
      width:190px;
      color: #333333;
    }
  }
  .sub-menus{
    display: inline-block;
    background: rgba($color: #172237, $alpha: 0.92) !important;
    color: #FFFFFF!important;
    padding: 0;
    margin: 0;
    vertical-align: top;
    flex: 0 0 190px;
    display: flex;
    align-items: stretch;
    max-height: 70vh;
    overflow-y:hidden;
    .list{
      padding: 0;
      margin: 0;
      flex:0 0 190px;
      overflow-y: auto;
      .item{
        height: 48px;
        line-height: 48px;
        width: 198px;
        padding-left:20px;
        cursor: pointer;
        &:hover{
          border-radius: 4px;
          background: rgba($color: #fff, $alpha: 0.15) !important;
        }
      }
      &::-webkit-scrollbar{
        width: 0;
        height: 0;
      }
    }

  }
  .header .header-item{
    color: #FFFFFF!important;
  }
}
</style>
