/*
 * @Author: zoujp
 * @Date: 2020-11-05 17:24:14
 * @LastEditTime: 2021-06-16 15:32:38
 * @LastEditors: wuqi
 * @Description: 方案应用
 * @FilePath: \user\src\api\plan\planApplyService.js
 */
import BaseService from "../BaseService";

/**
 * 方案销售趋势
 */
class PlanApplyService extends BaseService {
  pk = "";

  constructor(http) {
    super("", http);
  }

  /**
   * @description  查询方案分类
   * @param params
   * @returns {*}
   */
  planClassifyQueryList(params) {
    const { http } = this;
    return http.post("planClassifyQueryList", params);
  }

  /**
   * @description: 查询方案下列表
   * @param {*} params
   * @return {*}
   */
  querySchemeAllList(params) {
    const { http } = this;
    return http.post("querySchemeAllList", params);
  }

  /**
   * @description: 判断是否多企业
   * @param {*} params
   * @return {*}
   */
  queryAdminMulTenant(params) {
    const { http } = this;
    return http.post("queryAdminMulTenant", params);
  }

  /**
   * @description: 安装企业
   * @param {*} params
   * @return {*}
   */
  installScheme(params) {
    const { http } = this;
    return http.post("installScheme", params);
  }

  /**
   * @description: 验证账号
   * @param {*}
   * @return {*}
   */
  checkAccountPassword(params) {
    const { http } = this;
    return http.post("checkAccountPassword", params);
  }

  /**
   * @description: 查找已安装方案
   * @param {*} params
   * @return {*}
   */
  queryInstalledSchemeList(params) {
    const { http } = this;
    return http.post("queryInstalledSchemeList", params);
  }
}

export default PlanApplyService;
