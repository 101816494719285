/*
 * @Author: wuqi
 * @Date: 2021-01-15 16:35:06
 * @LastEditors: wuqi
 * @LastEditTime: 2021-07-08 14:32:39
 * @Description:
 */
class SockService {
  constructor(url, mc, timeout = 28 * 1000) {
    this.url = url;
    this.ws = null;
    this.timeout = timeout;
    this.lockReconnect = false; // 是否真正建立连接
    this.timeoutObj = null; // 心跳心跳倒计时
    this.serverTimeoutObj = null; // 心跳倒计时
    this.timeoutNum = null; // 断开 重连倒计时
    this.onMessageCallBack = mc;
    // this.init();
  }

  init() {
    this.ws = new WebSocket(this.url);
    this.ws.onopen = this.websocketOpen.bind(this);
    this.ws.onmessage = this.websocketMessage.bind(this);
    this.ws.onclose = this.websocketClose.bind(this);
    this.ws.onerror = this.websocketError.bind(this);
  }

  websocketOpen() {
    console.log(this);
    // 连接成功事件
    this.websocketSend("ping");
    // 开启心跳
    console.log("开启心跳");
    this.start();
  }

  websocketClose() {
    // 提示关闭
    // console.log("连接已关闭", evt);
    // 重连
    this.reconnect();
  }

  websocketSend(msg) {
    // 向服务器发送信息
    this.ws.send(msg);
  }

  websocketMessage(evt) {
    // 打印收到服务器的内容
    const data = evt.data;
    console.log("收到服务器信息", data);
    this.onMessageCallBack(evt);
    // 收到服务器信息，心跳重置
    this.reset();
  }

  websocketError() {
    // 连接失败事件
   // 错误
  //  console.log("WebSocket连接发生错误", evt);
   // 重连
   this.reconnect();
  }

  reconnect() { // 重新连接
    if (this.lockReconnect) {
        return;
    }
    this.lockReconnect = true;
    // 没连接上会一直重连，设置延迟避免请求过多
    this.timeoutNum && clearTimeout(this.timeoutNum);
    this.timeoutNum = setTimeout(() => {
        // 新连接
        this.init();
        this.lockReconnect = false;
    }, 5000);
  }

  reset() { // 重置心跳
    // 清除时间
    clearTimeout(this.timeoutObj);
    clearTimeout(this.serverTimeoutObj);
    // 重启心跳
    this.start();
  }

  start() { // 开启心跳
    this.timeoutObj && clearTimeout(this.timeoutObj);
    this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj);
    this.timeoutObj = setTimeout(() => {
      // 这里发送一个心跳，后端收到后，返回一个心跳消息，
      if (this.ws.readyState === 1) { // 如果连接正常
        this.ws.send("heartCheck");
      } else { // 否则重连
        this.reconnect();
      }
      this.serverTimeoutObj = setTimeout(() => {
          // 超时关闭
          this.ws.close();
      }, this.timeout);
    }, this.timeout);
  }
}
export default SockService;
