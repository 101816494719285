/**
 * project: user
 * fileName: config
 * Created by 徐俊 on 2020/08/24
 * Copyright © 2020 Enfry Ltd. All rights reserved.
 */

const config = {
  appkey: "fa5c5b58d5500f2d8fbce38c5e660050",
  postUrl: "https://app.netease.im",
  // 用户自定义的登录注册地址
  loginUrl: "/webdemo/h5/login.html",
  registUrl: "/webdemo/h5/regist.html",
  homeUrl: "/webdemo/h5/index.html#/session",

  // 资源路径根目录，为了方便用户部署在二级以上URL路径上
  resourceUrl: "http://yx-web.nos.netease.com/webdoc/h5",
  // 用户logo地址
  logo: "http://yx-web.nos.netease.com/webdoc/h5/im/logo.png",
  // 默认用户头像
  defaultUserIcon: "", // "http://yx-web.nos.netease.com/webdoc/h5/im/default-icon.png",
  // 默认普通群头像
  defaultGroupIcon: "", // "http://yx-web.nos.netease.com/webdoc/h5/im/default-group.png",
  // 默认高级群头像
  defaultAdvancedIcon: "", // "http://yx-web.nos.netease.com/webdoc/h5/im/default-advanced.png",
  // 系统通知图标
  noticeIcon: "http://yx-web.nos.netease.com/webdoc/h5/im/notice-icon.png",
  // 我的手机图标
  myPhoneIcon: "", // "http://yx-web.nos.netease.com/webdoc/h5/im/my-phone.png",
  // 本地消息显示数量，会影响性能
  localMsglimit: 30,
  useDb: false
};

export default config;
