/*
 * @Descripttion: 打印
 * @Author: 彭博
 * @Date: 2020-08-20 09:52:04
 * @LastEditors: 彭博
 * @LastEditTime: 2020-08-20 10:10:52
 */
import PrintService from "./printService";

export const printService = new PrintService();
