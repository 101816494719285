var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    {
      staticClass: "el-menu-item",
      class: {
        "is-active-1": _vm.active && _vm.activeId == 1 && _vm.navStyles != 2,
        "is-active-2": _vm.active && _vm.activeId == 2 && _vm.navStyles != 2,
        "is-active-3": _vm.active && _vm.activeId == 3 && _vm.navStyles != 2,
        "is-active-4": _vm.active && _vm.activeId == 4 && _vm.navStyles != 2,
        "is-active-5": _vm.active && _vm.activeId == 5 && _vm.navStyles != 2,
        "is-active-6": _vm.active && _vm.activeId == 6 && _vm.navStyles != 2,
        "is-1": _vm.activeId == 1 && _vm.navStyles != 2,
        "is-2": _vm.activeId == 2 && _vm.navStyles != 2,
        "is-3": _vm.activeId == 3 && _vm.navStyles != 2,
        "is-4": _vm.activeId == 4 && _vm.navStyles != 2,
        "is-5": _vm.activeId == 5 && _vm.navStyles != 2,
        "is-6": _vm.activeId == 6 && _vm.navStyles != 2,
        // 布局二 额外处理
        "is-active-1-two":
          _vm.active && _vm.activeId == 1 && _vm.navStyles == 2,
        "is-active-2-two":
          _vm.active && _vm.activeId == 2 && _vm.navStyles == 2,
        "is-active-3-two":
          _vm.active && _vm.activeId == 3 && _vm.navStyles == 2,
        "is-active-4-two":
          _vm.active && _vm.activeId == 4 && _vm.navStyles == 2,
        "is-active-5-two":
          _vm.active && _vm.activeId == 5 && _vm.navStyles == 2,
        "is-active-6-two":
          _vm.active && _vm.activeId == 6 && _vm.navStyles == 2,
        "is-1-two": _vm.activeId == 1 && _vm.navStyles == 2,
        "is-2-two": _vm.activeId == 2 && _vm.navStyles == 2,
        "is-3-two": _vm.activeId == 3 && _vm.navStyles == 2,
        "is-4-two": _vm.activeId == 4 && _vm.navStyles == 2,
        "is-5-two": _vm.activeId == 5 && _vm.navStyles == 2,
        "is-6-two": _vm.activeId == 6 && _vm.navStyles == 2,
        "is-disabled": _vm.disabled,
        "is-collapseStyle": _vm.themeInfo.isCollapse,
        "is-active-custom":
          (_vm.active && _vm.activeId !== 7) ||
          (_vm.active && _vm.navStyles == 2),
      },
      style: [
        _vm.paddingStyle,
        _vm.itemStyle,
        { backgroundColor: _vm.backgroundColor, ..._vm.navStyle },
      ],
      attrs: { role: "menuitem", tabindex: "-1" },
      on: {
        click: _vm.handleClick,
        mouseenter: _vm.onMouseEnter,
        focus: _vm.onMouseEnter,
        blur: _vm.onMouseLeave,
        mouseleave: _vm.onMouseLeave,
      },
    },
    [
      _c(
        "div",
        [
          _vm.parentMenu.$options.componentName === "ElMenu" &&
          _vm.rootMenu.collapse &&
          _vm.$slots.title
            ? _c(
                "el-tooltip",
                { attrs: { effect: "dark", placement: "right" } },
                [
                  _c(
                    "div",
                    { attrs: { slot: "content" }, slot: "content" },
                    [_vm._t("title")],
                    2
                  ),
                  _c("div", { staticStyle: {} }, [_vm._t("default")], 2),
                ]
              )
            : [_vm._t("default"), _vm._t("title")],
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }