// import { replacePath, replaceRedirect } from "../../util";

const routes = [
  {
    path: "/trip",
    name: "trip",
    component: () => import(/* webpackChunkName: "business-travel-trip" */"@/views/businessTravel/trip"),
    meta: {
      title: "我的行程",
      fullPage: false,
      keepAlive: true
    }
  }, {
    path: "/trip/tripDetail",
    name: "tripDetail",
    component: () => import(/* webpackChunkName: "business-travel-trip" */"@/views/businessTravel/trip/tripDetail"),
    meta: {
      title: "行程单",
      fullPage: false,
      keepAlive: true
    }
  }
];

// const PRE_URL = "/business-travel";

export default routes;
// .map((v) => replacePath(PRE_URL, v))
// .map((v) => replaceRedirect(PRE_URL, v));
