/*
 * @Author: zhangjiaheng
 * @Date: 2020-09-16 10:36:51
 * @LastEditors: zhangjiaheng
 * @LastEditTime: 2020-09-16 10:40:56
 * @Description: 任务类型的业务建模 特殊页面路由
 * @FilePath: \user\src\router\businessModel\task\index.js
 */
const routes = [
  {
    path: "/businessModel/taskGroup",
    name: "task",
    component: () => import(/* webpackChunkName: "user-basic-business-task" */ "@/views/businessModel/task/taskGroupView.vue"),
    meta: {
      title: "业务建模任务分组视图",
      fullPage: false,
      keepAlive: false
    }
  }

];

export default routes;
