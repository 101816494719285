// 主页的一些路由
const routes = [
  {
    path: "development",
    name: "development",
    component: () => import(/* webpackChunkName: "corporate-credit" */"@/views/corporateCredit/development"),
    meta: {
      title: "常用设置业务建模分类",
      keepAlive: true,
      fullPage: false
    }
  }
];

export default routes;
