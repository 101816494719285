var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.close,
          expression: "close",
        },
      ],
      staticClass: "theme-drawer",
      style: _vm.styleObj,
    },
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c("en-button", {
            staticClass: "theme",
            staticStyle: { "margin-left": "20px" },
            attrs: {
              icon: "iconzhuti-shixin",
              "icon-color": "#9367EB",
              type: "text",
              "icon-size": "22",
            },
          }),
          _c("span", { staticClass: "setting" }, [_vm._v("主题设置")]),
        ],
        1
      ),
      _c("div", { staticClass: "tab" }, [
        _c(
          "div",
          {
            class: { active: _vm.current === "1" },
            on: {
              click: function ($event) {
                return _vm.tabCurrent("1")
              },
            },
          },
          [_vm._v("布局")]
        ),
        _c(
          "div",
          {
            class: { active: _vm.current === "2" },
            on: {
              click: function ($event) {
                return _vm.tabCurrent("2")
              },
            },
          },
          [_vm._v("风格")]
        ),
        _c(
          "div",
          {
            class: { active: _vm.current === "3" },
            on: {
              click: function ($event) {
                return _vm.tabCurrent("3")
              },
            },
          },
          [_vm._v("子菜单")]
        ),
      ]),
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.current === "1",
                expression: "current === '1'",
              },
            ],
            staticClass: "layout",
          },
          [
            _c("div", { staticClass: "styles" }, [
              _c("span", { staticClass: "label" }, [_vm._v("导航布局")]),
              _c(
                "div",
                { staticClass: "style-list" },
                _vm._l(_vm.navStyles, function (item) {
                  return _c("div", { key: item.style }, [
                    _c("div", {
                      staticClass: "style-item",
                      style: {
                        backgroundPosition:
                          item.style === _vm.themeSetting.navStyle
                            ? item.activeIconPosition
                            : item.iconPosition,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.changeNavStyle(item)
                        },
                      },
                    }),
                    _c("div", { staticClass: "item-text" }, [
                      _vm._v(_vm._s(item.text)),
                    ]),
                  ])
                }),
                0
              ),
            ]),
            _c("div", { staticClass: "styles" }, [
              _c("span", { staticClass: "label" }, [_vm._v("首页页签")]),
              _c(
                "div",
                { staticClass: "style-list" },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.themeSetting.openIndexTab,
                      callback: function ($$v) {
                        _vm.$set(_vm.themeSetting, "openIndexTab", $$v)
                      },
                      expression: "themeSetting.openIndexTab",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.current === "2",
                expression: "current === '2'",
              },
            ],
            staticClass: "layout",
          },
          [
            _c("div", { staticClass: "styles" }, [
              _c("span", { staticClass: "label" }, [_vm._v("推荐样式")]),
              _c(
                "div",
                { staticClass: "style-list" },
                _vm._l(_vm.RecommendedStyles, function (item) {
                  return (_vm.themeSetting.navStyle === 1 &&
                    item.style !== 3 &&
                    item.style !== 4 &&
                    item.style !== 5 &&
                    item.style !== 6 &&
                    item.style !== 7 &&
                    item.style !== 8) ||
                    (_vm.themeSetting.navStyle === 2 &&
                      item.style !== 1 &&
                      item.style !== 2 &&
                      item.style !== 5 &&
                      item.style !== 6 &&
                      item.style !== 7 &&
                      item.style !== 8) ||
                    (_vm.themeSetting.navStyle === 3 &&
                      item.style !== 1 &&
                      item.style !== 2 &&
                      item.style !== 3 &&
                      item.style !== 4)
                    ? _c("div", { key: item.style }, [
                        _c("div", {
                          staticClass: "style-item3",
                          staticStyle: { "margin-bottom": "20px" },
                          style: {
                            backgroundPosition:
                              item.style === _vm.themeSetting.RecommendedStyles
                                ? item.activeIconPosition
                                : item.iconPosition,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.changeRecommendedStyles(item)
                            },
                          },
                        }),
                        _c("div", { staticClass: "item-text" }, [
                          _vm._v(_vm._s(item.text)),
                        ]),
                      ])
                    : _vm._e()
                }),
                0
              ),
            ]),
            _c("div", { staticClass: "setting-item" }, [
              _c("span", { staticClass: "label" }, [_vm._v("主题风格")]),
              _c(
                "div",
                { staticClass: "color-list" },
                _vm._l(_vm.colorList, function (item) {
                  return _c(
                    "span",
                    {
                      key: item.type,
                      staticClass: "color-item",
                      style: { background: item.bgColor },
                      on: {
                        click: function ($event) {
                          return _vm.changeThemeColor(item)
                        },
                      },
                    },
                    [
                      _c("en-icon", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: item.type === _vm.themeSetting.themeColor,
                            expression: "item.type === themeSetting.themeColor",
                          },
                        ],
                        attrs: {
                          name: "iconyixuan",
                          size: "small",
                          color: item.type === "white" ? "#000" : "#fff",
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: false,
                    expression: "false",
                  },
                ],
                staticClass: "setting-items",
              },
              [
                _c(
                  "el-collapse",
                  {
                    staticClass: "collapse",
                    staticStyle: { margin: "0 20px" },
                    attrs: { accordion: "" },
                  },
                  [
                    _c(
                      "el-collapse-item",
                      {
                        staticClass: "collapse-item",
                        attrs: { title: "顶部设置" },
                      },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.themeSetting.openCustomColor,
                                expression: "themeSetting.openCustomColor",
                              },
                            ],
                            staticClass: "setting-item-unFlex",
                          },
                          [
                            _c("span", { staticClass: "label" }, [
                              _vm._v("顶部图标"),
                            ]),
                            _c(
                              "el-radio",
                              {
                                staticStyle: { color: "#FFFFFF" },
                                attrs: { label: "1" },
                                model: {
                                  value: _vm.themeSetting.TopIcon,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.themeSetting, "TopIcon", $$v)
                                  },
                                  expression: "themeSetting.TopIcon",
                                },
                              },
                              [_vm._v("默认彩色")]
                            ),
                            _c(
                              "el-radio",
                              {
                                staticStyle: { color: "#FFFFFF" },
                                attrs: { label: "2" },
                                model: {
                                  value: _vm.themeSetting.TopIcon,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.themeSetting, "TopIcon", $$v)
                                  },
                                  expression: "themeSetting.TopIcon",
                                },
                              },
                              [_vm._v("纯色")]
                            ),
                            _c(
                              "div",
                              { staticClass: "setting-components" },
                              [
                                _vm.themeSetting.TopIcon === "2"
                                  ? _c("my-color-picker", {
                                      attrs: { typeSelections: 1 },
                                      model: {
                                        value: _vm.themeSetting.TopIconColor,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.themeSetting,
                                            "TopIconColor",
                                            $$v
                                          )
                                        },
                                        expression: "themeSetting.TopIconColor",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.themeSetting.openCustomColor,
                                expression: "themeSetting.openCustomColor",
                              },
                            ],
                            staticClass: "setting-item-unFlex",
                          },
                          [
                            _c("span", { staticClass: "label" }, [
                              _vm._v("顶部底色"),
                            ]),
                            _c(
                              "el-radio",
                              {
                                staticStyle: { color: "#FFFFFF" },
                                attrs: { label: "1" },
                                model: {
                                  value: _vm.themeSetting.topSettingRadio,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.themeSetting,
                                      "topSettingRadio",
                                      $$v
                                    )
                                  },
                                  expression: "themeSetting.topSettingRadio",
                                },
                              },
                              [_vm._v("设置颜色")]
                            ),
                            _c(
                              "div",
                              { staticClass: "setting-components" },
                              [
                                _vm.themeSetting.topSettingRadio == "1"
                                  ? _c("my-color-picker", {
                                      attrs: { typeSelections: 2 },
                                      model: {
                                        value: _vm.themeSetting.topColor,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.themeSetting,
                                            "topColor",
                                            $$v
                                          )
                                        },
                                        expression: "themeSetting.topColor",
                                      },
                                    })
                                  : _c(
                                      "div",
                                      { staticClass: "Upload-components" },
                                      [
                                        _c(
                                          "en-upload",
                                          {
                                            staticClass: "foot-upload",
                                            attrs: {
                                              "on-change": _vm.topChangeFile,
                                              limitNum: "9",
                                              limitType: "3",
                                            },
                                          },
                                          [
                                            _c("div", { staticClass: "icon" }, [
                                              _vm._v("+"),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c("div", {
                  staticClass: "divider",
                  staticStyle: { "margin-top": "10px" },
                }),
                _c(
                  "el-collapse",
                  {
                    staticClass: "collapse",
                    staticStyle: { margin: "0 20px" },
                    attrs: { accordion: "" },
                  },
                  [
                    _c(
                      "el-collapse-item",
                      {
                        staticClass: "collapse-item",
                        attrs: { title: "导航设置" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "setting-item" },
                          [
                            _c("span", { staticClass: "label" }, [
                              _vm._v("标签样式"),
                            ]),
                            _c(
                              "el-select",
                              {
                                staticClass: "selectAll",
                                staticStyle: { color: "#FFFFFF" },
                                attrs: {
                                  size: "small",
                                  placeholder: "请选择样式",
                                },
                                model: {
                                  value: _vm.themeSetting.themeStyle,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.themeSetting,
                                      "themeStyle",
                                      $$v
                                    )
                                  },
                                  expression: "themeSetting.themeStyle",
                                },
                              },
                              [
                                _c("template", { slot: "prefix" }, [
                                  _vm.themeSetting.themeStyle === 1
                                    ? _c(
                                        "div",
                                        { staticClass: "iconTargetOne" },
                                        [
                                          _c("div", {
                                            staticClass: "iconLeftOne",
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "iconCenterOne" },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  staticClass: "en-icon",
                                                  staticStyle: {
                                                    color: "#3e90fe",
                                                    width: "12px",
                                                    height: "12px",
                                                    "vertical-align": "middle",
                                                  },
                                                  attrs: {
                                                    "aria-hidden": "true",
                                                  },
                                                },
                                                [
                                                  _c("use", {
                                                    attrs: {
                                                      "xlink:href":
                                                        "#iconshouye",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "iconTextOne" },
                                            [_vm._v("首页")]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.themeSetting.themeStyle === 2
                                    ? _c(
                                        "div",
                                        { staticClass: "iconTargetTwo" },
                                        [
                                          _c("div", {
                                            staticClass: "iconLeftTwo",
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "iconCenterTwo" },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  staticClass: "en-icon",
                                                  staticStyle: {
                                                    color: "#FFFFFF",
                                                    width: "12px",
                                                    height: "12px",
                                                    "vertical-align": "middle",
                                                  },
                                                  attrs: {
                                                    "aria-hidden": "true",
                                                  },
                                                },
                                                [
                                                  _c("use", {
                                                    attrs: {
                                                      "xlink:href":
                                                        "#iconshouye",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "iconTextTwo" },
                                            [_vm._v("首页")]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.themeSetting.themeStyle === 3
                                    ? _c(
                                        "div",
                                        { staticClass: "iconTargetThere" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "iconCenterThere" },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  staticClass: "en-icon",
                                                  staticStyle: {
                                                    color: "#FFFFFF",
                                                    width: "12px",
                                                    height: "12px",
                                                    "vertical-align": "middle",
                                                  },
                                                  attrs: {
                                                    "aria-hidden": "true",
                                                  },
                                                },
                                                [
                                                  _c("use", {
                                                    attrs: {
                                                      "xlink:href":
                                                        "#iconshouye",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "iconTextThere" },
                                            [_vm._v("首页")]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.themeSetting.themeStyle === 4
                                    ? _c(
                                        "div",
                                        { staticClass: "iconTargetFour" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "iconCenterFour" },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  staticClass: "en-icon",
                                                  staticStyle: {
                                                    color: "#FFFFFF",
                                                    width: "12px",
                                                    height: "12px",
                                                    "vertical-align": "middle",
                                                  },
                                                  attrs: {
                                                    "aria-hidden": "true",
                                                  },
                                                },
                                                [
                                                  _c("use", {
                                                    attrs: {
                                                      "xlink:href":
                                                        "#iconshouye",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "iconTextFour" },
                                            [_vm._v("首页")]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.themeSetting.themeStyle === 5
                                    ? _c(
                                        "div",
                                        { staticClass: "iconTargetFive" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "iconCenterFive" },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  staticClass: "en-icon",
                                                  staticStyle: {
                                                    color: "#FFFFFF",
                                                    width: "12px",
                                                    height: "12px",
                                                    "vertical-align": "middle",
                                                  },
                                                  attrs: {
                                                    "aria-hidden": "true",
                                                  },
                                                },
                                                [
                                                  _c("use", {
                                                    attrs: {
                                                      "xlink:href":
                                                        "#iconshouye",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "iconTextFive" },
                                            [_vm._v("首页")]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.themeSetting.themeStyle === 6
                                    ? _c(
                                        "div",
                                        { staticClass: "iconTargetSix" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "iconCenterSix" },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  staticClass: "en-icon",
                                                  staticStyle: {
                                                    color: "#FFFFFF",
                                                    width: "12px",
                                                    height: "12px",
                                                    "vertical-align": "middle",
                                                  },
                                                  attrs: {
                                                    "aria-hidden": "true",
                                                  },
                                                },
                                                [
                                                  _c("use", {
                                                    attrs: {
                                                      "xlink:href":
                                                        "#iconshouye",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "iconTextSix" },
                                            [_vm._v("首页")]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                                _c(
                                  "el-option",
                                  { attrs: { label: "样式一", value: 1 } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "el-input__prefix",
                                        staticStyle: { float: "left" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "iconTargetOne" },
                                          [
                                            _c("div", {
                                              staticClass: "iconLeftOne",
                                            }),
                                            _c(
                                              "div",
                                              { staticClass: "iconCenterOne" },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "en-icon",
                                                    staticStyle: {
                                                      color: "#3e90fe",
                                                      width: "12px",
                                                      height: "12px",
                                                      "vertical-align":
                                                        "middle",
                                                    },
                                                    attrs: {
                                                      "aria-hidden": "true",
                                                    },
                                                  },
                                                  [
                                                    _c("use", {
                                                      attrs: {
                                                        "xlink:href":
                                                          "#iconshouye",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "iconTextOne" },
                                              [_vm._v("首页")]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "font-size": "13px",
                                          "margin-left": "75px",
                                        },
                                      },
                                      [_vm._v("样式一")]
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-option",
                                  { attrs: { label: "样式二", value: 2 } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "el-input__prefix",
                                        staticStyle: { float: "left" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "iconTargetTwo" },
                                          [
                                            _c("div", {
                                              staticClass: "iconLeftTwo",
                                            }),
                                            _c(
                                              "div",
                                              { staticClass: "iconCenterTwo" },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "en-icon",
                                                    staticStyle: {
                                                      color: "#FFFFFF",
                                                      width: "12px",
                                                      height: "12px",
                                                      "vertical-align":
                                                        "middle",
                                                    },
                                                    attrs: {
                                                      "aria-hidden": "true",
                                                    },
                                                  },
                                                  [
                                                    _c("use", {
                                                      attrs: {
                                                        "xlink:href":
                                                          "#iconshouye",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "iconTextTwo" },
                                              [_vm._v("首页")]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "font-size": "13px",
                                          "margin-left": "75px",
                                        },
                                      },
                                      [_vm._v("样式二")]
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-option",
                                  { attrs: { label: "样式三", value: 3 } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "el-input__prefix",
                                        staticStyle: { float: "left" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "iconTargetThere" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "iconCenterThere",
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "en-icon",
                                                    staticStyle: {
                                                      color: "#FFFFFF",
                                                      width: "12px",
                                                      height: "12px",
                                                      "vertical-align":
                                                        "middle",
                                                    },
                                                    attrs: {
                                                      "aria-hidden": "true",
                                                    },
                                                  },
                                                  [
                                                    _c("use", {
                                                      attrs: {
                                                        "xlink:href":
                                                          "#iconshouye",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "iconTextThere" },
                                              [_vm._v("首页")]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "font-size": "13px",
                                          "margin-left": "75px",
                                        },
                                      },
                                      [_vm._v("样式三")]
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-option",
                                  { attrs: { label: "样式四", value: 4 } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "el-input__prefix",
                                        staticStyle: { float: "left" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "iconTargetFour" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "iconCenterFour" },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "en-icon",
                                                    staticStyle: {
                                                      color: "#FFFFFF",
                                                      width: "12px",
                                                      height: "12px",
                                                      "vertical-align":
                                                        "middle",
                                                    },
                                                    attrs: {
                                                      "aria-hidden": "true",
                                                    },
                                                  },
                                                  [
                                                    _c("use", {
                                                      attrs: {
                                                        "xlink:href":
                                                          "#iconshouye",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "iconTextFour" },
                                              [_vm._v("首页")]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "font-size": "13px",
                                          "margin-left": "75px",
                                        },
                                      },
                                      [_vm._v("样式四")]
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-option",
                                  { attrs: { label: "样式五", value: 5 } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "el-input__prefix",
                                        staticStyle: { float: "left" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "iconTargetFive" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "iconCenterFive" },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "en-icon",
                                                    staticStyle: {
                                                      color: "#FFFFFF",
                                                      width: "12px",
                                                      height: "12px",
                                                      "vertical-align":
                                                        "middle",
                                                    },
                                                    attrs: {
                                                      "aria-hidden": "true",
                                                    },
                                                  },
                                                  [
                                                    _c("use", {
                                                      attrs: {
                                                        "xlink:href":
                                                          "#iconshouye",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "iconTextFive" },
                                              [_vm._v("首页")]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "font-size": "13px",
                                          "margin-left": "75px",
                                        },
                                      },
                                      [_vm._v("样式五")]
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-option",
                                  { attrs: { label: "样式六", value: 6 } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "el-input__prefix",
                                        staticStyle: { float: "left" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "iconTargetSix" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "iconCenterSix" },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "en-icon",
                                                    staticStyle: {
                                                      color: "#FFFFFF",
                                                      width: "12px",
                                                      height: "12px",
                                                      "vertical-align":
                                                        "middle",
                                                    },
                                                    attrs: {
                                                      "aria-hidden": "true",
                                                    },
                                                  },
                                                  [
                                                    _c("use", {
                                                      attrs: {
                                                        "xlink:href":
                                                          "#iconshouye",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "iconTextSix" },
                                              [_vm._v("首页")]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "font-size": "13px",
                                          "margin-left": "75px",
                                        },
                                      },
                                      [_vm._v("样式六")]
                                    ),
                                  ]
                                ),
                              ],
                              2
                            ),
                            _c("my-color-picker", {
                              attrs: { typeSelections: 1 },
                              model: {
                                value: _vm.themeSetting.SelectLabelColor,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.themeSetting,
                                    "SelectLabelColor",
                                    $$v
                                  )
                                },
                                expression: "themeSetting.SelectLabelColor",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "setting-item" },
                          [
                            _c("span", { staticClass: "label" }, [
                              _vm._v("选中文字"),
                            ]),
                            _c("my-color-picker", {
                              attrs: { typeSelections: 1 },
                              model: {
                                value: _vm.themeSetting.HaverColor,
                                callback: function ($$v) {
                                  _vm.$set(_vm.themeSetting, "HaverColor", $$v)
                                },
                                expression: "themeSetting.HaverColor",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "setting-item" },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "label",
                                staticStyle: { "margin-right": "7px" },
                              },
                              [_vm._v("未选中文字")]
                            ),
                            _c("my-color-picker", {
                              attrs: { typeSelections: 1 },
                              model: {
                                value: _vm.themeSetting.NoHaverColor,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.themeSetting,
                                    "NoHaverColor",
                                    $$v
                                  )
                                },
                                expression: "themeSetting.NoHaverColor",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.themeSetting.openCustomColor,
                                expression: "themeSetting.openCustomColor",
                              },
                            ],
                            staticClass: "setting-item-unFlex",
                          },
                          [
                            _c("span", { staticClass: "label" }, [
                              _vm._v("导航底色"),
                            ]),
                            _c(
                              "el-radio",
                              {
                                staticStyle: { color: "#FFFFFF" },
                                attrs: { label: "1" },
                                model: {
                                  value: _vm.themeSetting.navColorRadio,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.themeSetting,
                                      "navColorRadio",
                                      $$v
                                    )
                                  },
                                  expression: "themeSetting.navColorRadio",
                                },
                              },
                              [_vm._v("设置颜色")]
                            ),
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  content: "最佳尺寸：190*840px",
                                  placement: "top",
                                },
                              },
                              [_c("i", { staticClass: "el-icon-info" })]
                            ),
                            _c(
                              "div",
                              { staticClass: "setting-components" },
                              [
                                _vm.themeSetting.navColorRadio == "1"
                                  ? _c("my-color-picker", {
                                      attrs: { typeSelections: 2 },
                                      model: {
                                        value: _vm.themeSetting.navColor,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.themeSetting,
                                            "navColor",
                                            $$v
                                          )
                                        },
                                        expression: "themeSetting.navColor",
                                      },
                                    })
                                  : _c(
                                      "div",
                                      { staticClass: "Upload-components" },
                                      [
                                        _c(
                                          "en-upload",
                                          {
                                            staticClass: "foot-upload",
                                            attrs: {
                                              "on-change": _vm.navChangeFile,
                                              limitNum: "9",
                                              limitType: "3",
                                            },
                                          },
                                          [
                                            _c("div", { staticClass: "icon" }, [
                                              _vm._v("+"),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c("div", {
                  staticClass: "divider",
                  staticStyle: { "margin-top": "10px" },
                }),
                _c(
                  "div",
                  {
                    staticClass: "setting-item-unFlex",
                    staticStyle: {
                      margin: "15px 20px 0 20px",
                      "padding-bottom": "20px",
                    },
                  },
                  [
                    _c("span", { staticClass: "label" }, [_vm._v("背景颜色")]),
                    _c(
                      "el-radio",
                      {
                        staticStyle: { color: "#FFFFFF" },
                        attrs: { label: "1" },
                        model: {
                          value: _vm.themeSetting.bgColorRadio,
                          callback: function ($$v) {
                            _vm.$set(_vm.themeSetting, "bgColorRadio", $$v)
                          },
                          expression: "themeSetting.bgColorRadio",
                        },
                      },
                      [_vm._v("设置颜色")]
                    ),
                    _c(
                      "div",
                      { staticClass: "setting-components" },
                      [
                        _vm.themeSetting.bgColorRadio == "1"
                          ? _c("my-color-picker", {
                              attrs: { typeSelections: 2 },
                              model: {
                                value: _vm.themeSetting.bgColor,
                                callback: function ($$v) {
                                  _vm.$set(_vm.themeSetting, "bgColor", $$v)
                                },
                                expression: "themeSetting.bgColor",
                              },
                            })
                          : _c(
                              "div",
                              { staticClass: "Upload-components" },
                              [
                                _c(
                                  "en-upload",
                                  {
                                    staticClass: "foot-upload",
                                    attrs: {
                                      "on-change": _vm.bgChangeFile,
                                      limitNum: "9",
                                      limitType: "3",
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "icon" }, [
                                      _vm._v("+"),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.current === "3",
                expression: "current === '3'",
              },
            ],
            staticClass: "layout",
          },
          [
            _c("div", { staticClass: "styles" }, [
              _c("span", { staticClass: "label" }, [_vm._v("推荐样式")]),
              _c(
                "div",
                { staticClass: "style-list" },
                _vm._l(_vm.SubmenuStyles, function (item) {
                  return _c("div", { key: item.style }, [
                    (_vm.themeSetting.navStyle === 1 && item.style !== 4) ||
                    (_vm.themeSetting.navStyle === 3 && item.style !== 3) ||
                    (_vm.themeSetting.navStyle === 2 &&
                      item.style !== 3 &&
                      item.style !== 4)
                      ? _c("div", [
                          _c("div", {
                            staticClass: "style-item3",
                            style: {
                              backgroundPosition:
                                item.style === _vm.themeSetting.SubmenuStyles
                                  ? item.activeIconPosition
                                  : item.iconPosition,
                            },
                            on: {
                              click: function ($event) {
                                _vm.themeSetting.SubmenuStyles = item.style
                              },
                            },
                          }),
                          _c("div", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(item.text)),
                          ]),
                        ])
                      : _vm._e(),
                  ])
                }),
                0
              ),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }