/*
 * @Description: 模板接口等
 * @Author: 梁平贤
 * @Date: 2020/6/29 11:45
 */

import { downFile } from "@/tools/util";
import BaseService from "../BaseService";

export default class BusinessDetailService extends BaseService {
  constructor(http) {
    super("business-template", http);
  }

  /**
   * 获取业务建模详情
   * @param params
   */
  async detail(params) {
    return this.http.post("/mdSearchDataInfo", params);
  }

  /**
   * 基础数据类型业务建模详情查询
   * @param params
   */
  basicDetail(params) {
    return this.http.post("/mdSearchBdDataInfo", params);
  }

  /**
   * 数据对象详情
   * @param params
   */
  dataObjectDetail(params) {
    return this.http.post("/mdSearchObjDataInfo", params);
  }

  /**
   * 纯基础数据的查询或新增
   * @param params
   */
  pureBasicDetail(params) {
    return this.http.post("/bdQueryBaseDataInfo", params);
  }

  /**
   * 消息中心新增业务建模,查询映射赋值
   * @param params
   * @returns {*}
   */
  messageDataMapping(params) {
    return this.http.post("/queryDataMapping", params);
  }

  /**
   * 查询接口配置,目前用于企业征信查询关联的字段
   * @param params
   * @returns {*}
   */
  queryInterfaceDetail(params) {
    return this.http.post("queryInterfaceConfig", params);
  }

  /**
   * 查询其它接口
   * @param params
   * @returns {*}
   */
  queryOtherInterface(params) {
    return this.http.post("bdOcr", params);
  }

  /**
   * 查询天眼查权限
   * @returns {*}
   */
  querySkyEyeAccess() {
    return this.http.post("tlUserAccessButton");
  }

  /**
   * 模糊查询天眼查数据,列表选择
   * @param params
   */
  queryTYC(name) {
    const params = {
      tycType: "353",
      aginSel: "0",
      reqTycData: JSON.stringify({ word: name })
    };
    return this.http.post("queryTYC", params);
  }

  /**
   * @description 查询资源被占用情况
   * @param params
   */
  queryResourceReport(params) {
    const { http } = this;
    return http.post("mdQueryResourceReport", params);
  }

  /**
   * @description 查询自动赋值 数据 列头
   * @param params
   */
  querySubColInfo(params) {
    const { http } = this;
    return http.post("bdQuerySubColInfo", params);
  }

  /**
   * @description 查询人员树
   * @param params
   */
  queryPersonDeptTree(params) {
    const { http } = this;
    return http.post("bdQueryPersonDeptTree", params);
  }

  /**
   * @description 查询基础数据（非人员）树
   * @param params
   */
  queryObjectTree(params) {
    const { http } = this;
    return http.post("bdQueryObjectTree", params);
  }

  /**
   * @description 查询基础数据对应明细区 区域数据
   * @param params
   */
  querySubPageMode(params) {
    const { http } = this;
    return http.post("bdQuerySubPageMode", params);
  }

  /**
   * @description 查询签到记录（签到控件日志信息）
   * @param params
   */
  querySingInList(params) {
    const { http } = this;
    return http.post("mdSingInList", params);
  }

  /**
   * @description 查询签到人员（签到控件日志信息）
   * @param params
   */
  querySingPersonList(params) {
    const { http } = this;
    return http.post("mdSingInPersonList", params);
  }

  /**
   * @description 查询签到二维码
   * @param params
   */
  querySignInCode(params) {
    const { http } = this;
    return http.post("mdQuerySingInCode", params);
  }

  /**
   * @description 刷新签到二维码
   * @param params
   */
  refreshSignInCode(params) {
    const { http } = this;
    return http.post("mdRefreshSingInCode", params);
  }

  /**
   * @description 签到
   * @param params
   */
  mdSignIn(params) {
    const { http } = this;
    return http.post("mdSingIn", params);
  }

  /**
   * 模板文件
   * @param params
   */
  mdGetFileModel(params) {
    const { http } = this;
    return http.post("mdGetFileModel", params);
  }

  /**
   * Word文件
   * @param params
   */
  mdGetProduceContent(params) {
    const { http } = this;
    return http.post("mdGetProduceContent", params);
  }

  /**
   * 在线创建word文件
   * */
  mdCreateNewDocx() {
    const { http } = this;
    return http.post("mdCreateNewDocx");
  }

  /**
   * 文本关联数据  基础数据
   * @param params
   */
  queryNewRelationDataList(params) {
    const { http } = this;
    return http.post("mdQueryNewRelationDataList", params);
  }

  async exportDetailTemplate(param) {
    const { http } = this;
    const res = await http.post("mdExportDetailTemplate", { ...param, requestType: "stream" }, {
      responseType: "arraybuffer"
    });
    const fileName = res.headers["content-disposition"] && res.headers["content-disposition"].split("filename=").pop();
    const blob = new Blob([res.data], { type: res.headers["content-type"] });
    downFile(blob, decodeURI(fileName));
    return res;
  }

  async exportImportExcelResult(param) {
    const { http } = this;
      const res = await http.post("importExcelResult", { ...param, requestType: "stream" }, {
        responseType: "arraybuffer"
      });
      const fileName = res.headers["content-disposition"] && res.headers["content-disposition"].split("filename=").pop();
      const blob = new Blob([res.data], { type: res.headers["content-type"] });
      downFile(blob, decodeURI(fileName));
      return res;
  }

  // 单点登录
  async mdSwitchAccount(params) {
    return this.http.post("mdSwitchAccount", params);
  }

  // 查询关联邮件
  async getMdRefMailList(params) {
    return this.http.post("getMdRefMailList", params);
  }

  // 关联凭证
  vcQueryVoucherListByBusinessKey(params) {
    return this.http.post("vcQueryVoucherListByBusinessKey", params);
  }

  // 获取关联数据历史记忆
  async getPopupWindowById(params) {
    return this.query("pop-up-window", params);
  }

  // 设置关联数据操作
  async updatePopupWindow(params) {
    const config = {
     headers: { "Content-Type": "application/json" }
    };
    console.log(this.http);

    return this.http.put("pop-up-window ", params, config);
  }
}
