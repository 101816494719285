import BaseService from "../BaseService";

/**
 * 请求例子
 * pk 这个模块的主键id，比如用户对象为user={userId, userName, ...} pk="userId"
 * module 字段，用于url的公共拼接，也可以不使用module，module只是方便统一的修改以及，根绝接口标准可以做统一的抽离封装
 */
class ExampleService extends BaseService {
  pk = "userId"

  constructor(http) {
    super("example", http);
  }

  /**
   * 查询列表
   * @param params 查询条件
   * @returns {*}
   */
  list(params) {
    const { module } = this;
    return this.query(`/${module}/list`, params);
  }

  /**
   * 根据Id获取数据
   * @param id
   * @returns {*}
   */
  get(id) {
    const { module } = this;
    return this.query(`/${module}`, {
      id
    });
  }

  /**
   * 增加/修改数据
   * 根据pk中的值可以区分是增加还是修改，这个可以根绝后台接口，抽离到BaseService中。暂时不清楚接口，先这么简单些一个例子
   * @param params
   * @returns {*}
   */
  update(params) {
    const { http, module } = this;
    if (params[this.pk]) {
      return http.post(`/${module}/update`, params);
    }
    return http.post("/example/add", params); // 不使用module
  }

  /**
   * 删除
   * @param id
   * @returns {*}
   */
  delete(id) {
    const { http, module } = this;
    return http.post(`/${module}/delete`, {
      id
    });
  }

  /**
   * @description  查询加签人树
   * @param params 登录参数
   * @returns {*}
   */
  queryPersonDeptTree(params) {
    const { http } = this;
    return http.post("bdQueryPersonDeptTree", params);
  }
}

export default ExampleService;
