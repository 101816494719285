import BaseService from "../BaseService";

export default class SwitchService extends BaseService {
  queryUserTenantAndDeptInfo() {
    return this.http.post("queryUserTenantAndDeptInfo");
  }

  switchUserInfoByAccAndTenantId(param) {
    return this.http.post("switchUserInfoByAccAndTenantId", param);
  }
}
