import BaseService from "../BaseService";

class GlbSearchService extends BaseService {
  constructor(http) {
    super("", http);
  }

  queryGlobalSearchList(params) {
    return this.http.post("/queryGlobalSearchList", params);
  }

  queryGlobalSearchHistory(params) {
    return this.http.post("/searchHistory", params);
  }

  globalSearch(params) {
    return this.http.post("/globalSearch", params);
  }

  delSearchHistory(params) {
    return this.http.post("/delSearchHistory", params);
  }
}

export default GlbSearchService;
