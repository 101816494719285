/*
 * @Author: zoujp
 * @Date: 2020-06-19 14:13:32
 * @LastEditTime: 2020-12-02 09:33:53
 * @LastEditors: wuqi
 * @Description: 公司圈
 * @FilePath: \user\src\api\companyCircle\CompanyCicleService.js
 */
import BaseService from "../BaseService";

/**
 * login相关
 */
class CompanyCircleService extends BaseService {
  pk = ""

  constructor(http) {
    super("", http);
  }

  /**
   * @description  查询公司圈消息列表
   * @param params 参数
   * @returns {*}
   */
  themeList(params) {
    const { http } = this;
    return http.post("themeList", params);
  }

  /**
   * @description  查询公司圈消息条数，待回执条数
   * @param params 参数
   * @returns {*}
   */
  readReceipt(params) {
    const { http } = this;
    return http.post("readReceipt", params);
  }

  /**
   * @description: 发布说说
   * @param {*} params
   * @return {*}
   */
  sendTheme(params) {
    const { http } = this;
    return http.post("sendTheme", params);
  }

  /**
   * @description: 公司圈点赞/取消点赞
   * @param {*}
   * @return {*}
   */
  likeTheme(params) {
    const { http } = this;
    return http.post("likeTheme", params);
  }

  /**
   * @description: 评论公司圈
   * @param {*} params
   * @return {*}
   */
  replyTheme(params) {
    const { http } = this;
    return http.post("replyTheme", params);
  }

  /**
   * @description: 删除评论/回复
   * @param {*} params {replyId:"回复ID"}
   * @return {*}
   */
  deleteThemeReply(params) {
    const { http } = this;
    return http.post("deleteThemeReply", params);
  }

  /**
   * @description: 删除说说
   * @param {*} params {themeId:"说说ID"}
   * @return {*}
   */
  deleteTheme(params) {
    const { http } = this;
    return http.post("deleteTheme", params);
  }

  /**
   * @description: 执行回执
   * @param {*} params  {themeId:"说说ID"}
   * @return {*}
   */
  receiptToRead(params) {
    const { http } = this;
    return http.post("receiptToRead", params);
  }

  /**
   * @description: 查看回执
   * @param {*} params {themeId:说说ID}
   * @return {*}
   */
  themeReceiptList(params) {
    const { http } = this;
    return http.post("themeReceiptList", params);
  }
}

export default CompanyCircleService;
