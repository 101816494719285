// 菜单分类类型

export const menuClassifyTypeEnum = {
  // 自定义
  custom: 0,
  // 首页
  home: 1,
  // 商旅
  businessTravel: 2,
  // 办公管理
  work: 3,
  // 工具应用
  tool: 4,
  // 智能核算
  accounting: 5,
  // 魔钥
  magicKey: 6,
  // 智能看板
  kanBan: 7,
  // 帮助中心
  helpCenter: 8,
  // 我的方案
  myScheme: 9,
  // 税务
  tax: 10,
  // 我的事项
  myMatter: 11,
  // 主题
  theme: 12,
  // 业务建模
  businessModeling: 13,
  // 其它分类
  other: 14,
  // 报表查询
  report: 15,
  // 法律知识库
  lawAngel: 16
};

// 固定菜单类型
export const menuFixedTypeEnum = {
  // 未定义
  none: "000",
  // 行程预定
  travelBooking: "001",
  // 企业沟通
  chat: "002",
  // 事项申请
  matterApply: "003",
  // 业务建模分类
  businessClassify: "004",
  // 我的行程
  myTrip: "005",
  // 考勤签到
  attendanceSignIn: "006",
  // 文档管理
  documentManagement: "007",
  // 扫一扫
  scan: "008",
  // 申请单完结
  applyEnd: "009",
  // 报表查询
  report: "010",
  // 待办事项
  gtasks: "011",
  // 未读通知
  notice: "012",
  // 已读通知
  readNotice: "013",
  // 我的表单
  myForm: "014",
  // 我的订单
  myOrder: "015",
  // 已办任务
  finishedTasks: "016",
  // 发起沟通
  toChat: "017",
  // 人事信息
  personnelInfo: "018",
  // 票夹
  wallet: "019",
  // 票夹
  myTask: "020",
  // 机票预订
  flightBooking: "021",
  // 酒店预订
  hotelBooking: "022",
  // 用车预订
  carBooking: "023",
  // 订单查询
  orderQuery: "024",
  // 凭证生成
  credentialsGenerated: "025",
  // 凭证处理
  credentialsHandle: "026",
  // 凭证输出
  credentialsOut: "027",
  // 开票申请
  invoiceApply: "028",
  // 结算汇总
  settlementSummary: "029",
  // 业务管理
  businessManagement: "030",
  // 接口管理
  interfaceManagement: "031",
  // 下载中心
  downCenter: "032",
  // 日历
  calendar: "033",
  // 我的en
  myEn: "034",
  // 公司圈
  companyCircle: "035",
  // 业务建模列表
  businessList: "036",
  // 我的业务
  myBusiness: "038",
  // 凭证生成
  credentialsGenerated1: "039",
  // 凭证处理
  credentialsHandle1: "040",
  // 凭证输出
  credentialsOut1: "041",
  // 余额表
  balanceSheet: "042",
  // 总账
  generalLedger: "043",
  // 明细表
  detailTable: "044",
  // 辅助核算余额表
  auxiliaryAccountingBalanceSheet: "045",
  // 辅助核算明细表
  auxiliaryAccountingDetailedTable: "046",
  // 多栏账
  multiColumnAccount: "047",
  // 期末结转
  finalTransaction: "048",
  // 我的工资条
  mySalarySheet: "049",
  // 工资条管理
  salarySheet: "050",
  // 结账
  invoicing: "051",
  // 资源设置
  resourceSettings: "052",
  // 资源维护
  resourceMaintain: "053",
  // 新增物品
  newAddArticle: "054",
  // 物品申请
  articleApply: "055",
  // 物品清单
  articleListing: "056",
  // 物品盘点
  articleCheck: "057",
  // 财务报表
  financialReport: "058",
  // 接口平台
  interfacePlatform: "059",
  // 我的方案
  myScheme: "060",
  // 销售与趋势
  salesAndTrends: "061",
  // 我的文件夹
  myFolder: "062",
  // 税务报表
  taxReport: "063",
  // 现金流量明细
  cashFlowDetail: "064",
  // 主题
  theme: "065",
  // 凭证审核
  credentialsCheck: "066",
  // 模板看板应用
  templateKanBan: "067",
  // 方案应用
  scheme: "068",
  // 待确认申请列表
  magicConfirmApplyList: "070",
  // 魔钥章桶管理
  MmagicChapterarrelsManager: "071",
  // 邮箱
  mail: "073"
};

export const menuDataTypeEnum = {
  // 固化应用
  fixed: 1,
  // 分类，分类也可以当应用加到常用
  sort: 2,
  // 任务类型应用
  task: 3,
  // 业务建模应用
  business: 4,
  // 基础数据应用
  basic: 5,
  // 看板数据应用
  kanBan: 6,
  // 主题数据应用
  theme: 7,
  // 分类看板
  kanBanClassify: 10,
  // 模版看板应用
  kanBanTemplate: 11,
  // 模板列表
  templateList: 12,
  // 外链
  outLink: 15
};

export function styleSize(menu) {
  if (menu.dataType === menuDataTypeEnum.sort) {
    return "0,0,12,3";
  }
  if (menu.dataType === menuDataTypeEnum.business || menu.dataType === menuDataTypeEnum.kanBanTemplate) {
    return "0,0,6,4";
  }
  if (menu.dataType === menuDataTypeEnum.theme) {
    return "6,6,6,4";
  }
  const code = menu.code;
  if (!code) {
    return "0,0,12,4";
  }
  if (code === menuFixedTypeEnum.chat) {
    return "0,3,12,3";
  }
  if (code === menuFixedTypeEnum.attendanceSignIn || code === menuFixedTypeEnum.documentManagement) {
    return "3,6,3,4";
  }
  if (code === menuFixedTypeEnum.applyEnd) {
    return "0,0,4,4";
  }
  if (code === menuFixedTypeEnum.unreadNotice || code === menuFixedTypeEnum.readNotice || menuFixedTypeEnum.report || code === menuFixedTypeEnum.wallet) {
    return "0,0,6,4";
  }
  if (code === menuFixedTypeEnum.calendar) {
    return "0,6,3,4";
  }
  if (code === menuFixedTypeEnum.myFolder) {
    return "6,6,6,4";
  }
  return "0,0,12,4";
}

export const businessClassifyIcon = [
  "fenlei-yewujianmoshixin",
  "fenlei-yewujianmoshixin1",
  "fenlei-yewujianmoshixin4",
  "fenlei-yewujianmoshixin2",
  "fenlei-yewujianmoshixin3",
  "fenlei-yewujianmoshixin7",
  "fenlei-yewujianmoshixin5",
  "fenlei-yewujianmoshixin8",
  "fenlei-yewujianmoshixin9",
  "fenlei-yewujianmoshixin6"
];

export const commonIconData = { icon: "zidingyicaidan-shixin", color: "#F76B6B" };
export const customType = { icon: "zidingyicaidan-shixin", color: "#F76B6B" };
export function businessTemplateMenuIcon(icon) {
  if (!icon || icon?.length === 0) {
    return {};
  }
  if (icon <= 20) {
    return { icon: `bus-board-icon${icon}`, color: "#F76B6B" };
  }
  return { icon: `bus-ywmb-icon${icon}`, color: "#F76B6B" };
}

function getFixedMenuIconData() {
  const fixedMenuIconData = {};
  fixedMenuIconData[menuFixedTypeEnum.flightBooking] = { icon: "jipiaoyuding-shixin", color: "#49BEF2" };
  fixedMenuIconData[menuFixedTypeEnum.orderQuery] = { icon: "dingdanchaxun-shixin", color: "#F76B6B" };
  fixedMenuIconData[menuFixedTypeEnum.hotelBooking] = { icon: "jiudianyuding-shixin", color: "#FFAD2C" };
  fixedMenuIconData[menuFixedTypeEnum.carBooking] = { icon: "yongcheyuding-shixin", color: "#60A9EF" };
  fixedMenuIconData[menuFixedTypeEnum.myTrip] = { icon: "wodehangcheng-shixin", color: "#3BD4A5" };
  fixedMenuIconData[menuFixedTypeEnum.myTask] = { icon: "wodehangcheng-shixin", color: "#26C393" };
  fixedMenuIconData[menuFixedTypeEnum.gtasks] = { icon: "daibanshixiang-shixin", color: "#FFAD2C" };
  fixedMenuIconData[menuFixedTypeEnum.finishedTasks] = { icon: "yibanrenwu-shixin", color: "#60A9EF" };
  fixedMenuIconData[menuFixedTypeEnum.notice] = { icon: "xiaoxizhongxin-shixin", color: "#FF8C8C" };
  fixedMenuIconData[menuFixedTypeEnum.attendanceSignIn] = { icon: "kaoqinqiandao-shixin", color: "#60A9EF" };
  fixedMenuIconData[menuFixedTypeEnum.documentManagement] = { icon: "wendangguanli-shixin", color: "#49BEF2" };
  fixedMenuIconData[menuFixedTypeEnum.personnelInfo] = { icon: "renshixinxi-shixin", color: "#A37BF2" };
  fixedMenuIconData[menuFixedTypeEnum.myBusiness] = { icon: "wodeyewu-shixin", color: "#3BD4A5" };
  fixedMenuIconData[menuFixedTypeEnum.chat] = { icon: "wodegoutong-shixin", color: "#49BEF2" };
  fixedMenuIconData[menuFixedTypeEnum.report] = { icon: "baobiaochaxun-shixin", color: "#FFC466" };
  fixedMenuIconData[menuFixedTypeEnum.calendar] = { icon: "rili-shixin", color: "#60A9EF" };
  fixedMenuIconData[menuFixedTypeEnum.wallet] = { icon: "wodepiaojia-shixin", color: "#26C393" };
  fixedMenuIconData[menuFixedTypeEnum.salarySheet] = { icon: "gongziguanli-shixin", color: "#60A9EF" };
  fixedMenuIconData[menuFixedTypeEnum.mySalarySheet] = { icon: "wodegongzi-shixin", color: "#9367EB" };
  fixedMenuIconData[menuFixedTypeEnum.credentialsGenerated1] = { icon: "pingzhengshengcheng-shixin", color: "#FFC466" };
  fixedMenuIconData[menuFixedTypeEnum.credentialsHandle1] = { icon: "pingzhengchuli-shixin", color: "#60A9EF" };
  fixedMenuIconData[menuFixedTypeEnum.credentialsCheck] = { icon: "pingzhengshenhe-shixin", color: "#60A9EF" };
  fixedMenuIconData[menuFixedTypeEnum.balanceSheet] = { icon: "yuebiao-shixin", color: "#3BD4A5" };
  fixedMenuIconData[menuFixedTypeEnum.generalLedger] = { icon: "zongzhang-shixin", color: "#FF8C8C" };
  fixedMenuIconData[menuFixedTypeEnum.detailTable] = { icon: "mingxizhang-shixin", color: "#A37BF2" };
  fixedMenuIconData[menuFixedTypeEnum.auxiliaryAccountingBalanceSheet] = { icon: "fuzhuhesuanyuebiao-shixin", color: "#6ACCF7" };
  fixedMenuIconData[menuFixedTypeEnum.auxiliaryAccountingDetailedTable] = { icon: "fuzhuhesuanmingxizhang-shixin", color: "#3e90fe" };
  fixedMenuIconData[menuFixedTypeEnum.multiColumnAccount] = { icon: "duolanzhang-shixin", color: "#9367EB" };
  fixedMenuIconData[menuFixedTypeEnum.cashFlowDetail] = { icon: "xianjinliuliangmingxi-shixin", color: "#9367EB" };
  fixedMenuIconData[menuFixedTypeEnum.financialReport] = { icon: "caiwubaobiao-shixin", color: "#FFC466" };
  fixedMenuIconData[menuFixedTypeEnum.finalTransaction] = { icon: "qimojiezhuan-shixin", color: "#FF8C8C" };
  fixedMenuIconData[menuFixedTypeEnum.invoicing] = { icon: "jiezhang-shixin", color: "#26C393" };
  fixedMenuIconData[menuFixedTypeEnum.interfaceManagement] = { icon: "jiekouguanli-shixin", color: "#6ACCF7" };
  fixedMenuIconData[menuFixedTypeEnum.downCenter] = { icon: "xiazaizhongxin-shixin", color: "#26C393" };
  fixedMenuIconData[menuFixedTypeEnum.settlementSummary] = { icon: "jiesuanzongjie-shixin", color: "#9367EB" };
  fixedMenuIconData[menuFixedTypeEnum.invoiceApply] = { icon: "kaipiaoshenqing-shixin", color: "#FFAD2C" };
  fixedMenuIconData[menuFixedTypeEnum.taxReport] = { icon: "shuiwubaobiao-shixin", color: "#FFAD2C" };
  fixedMenuIconData[menuFixedTypeEnum.myOrder] = { icon: "wodedingdan-shixin", color: "#9367EB" };
  fixedMenuIconData[menuFixedTypeEnum.taxReport] = { icon: "shuiwubaobiao-shixin", color: "#FFAD2C" };
  fixedMenuIconData[menuFixedTypeEnum.myFolder] = { icon: "wodewenjianjia1", color: "#9367EB" };

  fixedMenuIconData[menuFixedTypeEnum.none] = { icon: "guize-liebiao", color: "#F76B6B" };
  return fixedMenuIconData;
}
export const fixedMenuIconData = getFixedMenuIconData();

/**
 * 菜单路由数据
 * @param menu
 * @returns {{path: string, query: 参数}}
 */
export function menuRouterData(menu) {
  const type = menu.customType;
  if (type) {
    if (type === menuClassifyTypeEnum.home) {
      return { path: "/home/index" };
    }
    if (type === menuClassifyTypeEnum.kanBan) {
      return { path: "" };
    }
    if (type === menuClassifyTypeEnum.myMatter) {
      return { path: "/en" };
    }
    if (type === menuClassifyTypeEnum.helpCenter) {
      return { path: "/help" };
    }
    if (type === menuClassifyTypeEnum.report) {
      return { path: "/report" };
    }
    if (type === menuClassifyTypeEnum.lawAngel) {
      return { path: "/lawAngel" };
    }
  } else {
    const { refData, dataType, code } = menu;
    if (dataType === menuDataTypeEnum.business || dataType === menuDataTypeEnum.basic || dataType === menuDataTypeEnum.task) {
      return {
        path: "/toBusinessList",
        query: {
          templateId: refData.id, templateName: refData.name, page: -1, type: refData.type
        }
      };
    }
    if (dataType === menuDataTypeEnum.theme) {
      return { path: "/themeSet/viewDefault", query: { templateId: refData.id, viewId: refData.viewId, templateName: refData.name } };
    }
    if (dataType === menuDataTypeEnum.templateList) {
      return { path: "/businessModel/list", query: { templateId: refData.templateId || refData.id, templateName: refData.templateName || refData.name } };
    }
    if (dataType === menuDataTypeEnum.kanBanTemplate) {
      return { path: "/toModelChartUser", query: { type: refData.refType, templateId: refData.refId || refData.templateId || refData.id, templateName: refData.templateName || refData.name } };
    }
    if (dataType === menuDataTypeEnum.kanBanClassify) {
      return { path: "/toModelChartUser", query: { type: refData.refType, templateId: refData.refId || refData.templateId || refData.id, templateName: refData.templateName || refData.name } };
    }
    if (dataType === menuDataTypeEnum.fixed) {
      if (code === menuFixedTypeEnum.mail) {
        return { path: "/mailbox" };
      }
      if (code === menuFixedTypeEnum.myBusiness) {
        return { path: "/myBuss" };
      }
      if (code === menuFixedTypeEnum.myTask) {
        return { path: "/myTask" };
      }
      if (code === menuFixedTypeEnum.myOrder) {
        return { path: "/myOrder" };
      }
      if (code === menuFixedTypeEnum.finishedTasks) {
        return { path: "/handle" };
      }
      if (code === menuFixedTypeEnum.notice) {
        return { path: "/myRead" };
      }
      if (code === menuFixedTypeEnum.gtasks) {
        return { path: "/waitdo" };
      }
      if (code === menuFixedTypeEnum.gtasks) {
        return { path: "/waitdo" };
      }
      if (code === menuFixedTypeEnum.interfacePlatform) {
        return { path: "/apiPlat" };
      }
      if (code === menuFixedTypeEnum.interfacePlatform) {
        return { path: "/apiPlat" };
      }
      if (code === menuFixedTypeEnum.carBooking) {
        return { path: "/business-travel/car" };
      }
      if (code === menuFixedTypeEnum.flightBooking) {
        return { path: "/business-travel/flight" };
      }
      if (code === menuFixedTypeEnum.hotelBooking) {
        return { path: "/business-travel/hotel/entry" };
      }
      if (code === menuFixedTypeEnum.myOrder) {
        return { path: "/business-travel/order" };
      }
      if (code === menuFixedTypeEnum.myTrip) {
        return { path: "/business-travel/trip" };
      }
      if (code === menuFixedTypeEnum.orderQuery) {
        return { path: "/business-travel/order/query" };
      }
      if (code === menuFixedTypeEnum.chat) {
        return { path: "/chat" };
      }
      if (code === menuFixedTypeEnum.calendar) {
        return { path: "/home/calendar" };
      }
      if (code === menuFixedTypeEnum.documentManagement) {
        return { path: "/document-management" };
      }
      if (code === menuFixedTypeEnum.downCenter) {
        return { path: "/download-management" };
      }
      if (code === menuFixedTypeEnum.personnelInfo) {
        return { path: "/hr" };
      }
      if (code === menuFixedTypeEnum.credentialsGenerated1) {
        return { path: "/intelligent-accounting/credential" };
      }
      if (code === menuFixedTypeEnum.credentialsHandle1) {
        return { path: "/intelligent-accounting/voucherEntry" };
      }
      if (code === menuFixedTypeEnum.credentialsCheck) {
        return { path: "/intelligent-accounting/voucherExamine" };
      }
      if (code === menuFixedTypeEnum.balanceSheet) {
        return { path: "/intelligent-accounting/balanceList" };
      }
      if (code === menuFixedTypeEnum.generalLedger) {
        return { path: "/intelligent-accounting/generalLedger" };
      }
      if (code === menuFixedTypeEnum.detailTable) {
        return { path: "/intelligent-accounting/specification" };
      }
      if (code === menuFixedTypeEnum.auxiliaryAccountingBalanceSheet) {
        return { path: "/intelligent-accounting/assistBalanceList" };
      }
      if (code === menuFixedTypeEnum.auxiliaryAccountingDetailedTable) {
        return { path: "/intelligent-accounting/assistSpecofocation" };
      }
      if (code === menuFixedTypeEnum.multiColumnAccount) {
        return { path: "/intelligent-accounting/multiColAccount" };
      }
      if (code === menuFixedTypeEnum.cashFlowDetail) {
        return { path: "/intelligent-accounting/cashFlowDetail" };
      }
      if (code === menuFixedTypeEnum.invoicing) {
        return { path: "/intelligent-accounting/checkList" };
      }
      if (code === menuFixedTypeEnum.finalTransaction) {
        return { path: "/intelligent-accounting/carryOver" };
      }
      if (code === menuFixedTypeEnum.myFolder) {
        return { path: "/my-folder/index" };
      }
      if (code === menuFixedTypeEnum.mySalarySheet) {
        return { path: "/payroll/paystub" };
      }
      if (code === menuFixedTypeEnum.salarySheet) {
        return { path: "/payroll/payroll" };
      }
      if (code === menuFixedTypeEnum.attendanceSignIn) {
        return { path: "/hr/attendance" };
      }
      if (code === menuFixedTypeEnum.articleApply) {
        // 物品申请
        return { path: "/moyaoResources" };
      }
      if (code === menuFixedTypeEnum.report) {
        return { path: "/report" };
      }
      if (code === menuFixedTypeEnum.financialReport) {
        return { path: "/intelligent-accounting/reportIndex" };
      }
      if (code === menuFixedTypeEnum.invoicing) {
        return { path: "/intelligent-accounting/checkList" };
      }
      if (code === menuFixedTypeEnum.myScheme) {
        return { path: "/plan/myPlan" };
      }
      if (code === menuFixedTypeEnum.salesAndTrends) {
        return { path: "/plan/salesTrends" };
      }
      if (code === menuFixedTypeEnum.taxReport) {
        return { path: "/taxChart" };
      }
      if (code === menuFixedTypeEnum.scheme) {
        return { path: "/plan/planApply" };
      }
    }
  }
  return { path: "" };
}

export function classifyMenuData(menu, menus) {
  const datas = [];
  if (menu.dataType === menuDataTypeEnum.sort) {
    const datas1 = menus.filter((item) => item.id === menu.refId);
    datas.push(...datas1);
  } else if (menu.dataType === menuDataTypeEnum.task || menu.dataType === menuDataTypeEnum.task || menu.dataType === menuDataTypeEnum.business || menu.dataType === menuDataTypeEnum.basic) {
    const datas1 = menus.filter((item) => item.customType !== menuClassifyTypeEnum.home && item.customType !== menuClassifyTypeEnum.custom && item?.dataList?.length > 0);
    datas1.forEach((item) => {
      item.dataList.forEach((item) => {
        if (item.refId === menu.refId) {
          datas.push(item);
        }
      });
    });
  }
  return datas.length > 0 ? datas[0] : menu;
}

export function handleFirstLevelMenu(data) {
  if (data.icon === "0_1") {
    // 首页
    data.customIcon = "shouye";
    data.customType = menuClassifyTypeEnum.home;
  } else if (data.icon === "1_1") {
    // 自定义菜单
    data.customIcon = data.showIcon;
    data.customColor = data.color;
    data.customType = menuClassifyTypeEnum.custom;
  } else if (data.icon === "2_1") {
    // 商旅管理
    data.customIcon = "shanglvguanli-shixin";
    data.customColor = "#26C393FF";
    data.customType = menuClassifyTypeEnum.businessTravel;
  } else if (data.icon === "2_2") {
    // 办公管理
    data.customIcon = "bangongguanli";
    data.customColor = "#FFAD2C";
    data.customType = menuClassifyTypeEnum.work;
  } else if (data.icon === "2_17") {
    // 报表查询
    data.customIcon = "baobiaochaxun-shixin";
    data.customType = menuClassifyTypeEnum.report;
    data.customColor = "#3e90fe";
  } else if (data.icon === "2_3") {
    // 工具应用
    data.customIcon = "gongjuyingyong";
    data.customType = menuClassifyTypeEnum.tool;
    data.customColor = "#3e90fe";
  } else if (data.icon === "2_10") {
    // 智能核算
    data.customIcon = "zhinenghesuan";
    data.customColor = "#54BAEA";
    data.customType = menuClassifyTypeEnum.accounting;
  } else if (data.icon === "2_11") {
    // 魔钥
    data.customType = menuClassifyTypeEnum.magicKey;
  } else if (data.icon === "2_12") {
    // 智能看板
    data.customType = menuClassifyTypeEnum.kanBan;
  } else if (data.icon === "2_13") {
    // 帮助中心
    data.customIcon = "bangzhuzhongxin";
    data.customType = menuClassifyTypeEnum.helpCenter;
  } else if (data.icon === "2_14") {
    // 我的方案
    data.customType = menuClassifyTypeEnum.myScheme;
  } else if (data.icon === "2_15") {
    // 税务V4.0
    data.customIcon = "shuiwuguanli";
    data.customColor = "#26C393FF";
    data.customType = menuClassifyTypeEnum.tax;
  } else if (data.icon === "2_7") {
    // 我的事项
    data.customIcon = "wodeen";
    data.customColor = "#20C493";
    data.customType = menuClassifyTypeEnum.myMatter;
  } else if (data.icon === "2_6") {
    // 其它分类
    data.customIcon = "qitafenlei";
    data.customColor = "#9367EB";
    data.customType = menuClassifyTypeEnum.other;
  } else if (data.icon === "2_18") {
    // 法律知识库
    data.customIcon = "fatianshi";
    data.customType = menuClassifyTypeEnum.lawAngel;
  } else if (data.type === 6) {
    // 主题V4.2
    data.customIcon = businessClassifyIcon[data?.refData?.icon - 1];
    data.customType = menuClassifyTypeEnum.theme;
    data.customColor = "#3e90fe";
  } else if (data.type === 3) {
    data.customIcon = businessClassifyIcon[data?.refData?.icon - 1];
    data.customType = menuClassifyTypeEnum.businessModeling;
    data.customColor = "#3e90fe";
  } else if (data.type === 1) {
    if (data.dataType !== menuDataTypeEnum.outLink) {
      data.customIcon = customType.icon;
      data.customColor = customType.color;
    } else {
      data.customIcon = data.showIcon || "waibulianjie";
      data.customColor = data.color || "#3e90fe";
    }
  }
  if (!data.customIcon) {
     data.customIcon = data.dataType !== menuDataTypeEnum.outLink ? commonIconData.icon : (data.showIcon || "waibulianjie");
  }
  if (!data.customColor) {
    data.customColor = data.dataType !== menuDataTypeEnum.outLink ? commonIconData.color : (data.color || "#3e90fe");
  }
}

export function handleSecondLevelMenu(menu) {
  if (menu.dataType === menuDataTypeEnum.fixed) {
    const data = fixedMenuIconData[menu.code] ? fixedMenuIconData[menu.code] : fixedMenuIconData[menuFixedTypeEnum.none];
    menu.customIcon = data.icon;
    menu.customColor = data.color;
  } else if (menu.dataType === menuDataTypeEnum.task
    || menu.dataType === menuDataTypeEnum.basic
    || menu.dataType === menuDataTypeEnum.business) {
    const { icon, color } = businessTemplateMenuIcon(menu.icon);
    menu.customIcon = icon;
    menu.customColor = color;
  } else if (menu.dataType === menuDataTypeEnum.kanBanClassify) {
    menu.customIcon = businessClassifyIcon[menu.refData?.icon];
    menu.customColor = "#F76B6B";
  } else if (menu.dataType === menuDataTypeEnum.kanBan || menu.dataType === menuDataTypeEnum.theme) {
    const { icon, color } = businessTemplateMenuIcon(menu.refData?.icon);
    menu.customIcon = icon;
    menu.customColor = color;
  }
  if (!menu.customIcon) {
    if (menu.dataType !== menuDataTypeEnum.outLink) {
      menu.customIcon = customType.icon;
      menu.customColor = customType.color;
    } else {
      menu.customIcon = menu.showIcon || "waibulianjie";
      menu.customColor = menu.color || "#3e90fe";
    }
  }
}

export function handleThreeLevelMenu(menu) {
  if (menu.dataType === menuDataTypeEnum.kanBanTemplate) {
    const { icon, color } = businessTemplateMenuIcon(menu.refData?.icon ? menu.refData?.icon : menu.icon);
    menu.customIcon = icon;
    menu.customColor = color;
  } else if (menu.dataType === menuDataTypeEnum.templateList) {
    const { icon, color } = businessTemplateMenuIcon(menu.refData?.icon);
    menu.customIcon = icon;
    menu.customColor = color;
  }
  if (!menu.customIcon) {
    if (menu.dataType !== menuDataTypeEnum.outLink) {
      menu.customIcon = customType.icon;
      menu.customColor = customType.color;
    } else {
      menu.customIcon = menu.showIcon || "waibulianjie";
      menu.customColor = menu.color || "#3e90fe";
    }
  }
}
