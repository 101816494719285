<template>
  <li
    class="el-menu-item"
    role="menuitem"
    tabindex="-1"
    :style="[paddingStyle, itemStyle, { backgroundColor, ...navStyle }]"
    :class="{
      'is-active-1': activeId==1,
      'is-active-2': activeId==2,
      'is-active-3': activeId==3,
      'is-active-4': activeId==4,
      'is-active-5': activeId==5,
      'is-active-6': activeId==6,
      'is-disabled': disabled,
      'is-active-custom': active
    }"
    @click="handleClick"
    @mouseenter="onMouseEnter"
    @focus="onMouseEnter"
    @blur="onMouseLeave"
    @mouseleave="onMouseLeave"
  >
  <div :style="{itemsStyle}">
    <el-tooltip
      v-if="parentMenu.$options.componentName === 'ElMenu' && rootMenu.collapse && $slots.title"
      effect="dark"
      placement="right"
    >
      <div slot="content">
        <slot name="title"></slot>
      </div>
      <div style="position: absolute;left: 0;top: 0;height: 100%;width: 100%;display: inline-block;box-sizing: border-box;padding: 0 20px;">
        <slot></slot>
      </div>
    </el-tooltip>
    <template v-else>
      <slot></slot>
      <slot name="title"></slot>
    </template>
  </div>
  </li>
</template>
<script>
import Emitter from "element-ui/src/mixins/emitter";
import {
  createNamespacedHelpers
} from "vuex";
import Menu from "./menu-mixin";

const { mapState: mapStateTheme } = createNamespacedHelpers("main");

export default {
  name: "ElMenuItem",
  componentName: "ElMenuItem",
  mixins: [Menu, Emitter],
  props: {
    index: {
      default: null,
      validator: (val) => typeof val === "string" || val === null
    },
    activeId: Number,
    route: [String, Object],
    disabled: Boolean
  },
  computed: {
    ...mapStateTheme(["themeInfo"]),
    titleStyle() {
      return {
        position: "absolute",
        left: "0",
        top: "0",
        height: "100%",
        width: "100%",
        display: "flex",
        boxSizing: "border-box",
        justifyContent: "center",
        alignItems: "center"
      };
    },
    itemsStyle() {
              const {
        openCustomColor, navColor, NoHaverColor, HaverColor, SelectLabelColor, themeStyle
        } = this.themeInfo;
        return {
          backgroundColor: this.active ? themeStyle === 1 ? "#FFFFFF" : `${SelectLabelColor } !important` : `${navColor}!important`,
          color: this.active ? `${HaverColor} !important` : `${NoHaverColor }!important`,
          borderLeftColor: this.active ? `${SelectLabelColor } !important` : "#FFFFFF",
          height: themeStyle === 3 ? "30px" : "40px",
          margin: "0 auto"
        };
    },
    navStyle() {
      const {
 openCustomColor, navColor, NoHaverColor, HaverColor, SelectLabelColor, themeStyle
 } = this.themeInfo;
        console.log("themeStyle", themeStyle);
        if (this.themeInfo.RecommendedStyles === 5) {
          if (this.themeInfo.themeColor === "dark") {
            return {
            background: this.active ? "#FFFFFF15 !important" : "",
            color: this.active ? "#FFFFFF !important" : "#FFFFFF!important",
            borderLeftColor: this.active ? `${SelectLabelColor } !important` : "#FFFFFF",
            height: themeStyle === 3 ? "34px" : "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
           };
          }
            return {
            // backgroundColor: "#FFFFFF !important",
            // color: this.active ? `${HaverColor} !important` : `${NoHaverColor }!important`,
            borderLeftColor: this.active ? `${SelectLabelColor } !important` : "#FFFFFF",
            height: themeStyle === 3 ? "34px" : "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          };
        }
        if (this.themeInfo.RecommendedStyles === 6) {
          // 样式二
          if (this.themeInfo.themeColor === "write") {
            return {
              // background: this.active ? "#3E90FE25 !important" : "#FFFFFF!important",
              // color: this.active ? "#3E90FE !important" : "",
              // borderLeftColor: this.active ? `${SelectLabelColor } !important` : "#FFFFFF",
              height: "50px!important",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
          };
          }
          return {
          // backgroundColor: `${navColor} !important`,
          color: "#FFFFFF !important",
          // borderLeftColor: this.active ? `${SelectLabelColor } !important` : "#FFFFFF",
          height: "50px!important",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        };
        }
        if (this.themeInfo.RecommendedStyles === 7) {
          // 样式二
          if (this.themeInfo.themeColor === "write") {
            return {
              // background: this.active ? "#3E90FE25 !important" : "#FFFFFF!important",
              // color: this.active ? "#3E90FE !important" : "",
              // borderLeftColor: this.active ? `${SelectLabelColor } !important` : "#FFFFFF",
              height: "50px!important",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
          };
          }
          return {
          // backgroundColor: `${navColor} !important`,
          // color: "#FFFFFF !important",
          // borderLeftColor: this.active ? `${SelectLabelColor } !important` : "#FFFFFF",
          height: "50px!important",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        };
        }
          return {
          backgroundColor: this.active ? themeStyle === 1 ? "#FFFFFF" : `${SelectLabelColor } !important` : `${navColor}!important`,
          // color: this.active ? `${HaverColor} !important` : `${NoHaverColor }!important`,
          borderLeftColor: this.active ? `${SelectLabelColor } !important` : "#FFFFFF",
          height: themeStyle === 3 ? "34px" : "40px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        };
    },
    active() {
      return this.index === this.rootMenu.activeIndex;
    },
    hoverBackground() {
      return this.rootMenu.hoverBackground;
    },
    backgroundColor() {
      return this.rootMenu.backgroundColor || "";
    },
    activeTextColor() {
      return this.rootMenu.activeTextColor || "";
    },
    textColor() {
      return this.rootMenu.textColor || "";
    },
    mode() {
      return this.rootMenu.mode;
    },
    itemStyle() {
      const style = { color: this.active ? this.themeInfo.navColor : this.textColor };
      // const style = { color: this.active ? this.activeTextColor : this.textColor };
      if (this.mode === "horizontal" && !this.isNested) {
        // style.borderBottomColor = this.active
        //   ? (this.rootMenu.activeTextColor ? this.activeTextColor : "")
        //   : "transparent";
        //  style.borderBottomColor = "transparent";
      }
      return style;
    },
    isNested() {
      return this.parentMenu !== this.rootMenu;
    }
  },
  mounted() {
    this.parentMenu.addItem(this);
    this.rootMenu.addItem(this);
  },
  beforeDestroy() {
    this.parentMenu.removeItem(this);
    this.rootMenu.removeItem(this);
  },
  methods: {
    onMouseEnter() {
      if ((this.mode === "horizontal" && !this.rootMenu.backgroundColor) || this.themeInfo.openCustomColor) {
        return;
      }
      this.$el.style.backgroundColor = this.hoverBackground;
    },
    onMouseLeave() {
      if ((this.mode === "horizontal" && !this.rootMenu.backgroundColor) || this.themeInfo.openCustomColor) {
        return;
      }
      this.$el.style.backgroundColor = this.backgroundColor;
    },
    handleClick() {
      if (!this.disabled) {
        this.dispatch("ElMenu", "item-click", this);
        this.$emit("click", this);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.el-menu-item,
.el-submenu__title {
  height: 40px;
  line-height: 40px;
  float: none;
  position: relative;
  white-space: nowrap;
  list-style: none;
  display: inline-block;
}
</style>
