export default {
  /**
   * 该方法能直接批量修改vuex中的数据
   * 例如在vue组件中使用时,通过...mapMutation(['_changeVuexState']),默认已用mixin全局引入
   * this._changeVuexState({token:'xxxxxx',data: Date.now()})能同时设置多个值
   * @param {*} state
   * @param {*} data
   */
  _MxChangeVuexState(state, data) {
    Object.keys(data).forEach((ele) => {
      state[ele] = data[ele];
    });
  },
  saveSaMxSubjectlist(state, data) {
    state.saMxSubjectlist = data;
  },
  saveSaPzclConditionlist(state, data) {
    state.saPzclConditionlist = data;
  },
  setCacheVoucherData(state, data) {
    state.cacheVoucherData = data;
  }
};
