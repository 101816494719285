/*
 * @Author: zoujp
 * @Date: 2020-06-24 11:28:40
 * @LastEditTime: 2020-09-11 10:56:40
 * @LastEditors: pengyu
 * @Description: 智能核算跳转条件
 * @FilePath: \user\src\store\modules\intelligentAccounting\mutations.js
 */
export default {
  saMxSubjectlist: [],
  saPzclConditionlist: [],
  cacheVoucherData: {}
};
