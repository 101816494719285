// 模块路由
import development from "./modules/development";
import fullScreen from "./modules/fullScreen";
import home from "./modules/home";
import law from "./modules/law";
import manageRisk from "./modules/manageRisk";
import manageStatus from "./modules/manageStatus";
import property from "./modules/property";

const routes = [
  {
    path: "/corporate-credit",
    redirect: "/corporate-credit/home",
    component: () => import(/* webpackChunkName: "corporate-credit" */"@/views/corporateCredit/index"),
    children: [
      ...home,
      ...development,
      ...fullScreen,
      ...law,
      ...manageRisk,
      ...manageStatus,
      ...property
    ]
  }
];

export default routes;
