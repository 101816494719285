<!--
 * @Author: Fenngding
 * @Date: 2019-08-09 16:37:50
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-03-05 10:43:16
 * @Email: fenngding@live.com
 * @Description:
 -->
<template>
  <!-- <ScreenAdapter :width="screenWidth" :height="screenHeight"> -->
    <div id="user-entry-container"  >
      <!--<keep-alive>-->
      <component
        :is="routerViewLayOut"
        :loading="loading"
        :page-layout="pageLayout"
      ></component>
      <!--</keep-alive>-->
    </div>
    <!-- </ScreenAdapter> -->
</template>
<script>
import { isBoolean } from "lodash";
import { mapState } from "vuex";
import { enChart } from "@/api/businessChart";
import mainFullPage from "@/layout/main-full-page";
import mainMenuPage from "@/layout/main-menu-page";
import microFullPage from "@/layout/micro-full-page";
import microMenuPage from "@/layout/micro-menu-page";
import microMenuV from "@/layout/micro-menu-v";
import ScreenAdapter from "@/components/screenAdapter";

export default {
  name: "App",
  components: {
    mainFullPage,
    mainMenuPage,
    microFullPage,
    microMenuV,
    microMenuPage,
    ScreenAdapter
  },
  props: {
    loading: Boolean, // 微服务的加载动画
    config: Object
  },
  data() {
    return {
      fullViewPage: true,
      pageLayout: 0,
      cacheRouters: {},
      currentRouter: null,
      screenWidth: document.documentElement.clientWidth,
      screenHeight: document.documentElement.clientHeight
    };
  },
  computed: {
    ...mapState(["componyMeta"]),
    // 根据规则判断返回渲染模板,比如有 微服务的渲染模板,带菜单的渲染模板,不带菜单全屏的渲染模板
    routerViewLayOut() {
      if (!this.fullViewPage) {
        return "microMenuPage";
      }
      return "mainFullPage";
    }
  },

  created() {
    window.api = process.env.VUE_APP_EN_URL;
    // 刷新后设置需要重新设置meta
    this.setMetaInfo(this.componyMeta);
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        this.currentRouter = to;
        // this.$nextTick(() => {
        //   if (to.path !== "/chat") { // fixbug : 解决进入首页后 由于未清空当前session 导致IM未读数无法正常获取
        //     this.$store.dispatch("chat/resetCurrSession");
        //   }
        // });
        if (isBoolean(to.meta.fullPage)) {
          this.fullViewPage = to.meta.fullPage;
        } else {
          this.fullViewPage = true;
        }
        const { path } = to;
        if (this.cacheRouters[path]) {
          this.pageLayout = this.cacheRouters[path].pageLayout;
        } else {
          this.pageLayout = to.meta.pageLayout || 0;
        }
      }
    }
  },

  methods: {
    setMetaInfo(meta) {
      const link = document.querySelector("link[rel*='icon']") || document.createElement("link");
      document.title = meta.mainSlogan || document.title;
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = meta.channelLogoUrl;
      document.getElementsByTagName("head")[0].appendChild(link);
    }
  },
  mounted() {
    const timer = setInterval(() => {
      console.log("刷新token");
    }, 25 * 60 * 1000);
    // 每过5分钟 请求一个接口保证 用户不会退出 大屏展示需要 zjh
    const timer1 = setInterval(() => {
      if (window.location.pathname !== "/user/login") {
        // 在非登录页
        if (window.navigator.onLine) {
          enChart.queryRbacGloableVariableList();
        } else {
          clearInterval(timer1);
        }
      }
    }, 300000); // 5分钟一次
    this.$once("hook:beforeDestroy", () => {
      clearInterval(timer);
    });
  },
  beforeMount() {
    this.$eventHub.$on("CHANGE_LAYOUT", (res) => {
      const { path } = this.currentRouter;
      this.pageLayout = res;
      this.cacheRouters[path] = {
        pageLayout: res
      };
    });
  },
  // 所有页面更新都会触发此函数
  updated() {
    this.$store.dispatch("chat/connect", this.$store.state.userInfo);
  },
  destroyed() {
    this.$eventHub.$off("CHANGE_LAYOUT");
  }
};
</script>
<style lang="scss">
  body {
    overflow: hidden;
  }
  #problem-z-index-important {
    z-index: 2000 !important;
  }
</style>
<style lang='scss' scoped>
  #user-entry-container {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    height: 100%;
    overflow-y: hidden;
  }
</style>
