/*
 * @Author: zoujp
 * @Date: 2020-06-28 10:00:07
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-06-28 11:35:30
 * @Description: 结账-接口
 */
import BaseService from "../BaseService";

class SettleAcountsService extends BaseService {
  pk = ""

  constructor(http) {
    super("", http);
  }

  /**
 * @description: 查询结账的会计期间
 * @param params 查询参数
 */
  queryCloseAccountAcQueryAccTime(params) {
    const { http } = this;
    return http.post("closeAccountAcQueryAccTime", params);
  }

  /**
* @description: 查询结账消息
* @param params 查询参数
*/
  queryCloseAccount(params) {
    const { http } = this;
    return http.post("vcQueryCloseAccount", params);
  }

  /**
 * @description: 结账检查权限
 * @param params 查询参数
 */
  checkIsSettleAccount(params) {
    const { http } = this;
    return http.post("vcCheckIsSettleAccount", params);
  }

  /**
 * @description: 结账检查
 * @param params 查询参数
 */
  checkCloseAccount(params) {
    const { http } = this;
    return http.post("vcCheckCloseAccount", params);
  }

  /**
 * @description: 结账
 * @param params 查询参数
 */
  closeAccount(params) {
    const { http } = this;
    return http.post("vcCloseAccount", params);
  }

  /**
 * @description: 反结账
 * @param params 查询参数
 */
  reCloseAccount(params) {
    const { http } = this;
    return http.post("vcReCloseAccount", params);
  }

  /**
   *
   *
 * @description: 结账日志
 * @param params 参数
 */
  queryVcCloseLogList(params) {
    const { http } = this;
    return http.post("queryVcCloseLogList", params);
  }
}

export default SettleAcountsService;
