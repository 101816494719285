/*
 * @Author: pengyu
 * @Date: 2020-06-16 11:39:07
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-07-29 15:19:05
 * @Description: file content
 */
const routes = [
  {
    path: "/intelligent-accounting/credential",
    component: () => import(/* webpackChunkName:"intelligent-accounting" */ "@/views/intelligentAccounting/credential"),
    meta: {
      title: "凭证生成",
      keepAlive: false,
      fullPage: false
    }
  },
  {
    path: "/intelligent-accounting/voucherEntry",
    component: () => import(/* webpackChunkName:"intelligent-accounting" */ "@/views/intelligentAccounting/voucherHandle/voucherEntry.vue"),
    meta: {
      title: "凭证处理",
      keepAlive: false,
      fullPage: false
    }
  },
  {
    path: "/intelligent-accounting/voucherExamine",
    component: () => import(/* webpackChunkName:"intelligent-accounting" */ "@/views/intelligentAccounting/voucherHandle/voucherEntry.vue"),
    meta: {
      title: "凭证审核",
      keepAlive: true,
      fullPage: false
    }
  },
  {
    path: "/intelligent-accounting/voucherList",
    component: () => import(/* webpackChunkName:"intelligent-accounting" */ "@/views/intelligentAccounting/voucherHandle/voucherList.vue"),
    meta: {
      title: "凭证处理",
      keepAlive: false,
      fullPage: false
    }
  },
  {
    path: "/intelligent-accounting/voucherAdd",
    component: () => import(/* webpackChunkName:"intelligent-accounting" */ "@/views/intelligentAccounting/voucherAdd"),
    meta: {
      title: "新增凭证",
      keepAlive: true,
      fullPage: false,
      pageLayout: 1
    }
  },
  {
    path: "/intelligent-accounting/voucherDetail",
    component: () => import(/* webpackChunkName:"intelligent-accounting" */ "@/views/intelligentAccounting/voucherDetail"),
    meta: {
      title: "凭证详情",
      keepAlive: false,
      fullPage: false,
      pageLayout: 1
    }
  },
  {
    path: "/intelligent-accounting/voucherEdit",
    component: () => import(/* webpackChunkName:"intelligent-accounting" */ "@/views/intelligentAccounting/voucherEdit"),
    meta: {
      title: "凭证编辑",
      keepAlive: false,
      fullPage: false,
      pageLayout: 1
    }
  },
  {
    path: "/intelligent-accounting/voucherCharge",
    component: () => import(/* webpackChunkName:"intelligent-accounting" */ "@/views/intelligentAccounting/voucherCharge"),
    meta: {
      title: "冲销凭证",
      keepAlive: true,
      fullPage: false,
      pageLayout: 1
    }
  },
  {
    path: "/intelligent-accounting/balanceList",
    name: "accountBook",
    component: () => import(/* webpackChunkName:"intelligent-accounting" */ "@/views/intelligentAccounting/accountBook/balanceList"),
    meta: {
      title: "余额表",
      keepAlive: false,
      fullPage: false
    }
  },
  {
    path: "/intelligent-accounting/generalLedger",
    name: "generalLedger",
    component: () => import(/* webpackChunkName:"intelligent-accounting" */ "@/views/intelligentAccounting/accountBook/generalLedger"),
    meta: {
      title: "总账",
      keepAlive: false,
      fullPage: false
    }
  },
  {
    path: "/intelligent-accounting/specification",
    name: "specification",
    component: () => import(/* webpackChunkName:"intelligent-accounting" */ "@/views/intelligentAccounting/accountBook/specification"),
    meta: {
      title: "明细表",
      keepAlive: false,
      fullPage: false
    }
  },
  {
    path: "/intelligent-accounting/assistBalanceList",
    name: "assistBalanceList",
    component: () => import(/* webpackChunkName:"intelligent-accounting" */ "@/views/intelligentAccounting/accountBook/assistBalanceList"),
    meta: {
      title: "辅助核算余额表",
      keepAlive: false,
      fullPage: false
    }
  },
  {
    path: "/intelligent-accounting/assistSpecofocation",
    name: "assistSpecofocation",
    component: () => import(/* webpackChunkName:"intelligent-accounting" */ "@/views/intelligentAccounting/accountBook/assistSpecofocation"),
    meta: {
      title: "辅助明细余额表",
      keepAlive: false,
      fullPage: false
    }
  },
  {
    path: "/intelligent-accounting/multiColAccount",
    name: "multiColAccount",
    meta: {
      title: "多栏帐",
      keepAlive: false,
      fullPage: false
    },
    component: () => import(/* webpackChunkName:"intelligent-accounting" */ "@/views/intelligentAccounting/accountBook/multiColAccount")
  },
  {
    path: "/intelligent-accounting/cashFlowDetail",
    name: "cashFlowDetail",
    meta: {
      title: "现金流量明细",
      keepAlive: false,
      fullPage: false
    },
    component: () => import(/* webpackChunkName:"intelligent-accounting" */ "@/views/intelligentAccounting/accountBook/cashFlowDetail")
  },
  {
    path: "/intelligent-accounting/carryOver",
    name: "carryOver",
    meta: {
      title: "结转",
      keepAlive: false,
      fullPage: false
    },
    component: () => import(/* webpackChunkName:"intelligent-accounting" */ "@/views/intelligentAccounting/finalCarryOver")
  },
  {
    path: "/intelligent-accounting/checkList",
    name: "checkList",
    meta: {
      title: "结账",
      keepAlive: false,
      fullPage: false
    },
    component: () => import(/* webpackChunkName:"intelligent-accounting" */ "@/views/intelligentAccounting/settlement/checkList.vue")
  },
  {
    path: "/intelligent-accounting/reportIndex",
    name: "reportIndex",
    meta: {
      title: "财务报表入口",
      keepAlive: false,
      fullPage: false
    },
    component: () => import(/* webpackChunkName:"intelligent-accounting" */ "@/views/intelligentAccounting/financialReport/reportIndex")
  },
  {
    path: "/intelligent-accounting/reportList",
    name: "reportList",
    meta: {
      title: "财务报表",
      keepAlive: false,
      fullPage: false
    },
    component: () => import(/* webpackChunkName:"intelligent-accounting" */ "@/views/intelligentAccounting/financialReport/reportList")
  }
];

export default routes;
