/*
 * @Author: your name
 * @Date: 2020-09-23 17:13:56
 * @LastEditTime: 2020-12-26 09:24:24
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \user\src\router\tax\index.js
 */
/**
 * project: user
 * fileName: index
 * Created by 徐俊 on 2020/09/16
 * Copyright © 2020 Enfry Ltd. All rights reserved.
 */

const routes = [
  {
    path: "/taxReport",
    component: () => import(/* webpackChunkName:"setting" */ "@/views/tax/report"),
    meta: {
      title: "税务报表",
      keepAlive: false,
      fullPage: false
    }
  }, {
    path: "/taxChart",
    component: () => import(/* webpackChunkName:"setting" */ "@/views/tax/chart"),
    meta: {
      title: "税务报表",
      keepAlive: false,
      fullPage: false
    }
  }
];

export default routes;
