const routes = [
  {
    path: "/payroll/importPayroll",
    name: "importPayroll",
    component: () => import(/* webpackChunkName:"payroll" */ "@/views/payRoll/hr/importPayroll/importPayroll"),
    meta: {
      title: "hr-导入工资条",
      keepAlive: true,
      fullPage: false
    }
  }
];

export default routes;
