/*
 * @Author: zhulin
 * @Date: 2020-06-16 17:12:51
 * @LastEditors: zhulin
 * @LastEditTime: 2020-07-28 14:32:41
 * @Description: file content
 */
import CorporateService from "./corporateService";

export const corporateService = new CorporateService();
