/*
 * @Author: your name
 * @Date: 2020-11-23 14:08:42
 * @LastEditTime: 2021-06-28 11:28:13
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \user\src\api\hr\HrService.js
 */
import BaseService from "../BaseService";

class HrService extends BaseService {
  pk = ""

  constructor(http) {
    super("", http);
  }

  /**
   * 查询假期设置
   */
  queryTenantHolidaySet() {
    return this.http.post("queryTenantHolidaySet", {});
  }

  /**
   * 查询假期余额
   */
  queryMyHolidayBalance() {
    return this.http.post("queryMyHolidayBalance", {});
  }

  /**
   * 查询假期日志
   */
  queryMyHolidayRecords(params) {
    return this.http.post("queryMyHolidayRecords", params);
  }

  /**
   * 查询考勤明细
   */
  queryAttDetailPageModelList(params) {
    return this.http.post("queryAttDetailPageModel", params);
  }

  /**
   * 查询加班记录
   */
  queryMdMyWorkOverTimeInfo(params) {
    return this.http.post("queryMdMyWorkOverTimeInfo", params);
  }

  /** 考勤报表
   * 查询机构树
   */
  queryDeptTreeAll(params) {
    return this.http.post("queryDeptTreeAll", params);
  }

  /** 考勤报表
   * 查询用户习惯 部门表头
   */
  queryUserBehavior(params) {
    return this.http.post("queryUserBehavior", params);
  }

  /** 考勤报表
   * 保存用户习惯 部门表头
   */
  saveUserBehavior(params) {
    return this.http.post("saveUserBehavior", params);
  }

  /** 考勤报表
   * 查询部门数据
   */
  queryReportAttDept(params) {
    return this.http.post("queryReportAttDept", params);
  }

  /** 考勤报表
   * 查询部门 日期数据
   */
  queryReportAttDay(params) {
    return this.http.post("queryReportAttDay", params);
  }

  /** 考勤报表
   * 查询人员表格 表头
   */
  queryAttPersonDetailTitle(params) {
    return this.http.post("queryAttPersonDetailTitle", params);
  }

  /** 考勤报表
   * 查询人员表格 表数据
   */
  queryAttPersonDetail(params) {
    return this.http.post("queryAttPersonDetail", params);
  }

  /** 考勤报表
   * 查询按实际 按工作日
   */
  queryUserConfig(params) {
    return this.http.post("queryUserConfig", params);
  }

  /** 考勤报表
   * 保存按实际 按工作日
   */
  saveUserConfig(params) {
    return this.http.post("saveUserConfig", params);
  }

  /** 考勤报表
   * 查考勤明细 穿透数据
   */
  queryAttDetailPageModel(params) {
    return this.http.post("queryAttDetailPageModel", params);
  }

  /** 考勤报表
   * 查打卡明细 穿透数据
   */
  attQueryRecordList(params) {
    return this.http.post("attQueryRecordList", params);
  }

   // 导出 部门汇总数据
   queryReportAttDeptExport(params) {
    return this.http.post("/queryReportAttDeptExport", params, { responseType: "arraybuffer" });
  }

  // 导出 人员数据
  queryReportAttUserExport(params) {
    return this.http.post("/queryReportAttUserExport", params, { responseType: "arraybuffer" });
  }

  // 考勤明细导出 第一次穿透
  queryAttDetailsExport(params) {
    return this.http.post("/queryAttDetailsExport", params, { responseType: "arraybuffer" });
  }

  // 打卡明细导出 第二次穿透
  attQueryRecordExport(params) {
    return this.http.post("/attQueryRecordExport", params, { responseType: "arraybuffer" });
  }

  // 查询全局变量
  queryRbacGloableVariableList(params) {
    return this.http.post("/queryRbacGloableVariableList", params);
  }
}

export default HrService;
