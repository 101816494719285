/*
 * @Author: 陈炯明
 * @Date: 2020-07-02 15:42:06
 * @LastEditTime: 2021-01-21 14:28:53
 * @LastEditors: wuqi
 * @Description: 合同报表，流程报表，明细报表-路由
 * @FilePath: \user1\src\router\report\index.js
 */

import analysisModel from "./analysisModel";

const routes = [
  {
    path: "/report",
    name: "report",
    component: () => import(/* webpackChunkName: "report" */ "@/views/report/index"),
    meta: {
      title: "报表列表",
      fullPage: false,
      keepAlive: true
    }
  },
  // 单据明细， 表单的报表，应该是不需要重构了。
  {
    path: "/report/detail",
    name: "detail",
    component: () => import(/* webpackChunkName: "report" */ "@/views/report/system/detail"),
    meta: {
      title: "系统报表-单据明细表",
      fullPage: false,
      keepAlive: true
    }
  },
  {
    path: "/report/contract",
    name: "contract",
    component: () => import(/* webpackChunkName: "report" */ "@/views/report/system/contract"),
    meta: {
      title: "系统报表-合同统计表",
      fullPage: false,
      keepAlive: true
    }
  },
  {
    path: "/report/flow",
    name: "flow",
    component: () => import(/* webpackChunkName: "report" */ "@/views/report/system/flow"),
    meta: {
      title: "系统报表-流程统计表",
      fullPage: false,
      keepAlive: true
    }
  },
  {
    path: "/report/budget",
    name: "budget",
    component: () => import(/* webpackChunkName: "report" */ "@/views/report/budgetReport/budgetReport"),
    meta: {
      title: "预算执行",
      fullPage: false,
      keepAlive: false
    }
  },
  ...analysisModel
];

export default routes;
