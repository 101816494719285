var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "menu-container",
      staticStyle: { display: "flex" },
      style: [_vm.navStyle, _vm.BackgroundImage],
    },
    [
      _c(
        "div",
        {
          staticClass: "menu-container",
          staticStyle: { float: "left" },
          style: _vm.navStyle,
        },
        [
          _vm.themeInfo.SubmenuStyles == 3
            ? _c(
                "el-menu",
                {
                  class: {
                    collapse: _vm.isCollapse,
                    menu: _vm.navStyles === 1,
                    "menu-short": _vm.navStyles === 2,
                  },
                  style: [_vm.navBorderStyle, _vm.navStyle],
                  attrs: {
                    "default-active": _vm.activeIndex,
                    "text-color": "#a9b5c6",
                    "active-text-color": "#fff",
                    "unique-opened": true,
                    "collapse-transition": "",
                    collapse: _vm.isCollapse,
                  },
                  on: { open: _vm.handleOpen, close: _vm.handleClose },
                },
                [
                  _vm.themeInfo.SubmenuStyles == 3
                    ? _c("div", [
                        _vm.navStyles === 1
                          ? _c(
                              "div",
                              _vm._l(_vm.menuList, function (item, index) {
                                return _c(
                                  "span",
                                  {
                                    key: index,
                                    staticClass: "en-submenuStyle",
                                  },
                                  [
                                    item.children && item.children.length > 0
                                      ? _c(
                                          "el-submenu",
                                          {
                                            staticClass: "en-submenu",
                                            attrs: {
                                              classList: item.data.classifyList,
                                              menus: item.children,
                                              index: item.index,
                                              activeId: _vm.activeId,
                                            },
                                            nativeOn: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.clickMenuItem(item)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "template",
                                              { slot: "title" },
                                              [
                                                _c("en-icon", {
                                                  class: {
                                                    "en-icon": true,
                                                  },
                                                  attrs: {
                                                    name: _vm.setChildrenIcon(
                                                      item.icon
                                                    ),
                                                    size: "16",
                                                  },
                                                }),
                                                _vm.isCollapse
                                                  ? _c("i", {
                                                      staticClass:
                                                        "el-icon-arrow-right icon",
                                                    })
                                                  : _vm._e(),
                                                _c(
                                                  "span",
                                                  {
                                                    class: {
                                                      "en-icon-title": true,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.name?.length > 6
                                                          ? item.name.slice(
                                                              0,
                                                              6
                                                            ) + "..."
                                                          : item.name
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._l(
                                              item.children,
                                              function (subItem) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: subItem.index,
                                                    staticClass: "submenu",
                                                  },
                                                  [
                                                    subItem.children &&
                                                    subItem.children.length > 0
                                                      ? _c(
                                                          "el-submenu",
                                                          {
                                                            attrs: {
                                                              menus:
                                                                subItem.children,
                                                              index:
                                                                subItem.index,
                                                            },
                                                            nativeOn: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                return _vm.clickMenuItem(
                                                                  subItem
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _c("en-icon", {
                                                                  class: {
                                                                    "en-icon": true,
                                                                  },
                                                                  attrs: {
                                                                    name: _vm.setChildrenIcon(
                                                                      subItem.icon
                                                                    ),
                                                                    size: "16",
                                                                  },
                                                                }),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    class: {
                                                                      "en-icon-title": true,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        subItem
                                                                          .name
                                                                          .length >
                                                                          4
                                                                          ? subItem.name.slice(
                                                                              0,
                                                                              4
                                                                            ) +
                                                                              "..."
                                                                          : subItem.name
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _vm._l(
                                                              subItem.children,
                                                              function (
                                                                lastItem
                                                              ) {
                                                                return _c(
                                                                  "el-menu-item",
                                                                  {
                                                                    key: lastItem.index,
                                                                    attrs: {
                                                                      activeId:
                                                                        _vm.activeId,
                                                                      index:
                                                                        lastItem.index,
                                                                    },
                                                                    nativeOn: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          return _vm.clickMenuItem(
                                                                            lastItem
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "en-icon",
                                                                      {
                                                                        class: {
                                                                          "en-icon": true,
                                                                        },
                                                                        attrs: {
                                                                          name: _vm.setChildrenIcon(
                                                                            lastItem.icon
                                                                          ),
                                                                          size: "16",
                                                                        },
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        class: {
                                                                          "en-icon-title": true,
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            lastItem
                                                                              .name
                                                                              .length >
                                                                              4
                                                                              ? lastItem.name.slice(
                                                                                  0,
                                                                                  4
                                                                                ) +
                                                                                  "..."
                                                                              : lastItem.name
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        )
                                                      : _c(
                                                          "el-menu-item",
                                                          {
                                                            attrs: {
                                                              activeId:
                                                                _vm.activeId,
                                                              index:
                                                                subItem.index,
                                                            },
                                                            nativeOn: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                return _vm.clickMenuItem(
                                                                  subItem
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("en-icon", {
                                                              class: {
                                                                "en-icon": true,
                                                              },
                                                              attrs: {
                                                                name: _vm.setChildrenIcon(
                                                                  subItem.icon
                                                                ),
                                                                size: "16",
                                                              },
                                                            }),
                                                            _c(
                                                              "span",
                                                              {
                                                                class: {
                                                                  "en-icon-title": true,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    subItem.name
                                                                      .length >
                                                                      6
                                                                      ? subItem.name.slice(
                                                                          0,
                                                                          6
                                                                        ) +
                                                                          "..."
                                                                      : subItem.name
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        )
                                      : _c(
                                          "el-menu-item",
                                          {
                                            attrs: {
                                              index: item.index,
                                              activeId: _vm.activeId,
                                            },
                                            nativeOn: {
                                              click: function ($event) {
                                                return _vm.clickMenuItem(item)
                                              },
                                            },
                                          },
                                          [
                                            _c("en-icon", {
                                              class: {
                                                "en-icon": true,
                                              },
                                              attrs: {
                                                name: item.icon,
                                                size: "16",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              {
                                                class: {
                                                  "en-icon-title": true,
                                                },
                                                attrs: { slot: "title" },
                                                slot: "title",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    item.name.length > 6
                                                      ? item.name.slice(0, 6) +
                                                          "..."
                                                      : item.name
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                        _vm.navStyles === 2
                          ? _c(
                              "div",
                              _vm._l(_vm.menuList, function (item, index) {
                                return _c(
                                  "span",
                                  {
                                    key: index,
                                    staticClass: "en-submenuStyleTwo",
                                  },
                                  [
                                    item.children && item.children.length > 0
                                      ? _c(
                                          "el-submenu",
                                          {
                                            staticClass: "en-submenu",
                                            attrs: {
                                              classList: item.data.classifyList,
                                              menus: item.children,
                                              index: item.index,
                                              activeId: _vm.activeId,
                                            },
                                            nativeOn: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.clickMenuItem(item)
                                              },
                                            },
                                          },
                                          [
                                            _c("template", { slot: "title" }, [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    height: "40px",
                                                    "text-align": "center",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "h18" },
                                                    [
                                                      _c("en-icon", {
                                                        staticClass: "en-icon",
                                                        attrs: {
                                                          name: _vm.setChildrenIcon(
                                                            item.icon
                                                          ),
                                                          size: "12",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  !_vm.isCollapse
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "h18 font12 ml-10",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "en-icon-title",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.name
                                                                    .length > 4
                                                                    ? item.name.slice(
                                                                        0,
                                                                        4
                                                                      ) + "..."
                                                                    : item.name
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm.isCollapse
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "h18 font12 ml-10",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "en-icon-title",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.name.slice(
                                                                    0,
                                                                    2
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]),
                                            _vm._l(
                                              item.children,
                                              function (subItem) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: subItem.index,
                                                    staticClass: "submenu",
                                                  },
                                                  [
                                                    subItem.children &&
                                                    subItem.children.length > 0
                                                      ? _c(
                                                          "el-submenu",
                                                          {
                                                            attrs: {
                                                              menus:
                                                                subItem.children,
                                                              index:
                                                                subItem.index,
                                                            },
                                                            nativeOn: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                return _vm.clickMenuItem(
                                                                  subItem
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "textAlign h40",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "h18",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "en-icon",
                                                                          {
                                                                            staticClass:
                                                                              "en-icon",
                                                                            attrs:
                                                                              {
                                                                                name: _vm.setChildrenIcon(
                                                                                  subItem.icon
                                                                                ),
                                                                                size: "12",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    !_vm.isCollapse
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "h18 font12 ml-10",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "en-icon-title",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    subItem.name >
                                                                                      4
                                                                                      ? subItem.name.slice(
                                                                                          0,
                                                                                          4
                                                                                        ) +
                                                                                          "..."
                                                                                      : subItem.name
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm.isCollapse
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "h18 font12 ml-10",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "en-icon-title",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    subItem.name.slice(
                                                                                      0,
                                                                                      2
                                                                                    )
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._l(
                                                              subItem.children,
                                                              function (
                                                                lastItem
                                                              ) {
                                                                return _c(
                                                                  "el-menu-item",
                                                                  {
                                                                    key: lastItem.index,
                                                                    attrs: {
                                                                      navStyles:
                                                                        _vm.navStyles,
                                                                      activeId:
                                                                        _vm.activeId,
                                                                      index:
                                                                        lastItem.index,
                                                                    },
                                                                    nativeOn: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          return _vm.clickMenuItem(
                                                                            lastItem
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "textAlign h40",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "h18",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "en-icon",
                                                                              {
                                                                                staticClass:
                                                                                  "en-icon",
                                                                                attrs:
                                                                                  {
                                                                                    name: _vm.setChildrenIcon(
                                                                                      lastItem.icon
                                                                                    ),
                                                                                    size: "12",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        !_vm.isCollapse
                                                                          ? _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "h18 font12 ml-10",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "span",
                                                                                  {
                                                                                    staticClass:
                                                                                      "en-icon-title",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        lastItem.name >
                                                                                          4
                                                                                          ? lastItem.name.slice(
                                                                                              0,
                                                                                              4
                                                                                            ) +
                                                                                              "..."
                                                                                          : lastItem.name
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        _vm.isCollapse
                                                                          ? _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "h18 font12 ml-10",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "span",
                                                                                  {
                                                                                    staticClass:
                                                                                      "en-icon-title",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        lastItem.name.slice(
                                                                                          0,
                                                                                          2
                                                                                        )
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        )
                                                      : _c(
                                                          "el-menu-item",
                                                          {
                                                            attrs: {
                                                              activeId:
                                                                _vm.activeId,
                                                              navStyles:
                                                                _vm.navStyles,
                                                              index:
                                                                subItem.index,
                                                            },
                                                            nativeOn: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                return _vm.clickMenuItem(
                                                                  subItem
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "textAlign h40",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "h18",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "en-icon",
                                                                      {
                                                                        staticClass:
                                                                          "en-icon",
                                                                        attrs: {
                                                                          name: _vm.setChildrenIcon(
                                                                            subItem.icon
                                                                          ),
                                                                          size: "12",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                !_vm.isCollapse
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "h18 font12 ml-10",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "en-icon-title",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                subItem.name >
                                                                                  4
                                                                                  ? subItem.name.slice(
                                                                                      0,
                                                                                      4
                                                                                    ) +
                                                                                      "..."
                                                                                  : subItem.name
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                _vm.isCollapse
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "h18 font12 ml-10",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "en-icon-title",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                subItem.name.slice(
                                                                                  0,
                                                                                  2
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        )
                                      : _c(
                                          "el-menu-item",
                                          {
                                            staticStyle: {
                                              "text-align": "center",
                                            },
                                            attrs: {
                                              index: item.index,
                                              navStyles: _vm.navStyles,
                                              activeId: _vm.activeId,
                                            },
                                            nativeOn: {
                                              click: function ($event) {
                                                return _vm.clickMenuItem(item)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "textAlign h40" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "h18" },
                                                  [
                                                    _c("en-icon", {
                                                      attrs: {
                                                        name: item.icon,
                                                        size: "12",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                !_vm.isCollapse
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "h18 font12 ml-10",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            attrs: {
                                                              slot: "title",
                                                            },
                                                            slot: "title",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.name
                                                                  .length > 4
                                                                  ? item.name.slice(
                                                                      0,
                                                                      4
                                                                    ) + "..."
                                                                  : item.name
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.isCollapse
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "h18 font12 ml-10",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            attrs: {
                                                              slot: "title",
                                                            },
                                                            slot: "title",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.name.slice(
                                                                  0,
                                                                  2
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        ),
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _vm.themeInfo.SubmenuStyles == 1 || _vm.themeInfo.SubmenuStyles == 2
            ? _c(
                "en-menu",
                {
                  key: _vm.updateKey,
                  class: {
                    collapse: _vm.isCollapse,
                    menu: _vm.navStyles === 1,
                    "menu-short": _vm.navStyles === 2,
                  },
                  style: [_vm.navBorderStyle, _vm.navStyle],
                  attrs: {
                    "default-active": _vm.activeIndex,
                    "text-color": "#a9b5c6",
                    "active-text-color": "#fff",
                    "unique-opened": true,
                    "collapse-transition": "",
                    collapse: _vm.isCollapse,
                  },
                  on: { open: _vm.handleOpen, close: _vm.handleClose },
                },
                [
                  _vm.themeInfo.SubmenuStyles == 2
                    ? _c("div", [
                        _vm.navStyles === 1
                          ? _c(
                              "div",
                              _vm._l(_vm.menuList, function (item, index) {
                                return _c(
                                  "span",
                                  {
                                    key: index,
                                    staticClass: "en-submenuStyle",
                                  },
                                  [
                                    item.children && item.children.length > 0
                                      ? _c(
                                          "en-submenu",
                                          {
                                            staticClass: "en-submenu",
                                            attrs: {
                                              classList: item.data.classifyList,
                                              menus: item.children,
                                              popperClass: "shortFix",
                                              popperStyle: _vm.subStyle,
                                              index: item.index,
                                              activeId: _vm.activeId,
                                            },
                                            nativeOn: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.clickMenuItem(item)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "template",
                                              { slot: "title" },
                                              [
                                                _c("en-icon", {
                                                  class: {
                                                    "en-icon": true,
                                                  },
                                                  attrs: {
                                                    name: _vm.setChildrenIcon(
                                                      item.icon
                                                    ),
                                                    size: "16",
                                                  },
                                                }),
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    staticClass: "item",
                                                    attrs: {
                                                      "open-delay": 1000,
                                                      effect: "light",
                                                      disabled:
                                                        item?.name?.length < 6,
                                                      content:
                                                        item.name.length > 6
                                                          ? item.name.slice(
                                                              0,
                                                              5
                                                            ) + "..."
                                                          : item.name,
                                                      placement: "right-end",
                                                    },
                                                  },
                                                  [
                                                    !_vm.isCollapse
                                                      ? _c(
                                                          "span",
                                                          {
                                                            class: {
                                                              "en-icon-title": true,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.name
                                                                  .length > 6
                                                                  ? item.name.slice(
                                                                      0,
                                                                      5
                                                                    ) + "..."
                                                                  : item.name
                                                              )
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._l(
                                              item.children,
                                              function (subItem) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: subItem.index,
                                                    staticClass: "submenu",
                                                  },
                                                  [
                                                    subItem.children &&
                                                    subItem.children.length > 0
                                                      ? _c(
                                                          "en-submenu",
                                                          {
                                                            attrs: {
                                                              menus:
                                                                subItem.children,
                                                              index:
                                                                subItem.index,
                                                            },
                                                            nativeOn: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                return _vm.clickMenuItem(
                                                                  subItem
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _c("en-icon", {
                                                                  class: {
                                                                    "en-icon": true,
                                                                  },
                                                                  attrs: {
                                                                    name: _vm.setChildrenIcon(
                                                                      subItem.icon
                                                                    ),
                                                                    size: "16",
                                                                  },
                                                                }),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    class: {
                                                                      "en-icon-title": true,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item
                                                                          .subItem
                                                                          ?.length >
                                                                          6
                                                                          ? item.subItem.slice(
                                                                              0,
                                                                              5
                                                                            ) +
                                                                              "..."
                                                                          : item.subItem
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _vm._l(
                                                              subItem.children,
                                                              function (
                                                                lastItem
                                                              ) {
                                                                return _c(
                                                                  "en-menu-item",
                                                                  {
                                                                    key: lastItem.index,
                                                                    attrs: {
                                                                      activeId:
                                                                        _vm.activeId,
                                                                      index:
                                                                        lastItem.index,
                                                                    },
                                                                    nativeOn: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          return _vm.clickMenuItem(
                                                                            lastItem
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "en-icon",
                                                                      {
                                                                        class: {
                                                                          "en-icon": true,
                                                                        },
                                                                        attrs: {
                                                                          name: _vm.setChildrenIcon(
                                                                            lastItem.icon
                                                                          ),
                                                                          size: "16",
                                                                        },
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        class: {
                                                                          "en-icon-title": true,
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item
                                                                              .lastItem
                                                                              ?.length >
                                                                              6
                                                                              ? item.lastItem.slice(
                                                                                  0,
                                                                                  6
                                                                                ) +
                                                                                  "..."
                                                                              : item.lastItem
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        )
                                                      : _c(
                                                          "en-menu-item",
                                                          {
                                                            attrs: {
                                                              activeId:
                                                                _vm.activeId,
                                                              index:
                                                                subItem.index,
                                                            },
                                                            nativeOn: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                return _vm.clickMenuItem(
                                                                  subItem
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("en-icon", {
                                                              class: {
                                                                "en-icon": true,
                                                              },
                                                              attrs: {
                                                                name: _vm.setChildrenIcon(
                                                                  subItem.icon
                                                                ),
                                                                size: "16",
                                                              },
                                                            }),
                                                            _c(
                                                              "span",
                                                              {
                                                                class: {
                                                                  "en-icon-title": true,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.subItem
                                                                      ?.length >
                                                                      6
                                                                      ? item.subItem.slice(
                                                                          0,
                                                                          5
                                                                        ) +
                                                                          "..."
                                                                      : item.subItem
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        )
                                      : _c(
                                          "en-menu-item",
                                          {
                                            attrs: {
                                              index: item.index,
                                              activeId: _vm.activeId,
                                            },
                                            nativeOn: {
                                              click: function ($event) {
                                                return _vm.clickMenuItem(item)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "item",
                                                attrs: {
                                                  "open-delay": 1000,
                                                  effect: "light",
                                                  disabled:
                                                    item?.name?.length < 6,
                                                  content:
                                                    item.name.length > 6
                                                      ? item.name.slice(0, 5) +
                                                        "..."
                                                      : item.name,
                                                  placement: "right-end",
                                                },
                                              },
                                              [
                                                _c("en-icon", {
                                                  staticClass: "en-icon",
                                                  class: {
                                                    "en-icon": true,
                                                  },
                                                  attrs: {
                                                    name: item.icon,
                                                    size: "16",
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    class: {
                                                      "en-icon-title": true,
                                                    },
                                                    attrs: { slot: "title" },
                                                    slot: "title",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.name?.length > 6
                                                          ? item.name.slice(
                                                              0,
                                                              5
                                                            ) + "..."
                                                          : item.name
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                        _vm.navStyles === 2
                          ? _c(
                              "div",
                              _vm._l(_vm.menuList, function (item, index) {
                                return _c(
                                  "span",
                                  {
                                    key: index,
                                    staticClass: "en-submenuStyle",
                                  },
                                  [
                                    item.children && item.children.length > 0
                                      ? _c(
                                          "en-submenu",
                                          {
                                            staticClass: "en-submenu",
                                            attrs: {
                                              navStyles: _vm.navStyles,
                                              classList: item.data.classifyList,
                                              menus: item.children,
                                              popperClass: "shortFix",
                                              popperStyle: _vm.subStyle,
                                              index: item.index,
                                              activeId: _vm.activeId,
                                            },
                                            nativeOn: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.clickMenuItem(item)
                                              },
                                            },
                                          },
                                          [
                                            _c("template", { slot: "title" }, [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    height: "40px",
                                                    "text-align": "center",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "h18",
                                                      staticStyle: {
                                                        "margin-bottom": "3px",
                                                      },
                                                    },
                                                    [
                                                      _c("en-icon", {
                                                        staticClass: "en-icon",
                                                        staticStyle: {
                                                          "margin-right": "0px",
                                                        },
                                                        attrs: {
                                                          name: _vm.setChildrenIcon(
                                                            item.icon
                                                          ),
                                                          size: "12",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  !_vm.isCollapse
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "h18 font12",
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.name
                                                                  .length > 4
                                                                  ? item.name.slice(
                                                                      0,
                                                                      4
                                                                    ) + "..."
                                                                  : item.name
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm.isCollapse
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "h18 font12",
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.name.slice(
                                                                  0,
                                                                  2
                                                                )
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]),
                                            _vm._l(
                                              item.children,
                                              function (subItem) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: subItem.index,
                                                    staticClass: "submenu",
                                                  },
                                                  [
                                                    subItem.children &&
                                                    subItem.children.length > 0
                                                      ? _c(
                                                          "en-submenu",
                                                          {
                                                            attrs: {
                                                              menus:
                                                                subItem.children,
                                                              navStyles:
                                                                _vm.navStyles,
                                                              index:
                                                                subItem.index,
                                                            },
                                                            nativeOn: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                return _vm.clickMenuItem(
                                                                  subItem
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "textAlign h40",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "h18",
                                                                        staticStyle:
                                                                          {
                                                                            "margin-bottom":
                                                                              "3px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "en-icon",
                                                                          {
                                                                            staticClass:
                                                                              "en-icon",
                                                                            staticStyle:
                                                                              {
                                                                                "margin-right":
                                                                                  "0px",
                                                                              },
                                                                            attrs:
                                                                              {
                                                                                name: _vm.setChildrenIcon(
                                                                                  subItem.icon
                                                                                ),
                                                                                size: "12",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    !_vm.isCollapse
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "h18 font12",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "en-icon-title",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    subItem.name >
                                                                                      4
                                                                                      ? subItem.name.slice(
                                                                                          0,
                                                                                          4
                                                                                        ) +
                                                                                          "..."
                                                                                      : subItem.name
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm.isCollapse
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "h18 font12",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "en-icon-title",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    subItem.name.slice(
                                                                                      0,
                                                                                      2
                                                                                    )
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._l(
                                                              subItem.children,
                                                              function (
                                                                lastItem
                                                              ) {
                                                                return _c(
                                                                  "en-menu-item",
                                                                  {
                                                                    key: lastItem.index,
                                                                    attrs: {
                                                                      navStyles:
                                                                        _vm.navStyles,
                                                                      activeId:
                                                                        _vm.activeId,
                                                                      index:
                                                                        lastItem.index,
                                                                    },
                                                                    nativeOn: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          return _vm.clickMenuItem(
                                                                            lastItem
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "textAlign h40",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "h18",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "en-icon",
                                                                              {
                                                                                staticClass:
                                                                                  "en-icon",
                                                                                staticStyle:
                                                                                  {
                                                                                    "margin-right":
                                                                                      "0px",
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    name: _vm.setChildrenIcon(
                                                                                      lastItem.icon
                                                                                    ),
                                                                                    size: "12",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        !_vm.isCollapse
                                                                          ? _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "h18 font12",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "span",
                                                                                  {
                                                                                    staticClass:
                                                                                      "en-icon-title",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        lastItem.name >
                                                                                          4
                                                                                          ? lastItem.name.slice(
                                                                                              0,
                                                                                              4
                                                                                            ) +
                                                                                              "..."
                                                                                          : lastItem.name
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        _vm.isCollapse
                                                                          ? _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "h18 font12",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "span",
                                                                                  {
                                                                                    staticClass:
                                                                                      "en-icon-title",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        lastItem.name.slice(
                                                                                          0,
                                                                                          2
                                                                                        )
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        )
                                                      : _c(
                                                          "en-menu-item",
                                                          {
                                                            attrs: {
                                                              activeId:
                                                                _vm.activeId,
                                                              navStyles:
                                                                _vm.navStyles,
                                                              index:
                                                                subItem.index,
                                                            },
                                                            nativeOn: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                return _vm.clickMenuItem(
                                                                  subItem
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "textAlign h40",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "h18",
                                                                    staticStyle:
                                                                      {
                                                                        "margin-bottom":
                                                                          "3px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "en-icon",
                                                                      {
                                                                        staticClass:
                                                                          "en-icon",
                                                                        staticStyle:
                                                                          {
                                                                            "margin-right":
                                                                              "0px",
                                                                          },
                                                                        attrs: {
                                                                          name: _vm.setChildrenIcon(
                                                                            subItem.icon
                                                                          ),
                                                                          size: "12",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                !_vm.isCollapse
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "h18 font12",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "en-icon-title",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                subItem.name >
                                                                                  4
                                                                                  ? subItem.name.slice(
                                                                                      0,
                                                                                      4
                                                                                    ) +
                                                                                      "..."
                                                                                  : subItem.name
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                _vm.isCollapse
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "h18 font12",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "en-icon-title",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                subItem.name.slice(
                                                                                  0,
                                                                                  2
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        )
                                      : _c(
                                          "en-menu-item",
                                          {
                                            staticStyle: {
                                              "text-align": "center",
                                            },
                                            attrs: {
                                              index: item.index,
                                              navStyles: _vm.navStyles,
                                              activeId: _vm.activeId,
                                            },
                                            nativeOn: {
                                              click: function ($event) {
                                                return _vm.clickMenuItem(item)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "textAlign h40" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "h18",
                                                    staticStyle: {
                                                      "margin-bottom": "3px",
                                                    },
                                                  },
                                                  [
                                                    _c("en-icon", {
                                                      staticStyle: {
                                                        "margin-right": "0px",
                                                      },
                                                      attrs: {
                                                        name: item.icon,
                                                        size: "12",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                !_vm.isCollapse
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "h18 font12",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            attrs: {
                                                              slot: "title",
                                                            },
                                                            slot: "title",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.name
                                                                  .length > 4
                                                                  ? item.name.slice(
                                                                      0,
                                                                      4
                                                                    ) + "..."
                                                                  : item.name
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.isCollapse
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "h18 font12",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            attrs: {
                                                              slot: "title",
                                                            },
                                                            slot: "title",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.name.slice(
                                                                  0,
                                                                  2
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        ),
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm.themeInfo.SubmenuStyles == 1
                    ? _c("div", [
                        _vm.navStyles === 1
                          ? _c(
                              "div",
                              _vm._l(_vm.menuList, function (item, index) {
                                return _c(
                                  "span",
                                  {
                                    key: index,
                                    staticClass: "en-submenuStyle",
                                  },
                                  [
                                    item.children && item.children.length > 0
                                      ? _c(
                                          "en-submenu",
                                          {
                                            staticClass: "en-submenu",
                                            attrs: {
                                              classList: item.data.classifyList,
                                              menus: item.children,
                                              index: item.index,
                                              activeId: _vm.activeId,
                                            },
                                            nativeOn: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.clickMenuItem(item)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "template",
                                              { slot: "title" },
                                              [
                                                _c("en-icon", {
                                                  class: {
                                                    "en-icon": true,
                                                  },
                                                  attrs: {
                                                    name: _vm.setChildrenIcon(
                                                      item.icon
                                                    ),
                                                    size: "16",
                                                  },
                                                }),
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    staticClass: "item",
                                                    attrs: {
                                                      "open-delay": 1000,
                                                      disabled:
                                                        item?.name?.length < 6,
                                                      effect: "light",
                                                      content: item.name,
                                                      placement: "right-end",
                                                    },
                                                  },
                                                  [
                                                    !_vm.isCollapse
                                                      ? _c(
                                                          "span",
                                                          {
                                                            class: {
                                                              "en-icon-title": true,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.name
                                                                  .length > 6
                                                                  ? item.name.slice(
                                                                      0,
                                                                      5
                                                                    ) + "..."
                                                                  : item.name
                                                              )
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._l(
                                              item.children,
                                              function (subItem) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: subItem.index,
                                                    staticClass: "submenu",
                                                  },
                                                  [
                                                    subItem.children &&
                                                    subItem.children.length > 0
                                                      ? _c(
                                                          "en-submenu",
                                                          {
                                                            attrs: {
                                                              menus:
                                                                subItem.children,
                                                              index:
                                                                subItem.index,
                                                            },
                                                            nativeOn: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                return _vm.clickMenuItem(
                                                                  subItem
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _c("en-icon", {
                                                                  class: {
                                                                    "en-icon": true,
                                                                  },
                                                                  attrs: {
                                                                    name: _vm.setChildrenIcon(
                                                                      subItem.icon
                                                                    ),
                                                                    size: "16",
                                                                  },
                                                                }),
                                                                _c(
                                                                  "el-tooltip",
                                                                  {
                                                                    staticClass:
                                                                      "item",
                                                                    attrs: {
                                                                      "open-delay": 1000,
                                                                      disabled:
                                                                        item
                                                                          ?.subItem
                                                                          ?.length <
                                                                        6,
                                                                      effect:
                                                                        "light",
                                                                      content:
                                                                        item.subItem,
                                                                      placement:
                                                                        "right-end",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        class: {
                                                                          "en-icon-title": true,
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item
                                                                              .subItem
                                                                              ?.length >
                                                                              6
                                                                              ? item.subItem.slice(
                                                                                  0,
                                                                                  5
                                                                                ) +
                                                                                  "..."
                                                                              : item.subItem
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _vm._l(
                                                              subItem.children,
                                                              function (
                                                                lastItem
                                                              ) {
                                                                return _c(
                                                                  "en-menu-item",
                                                                  {
                                                                    key: lastItem.index,
                                                                    attrs: {
                                                                      activeId:
                                                                        _vm.activeId,
                                                                      index:
                                                                        lastItem.index,
                                                                    },
                                                                    nativeOn: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          return _vm.clickMenuItem(
                                                                            lastItem
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "en-icon",
                                                                      {
                                                                        class: {
                                                                          "en-icon": true,
                                                                        },
                                                                        attrs: {
                                                                          name: _vm.setChildrenIcon(
                                                                            lastItem.icon
                                                                          ),
                                                                          size: "16",
                                                                        },
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "el-tooltip",
                                                                      {
                                                                        staticClass:
                                                                          "item",
                                                                        attrs: {
                                                                          "open-delay": 1000,
                                                                          disabled:
                                                                            item
                                                                              ?.lastItem
                                                                              ?.length <
                                                                            6,
                                                                          effect:
                                                                            "light",
                                                                          content:
                                                                            item.lastItem,
                                                                          placement:
                                                                            "right-end",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            class:
                                                                              {
                                                                                "en-icon-title": true,
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                item
                                                                                  .lastItem
                                                                                  ?.length >
                                                                                  6
                                                                                  ? item.lastItem.slice(
                                                                                      0,
                                                                                      5
                                                                                    ) +
                                                                                      "..."
                                                                                  : item.lastItem
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        )
                                                      : _c(
                                                          "en-menu-item",
                                                          {
                                                            attrs: {
                                                              activeId:
                                                                _vm.activeId,
                                                              index:
                                                                subItem.index,
                                                            },
                                                            nativeOn: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                return _vm.clickMenuItem(
                                                                  subItem
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("en-icon", {
                                                              class: {
                                                                "en-icon": true,
                                                              },
                                                              attrs: {
                                                                name: _vm.setChildrenIcon(
                                                                  subItem.icon
                                                                ),
                                                                size: "16",
                                                              },
                                                            }),
                                                            _c(
                                                              "el-tooltip",
                                                              {
                                                                staticClass:
                                                                  "item",
                                                                attrs: {
                                                                  "open-delay": 1000,
                                                                  disabled:
                                                                    item
                                                                      ?.subItem
                                                                      ?.length <
                                                                    6,
                                                                  effect:
                                                                    "light",
                                                                  content:
                                                                    item.subItem,
                                                                  placement:
                                                                    "right-end",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    class: {
                                                                      "en-icon-title": true,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item
                                                                          .subItem
                                                                          ?.length >
                                                                          6
                                                                          ? item.subItem.slice(
                                                                              0,
                                                                              5
                                                                            ) +
                                                                              "..."
                                                                          : item.subItem
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        )
                                      : _c(
                                          "en-menu-item",
                                          {
                                            attrs: {
                                              index: item.index,
                                              activeId: _vm.activeId,
                                            },
                                            nativeOn: {
                                              click: function ($event) {
                                                return _vm.clickMenuItem(item)
                                              },
                                            },
                                          },
                                          [
                                            _c("en-icon", {
                                              staticClass: "en-icon",
                                              class: {
                                                "en-icon": true,
                                              },
                                              attrs: {
                                                name: item.icon,
                                                size: "16",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              {
                                                class: {
                                                  "en-icon-title": true,
                                                },
                                                attrs: { slot: "title" },
                                                slot: "title",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      item.name?.length > 6
                                                        ? item.name.slice(
                                                            0,
                                                            5
                                                          ) + "..."
                                                        : item.name
                                                    )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                        _vm.navStyles === 2
                          ? _c(
                              "div",
                              _vm._l(_vm.menuList, function (item, index) {
                                return _c(
                                  "span",
                                  {
                                    key: index,
                                    staticClass: "en-submenuStyle",
                                  },
                                  [
                                    item.children && item.children.length > 0
                                      ? _c(
                                          "en-submenu",
                                          {
                                            staticClass: "en-submenu",
                                            attrs: {
                                              navStyles: _vm.navStyles,
                                              classList: item.data.classifyList,
                                              menus: item.children,
                                              index: item.index,
                                              activeId: _vm.activeId,
                                            },
                                            nativeOn: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.clickMenuItem(item)
                                              },
                                            },
                                          },
                                          [
                                            _c("template", { slot: "title" }, [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    height: "40px",
                                                    "text-align": "center",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "h18",
                                                      staticStyle: {
                                                        "margin-bottom": "3px",
                                                      },
                                                    },
                                                    [
                                                      _c("en-icon", {
                                                        staticClass: "en-icon",
                                                        staticStyle: {
                                                          "margin-right": "0px",
                                                        },
                                                        attrs: {
                                                          size: "20",
                                                          name: _vm.setChildrenIcon(
                                                            item.icon
                                                          ),
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-tooltip",
                                                    {
                                                      staticClass: "item",
                                                      attrs: {
                                                        effect: "light",
                                                        "open-delay": 1000,
                                                        disabled:
                                                          item?.name?.length <
                                                          6,
                                                        content: item.name,
                                                        placement: "right-end",
                                                      },
                                                    },
                                                    [
                                                      !_vm.isCollapse
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "h18 font12",
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.name
                                                                      .length >
                                                                      4
                                                                      ? item.name.slice(
                                                                          0,
                                                                          4
                                                                        ) +
                                                                          "..."
                                                                      : item.name
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm.isCollapse
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "h18 font12",
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.name.slice(
                                                                      0,
                                                                      2
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]),
                                            _vm._l(
                                              item.children,
                                              function (subItem) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: subItem.index,
                                                    staticClass: "submenu",
                                                  },
                                                  [
                                                    subItem.children &&
                                                    subItem.children.length > 0
                                                      ? _c(
                                                          "en-submenu",
                                                          {
                                                            attrs: {
                                                              menus:
                                                                subItem.children,
                                                              navStyles:
                                                                _vm.navStyles,
                                                              index:
                                                                subItem.index,
                                                            },
                                                            nativeOn: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                return _vm.clickMenuItem(
                                                                  subItem
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "textAlign h40",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "h18",
                                                                        staticStyle:
                                                                          {
                                                                            "margin-bottom":
                                                                              "3px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "en-icon",
                                                                          {
                                                                            staticClass:
                                                                              "en-icon",
                                                                            staticStyle:
                                                                              {
                                                                                "margin-right":
                                                                                  "0px",
                                                                              },
                                                                            attrs:
                                                                              {
                                                                                size: "20",
                                                                                name: _vm.setChildrenIcon(
                                                                                  subItem.icon
                                                                                ),
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "el-tooltip",
                                                                      {
                                                                        staticClass:
                                                                          "item",
                                                                        attrs: {
                                                                          effect:
                                                                            "light",
                                                                          "open-delay": 1000,
                                                                          disabled:
                                                                            subItem?.length <
                                                                            6,
                                                                          content:
                                                                            subItem.name,
                                                                          placement:
                                                                            "right-end",
                                                                        },
                                                                      },
                                                                      [
                                                                        !_vm.isCollapse
                                                                          ? _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "h18 font12",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "span",
                                                                                  {
                                                                                    staticClass:
                                                                                      "en-icon-title",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        subItem.name >
                                                                                          4
                                                                                          ? subItem.name.slice(
                                                                                              0,
                                                                                              4
                                                                                            ) +
                                                                                              "..."
                                                                                          : subItem.name
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        _vm.isCollapse
                                                                          ? _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "h18 font12",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "span",
                                                                                  {
                                                                                    staticClass:
                                                                                      "en-icon-title",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        subItem.name.slice(
                                                                                          0,
                                                                                          2
                                                                                        )
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._l(
                                                              subItem.children,
                                                              function (
                                                                lastItem
                                                              ) {
                                                                return _c(
                                                                  "en-menu-item",
                                                                  {
                                                                    key: lastItem.index,
                                                                    attrs: {
                                                                      navStyles:
                                                                        _vm.navStyles,
                                                                      activeId:
                                                                        _vm.activeId,
                                                                      index:
                                                                        lastItem.index,
                                                                    },
                                                                    nativeOn: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          return _vm.clickMenuItem(
                                                                            lastItem
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "textAlign h40",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "h18",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "en-icon",
                                                                              {
                                                                                staticClass:
                                                                                  "en-icon",
                                                                                staticStyle:
                                                                                  {
                                                                                    "margin-right":
                                                                                      "0px",
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    size: "20",
                                                                                    name: _vm.setChildrenIcon(
                                                                                      lastItem.icon
                                                                                    ),
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "el-tooltip",
                                                                          {
                                                                            staticClass:
                                                                              "item",
                                                                            attrs:
                                                                              {
                                                                                effect:
                                                                                  "light",
                                                                                "open-delay": 1000,
                                                                                disabled:
                                                                                  lastItem?.length <
                                                                                  6,
                                                                                content:
                                                                                  lastItem.name,
                                                                                placement:
                                                                                  "right-end",
                                                                              },
                                                                          },
                                                                          [
                                                                            !_vm.isCollapse
                                                                              ? _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "h18 font12",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "en-icon-title",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            lastItem.name >
                                                                                              4
                                                                                              ? lastItem.name.slice(
                                                                                                  0,
                                                                                                  4
                                                                                                ) +
                                                                                                  "..."
                                                                                              : lastItem.name
                                                                                          )
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                            _vm.isCollapse
                                                                              ? _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "h18 font12",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "en-icon-title",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            lastItem.name.slice(
                                                                                              0,
                                                                                              2
                                                                                            )
                                                                                          )
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        )
                                                      : _c(
                                                          "en-menu-item",
                                                          {
                                                            attrs: {
                                                              activeId:
                                                                _vm.activeId,
                                                              navStyles:
                                                                _vm.navStyles,
                                                              index:
                                                                subItem.index,
                                                            },
                                                            nativeOn: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                return _vm.clickMenuItem(
                                                                  subItem
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "textAlign h40",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "h18",
                                                                    staticStyle:
                                                                      {
                                                                        "margin-bottom":
                                                                          "3px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "en-icon",
                                                                      {
                                                                        staticClass:
                                                                          "en-icon",
                                                                        staticStyle:
                                                                          {
                                                                            "margin-right":
                                                                              "0px",
                                                                          },
                                                                        attrs: {
                                                                          size: "20",
                                                                          name: _vm.setChildrenIcon(
                                                                            subItem.icon
                                                                          ),
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "el-tooltip",
                                                                  {
                                                                    staticClass:
                                                                      "item",
                                                                    attrs: {
                                                                      effect:
                                                                        "light",
                                                                      "open-delay": 1000,
                                                                      disabled:
                                                                        subItem?.length <
                                                                        6,
                                                                      content:
                                                                        subItem.name,
                                                                      placement:
                                                                        "right-end",
                                                                    },
                                                                  },
                                                                  [
                                                                    !_vm.isCollapse
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "h18 font12",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "en-icon-title",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    subItem.name >
                                                                                      4
                                                                                      ? subItem.name.slice(
                                                                                          0,
                                                                                          4
                                                                                        ) +
                                                                                          "..."
                                                                                      : subItem.name
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm.isCollapse
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "h18 font12",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "en-icon-title",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    subItem.name.slice(
                                                                                      0,
                                                                                      2
                                                                                    )
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        )
                                      : _c(
                                          "en-menu-item",
                                          {
                                            staticStyle: {
                                              "text-align": "center",
                                            },
                                            attrs: {
                                              index: item.index,
                                              navStyles: _vm.navStyles,
                                              activeId: _vm.activeId,
                                            },
                                            nativeOn: {
                                              click: function ($event) {
                                                return _vm.clickMenuItem(item)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "textAlign h40" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "h18",
                                                    staticStyle: {
                                                      "margin-bottom": "3px",
                                                    },
                                                  },
                                                  [
                                                    _c("en-icon", {
                                                      staticStyle: {
                                                        "margin-right": "0px",
                                                      },
                                                      attrs: {
                                                        name: item.icon,
                                                        size: "20",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                !_vm.isCollapse
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "h18 font12",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            attrs: {
                                                              slot: "title",
                                                            },
                                                            slot: "title",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.name
                                                                  .length > 4
                                                                  ? item.name.slice(
                                                                      0,
                                                                      4
                                                                    ) + "..."
                                                                  : item.name
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.isCollapse
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "h18 font12",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            attrs: {
                                                              slot: "title",
                                                            },
                                                            slot: "title",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.name.slice(
                                                                  0,
                                                                  2
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        ),
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _c("div", { staticClass: "custom" }, [
            _c(
              "div",
              {
                staticClass: "custom-homepage",
                style: _vm.leftIconStyle,
                on: { click: _vm.gotoCustomHomePage },
              },
              [_c("i", { staticClass: "el-icon-setting icon" })]
            ),
            _c("div", {
              class: { "custom-line": true, "custom-true": _vm.isCollapse },
              style: _vm.lineStyle,
            }),
            _c(
              "div",
              {
                staticClass: "toggle-collapse",
                on: { click: _vm.changeIsCollapseTrue },
              },
              [
                _vm.isCollapse
                  ? _c(
                      "div",
                      { staticClass: "item1", style: _vm.rightIconStyle },
                      [
                        _c("en-icon", {
                          staticClass: "en-icon",
                          staticStyle: { "margin-right": "0px" },
                          attrs: { name: "iconcaidan-zhankai", size: "14" },
                        }),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      { staticClass: "item2", style: _vm.rightIconStyle },
                      [
                        _c("en-icon", {
                          staticClass: "en-icon",
                          staticStyle: { "margin-right": "0px" },
                          attrs: { name: "iconcaidan-shouqi", size: "14" },
                        }),
                      ],
                      1
                    ),
              ]
            ),
          ]),
        ],
        1
      ),
      _vm.menuChildList.length >= 1 && _vm.themeInfo.SubmenuStyles == 2
        ? _c(
            "div",
            { staticStyle: { color: "#fff", float: "left" } },
            [
              _c(
                "el-menu",
                {
                  class: {
                    collapse: _vm.isCollapse,
                    menu: _vm.navStyles === 1,
                    "menu-short": _vm.navStyles === 2,
                  },
                  staticStyle: { width: "320px", padding: "0 14px" },
                  style: [_vm.navBorderStyle, _vm.navStyle],
                  attrs: {
                    "text-color": "#a9b5c6",
                    "active-text-color": "#fff",
                    "unique-opened": true,
                    "collapse-transition": "",
                    collapse: _vm.isCollapse,
                  },
                  on: { open: _vm.handleOpen, close: _vm.handleClose },
                },
                [
                  _c(
                    "div",
                    { staticClass: "en-newSubmenuBox" },
                    _vm._l(_vm.menuChildList, function (item, index) {
                      return _c(
                        "span",
                        {
                          key: index,
                          staticClass: "en-newSubmenu",
                          nativeOn: {
                            click: function ($event) {
                              return _vm.clickMenuItem(item)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "content-item-icon",
                              staticStyle: { "margin-top": "14px" },
                              style: {
                                backgroundColor: _vm.iconColorBg(index),
                              },
                            },
                            [_c("en-icon", { attrs: { name: item.icon } })],
                            1
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "en-icon-title",
                              staticStyle: {
                                "margin-top": "10px",
                                display: "block",
                              },
                              attrs: { slot: "title" },
                              slot: "title",
                            },
                            [_vm._v(" " + _vm._s(item.name) + " ")]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }