/**
 * @Author: 黎加冬
 * @Date: 2020-08-10 16:04:12
 * @LastEditTime: nputttime
 * @LastEditors: 黎加冬
 * @Description: 分析建模-汇总报表
 * @FilePath:
 */
const route = [
  {
    path: "/report/analysisModel/totalReport",
    name: "totalReport",
    component: () => import(/* webpackChunkName: "analysisModel" */ "@/views/report/analysisModel/totalReport"),
    meta: {
      title: "分析建模-汇总报表",
      fullPage: false,
      keepAlive: false
    },
    props: (route) => ({
      templateId: route.query.templateId,
      refConditions: route.query.refConditions,
      type: route.query.type || 1,
      shouldGetCondition: route.query.shouldGetCondition
      // templateId: route.params.templateId,
      // refConditions: route.params.refConditions,
      // type: route.params.type || 1
    })
  },
  {
    path: "/report/analysisModel/reportDetail",
    name: "reportDetail",
    component: () => import(/* webpackChunkName: "analysisModel" */ "@/views/report/analysisModel/reportDetail"),
    meta: {
      title: "分析建模-汇总报表明细",
      fullPage: false,
      keepAlive: false
    },
    props: (route) => ({
      templateId: route.query.templateId,
      refConditions: route.query.refConditions,
      isQueryList: route.query.isQueryList,
      reportType: route.query.reportType,
      sourceId: route.query.sourceId,
      dataType: route.query.dataType,
      type: route.query.type || 1,
      shouldGetCondition: route.query.shouldGetCondition
    })
  },
  {
    path: "/report/analysisModel/customReport",
    name: "customReport",
    component: () => import(/* webpackChunkName: "analysisModel" */ "@/views/report/analysisModel/customReport"),
    meta: {
      title: "分析建模-自定义报表",
      fullPage: false,
      keepAlive: false
    },
    props: (route) => ({
      templateId: route.query.templateId,
      refConditions: route.query.refConditions,
      isNewFlag: route.query.isNewFlag,
      type: route.query.type || 1,
      shouldGetCondition: route.query.shouldGetCondition
    })
  }
];
export default route;
