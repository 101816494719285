import { cloneDeep } from "lodash";
import { enEqual } from "@/tools/compare";
import BaseService from "../BaseService";
import {
  handleFirstLevelMenu, menuDataTypeEnum, menuFixedTypeEnum, styleSize, menuClassifyTypeEnum, handleSecondLevelMenu, handleThreeLevelMenu
} from "./handleMenu";

export default class HomeMenu extends BaseService {
  /**
   * 获取菜单
   * @param params 参数
   */
  async getMenu() {
    const { http } = this;
    let menuData = await http.post("getMenuApp");
    // 过滤垃圾数据、公司圈、扫一扫等
    menuData = menuData.filter((item) => item.type !== "" && item.icon !== "2_8" && item.icon !== "2_9" && item.icon !== "2_5");
    menuData = this.formatData(menuData);
    menuData.forEach((item) => {
      handleFirstLevelMenu(item);
      if (item?.dataList) {
        item?.dataList.forEach((item) => {
          handleSecondLevelMenu(item);
          if (item?.dataList) {
            item?.dataList.forEach((item) => {
              handleThreeLevelMenu(item);
            });
          }
        });
      }
    });

    menuData = menuData.filter((item) => !(item.customType === menuClassifyTypeEnum.custom && item?.dataList?.length === 0));
    // 首页排第一个，，其它按原有顺序
    const homeData = menuData.filter((item) => item.customType === menuClassifyTypeEnum.home);
    // 我的事项排第二个
    const menu1 = menuData.filter((item) => item.customType === menuClassifyTypeEnum.myMatter);
    // 帮助中心排第三个
    const menu2 = menuData.filter((item) => item.customType === menuClassifyTypeEnum.helpCenter);
    [...menu1, ...homeData, ...menu2].forEach((item) => {
      const index = menuData.indexOf(item);
      menuData.splice(index, 1);
    });
    const menuList = [];
    if (homeData.length > 0) {
      menuList.push(homeData[0]);

      const homeMenuData = homeData[0];
      homeMenuData?.dataList?.forEach((item) => {
        if (item.styleSize?.length > 0) {
          return;
        }
        homeMenuData.fisrtInit = true;
        // 初始化没有保存过菜单的数据
        if (item.dataType === menuDataTypeEnum.sort) {
          item.styleSize = "0,4,12,3";
        } else if (item.code === menuFixedTypeEnum.myBusiness) {
          item.styleSize = "0,0,12,4";
        } else if (item.code === menuFixedTypeEnum.chat) {
          item.styleSize = "0,7,12,3";
        } else if (item.code === menuFixedTypeEnum.calendar) {
          item.styleSize = "0,10,3,4";
        } else if (item.code === menuFixedTypeEnum.attendanceSignIn) {
          item.styleSize = "3,10,3,4";
        } else if (item.code === menuFixedTypeEnum.documentManagement) {
          item.styleSize = "6,10,6,4";
        } else {
          item.styleSize = styleSize(item);
        }
      });
    }
    if (menu1.length > 0) {
      menuList.push(menu1[0]);
    }
    if (menu2.length > 0) {
      menuList.push(menu2[0]);
    }
    // menuList.forEach((item) => {
    //   item.isShow = 0;
    // });
    menuList.push(...menuData);
    return menuList;
  }

  formatData(data) {
    const arr = [];
    data.forEach((b, a) => {
        const _b = cloneDeep(b);
        arr.push(_b);
        if (enEqual(_b.type, 0)) {
            // 首页中的分类应用，需要找到对应的dataList
            _b.dataList.forEach((d, c) => {
                const app = arr[a].dataList[c];
                if (enEqual(d.dataType, 2)) { // 分类应用
                    // arr[a]首页
                    // arr[a].dataList[c] 首页对应的某一个分类应用
                    // eslint-disable-next-line consistent-return
                    data.forEach((f) => {
                        if (enEqual(f.type, 0)) {
                            return true;
                        }
                        if (enEqual(app.refId, f.id)) {
                            app.dataList = cloneDeep(f.dataList);
                            app.type = f.type;
                        }
                    });
                }
                if ([4, 5, 10].includes(d.dataType)) {
                  // eslint-disable-next-line consistent-return
                  data.forEach((f) => {
                        if (enEqual(f.type, 0)) {
                            return true;
                        }
                        f.dataList && f.dataList.forEach((n) => {
                            if (enEqual(app.refId, n.refId)) {
                                app.dataList = cloneDeep(n.dataList);
                            }
                        });
                    });
                }
            });
        }
    });
    return arr;
  }

  /**
   * 保存菜单数据
   * @param datas 参数
   */
  saveMenu(datas) {
    // console.log(datas, "zzzzzz");
    const { http } = this;
    return http.post("saveAppMenuSortData", { sortList: JSON.stringify(datas) });
  }

  /**
   * 保存首页常用配置数据
   * @param data
   * @returns {*}
   */
  saveHomeData(data) {
    const { http } = this;
    const params = {};
    params.sortId = data?.refId?.length > 0 ? data.refId : data.id;
    if (data.dataType === 10) { // 分类看板 type=4
      data.type = 4;
    } else if (data.code === "2_17") {
        // 报表
        data.dataType = 17;
    } else {
      data.dataType = data.dataType || (data.type ? 2 : data.dataType);
    }
    const dataList = [];

    const et = Number(data.type); const ec = data.code; const dt = data.dataType;
    // 1-事项申请，2-业务处理，3报表 ，5-主题
    // if (data.code === menuFixedTypeEnum.matterApply) {
    //   params.type = 1;
    // } else if (data.code === menuFixedTypeEnum.report) {
    //   params.type = 3;
    // } else {
    //   params.type = 4;
    // }
    // dataType 1-固化菜单应用，2-分类应用，3-任务类型应用，4-业务建模应用，5-业务建模基础数据应用
    // type 分类类型：0-常用，1-用户自定义分类，2-系统固化分类，3-业务建模分类，4-其他分类 只要是分类，dataType 就是分类应用
    // zjh 29260 【正式环境】自定义首页看板修改后保存不生效
    if ([menuDataTypeEnum.business, menuDataTypeEnum.basic, menuDataTypeEnum.kanBanClassify].includes(dt)) { // 其他
        params.type = 4;
    } else if (et === 5) {
        params.type = 3;
    } else if (et === 6) {
        params.type = 5;
    } else if (et === 1 || et === 2 || et === 8) { // 自定义分类
        params.type = 4;
    } else if (ec === menuFixedTypeEnum.matterApply) {
        params.type = 1;
    } else if (et === 3 || ec === menuFixedTypeEnum.businessList || dt === menuDataTypeEnum.sort) { // 第一层的业务建模
        params.type = 2;
    } else {
        params.type = et || 4;
    }
    data.dataList?.forEach((item) => {
      if (item.isCommon === "000") {
        const d = {
          dataType: 1,
          refId: item.refId
        };
        if ([menuDataTypeEnum.business, menuDataTypeEnum.basic, menuDataTypeEnum.kanBanClassify].includes(data.dataType)) {
          d.dataType = 1;
        } else {
          d.dataType = item.dataType === 2 ? 2 : 1;
        }
        dataList.length < 8 ? dataList.push(d) : "";
      }
    });
    params.dataList = JSON.stringify(dataList);
    return http.post("saveCommonDataApp", params);
  }

  async deleteCustomMenu(dataId) {
    const { http } = this;
    await http.post("deleteAppCustomMenuSort", { id: dataId });
    return this.getMenu();
  }

  async saveCustomMenu(datas) {
    const menuDatas = [];
    // customDatas.forEach((item) => {
    //   const data = {
    //     name: item.name,
    //     icon: "1_1",
    //     type: 1,
    //     sorts: 0,
    //     isShow: item.selected ? "0" : "1",
    //     dataList: item.datas
    //   };
    //   menuDatas.push(data);
    // });
    console.log("saveCustomMenu", datas);
    datas.forEach((item, ind) => {
      item.sorts += ind;
      item?.dataList.forEach((app, _ind) => {
        app.sorts = 1 + _ind;
      });
      menuDatas.push(item);
    });
    // if (datas) {
    //   menuDatas.push(...datas);
    // }
    await this.saveMenu(menuDatas);
    return this.getMenu();
  }
}
