var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-full-page-container" },
    [
      _c(
        "keep-alive",
        [
          _vm.$route.meta.keepAlive
            ? _c("router-view", {
                key: _vm.$route.fullPath,
                staticClass: "full-page",
              })
            : _vm._e(),
        ],
        1
      ),
      !_vm.$route.meta.keepAlive
        ? _c("router-view", {
            key: _vm.$route.fullPath,
            staticClass: "full-page",
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }