/*
 * @Author: zhulin
 * @Date: 2020-8-22 14:59:10
 * @LastEditors: wuqi
 * @LastEditTime: 2021-06-16 14:13:15
 * @Description: Do not edit
 * @FilePath: \user\src\router\plan\index.js
 */
const routes = [
  {
    path: "/plan/salesTrends",
    component: () => import(/* webpackChunkName: "plan" */ "@/views/plan/salesTrends"),
    meta: {
      title: "销售与趋势",
      fullPage: false,
      keepAlive: false
    }
  },
  {
    path: "/plan/myPlan",
    component: () => import(/* webpackChunkName: "plan" */ "@/views/plan/myPlan"),
    meta: {
      title: "我的方案",
      fullPage: false,
      keepAlive: false
    }
  },
  {
    path: "/plan/planApply",
    component: () => import(/* webpackChunkName: "plan" */ "@/views/plan/planApply"),
    meta: {
      title: "方案应用",
      fullPage: false,
      keepAlive: false
    }
  },
  {
    path: "/plan/detail",
    component: () => import(/* webpackChunkName: "plan" */ "@/views/plan/detail"),
    meta: {
      title: "方案详情",
      fullPage: false,
      keepAlive: false
    }
  },
  {
    path: "/plan/loginList",
    component: () => import(/* webpackChunkName: "plan" */ "@/views/plan/loginList"),
    meta: {
      title: "人员登录",
      fullPage: false,
      keepAlive: false
    }
  },
  {
    path: "/plan/installList",
    component: () => import(/* webpackChunkName: "plan" */ "@/views/plan/installList"),
    meta: {
      title: "已安装方案",
      fullPage: false,
      keepAlive: false
    }
  }
];

export default routes;
