/**
 * @Author 李煜
 * @Date 2020/6/5
 * @Description ticketWallet 路由
 */
import { replacePath, replaceRedirect } from "../util";

const routes = [
  {
    path: "/",
    redirect: "/home"
  },
  {
    path: "/home",
    name: "tickethome",
    component: () => import(/* webpackChunkName: "ticket-wallet" */ "@/views/ticketWallet/home/index"),
    meta: {
      title: "home",
      keepAlive: true,
      fullPage: false
    }
  },
  {
    path: "/addInvoice",
    name: "addInvoice",
    component: () => import(/* webpackChunkName: "ticket-wallet" */ "@/views/ticketWallet/addInvoice/index"),
    meta: {
      title: "addInvoice",
      keepAlive: true,
      fullPage: false
    }
  }
];

const PRE_URL = "/ticket-wallet";
export default routes
  .map((v) => replacePath(PRE_URL, v))
  .map((v) => replaceRedirect(PRE_URL, v));
