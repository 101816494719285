/*
 * @Author: zhangjiaheng
 * @Date: 2020-08-06 09:37:44
 * @LastEditors: zhangjiaheng
 * @LastEditTime: 2020-09-23 17:15:21
 * @Description: 资源类型业务建模 特殊页面路由
 * @FilePath: \user\src\router\businessModel\resource\index.js
 */
const routes = [
  {
    path: "/businessModel/resources",
    name: "resources",
    component: () => import(/* webpackChunkName: "user-basic-business-resource" */ "@/views/businessModel/resources/resourcesView.vue"),
    meta: {
      title: "业务建模资源视图",
      fullPage: false,
      keepAlive: false
    }
  },
  {
    path: "/businessModel/calendar",
    name: "resourcesCalendar",
    component: () => import(/* webpackChunkName: "user-basic-business-resource" */ "@/views/businessModel/resources/resourecsCalender.vue"),
    meta: {
      title: "资源日历视图",
      fullPage: false,
      keepAlive: false
    }
  },
  {
    path: "/businessModel/taskcalendar",
    name: "resourcesCalendar",
    component: () => import(/* webpackChunkName: "user-basic-business-resource" */ "@/views/businessModel/taskCalendar/index.vue"),
    meta: {
      title: "业务建模日历视图",
      fullPage: false,
      keepAlive: false
    }
  },
  {
    path: "/businessModel/gantt",
    name: "gantt",
    component: () => import(/* webpackChunkName: "user-basic-business-resource" */ "@/views/businessModel/list/index.vue"),
    meta: {
      title: "业务建模甘特图",
      fullPage: false,
      keepAlive: false
    }
  }
];

export default routes;
