/*
 * @Author: Louis
 * @Date: 2019-08-15 10:41:31
 * @LastEditors: 周晴龙
 * @LastEditTime: 2020-08-11 14:44:50
 * @Description:
 */
// 模块路由
import car from "./modules/car";
import hotel from "./modules/hotel";
import trip from "./modules/trip";
import flight from "./modules/flight";
import order from "./modules/order";

const routes = [
  {
    path: "/",
    redirect: "/trip"
    // "/hotel/entry"
    // "/testEntry"
  },
  {
    path: "/testEntry",
    name: "testEntry",
    component: () => import(/* webpackChunkName: "business-travel-trip" */ "@/views/businessTravel/testEntry"),
    meta: {
      title: "testEntry",
      keepAlive: true,
      fullPage: false
    }
  },
  ...car,
  ...hotel,
  ...trip,
  ...flight,
  ...order
];

const PRE_URL = "/business-travel";
export default routes.map((v) => {
  v.path = `${PRE_URL}${v.path}`;
  if (v.redirect) {
    v.redirect = `${PRE_URL}${v.redirect}`;
  }
  return {
    ...v
  };
});
