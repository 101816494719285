/*
 * @Author: zhouql
 * @Date: 2020-07-22 16:56:53
 * @LastEditTime: 2020-12-17 10:12:31
 * @LastEditors: pengyu
 * @Description: In User Settings Edit
 * @FilePath: \user\src\api\intelligentAccounting\commonService.js
 */
import BaseService from "../BaseService";

class CommonService extends BaseService {
  pk = ""

  constructor(http) {
    super("", http);
  }

  /**
   * @description  查询账套
   * @param params 登录参数
   * @returns {*}
   */
  queryAccList(params = {}) {
    const { http } = this;
    return http.post("bdAcQueryAccList", params);
  }

  /**
   * @description  记账主体
   * @param params 登录参数
   * @returns {*}
   */
  queryObjectTreeExt(params = {}) {
    const { http } = this;
    return http.post("queryObjectTreeExt", params);
  }

  /**
   * @description  查询会计期间
   * @param params 参数
   */
  queryAccTime(params) {
    const { http } = this;
    return http.post("bdAcQueryAccTimes", params);
  }

  /**
   * @description: 查询详情
   * @param params 参数
   */
  queryAbDetail(params) {
    const { http } = this;
    return http.post("vcQueryAbDetail", params);
  }

  /**
   * @description: 查询指定账套下的所有科目
   * @param params 参数
   */
  queryAllSubject(params) {
    const { http } = this;
    return http.post("bdAcQueryAllSubject", params);
  }

  /**
   * @description  科目
   * @param params 参数
   */
  querySubjectHasAssit(params) {
    const { http } = this;
    return http.post("bdAcQuerySubjectHasAssit", params);
  }

  /**
   * @description  科目辅助余额表tab
   * @param params 参数
   */
  querySubjectAllAssit(params) {
    const { http } = this;
    return http.post("bdAcQuerySubjectAllAssit", params);
  }

  /**
   * @description  保存账套 和 记账主体 习惯
   * @param params 参数
   */
  saveAccHabit(params) {
    const { http } = this;
    return http.post("bdAcSaveAccHabit", params);
  }

  /**
   * @description: 人员机构树
   * @param {*} params
   */
  getDeptUserTree(params) {
    const { http } = this;
    return http.post("getDeptUserTree", params);
  }

  /**
   * @description: 查询辅助核算明细 弹窗
   * @param {*} params
   */
  queryAbAssitAccDetail(params) {
    const { http } = this;
    return http.post("vcQueryAbAssitAccDetail", params);
  }

  /**
   * @description: 账簿导出
   * @param {*} params
   */
  exportExcelData(params) {
    const { http } = this;
    const { typeId } = params;
    params = { ...params, requestType: "stream" };
    let url = "";
    switch (typeId) {
      case "ye": // 余额表
        url = "vcQueryAbBalanceExp";
        break;
      case "km": // 科目辅助余额表
        url = "vcQueryAbSubjectAssExp";
        break;
      case "sl": // 数量金额余额表
        url = "vcQueryAbNumAmountExp";
        break;
      case "wb": // 外币金额余额表
        url = "vcQueryAbForAmountExp";
        break;
      case "mx": // 余额表-明细表
        url = "vcQueryAbDetailExp";
        break;
      case "zz": // 总账
        url = "vcQueryAbTotalExp";
        break;
      case "fzye": // 辅助核算余额表
        url = "vcQueryAbAssitAccExp";
        break;
      case "xj": // 现金流量明细
        url = "cashFlowDetailExport";
        break;
      case "fzmx": // 辅助核算明细表
        url = "vcQueryAbAssitAccDetailExp";
        break;
      case "dlz": // 多栏帐
        url = "vcQueryAbAccTooExp";
        break;
      default:
        break;
    }

    if (url) {
      return http.post(url, params, { responseType: "arraybuffer" });
    }
    return false;
  }
}
export default CommonService;
