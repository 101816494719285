/*
 * @Author: zoujp
 * @Date: 2020-06-29 10:00:07
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-03 15:16:16
 * @Description: 财务报表-接口
 */
import BaseService from "../BaseService";

class FinancialReportService extends BaseService {
  pk = ""

  constructor(http) {
    super("", http);
  }

  /**
 * @description: 查看财务报表入口列表
 * @param params 查询参数
 */
  queryFiTemplateClassify(params) {
    const { http } = this;
    return http.post("queryFiTemplateClassify", params);
  }

  /**
 * @description: 查看最末级科目
 * @param params 查询参数
 */
  querySubjectNoPrarent(params) {
    const { http } = this;
    return http.post("bdAcQuerySubjectNoPrarent", params);
  }

  /**
 * @description: 获取报表数据
 * @param params 查询参数
 */
  queryFinanceReportData(params) {
    const { http } = this;
    return http.post("queryFinanceReportData", params);
  }

  /**
 * @description: 查询会计期间
 * @param params 查询参数
 */
  queryAccTimes(params) {
    const { http } = this;
    return http.post("bdAcQueryAccTimes", params);
  }

  /**
 * @description: 查询所有科目
 * @param params 查询参数
 */
  querySubjectAll(params) {
    const { http } = this;
    return http.post("querySubjectAll", params);
  }

   /**
 * @description: 财务报表导出
 * @param params 查询参数
 */
    exportFinanceReportData(params) {
      const { http } = this;
      return http.post("exportFinanceReportData", params, { responseType: "arraybuffer" });
    }
}

export default FinancialReportService;
