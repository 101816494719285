/*
 * @Author: pengyu
 * @Date: 2021-02-26 09:24:27
 * @LastEditors: pengyu
 * @LastEditTime: 2021-03-04 11:01:25
 * @Description: 邮箱列表接口合集
 */
import MailBoxService from "./mailBoxService";

export const mailBoxService = new MailBoxService();
