/**
 * @Author: 周晴龙
 * @Date: 2020-07-20 17:32:58
 * @LastEditTime: 2020-12-11 18:47:01
 * @LastEditors: wuqi
 * @Description: In User Settings Edit
 * @FilePath: \user\src\api\intelligentAccounting\BalanceService.js
 */
import BaseService from "../BaseService";

class BalanceService extends BaseService {
  pk = ""

  constructor(http) {
    super("", http);
  }

  /**
   * @description  余额表
   * @param params 参数
   */
  queryAbBalance(params) {
    const { http } = this;
    return http.post("vcQueryAbBalance", params);
  }

  /**
   * @description  科目辅助余额表
   * @param params 参数
   */
  queryAbSubjectAss(params) {
    const { http } = this;
    return http.post("vcQueryAbSubjectAss", params);
  }

  /**
   * @description  数量金额余额表
   * @param params 参数
   */
  queryAbNumAmount(params) {
    const { http } = this;
    return http.post("vcQueryAbNumAmount", params);
  }

  /**
   * @description  外币金额余额表
   * @param params 参数
   */
  queryAbForAmount(params) {
    const { http } = this;
    return http.post("vcQueryAbForAmount", params);
  }

  /**
   * @description  辅助核算余额表
   * @param params 参数
   */
  queryAssitAcc(params) {
    const { http } = this;
    return http.post("vcQueryAbAssitAcc", params);
  }

  /**
   * @description  机构
   * @param params 参数
   */
  queryDeptUserTree(params) {
    const { http } = this;
    return http.post("getDeptUserTree", params);
  }

  /**
   * @description  辅助核算明细账
   * @param params 参数
   */
  queryAbAssitAccDetail(params) {
    const { http } = this;
    return http.post("vcQueryAbAssitAccDetail", params);
  }

  /**
   * @description  多栏帐
   * @param params 参数
   */
  querymultiCol(params) {
    const { http } = this;
    return http.post("vcQueryAbAccToo", params);
  }
}

export default BalanceService;
