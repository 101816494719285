/*
 * @Author: zhulin
 * @Date: 2020-08-25 17:56:22
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-11-26 17:28:23
 * @Description: 主题
 * @FilePath: \user\src\router\themeSet\index.js
 */
const routes = [
  {
    path: "/themeSet/viewDefault",
    component: () => import(/* webpackChunkName: "themeSet" */ "@/views/themeSet/viewDefault"),
    meta: {
      title: "列表视图",
      fullPage: false,
      keepAlive: false
    }
  },
  {
    path: "/themeSet/shareList",
    component: () => import(/* webpackChunkName: "themeSet" */ "@/views/themeSet/share"),
    meta: {
      title: "分享管理",
      fullPage: false,
      keepAlive: false
    }
  },
  {
    path: "/themeSet/shareForm",
    component: () => import(/* webpackChunkName: "themeSet" */ "@/views/themeSet/share/addForm"),
    meta: {
      title: "共享组表单",
      fullPage: false,
      keepAlive: false
    }
  },
  {
    path: "/themeSet/compare",
    component: () => import(/* webpackChunkName: "themeSet" */ "@/views/themeSet/compare"),
    meta: {
      title: "对比页面",
      fullPage: false,
      keepAlive: false
    }
  },
  {
    path: "/themeSet/viewGroup",
    component: () => import(/* webpackChunkName: "themeSet" */ "@/views/themeSet/viewGroup"),
    meta: {
      title: "分组视图",
      fullPage: false,
      keepAlive: false
    }
  },
  {
    path: "/themeSet/viewCalendar",
    component: () => import(/* webpackChunkName: "themeSet" */ "@/views/themeSet/viewCalendar"),
    meta: {
      title: "日历视图",
      fullPage: false,
      keepAlive: false
    }
  },
  {
    path: "/themeSet/gantt",
    component: () => import(/* webpackChunkName: "themeSet" */ "@/views/themeSet/gantt"),
    meta: {
      title: "甘特图视图",
      fullPage: false,
      keepAlive: false
    }
  }
];

export default routes;
