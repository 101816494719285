/**
 * project: user
 * fileName: init SDK连接相关
 * Created by 徐俊 on 2020/08/24
 * Copyright © 2020 Enfry Ltd. All rights reserved.
 */
/* eslint-disable */

// import NIM from "../sdk/NIM_Web_NIM_v7.8.1";
import NIM from "NIM";
// import SDK from "../sdk/NIM_Web_SDK_v7.8.1";
// import SDK from "../sdk/NIM_Web_SDK_v6.1.0";
import config from "../sdk/config";

// import { onFriends, onSyncFriendAction } from "./friends";
import { onSessions, onUpdateSession ,resetCurrSession} from "./session";
import { onRoamingMsgs, onOfflineMsgs, onMsg } from "./msg";
// import {
//   onSysMsgs, onSysMsg, onSysMsgUnread, onCustomSysMsgs
// } from "./sysMsgs";
import {
  onTeams, onSynCreateTeam, onCreateTeam, onUpdateTeam, onTeamMembers, onUpdateTeamMember, onAddTeamMembers,
  onRemoveTeamMembers, onUpdateTeamManagers, onDismissTeam, onUpdateTeamMembersMute
} from "./team";
import { reLogin } from "@/api/tools";

// 重新初始化 NIM SDK
export function initNimSDK(store, userId) {
  const { state, commit, dispatch } = store;
  // 初始化SDK
  // store.state.chat = chat;
  // store.commit('_MxChangeVuexState',{ chat });//云信内部修改值,导致浏览器各种mutable报错
  window.nim = NIM.getInstance({
    debug: false,
    appKey: config.appkey,
    account: userId,
    token: userId,
    // transports: ["websocket"],
    db: config.useDb,
    syncSessionUnread: true,
    syncRobots: false,
    syncTeams: true,
    autoMarkRead: true, // 默认为true
    quickReconnect: true,
    // logFunc: new SDK.NIM.LoggerPlugin({
    //   url: '/webdemo/h5/getlogger',
    //   level: 'info'
    // }),
    onconnect: function onConnect(info) {
      console.log("连接成功",info);
      commit("updateUserId", userId);
      resetCurrSession(store)//连接后续重置会话 否则IM未读数读取异常
    },
    onerror: function onError(error) {
      console.log("网络连接状态异常",error);
    },
    
    ondisconnect: function onDisconnect(error) {
      switch (error.code) {
        case 302: // 账号或者密码错误, 请跳转到登录页面并提示错误
          console.log("帐号或密码错误");
          break;
        case "kicked": // 被踢, 请提示错误后跳转到登录页面
          {
            const str = error.from || "其他端";// PC
            console.log(`你的帐号被${str}踢出下线，请确定帐号信息安全!`);
            reLogin(`你的帐号被${str}踢出下线,3秒后返回登录页面，请确定帐号信息安全!`)
          }
          break;
        default:
          console.log("断开成功");
          break;
      }
    },

    // onfriends: onFriends,
    // onsyncfriendaction: onSyncFriendAction,
    // 用户名片 - methods/userInfo
    // 群组
    onteams(teams) {
      onTeams(store, teams)
    },
    onsynccreateteam(teams){
      onSynCreateTeam(store,teams)
    },
    onteammembers: onTeamMembers,
    onCreateTeam(team){
      onCreateTeam(store,team)
    },
    onDismissTeam(team){
      onDismissTeam(store,team)
    },
    onUpdateTeam(team){
      onUpdateTeam(store,team)
    },
    onAddTeamMembers(obj){
      onAddTeamMembers(store,obj)
    },
    onRemoveTeamMembers(objs){
      onRemoveTeamMembers(store,objs)
    },
    onUpdateTeamManagers(objs){
      onUpdateTeamManagers(store,objs)
    },
    onupdateteammember(objs){
      onUpdateTeamMember(store,objs)
    },
    onUpdateTeamMembersMute(objs){
      onUpdateTeamMembersMute(store,objs)
    },
    // onTeamMsgReceipt,
    // 会话
    onsessions(sessions) {
      console.log('onsessions')
      store.commit('deleteAllSessions');//账号不同 清除
      onSessions(store, sessions);
    },
    onupdatesession(session) {
      console.log('onUpdateSession')
      onUpdateSession(store, session);
    },
    // // // 消息
    // onroamingmsgs: onRoamingMsgs,
    // onofflinemsgs: onOfflineMsgs,
    onmsg: function (msg) {
      console.log('msg');
      onMsg(store,msg);
    },
    // shouldIgnoreMsg:function(msg) {
    //   return false;
    // },
    // // 系统通知
    // onsysmsg: onSysMsg,
    // onofflinesysmsgs: onSysMsgs,
    // onupdatesysmsg: onSysMsg, // 通过、拒绝好友申请会收到此回调
    //
    // onsysmsgunread: onSysMsgUnread,
    // onupdatesysmsgunread: onSysMsgUnread,
    // //
    // onofflinecustomsysmsgs: onCustomSysMsgs,
    // oncustomsysmsg: onCustomSysMsgs,
    // // // 同步完成
    onsyncdone: function onSyncDone() {
      // dispatch("hideLoading");
      console.log('onsyncdone')
      // 说明在聊天列表页
      if (store.state.currSessionId) {
        dispatch("setCurrSession", store.state.currSessionId);
      }
    }
  });
}
