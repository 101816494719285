/*
 * @Author: your name
 * @Date: 2020-07-06 16:51:54
 * @LastEditTime: 2020-07-06 16:53:52
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \user\src\api\businessChart\index.js
 */
import Chart from "./chart";

export const enChart = new Chart();
