export default {
  storedBusinessData(state) {
    return state.businessData;
  },
  // 获取待提交的业务建模数据
  storedSkyEyeBusinessData(state) {
    return state.skyEyeBusinessData;
  },
  // 日期全局变量
  dateVariables(state) {
    return state.dateVariables;
  }

};
