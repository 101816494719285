<template>
  <div class="select-group" :class="hasChildren ? 'has-item-children' : ''">
    <template v-if="hasChildren">
      <div @click="openHandle" class="item selected">
        <div class="label">
          {{ data.name }}
        </div>
        <en-icon name="xiala" :class="open ? 'arrowTransformReturn' : 'arrowTransform'"></en-icon>
      </div>
      <template v-if="open">
        <selectItem v-for="(node, index) in children" :key="index" :data="node" :is-children="true" :selected="selectedDeptId === node.id" @click.native="click(node)"> </selectItem>
      </template>
    </template>
    <template v-else>
      <selectItem :data="data" :selected="selectedId === data.id" @click.native="click(data)"> </selectItem>
    </template>
  </div>
</template>

<script>
import selectItem from "./select-item";

export default {
  name: "select-goup",
  components: {
    selectItem
  },
  props: {
    data: {
      type: Object
    },
    selectedId: {
      type: String
    },
    selectedDeptId: {
      type: String
    }
  },
  data() {
    return {
      open: true
    };
  },
  computed: {
    children() {
      return this.data.nodes;
    },
    hasChildren() {
      return this.children?.length > 1;
    },
    selectedChildren() {
      if (!this.hasChildren) {
        return false;
      }
      return this.children.filter((item) => item.id === this.selectedDeptId).length > 0;
    }
  },
  methods: {
    click(data) {
      this.$emit("change", data);
    },
    openHandle() {
      this.open = !this.open;
    }
  }
};
</script>

<style lang="scss" scoped>
.select-group {
  .item {
    height: 36px;
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    .label {
      font-weight: 400;
      color: #636c78;
      font-size: 12px;
      margin-left: 19px;
    }
    .en-icon {
      color: #ffffff;
      width: 16px;
      height: 8px;
      margin-right: 10px;
    }

    .arrowTransform {
      transition: 0.2s;
      transform-origin: center;
      transform: rotateZ(180deg);
    }

    .arrowTransformReturn {
      transition: 0.2s;
      transform-origin: center;
      transform: rotateZ(0deg);
    }
  }

  margin-bottom: 12px;

  .selected {
    background: #3e90fe;
    .label {
      color: #ffffff;
    }
  }

  &.has-item-children {
    border: 1px solid #e8ecf2;
  }
}
</style>
