/*
 * @Descripttion:
 * @Author: 彭博
 * @Date: 2020-08-20 09:51:52
 * @LastEditors: wuqi
 * @LastEditTime: 2021-09-14 14:22:43
 */
import BaseService from "../BaseService";

class PrintService extends BaseService {
  constructor(http) {
    super("", http);
  }

  /**
   * 根据templateId查询所有模板
   * @param {templateId} params
   */
  queryPrintTemplate(params) {
    const { http } = this;
    return http.post("mdPrintList", params); // params => templateId
  }

  /**
   * 查询当前模板内容
   * @param {templateId} params
   * @param {id} params
   * @param {printId} params
   */
  queryPrintDetail(params) {
    const { http } = this;
    return http.post("mdPrintDetail", params);
  }

  /**
   * @description:流程数据处理
   * @param {*} params
   * @return {*}
   */
  mdQueryApproveList(params) {
    const { http } = this;
    return http.post("mdQueryApproveList", params);
  }

  queryPrintDetailBatch(params) {
    const { http } = this;
    return http.post("mdPrintDetailBatch", params);
  }

  mdQueryApproveListBatch(params) {
    const { http } = this;
    return http.post("mdQueryApproveListBatch", params);
  }
}

export default PrintService;
