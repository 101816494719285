/*
 * @Author: zhangjiaheng
 * @Date: 2020-06-22 18:31:37
 * @LastEditTime: 2020-08-06 20:14:03
 * @LastEditors: zhangjiaheng
 * @Description: In User Settings Edit
 * @FilePath: \user\src\router\maoyao\index.js
 */
const routes = [
  {
    path: "/moyaoGroup",
    component: () => import(/* webpackChunkName: "home-mayao" */ "@/views/moYao/moyaoGroup"),
    meta: {
      title: "魔钥分组视图",
      fullPage: false,
      keepAlive: false
    }
  },
  {
    path: "/moyaoResources",
    component: () => import(/* webpackChunkName: "home-mayao" */ "@/views/moYao/moyaoResources"),
    meta: {
      title: "魔钥资源视图",
      fullPage: false,
      keepAlive: false
    }
  },
  {
    path: "/moyaoCalendar",
    component: () => import(/* webpackChunkName: "home-mayao" */ "@/views/moYao/moyaoCalendar"),
    meta: {
      title: "魔钥日历视图",
      fullPage: false,
      keepAlive: false
    }
  }
];

export default routes;
