export default {
  saveFileData(state, fileData) {
    state.fileData = fileData;
  },
  saveSelect(state, select) {
    state.select = select;
  },
  saveForm(state, form) {
    state.form = form;
  },
  saveCheckData(state, checkData) {
    state.checkData = checkData;
  },
  saveRefInfo(state, refInfo) {
    state.refInfo = refInfo;
  }
};
