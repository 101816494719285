/*
 * @Descripttion:
 * @Author: 彭博
 * @Date: 2020-07-20 17:43:05
 * @LastEditors: pengyu
 * @LastEditTime: 2020-09-23 16:30:24
 */

import BaseService from "../BaseService";

/**
 * select 相关
 */
class SelectService extends BaseService {
  pk = "";

  constructor(http) {
    super("", http);
  }

  // 查询辅助内容
  getAssist(params) {
    return this.http.post("/helpBdOperatorList", params);
  }

  // 查询人员树
  getPersonDept(params) {
    return this.http.post("/bdQueryPersonDeptTree", params);
  }

  // 查询级联数据
  queryCascade(params) {
    return this.http.post("/queryRelationListByParams", params);
  }

  // 查询人员树
  queryPersonTree(params) {
    return this.http.post("/imQueryPersonTree", params);
  }

  // 查询基础数据树
  queryBaseTree(params) {
    return this.http.post("/bdQueryObjectTree", params);
  }

  // 查询基础数据所有的信息
  queryBaseInfo(params) {
    return this.http.post("/bdQueryObjectALLInfo", params);
  }

  // 查询自定义数据
  queryCustom(params) {
    return this.http.post("/mdQueryLable", params);
  }

  // 保存自定义数据
  saveCustomItem(params) {
    return this.http.post("/mdSaveLable", params);
  }

  // 加班套件
  queryRuleAttrData(params) {
    return this.http.post("/queryRuleAttrData", params);
  }

  // 请假套件
  queryVacationTypeData(params) {
    return this.http.post("/queryVacationTypeData", params);
  }

  // 数据范围
  bdQuerySingleRangeFiterList(params) {
    return this.http.post("/bdQuerySingleRangeFiterList", params);
  }

  // 魔钥物品
  bdQueryMyGoodsTree(params) {
    return this.http.post("/bdQueryMyGoodsTree", params);
  }

  // 魔钥物品
  mdQueryResourceBaseData(params) {
    return this.http.post("/mdQueryResourceBaseData", params);
  }

  // 开户支行
  queryBankInfoList(params) {
    return this.http.post("/queryBankInfoList", params);
  }

  // requestDept 申请人部门单独接口
  queryUserInfoByIds(params) {
    return this.http.post("/mdQueryUserInfoByIds", params);
  }

  // 计算考勤（请假，销假，加班）时长
  calculateTime(params) {
    return this.http.post("attCalculateTime", params);
  }

  // 创建人
  queryDeptUserTree(params) {
    return this.http.post("queryDeptUserTree", params);
  }

  // 创建人部门
  queryObjectTreeExt(params) {
    return this.http.post("queryObjectTreeExt", params);
  }
}

export default SelectService;
