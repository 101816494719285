/*
 * @Author: zhulin
 * @Date: 2020-06-16 17:37:45
 * @LastEditors: zhulin
 * @LastEditTime: 2020-09-10 17:27:37
 * @Description: file content
 */
import BaseService from "../BaseService";

/**
 * 方案销售趋势
 */
class SalesTrendsService extends BaseService {
  pk = "";

  constructor(http) {
    super("", http);
  }

  /**
   * @description  查询方案
   * @param params
   * @returns {*}
   */
  querySchemeList(params) {
    const { http } = this;
    return http.post("querySchemeList", params);
  }

  /**
   * @description  查询表格数据
   * @param params
   * @returns {*}
   */
  queryRefCorpList(params) {
    const { http } = this;
    return http.post("queryRefCorpList", params);
  }

  /**
   * @description  查询图表
   * @param params
   * @returns {*}
   */
  queryRefCorpDetail(params) {
    const { http } = this;
    return http.post("queryRefCorpDetail", params);
  }
}

export default SalesTrendsService;
