/* eslint-disable */
import Vue from "vue";
import Utils from "@/tools/chat";
import config from "./sdk/config";

export default {
  /**
   * 该方法能直接批量修改vuex中的数据
   * 例如在vue组件中使用时,通过...mapMutation(['_changeVuexState']),默认已用mixin全局引入
   * this._changeVuexState({token:'xxxxxx',data: Date.now()})能同时设置多个值
   * @param {*} state
   * @param {*} data
   */
  _MxChangeVuexState(state, data) {
    Object.keys(data).forEach((ele) => {
      state[ele] = data[ele];
    });
  },
  updateUserId(state, userId) {
    state.userId = userId;
  },
  updateMyInfo(state, myInfo) {
    state.myInfo = Utils.mergeObject(state.myInfo, myInfo);
  },
  updateUserInfo(state, users) {
    const userInfos = state.userInfos;
    users.forEach((user) => {
      const account = user.account;
      if (account) {
        userInfos[account] = Utils.mergeObject(userInfos[account], user);
      }
    });
    state.userInfos = Utils.mergeObject(state.userInfos, userInfos);
  },
  updateFriends(state, friends) { // cutFriends = []
    const nim = window.nim;
    state.friendslist = nim.mergeFriends(state.friendslist, friends);
    // state.friendslist = chat.cutFriends(state.friendslist, cutFriends)
    state.friendslist = nim.cutFriends(state.friendslist, friends.invalid);
  },
  updateSearchlist(state, obj) {
    const type = obj.type;
    switch (type) {
      case "user":
        if (obj.list.length !== 0 || state.searchedUsers.length !== 0) {
          state.searchedUsers = obj.list;
        } else {
          state.searchedUsers = [];
        }
        break;
      case "team":
        if (obj.list.length !== 0 || state.searchedTeams.length !== 0) {
          state.searchedTeams = obj.list;
        } else {
          state.searchedTeams = [];
        }
        break;
    }
  },
  updateSessions(state, sessions) {
    const nim = window.nim;
    state.sessionlist = nim.mergeSessions(state.sessionlist, sessions);
    state.sessionlist.sort((a, b) => b.updateTime - a.updateTime);
    state.sessionlist.forEach((item) => {
      // if (item.scene === 'team') {
      //
      // }
      //item.name { ...item.id.split("p2p-")[1] };
      item.id=item.id||(item.scene+'-'+item.to)
      Utils.formatSession(state, item);
      state.sessionMap[item.id] = item;
    });
  },
  // updateFilterSessions(state,sessions){
  //   state.filterSessions=sessions
  //   const nim = window.nim;
  //   state.filterSessions = nim.mergeSessions(state.filterSessions, sessions);
  //   state.filterSessions.sort((a, b) => b.updateTime - a.updateTime);
  //   state.filterSessions.forEach((item) => {
  //     // if (item.scene === 'team') {
  //     //
  //     // }
  //     //item.name { ...item.id.split("p2p-")[1] };
  //     item.id=item.id||(item.scene+'-'+item.to)
  //     Utils.formatSession(state, item);
  //     state.filterSessionMap[item.id] = item;
  //   });
  // },
  deleteSessions(state, sessionIds) {
    const nim = window.nim;
    state.sessionlist = nim.cutSessionsByIds(state.sessionlist, sessionIds);
    console.log('state.sessionlist',state.sessionlist)
    this.dispatch("chat/setCurrSession", state.sessionlist[0].id);
  },
  deleteAllSessions(state) {
    state.sessionlist = [];
    state.sessionMap = {};
    state.msgs=[]
    state.msgsMap={}
  },
  // 初始化，收到离线漫游消息时调用
  updateMsgs(state, msgs) {
    const nim = window.nim;
    const tempSessionMap = {};
    msgs.forEach((msg) => {
      const sessionId = msg.sessionId;
      tempSessionMap[sessionId] = true;
      if (!state.msgs[sessionId]) {
        state.msgs[sessionId] = [];
      }
      // sdk会做消息去重
      state.msgs[sessionId] = nim.mergeMsgs(state.msgs[sessionId], [msg]);
      // state.msgs[sessionId].push(msg)
    });
    this.commit("chat/updateMsgByIdClient", msgs);
    for (const sessionId in tempSessionMap) {
      state.msgs[sessionId].sort((a, b) => {
        if (a.time === b.time) {
          // 机器人消息，回复消息时间和提问消息时间相同，提问在前，回复在后
          if (a.type === "robot" && b.type === "robot") {
            if (a.content && a.content.msgOut) {
              return 1;
            }
            if (b.content && b.content.msgOut) {
              return -1;
            }
          }
        }
        return a.time - b.time;
      });
      if (sessionId === state.currSessionId) {
        this.commit("chat/updateCurrSessionMsgs", {
          type: "init"
        });
      }
    }
  },
  // 更新追加消息，追加一条消息
  putMsg(state, msg) {
    const sessionId = msg.sessionId;
    if (!state.msgs[sessionId]) {
      state.msgs[sessionId] = [];
    }
    this.commit("chat/updateMsgByIdClient", msg);
    const tempMsgs = state.msgs[sessionId];
    const lastMsgIndex = tempMsgs.length - 1;
    if (tempMsgs.length === 0 || msg.time >= tempMsgs[lastMsgIndex].time) {
      tempMsgs.push(msg);
    } else {
      for (let i = lastMsgIndex; i >= 0; i--) {
        const currMsg = tempMsgs[i];
        if (msg.time >= currMsg.time) {
          state.msgs[sessionId].splice(i, 0, msg);
          break;
        }
      }
    }
  },
  // 删除消息列表消息
  deleteMsg(state, msg) {
    const sessionId = msg.sessionId;
    const tempMsgs = state.msgs[sessionId];
    if (!tempMsgs || tempMsgs.length === 0) {
      return;
    }
    const lastMsgIndex = tempMsgs.length - 1;
    for (let i = lastMsgIndex; i >= 0; i--) {
      const currMsg = tempMsgs[i];
      if (msg.idClient === currMsg.idClient) {
        state.msgs[sessionId].splice(i, 1);
        break;
      }
    }
  },
  // 替换消息列表消息，如消息撤回
  replaceMsg(state, obj) {
    const { sessionId, idClient, msg } = obj;
    const tempMsgs = state.msgs[sessionId];
    if (!tempMsgs || tempMsgs.length === 0) {
      return;
    }
    const lastMsgIndex = tempMsgs.length - 1;
    for (let i = lastMsgIndex; i >= 0; i--) {
      const currMsg = tempMsgs[i];
      console.log(idClient, currMsg.idClient, currMsg.text);
      if (idClient === currMsg.idClient) {
        state.msgs[sessionId].splice(i, 1, msg);
        break;
      }
    }
  },
  // 用idClient 更新消息，目前用于消息撤回
  updateMsgByIdClient(state, msgs) {
    if (!Array.isArray(msgs)) {
      msgs = [msgs];
    }
    const tempTime = (new Date()).getTime();
    msgs.forEach((msg) => {
      // 有idClient 且 5分钟以内的消息
      if (msg.idClient && (tempTime - msg.time < 1000 * 300)) {
        state.msgsMap[msg.idClient] = msg;
      }
    });
  },
  // 更新当前会话id，用于唯一判定是否在current session状态
  updateCurrSessionId(state, obj) {
    const type = obj.type || "";
    if (type === "destroy") {
      state.currSessionId = null;
    } else if (type === "init") {
      if (obj.sessionId && (obj.sessionId !== state.currSessionId)) {
        console.log('currSessionId')
        state.currSessionId = obj.sessionId;
      }
    }
  },
  // 更新当前会话列表的聊天记录，包括历史消息、单聊消息等，不包括聊天室消息
  // replace: 替换idClient的消息
  updateCurrSessionMsgs(state, obj) {
    console.log('updateCurrSessionMsgs',obj)
    const type = obj.type || "";
    if (type === "destroy") { // 清空会话消息
      state.currSessionMsgs = [];
      state.currSessionLastMsg = null;
      this.commit("chat/updateCurrSessionId", {
        type: "destroy"
      });
    } else if (type === "init") { // 初始化会话消息列表
      if (state.currSessionId) {
        const sessionId = state.currSessionId;
        let currSessionMsgs = [].concat(state.msgs[sessionId] || []);
        // 做消息截断
        const limit = config.localMsglimit;
        const msgLen = currSessionMsgs.length;
        if (msgLen - limit > 0) {
          state.currSessionLastMsg = currSessionMsgs[msgLen - limit];
          state.currSessionLastMsg.imUserId=state.userId
          currSessionMsgs = currSessionMsgs.slice(msgLen - limit, msgLen);
        } else if (msgLen > 0) {
          state.currSessionLastMsg = currSessionMsgs[0];
          state.currSessionLastMsg.imUserId=state.userId
        } else {
          state.currSessionLastMsg = null;
        }
        state.currSessionMsgs = [];
        let lastMsgTime = 0;
        currSessionMsgs.forEach((msg) => {
          if ((msg.time - lastMsgTime) > 1000 * 60 * 5) {
            lastMsgTime = msg.time;
            state.currSessionMsgs.push({
              type: "timeTag",
              text: Utils.formatDate(msg.time, false)
            });
          }
          state.currSessionMsgs.push(msg);
        });
      }
    } else if (type === "put") { // 追加一条消息
      const newMsg = obj.msg;
      let lastMsgTime = 0;
      const lenCurrMsgs = state.currSessionMsgs.length;
      if (lenCurrMsgs > 0) {
        lastMsgTime = state.currSessionMsgs[lenCurrMsgs - 1].time;
      }
      if (newMsg) {
        if ((newMsg.time - lastMsgTime) > 1000 * 60 * 5) {
          state.currSessionMsgs.push({
            type: "timeTag",
            text: Utils.formatDate(newMsg.time, false)
          });
        }
        state.currSessionMsgs.push(newMsg);
      }
    } else if (type === "concat") {
      // 一般用于历史消息拼接
      const currSessionMsgs = [];
      let lastMsgTime = 0;
      obj.msgs.forEach((msg) => {
        if ((msg.time - lastMsgTime) > 1000 * 60 * 5) {
          lastMsgTime = msg.time;
          currSessionMsgs.push({
            type: "timeTag",
            text: Utils.formatDate(msg.time, false)
          });
        }
        currSessionMsgs.push(msg);
      });
      currSessionMsgs.reverse();
      currSessionMsgs.forEach((msg) => {
        state.currSessionMsgs.unshift(msg);
      });
      if (obj.msgs[0]) {
        state.currSessionLastMsg = obj.msgs[0];
        state.currSessionLastMsg.imUserId=state.userId
      }
    } else if (type === "replace") {
      const msgLen = state.currSessionMsgs.length;
      const lastMsgIndex = msgLen - 1;
      if (msgLen > 0) {
        for (let i = lastMsgIndex; i >= 0; i--) {
          if (state.currSessionMsgs[i].idClient === obj.idClient) {
            state.currSessionMsgs.splice(i, 1, obj.msg);
            break;
          }
        }
      }
    }
  },
  updateSysMsgs(state, sysMsgs) {
    const nim = window.nim;
    if (!Array.isArray(sysMsgs)) {
      sysMsgs = [sysMsgs];
    }
    sysMsgs = sysMsgs.map((msg) => {
      msg.showTime = Utils.formatDate(msg.time, false);
      return msg;
    });
    // state.sysMsgs = chat.mergeSysMsgs(state.sysMsgs, sysMsgs)
    state.sysMsgs = [].concat(nim.mergeSysMsgs(state.sysMsgs, sysMsgs));
    Vue.set(state, sysMsgs, state.sysMsgs);
  },
  // 更新消息的状态，如管理员批准或拒绝入群后，会收到新消息，更新入群申请的状态
  updateSysMsgState(state, sysMsg) {
    const exitMsg = state.sysMsgs.find((msg) => msg.idServer === sysMsg.idServer);
    if (exitMsg) {
      exitMsg.state = sysMsg.state;
    }
  },
  updateSysMsgUnread(state, obj) {
    console.log('更新未读信息数')
    state.sysMsgUnread = { ...obj };
  },
  updateCustomSysMsgs(state, sysMsgs) {
    const nim = window.nim;
    if (!Array.isArray(sysMsgs)) {
      sysMsgs = [sysMsgs];
    }
    sysMsgs = sysMsgs.map((msg) => {
      msg.showTime = Utils.formatDate(msg.time, false);
      return msg;
    });
    // state.customSysMsgs = chat.mergeSysMsgs(state.customSysMsgs, sysMsgs)
    state.customSysMsgs = state.customSysMsgs.concat(sysMsgs);
    Vue.set(state, sysMsgs, state.customSysMsgs);
    this.commit("chat/updateCustomSysMsgUnread", {
      type: "add",
      unread: sysMsgs.length
    });
  },
  updateCustomSysMsgUnread(state, obj) {
    const { type, unread } = obj;
    switch (type) {
      case "reset":
        state.customSysMsgUnread = unread || 0;
        break;
      case "add":
        state.customSysMsgUnread += unread;
        break;
    }
  },
  resetSysMsgs(state, obj) {
    const type = obj.type;
    switch (type) {
      case 0:
        state.sysMsgs = [];
        break;
      case 1:
        state.customSysMsgs = [];
        this.commit("chat/updateCustomSysMsgUnread", {
          type: "reset"
        });
        break;
    }
  },
  deleteSysMsgs(state, obj) {
    const type = obj.type;
    const idServer = obj.idServer;
    let arr = type === 0 ? state.sysMsgs : state.customSysMsgs;
    arr = arr.filter((msg) => msg.idServer !== idServer);
    Vue.set(state, "sysMsgs", arr);
  },
  setNoMoreHistoryMsgs(state) {
    state.noMoreHistoryMsgs = true;
  },
  resetNoMoreHistoryMsgs(state) {
    state.noMoreHistoryMsgs = false;
  },
  updateTeamList(state, teams) {
    const nim = window.nim;
    state.teamlist = nim.mergeTeams(state.teamlist, teams);
    state.teamlist = nim.cutTeams(state.teamlist, teams.invalid);
    // console.log(state.muteTeamIds)
    state.muteTeamIds=state.teamlist.filter(item=>item.muteTeam).map(el=>el.teamId)
  },
  updateTeamMembers(state, obj) {
    const nim = window.nim;
    const teamId = obj.teamId;
    const members = obj.members;
    state.teamMembers = state.teamMembers || {};
    state.teamMembers[teamId] = nim.mergeTeamMembers(state.teamMembers[teamId], members);
    state.teamMembers[teamId] = nim.cutTeamMembers(state.teamMembers[teamId], members.invalid);
    state.teamMembers[teamId].sort((a, b) => {
      // 将群主和管理员排在队列前方
      if (a.type === "owner" || b.type === "owner") {
        return a.type === "owner" ? -1 : 1;
      }
      if (a.type === "manager" || b.type === "manager") {
        return a.type === "manager" ? -1 : b.type === "manager" ? 1 : 0;
      }
      return -1;
    });
    // state.teamMembers = { ...state.teamMembers };
    state.teamMembers = Object.assign({}, state.teamMembers)
  },
  removeTeamMembersByAccounts(state, obj) {
    const teamId = obj.teamId;
    const invalidAccounts = obj.accounts;
    if (state.teamMembers[teamId] === undefined) return;
    state.teamMembers[teamId] = state.teamMembers[teamId].filter((member) => invalidAccounts.indexOf(member.account) === -1);
    state.teamMembers = { ...state.teamMembers };
  },
  updateTeamInfo(state, team) {
    const index = state.teamlist.findIndex((item) => item.teamId === team.teamId);
    if (index === -1) return;
    for (const key in team) {
      // eslint-disable-next-line no-prototype-builtins
      if (key !== "teamId" && team.hasOwnProperty(key) && team[key]) {
        state.teamlist[index][key] = team[key];
      }
    }
  },
  updateTeamSettingConfig(state, obj) {
    state.teamSettingConfig = obj;
  },
  updateSentReceipedMap(state, obj) {
    if (!obj || obj.length < 1) {
      return;
    }
    const teamId = obj[0].teamId;
    if (!state.sentReceipedMap[teamId]) {
      state.sentReceipedMap[teamId] = [];
    }
    state.sentReceipedMap[teamId].push(...obj.map((msg) => msg.idServer));
  },
  updateSingleTeamMsgReads(state, obj) {
    state.teamMsgReads.forEach((item) => {
      if (item.idServer === obj.idServer) {
        item.unread = obj.unread;
        item.read = obj.read;
      }
    });
    // 更新已读未读账号列表
    const unreadAccounts = state.teamMsgReadsDetail.unreadAccounts;
    const findIndex = unreadAccounts.findIndex((account) => account === obj.account);
    if (findIndex >= 0) {
      unreadAccounts.splice(findIndex, 1);
      state.teamMsgReadsDetail.readAccounts.push(obj.account);
    }
  },
  initMsgReceiptDetail(state, obj) {
    state.teamMsgReadsDetail.readAccounts = obj.readAccounts;
    state.teamMsgReadsDetail.unreadAccounts = obj.unreadAccounts;
  },
  updateGsqMsgInfo(state,msgInfo){
    state.gsqMsgNum=msgInfo
  },
  updateGsqMsgNum(state,num){
    state.gsqMsgNum.msgCount=num
  },
  updateGsqCountNum(state,count){
    state.gsqMsgNum.count=count
  }
};
