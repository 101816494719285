/*
 * @Description: 对比
 * @Author: 梁平贤
 * @Date: 2020/6/30 14:09
 */

import {
  isBoolean, isString, isNumber, isNaN, isNil, toString
} from "lodash";

// 数值,字符串等转换bool值
export function enBoolean(val) {
  if (isBoolean(val)) {
    return val;
  }
  if (isNaN(val) || isNil(val)) {
    return false;
  }
  if (isString(val)) {
    return val !== "0" && val !== "000" && val !== "";
  }
  if (isNumber(val)) {
    return val !== 0;
  }
  return val;
}
// 反转,现有的业务逻辑很多定义都是反的
export function enBooleanReverse(val) {
  const value = enBoolean(val);
  return !value;
}

// 对比字符串和数字等,不会考虑类型
export function enEqual(val, aVal) {
  return toString(val) === toString(aVal);
}
