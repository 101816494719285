/*
 * @Descripttion: 明细表相关
 * @Author: 彭博
 * @Date: 2020-07-30 16:11:24
 * @LastEditors: wuqi
 * @LastEditTime: 2021-08-04 09:37:56
 */
import BaseService from "../BaseService";

export default class BusinessDetailListService extends BaseService {
  constructor(http) {
    super("business-template", http);
  }

  /**
   * @description 关联添加 查询数据
   * @param params
   */
  mdQuerySubDataColList(params) {
    const { http } = this;
    return http.post("mdQuerySubDataColList", params);
  }

  /**
   * @description 关联添加 查询业务建模数据
   * @param params
   */
  mdQuerySubDataList(params) {
    const { http } = this;
    return http.post("mdQuerySubDataList", params);
  }

  /**
   * @description 关联数据控件 关联添加 查询基础数据
   * @param params
   */
  mdQueryNewRelationDataList(params) {
    const { http } = this;
    return http.post("mdQueryNewRelationDataList", params);
  }

  /**
   * @description 关联数据控件 关联显示 查询数据
   * @param params
   */
  mdQueryRelationData(params) {
    const { http } = this;
    return http.post("mdQueryRelationData", params);
  }

  /**
   *  业务建模关联数据导出数据
   * @param params 设置参数
   * @returns {*}
   */
   mdRelationDataExport(params) {
    return this.http.post("/mdRelationDataExport", params, { responseType: "arraybuffer" });
  }

  /**
   *  基础数据 考勤 管理模型关联数据导出数据
   * @param params 设置参数
   * @returns {*}
   */
   mdRelationBaseDataExport(params) {
    return this.http.post("/mdRelationBaseDataExport", params, { responseType: "arraybuffer" });
  }

  /**
   *  明细导出数据
   * @param params 设置参数
   * @returns {*}
   */
   mdDataDetailExport(params) {
    return this.http.post("/mdDataDetailExport", params, { responseType: "arraybuffer" });
  }
}
