/*
 * @Author: Fenngding
 * @Date: 2019-09-04 09:13:28
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-11-20 11:23:30
 * @Email: fenngding@live.com
 * @Description:
 */
const routes = [
  {
    path: "/document-management",
    redirect: "/document-management/home"
  },
  {
    path: "/document-management/home",
    name: "dochome",
    component: () => import(/* webpackChunkName: "document-management" */ "@/views/documentManagement/home/index"),
    meta: {
      title: "home",
      keepAlive: true,
      fullPage: false
    }
  },
  {
    path: "/document-management/addDoc/:type",
    name: "addDoc",
    component: () => import(/* webpackChunkName: "document-management" */ "@/views/documentManagement/addDoc/index"),
    meta: {
      title: "addDoc",
      keepAlive: false,
      fullPage: false
    }
  }
];
export default routes;
