const routes = [
  {
    path: "/car",
    name: "carTravel",
    component: () => import(/* webpackChunkName: "business-travel-car" */"@/views/businessTravel/car"),
    meta: {
      title: "用车预订",
      fullPage: false,
      keepAlive: false
    }
  }
];
export default routes;
