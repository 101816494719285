/**
 * project: user
 * fileName: private
 * Created by 徐俊 on 2020/08/25
 * Copyright © 2020 Enfry Ltd. All rights reserved.
 */
import config from "@/store/modules/chat/sdk/config";

const Private = {

  parseNameIcon(state, session) {
    session.name = "";
    session.avatar = "";
    if (session.scene === "p2p") {
      let userInfo = null;
      if (session.to !== state.userId) {
        userInfo = state.userInfos[session.to];
      } else {
        return false;
      }
      if (userInfo) {
        session.name = this.getFriendAlias(userInfo);
        session.avatar = userInfo.avatar;
      }
    } else if (session.scene === "team") {
      let teamInfo = null;
      teamInfo = state.teamlist.find((team) => team.teamId === session.to);
      if (teamInfo) {
        session.name = teamInfo.name;
        session.avatar = teamInfo.avatar || (teamInfo.type === "normal" ? config.defaultGroupIcon : config.defaultAdvancedIcon);
      } else {
        session.name = `\u7FA4${session.to}`;
        session.avatar = session.avatar || config.defaultGroupIcon;
      }
    }

    if (!session.name) {
      session.name = session.to;
    }

    return session;
  },

  parseMsgText(state, msg) {
    if (!msg) {
      return "";
    }
    switch (msg.type) {
      case "audio":
        msg.text = `[语音]${parseInt((msg.file.dur + 500) / 1000, 10)}`;
        break;
      case "video":
        msg.text = `[视频]${parseInt((msg.file.dur + 500) / 1000, 10)}`;
        break;
      case "file":
        msg.text = `[文件]${msg.file.name}`;
        break;
      case "image":
        msg.text = "[图片]";
        break;
      case "geo":
        msg.text = `[地图]${msg.geo.title}`;
        break;
      case "tip":
        msg.text = `[提醒]${msg?.tip || ""}`;
        break;
      case "notification":
        msg.text = `${Private.getTeamSysmMsg(state, msg)}`;
        break;
      case "custom":
        msg.text = this.parseCustomMsg(msg);
        break;
    }

    return msg.text;
  },

  parseCustomMsg(msg) {
    if (msg.type === "custom") {
      try {
        const cnt = JSON.parse(msg.content);
        switch (cnt.type) {
          case 1:
            return "[猜拳消息]";
          case 2:
            return "[阅后即焚]";
          case 3:
            return "[贴图表情]";
          case 4:
            return "[白板消息]";
          case 1000:
            return "[共享]";
        }
      } catch (e) {
        console.log(e);
      }
      return "[自定义消息]";
    }
    return "";
  },

  getTeamSysmMsg(state, msg) {
    let text; const
    nicks = this.getNickNames(state, msg.attach.users);

    switch (msg.attach.type) {
      case "updateTeam":
        text = this.getTeamUpdateInfo(state, msg);
        break;
      case "addTeamMembers": {
        const op = nicks.pop();

        text = `${op}邀请${nicks.join()}加入群聊`;
        break;
      }
      case "removeTeamMembers": {
        const op = nicks.pop();
        text = `${nicks.join()}被${op}移出群聊`;
        break;
      }
      case "acceptTeamInvite": {
        const op = nicks.pop();
        text = `${nicks.join()}接受了${op}入群邀请`;
        break;
      }
      case "passTeamApply": {
        const op = nicks.shift();
        if (nicks.length === 1 && op === nicks[0]) {
          // 此情况为高级群设置不需要验证，用户申请入群后，收到的群消息提示
          text = `${op}加入群`;
        } else {
          text = `${op}通过了${nicks}入群邀请`;
        }
        break;
      }
      case "addTeamManagers": {
        // todo test
        const op = nicks.pop();
        text = `${op}新增了${nicks}为管理员`;
        break;
      }
      case "removeTeamManagers": {
        // todo test
        const op = nicks.pop();
        text = `${op}移除了${nicks}的管理员权限`;
        break;
      }
      case "leaveTeam": {
        text = `${nicks.join()}退出了群`;
        break;
      }
      case "dismissTeam": {
        text = `${nicks.join()}解散了群`;
        break;
      }
      case "transferTeam": {
        // todo test
        const nicks = this.getNickNames(state, msg.attach.users);
        const op = nicks.shift();
        text = `${op}转让群主给${nicks}`;
        break;
      }
      case "updateTeamMute": {
        const nicks = this.getNickNames(state, msg.attach.users);
        // const op = nicks.shift();
        text = `${nicks}被管理员${msg.attach.mute ? "禁言" : "解除禁言"}`;
        break;
      }
      default:
        break;
    }
    return text;
  },

  // todo 写成私有成员方法
  getNickNames(state, users) {
    return users ? users.map((user) => (user.account === state.userId ? "你" : user.nick)) : [];
  },

  // todo 写成私有成员方法
  getTeamUpdateInfo(state, msg) {
    let text;
    const team = msg.attach.team;
    const op = this.getNickNames(state, msg.attach.users).pop();
    if (team.name) {
      text = `${op}修改群名为${team.name}`;
    } else if (team.intro) {
      text = `${op}修改群介绍为${team.intro}`;
      // eslint-disable-next-line brace-style
    }
    // 由于群公告的交互与 Android iOS 不一致，现版本不适配群公告
    // else if (team['announcement']) {
    //   text = `${op}修改群公告为${team['announcement']}`
    // }
    else if (team.joinMode) {
      text = `群身份验证模式更新为${team.joinMode === "noVerify" ? "不需要验证" : team.joinMode === "needVerify" ? "需要验证" : "禁止任何人加入"}`;
    } else if (team.inviteMode) {
      text = `邀请他人权限为${team.inviteMode === "all" ? "所有人" : "管理员"}`;
    } else if (team.updateTeamMode) {
      text = `群资料修改权限为${team.updateTeamMode === "all" ? "所有人" : "管理员"}`;
    } else if (team.beInviteMode) {
      text = `被邀请人身份${team.beInviteMode === "noVerify" ? "不需要验证" : "需要验证"}`;
    } else {
      text = "更新群信息";
    }
    return text;
  },

  /* 获得有效的备注名 */
  getFriendAlias(userInfo) {
    userInfo.alias = userInfo.alias ? userInfo.alias.trim() : "";
    return userInfo.alias || userInfo.nick || userInfo.account;
  }
};

export default Private;
