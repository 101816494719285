/*
 * @Author: pengyu
 * @Date: 2020-06-16 17:12:51
 * @LastEditors: pengyu
 * @LastEditTime: 2020-11-19 11:23:21
 * @Description: 智能核算-接口汇总
 */
import IntelligentService from "./IntelligentService"; // 凭证处理接口
import CredentialService from "./CredentialService"; // 凭证生成接口
import SettleAccounts from "./SettleAccounts"; // 结账接口
import FinancialReport from "./FinancialReport"; // 财务报表
import VoucherDetail from "./VoucherDetail";
import CommonService from "./CommonService";// 公用接口
import BalanceService from "./BalanceService";// 余额表接口
import GeneralLedgerService from "./GeneralLedgerService";// 总账
import CashFlowService from "./CashFlowService"; // 现金流量明细
import CarryOverService from "./CarryOverService"; // 结转

export const intelligentService = new IntelligentService();
export const credentialService = new CredentialService();
export const settleAccounts = new SettleAccounts();
export const financialReport = new FinancialReport();
export const voucherDetail = new VoucherDetail();
export const commonService = new CommonService();
export const balanceService = new BalanceService();
export const generalLedgerService = new GeneralLedgerService();
export const cashFlowService = new CashFlowService();
export const carryOverService = new CarryOverService();
