import {
    zh
} from "./zh/index";
import {
    en
} from "./en/index";
// console.log(zh, 'zzzzzzzz')
export default {
    zh,
    en
};
