<script>
import ElCollapseTransition from "element-ui/src/transitions/collapse-transition";
import Emitter from "element-ui/src/mixins/emitter";
import Popper from "element-ui/src/utils/vue-popper";
import { cloneDeep } from "lodash";
import {
  createNamespacedHelpers
} from "vuex";
import menuMixin from "./menu-mixin";

const { mapState: mapStateTheme } = createNamespacedHelpers("main");

const poperMixins = {
  props: {
    transformOrigin: {
      type: [Boolean, String],
      default: false
    },
    offset: Popper.props.offset,
    boundariesPadding: Popper.props.boundariesPadding,
    popperOptions: Popper.props.popperOptions
  },
  data: Popper.data,
  methods: Popper.methods,
  beforeDestroy: Popper.beforeDestroy,
  deactivated: Popper.deactivated
};
export default {
  name: "ElSubmenu",

  componentName: "ElSubmenu",

  components: { ElCollapseTransition },

  mixins: [
    menuMixin,
    Emitter,
    poperMixins
  ],

  props: {
    index: {
      type: String,
      required: true
    },
    showTimeout: {
      type: Number,
      default: 300
    },
    hideTimeout: {
      type: Number,
      default: 300
    },
    classList: {
      type: Array,
      default() {
        return [];
      }
    },
    menus: {
      type: Array
    },
    popperClass: String,
    disabled: Boolean,
    popperAppendToBody: {
      type: Boolean,
      default: undefined
    }
  },

  data() {
    return {
      hoverTimer: null,
      popperJS: null,
      timeout: null,
      items: {},
      submenus: {},
      mouseInChild: false,
      showPopMenu: false

    };
  },
  computed: {
    ...mapStateTheme(["themeInfo"]),
    navStyle() {
      const {
 openCustomColor, HaverColor, NoHaverColor, SelectLabelColor, themeStyle, navColor
} = this.themeInfo;
        if (this.themeInfo.RecommendedStyles === 5) {
          if (this.themeInfo.themeColor === "dark") {
            return {
            background: navColor
          };
          }
          return {
            background: "#FFFFFF !important"
          };
        }
        return {
          backgroundColor: this.active ? themeStyle === 1 ? "#FFFFFF" : `${SelectLabelColor } !important` : `${navColor}!important`,
          color: this.active ? `${HaverColor} !important` : `${NoHaverColor }!important`,
          borderLeftColor: this.active ? `${SelectLabelColor } !important` : "#FFFFFF"
        };
    },
    SubNavStyle() {
      const {
 openCustomColor, HaverColor, NoHaverColor, SelectLabelColor, themeColor, themeStyle, navColor
} = this.themeInfo;
        return {
          backgroundColor: themeColor === "white" ? "#FFFFFF !important" : ""
        };
    },
    // popper option
    appendToBody() {
      return this.popperAppendToBody === undefined
        ? this.isFirstLevel
        : this.popperAppendToBody;
    },
    // 处理菜单数据
    subsetList() {
      if (this.classList.length === 0) {
        return [];
      }
        // 根据 存储的 二级分类
        const classifyList = cloneDeep(this.classList || []);
        // {name:"",id:"def",color:"",showIcon:"",refDataIds,refDataList:[]}
        const clonedataList = cloneDeep(this.menus);
        // 循环 二级分类 处理数据
        classifyList.forEach((v) => {
            v.dataList = [];
            // 字符串变 数字
            v.refDataList = v.refDataIds.split(",");
            v.refDataList.forEach((vId) => {
             const classItem = clonedataList.find((item) => item.data.refId === vId);
             if (classItem && classItem.data && !classItem.data.referji) {
               v.dataList.push(classItem.data);
               classItem.data.referji = true;
             }
            });
        });

        const def = { name: "默认分组", id: "def", dataList: [] };
        def.dataList = clonedataList.filter((v) => !v.data?.referji).map((item) => item.data);

        classifyList.unshift(def);
        return classifyList;
    },
    menuTransitionName() {
      return this.rootMenu.collapse ? "el-zoom-in-left" : "el-zoom-in-top";
    },
    opened() {
      return this.rootMenu.openedMenus.indexOf(this.index) > -1;
    },
    poped() {
      return this.rootMenu.popedMenus.indexOf(this.index) > -1;
    },
    active() {
      let isActive = false;
      const { submenus } = this;
      const { items } = this;
      Object.keys(items).forEach((index) => {
        if (items[index].active) {
          isActive = true;
        }
      });
      Object.keys(submenus).forEach((index) => {
        if (submenus[index].active) {
          isActive = true;
        }
      });

      return isActive;
    },
    hasThreeLevel() {
     return this.classList?.length > 0;
    },
    hoverBackground() {
      return this.rootMenu.hoverBackground;
    },
    backgroundColor() {
      return this.rootMenu.backgroundColor || "";
      // return "#2A3345";
    },
    activeTextColor() {
      return this.rootMenu.activeTextColor || "";
    },
    textColor() {
      return this.rootMenu.textColor || "";
    },
    mode() {
      return this.rootMenu.mode;
    },
    isMenuPopup() {
      console.log(this.rootMenu.isMenuPopup);
      return this.rootMenu.isMenuPopup;
    },
    HeightsStyle() {
      const { themeColor, RecommendedStyles } = this.themeInfo;
      if (RecommendedStyles === 5) {
        return { height: "34px !important", lineHeight: "34px !important" };
      }
        return {};
    },
    titleStyle() {
      if (this.mode !== "horizontal") {
        return { color: this.textColor };
      }
      return {
        borderBottomColor: this.active
          ? (this.rootMenu.activeTextColor ? this.activeTextColor : "")
          : "transparent",
        color: this.active
          ? this.activeTextColor
          : this.textColor
      };
    },
    isFirstLevel() {
      let isFirstLevel = true;
      let parent = this.$parent;
      while (parent && parent !== this.rootMenu) {
        if (["ElSubmenu", "ElMenuItemGroup"].indexOf(parent.$options.componentName) > -1) {
          isFirstLevel = false;
          break;
        } else {
          parent = parent.$parent;
        }
      }
      return isFirstLevel;
    }
  },
  watch: {

    poped() {
      if (!this.rootMenu.collapse) {
        this.$nextTick(() => {
          this.updatePopper();
        });
      } else {
        this.$nextTick(() => {
          this.updatePopper();
        });
      }
    }
  },
  created() {
    this.$on("toggle-collapse", this.handleCollapseToggle);
    this.$on("mouse-enter-child", () => {
      this.mouseInChild = true;
      clearTimeout(this.timeout);
    });
    this.$on("mouse-leave-child", () => {
      this.mouseInChild = false;
      clearTimeout(this.timeout);
    });
  },
  mounted() {
    this.parentMenu.addSubmenu(this);
    this.rootMenu.addSubmenu(this);
    this.initPopper();
  },
  beforeDestroy() {
    this.parentMenu.removeSubmenu(this);
    this.rootMenu.removeSubmenu(this);
  },
  methods: {
    handleCollapseToggle(value) {
      if (value) {
        this.initPopper();
      } else {
        this.doDestroy();
      }
    },
    addItem(item) {
      this.$set(this.items, item.index, item);
    },
    removeItem(item) {
      delete this.items[item.index];
    },
    // 设置图标
    setChildrenIcon(icon) {
      if (icon.indexOf("bus-board-icon") > -1 || icon.indexOf("bus-ywmb-icon") > -1) {
        return `${icon}_1`;
      }
      return icon;
    },
    setClassIcon(icon) {
      if (icon) {
        return `erjifenlei${icon}_1`;
      }
        return "bus-ywmb-icon_1";
    },
    addSubmenu(item) {
      this.$set(this.submenus, item.index, item);
    },
    removeSubmenu(item) {
      delete this.submenus[item.index];
    },
    handleClick() {
      const { rootMenu, disabled } = this;
      if (
        (rootMenu.menuTrigger === "hover" && rootMenu.mode === "horizontal")
          || (rootMenu.collapse && rootMenu.mode === "vertical")
          || disabled
      ) {
        return;
      }
      this.dispatch("ElMenu", "submenu-click", this);
    },
    handleMouseenter(event, showTimeout = this.showTimeout) {
      if (!("ActiveXObject" in window) && event.type === "focus" && !event.relatedTarget) {
        return;
      }
      const { rootMenu, disabled } = this;
      const that = this;
      if (
        (rootMenu.menuTrigger === "click" && rootMenu.mode === "horizontal")
          || (!rootMenu.collapse && rootMenu.mode === "vertical")
          || disabled
      ) {
        if ((!rootMenu.collapse && rootMenu.mode === "vertical")) {
          this.dispatch("ElSubmenu", "mouse-enter-child");
          clearTimeout(that.hoverTimer);
          clearTimeout(that.timeout);
          that.hoverTimer = setTimeout(() => {
            if (that.hoverTimer) {
              that.handleMouseenterCallBack(showTimeout);
            }
          }, 500);
        }
        return;
      }
      this.dispatch("ElSubmenu", "mouse-enter-child");
      this.handleMouseenterCallBack(showTimeout);
    },
    handleMouseenterCallBack(showTimeout) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.rootMenu.popMenu(this.index, this.indexPath);
      }, showTimeout);

      if (this.appendToBody) {
        this.$parent.$el.dispatchEvent(new MouseEvent("mouseenter"));
      }
    },
    handleMouseleave(deepDispatch = false) {
      const { rootMenu } = this;
      if (
        (rootMenu.menuTrigger === "click" && rootMenu.mode === "horizontal")
          // || (!rootMenu.collapse && rootMenu.mode === "vertical")
      ) {
        return;
      }
      this.dispatch("ElSubmenu", "mouse-leave-child");
      clearTimeout(this.hoverTimer);
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        !this.mouseInChild && this.rootMenu.closePopMenu(this.index);
      }, this.hideTimeout);

      if (this.appendToBody && deepDispatch) {
        if (this.$parent.$options.name === "ElSubmenu") {
          this.$parent.handleMouseleave(true);
        }
      }
    },

    exterHandleScroll() {
      console.log(this.$refs.menuList);
      this.$refs.menuHeader.scrollLeft = this.$refs.menuListWrap.scrollLeft;
      this.$refs.menuList.scrollTop = this.$refs.menuListWrap.scrollTop;
    },
    clickMenuItem(item) {
      this.rootMenu.$parent.clickMenuItem(item);
    },
    handleTitleMouseenter() {
      if (this.mode === "horizontal" && !this.rootMenu.backgroundColor) {
        return;
      }
      const title = this.$refs["submenu-title"];
      title && (title.style.backgroundColor = this.rootMenu.hoverBackground);
    },
    handleTitleMouseleave() {
      if (this.mode === "horizontal" && !this.rootMenu.backgroundColor) {
        return;
      }
      const title = this.$refs["submenu-title"];
      title && (title.style.backgroundColor = this.rootMenu.backgroundColor || "");
    },
    updatePlacement() {
      this.currentPlacement = this.mode === "horizontal" && this.isFirstLevel
        ? "bottom-start"
        : "right-start";
    },
    initPopper() {
      this.referenceElm = this.$el;
      this.popperElm = this.$refs.menu;
      this.updatePlacement();
    }
  },
  render() {
    const {
      active,
      opened,
      activeId,
      poped,
      paddingStyle,
      titleStyle,
      HeightsStyle,
      navStyle,
      SubNavStyle,
      themeInfo,
      // backgroundColor,
      rootMenu,
      currentPlacement,
      menuTransitionName,
      mode,
      disabled,
      popperClass,
      $slots,
      menus,
      subsetList,
      setChildrenIcon,
      setClassIcon,
      isFirstLevel,
      hasThreeLevel
    } = this;
    const MenuHeaders = (<div class='header' ref='menuHeader' on-scroll={() => { this.sysHandleScroll(); }} >
            {subsetList.map((item) => <div class='header-item' on-click={() => { this.clickMenuItem(item); }}>
             <en-icon class="icon" name={setClassIcon(item.showIcon)} size="16" style={{ color: item.color }} /><span class='lable'>{item.name}</span>
            </div>)}
          </div>);
    const MenuList = (<div class='sub-menus' ref='menuListWrap' on-scroll={() => { this.exterHandleScroll(); }}>{
                subsetList.map((item) => <ul class='list' ref='menuList'>
                  {
                    item?.dataList?.length
                    ? item?.dataList.map((sub) => <li class='item' on-click={() => { this.clickMenuItem(sub); }}>
                                                        <en-icon class="icon" name={setChildrenIcon(sub.customIcon)} size="16" color={sub.customColor} /><span class='lable'>{sub.name}</span>
                                                       </li>)
                    : ""
                  }
                </ul>)
                }</div>);
    const MenuSecList = (<div class='sub-sec-menus' style={{ ...SubNavStyle }} ref='menuListWrap' on-scroll={() => { this.exterHandleScroll(); }}>{
            menus.map((item) => <div class='item' ref='menuList' on-click={() => { this.clickMenuItem(item); }}>
              {
                <span ><en-icon class="icon" name={setChildrenIcon(item.icon)} size="16" style={{ color: item.iconColor }} /><span class='lable'>{item.name}</span></span>
              }
            </div>)
            }</div>);
    const popupMenu = (
      <transition name={menuTransitionName}>
        <div
          ref='menu'
          v-show={poped}
          class={[`el-menu--${mode}`, popperClass]}
          on-mouseenter={($event) => this.handleMouseenter($event, 100)}
          on-mouseleave={() => this.handleMouseleave(true)}
          on-focus={($event) => this.handleMouseenter($event, 100)}>
          <div
            role='menu'
            class={["el-menu el-menu--popup", `el-menu--popup-${currentPlacement}`, "popup-menus", `${themeInfo.themeColor}-popup-menus`]}
            style={{ backgroundColor: rootMenu.backgroundColor || "", ...SubNavStyle }}>
            {
              !hasThreeLevel
              ? (<div>
                  {MenuSecList}
                </div>)
              : ([MenuHeaders, MenuList])
            }
          </div>
        </div>
      </transition>
    );
    // const inlineMenu = (
    //   <el-collapse-transition>
    //     <ul
    //       role='menu'
    //       class='el-menu el-menu--inline'
    //       v-show={ opened }
    //       style={{ backgroundColor: rootMenu.backgroundColor || "" }}>
    //       {$slots.default}
    //     </ul>
    //   </el-collapse-transition>
    // );

    let submenuTitleIcon = (
      (rootMenu.mode === "horizontal" && isFirstLevel)
        || (rootMenu.mode === "vertical" && !rootMenu.collapse)
    ) ? "el-icon-arrow-down" : "el-icon-arrow-right";
    if (rootMenu.mode === "vertical") {
      submenuTitleIcon = opened ? "el-icon-arrow-down" : "el-icon-arrow-right";
      if (rootMenu.collapse) {
        submenuTitleIcon = opened ? "el-icon-arrow-right" : "el-icon-arrow-right";
      }
    }
    return (
      <li
        class={{
          "el-submenu": true,
          "is-active": active,
          "is-opened": opened,
          "is-disabled": disabled,
          "el-submenu-hor": rootMenu.mode === "horizontal"
        }}
        role='menuitem'
        aria-haspopup='true'
        aria-expanded={opened}
        on-mouseenter={this.handleMouseenter}
        on-mouseleave={() => this.handleMouseleave(false)}
        on-focus={this.handleMouseenter}
      >
        <div
        class={{
          "el-submenu__title": true,
          "is-active-1": themeInfo.themeStyle === 1,
          "is-active-2": themeInfo.themeStyle === 2,
          "is-active-3": themeInfo.themeStyle === 3,
          "is-active-4": themeInfo.themeStyle === 4,
          "is-active-5": themeInfo.themeStyle === 5,
          "is-active-6": themeInfo.themeStyle === 6,
          "is-active-custom": active
        }}
          ref='submenu-title'
          on-click={this.handleClick}
          on-mouseenter={this.handleTitleMouseenter}
          on-mouseleave={this.handleTitleMouseleave}
          style={[paddingStyle, titleStyle, HeightsStyle]}
        >
          {$slots.title}
          <i class={["el-submenu__icon-arrow", submenuTitleIcon]}></i>
        </div>
        { popupMenu }

      </li>
    );
  }
};
</script>
<style lang="scss" scoped>
.el-menu-item,
.el-submenu__title {
  height: 50px!important;
  line-height: 50px!important;
  position: relative;
  white-space: nowrap;
  list-style: none;
  float: none;
  /deep/ .el-submenu__icon-arrow {
    transform: none !important;
  }
}
.el-submenu-hor{
  float: none;
  display: inline-block;
  margin-left: -1px!important;
.is-active-1 {
  background: #fff !important;
  // background: #394356;
  border-left: 5px solid #3e90fe;
}
.is-active-2 {
  background: #fff !important;
}
.is-active-3 {
  margin:0 1px;
  height: 34px;
  line-height: 34px;
  // background: #fff !important;
  border-radius: 20px;
}
.is-active-4 {
  height: 50px;
  line-height: 50px;
  border-radius: 4px;
}
.is-active-5 {
  background: #fff !important;
  height: 34px;
  line-height: 34px;
  border-radius: 20px 0 0 20px;
}
.is-active-6 {
  background: #fff !important;
  height: 34px;
  line-height: 34px;
  border-radius: 20px 20px 0 20px;
}
}
.arrow-left{
  background: #232c3d !important;
  width: 10px;
  height: 10px;
  display: inline-block;
  position: absolute;
  top: 18px;
  transform:rotate(45deg)
}
.popup-menus {
  min-width: 190px;
  max-width: 70vw;
  overflow: hidden;
  white-space: nowrap;
  padding: 0 0 2px 0;
  color: #1B1C1E;
  font-size: 14px;
  background: #FFFFFF;
  border-radius: 4px;
  .header{
    height: 40px;
    line-height: 40px;
    overflow-x: auto;
    background: #FFFFFF;
    writing-mode: horizontal-tb;
    border-bottom: 1px solid #E6EAF0;
    &::-webkit-scrollbar{
      width: 0px;
      height: 0px;
    }
    .header-item{
      cursor: pointer;
      writing-mode:horizontal-tb;
      display: inline-block;
      align-items: center;
      align-content: center;
      padding-left: 20px;
      width:190px;
      color: #1B1C1E;
      background: #FFFFFF;
    }
  }
  .sub-menus{
    display: inline-block;
    color: #1B1C1E;
    padding: 0;
    margin: 0;
    vertical-align: top;
    flex: 0 0 190px;
    display: flex;
    align-items: stretch;
    max-height: 70vh;
    overflow-y:hidden;
    .list{
      padding: 0;
      margin: 0;
      border-right: 1px solid rgba(117, 131, 154, 0.5);
      flex:0 0 190px;
      overflow-y: auto;
      .item{
        padding: 0 15px ;
        height: 56px;
        line-height: 56px;
        cursor: pointer;
        &:hover{
          border-radius: 4px;
          color: #1B1C1E !important;
          background: #F5F8FC !important;
        }
      }
      &::-webkit-scrollbar{
        width: 0;
        height: 0;
      }
    }

  }
  .sub-sec-menus{
    max-height: 504px;
    max-width: 70vw;
    writing-mode: vertical-lr;
    white-space: initial;
    padding-bottom: 2px;
    color: #1B1C1E;
    background: #FFFFFF;
    .item{
      writing-mode:horizontal-tb;
      display: inline-block;
      height: 56px;
      line-height: 56px;
      width: 198px;
      cursor: pointer;
      padding-left:20px;
      &:hover{
          border-radius: 4px;
          color: #1B1C1E !important;
          background: #F5F8FC !important;
      }
    }
  }
  .lable,.icon {
      display: inline-block;
      vertical-align: middle !important;
    }
    .lable{
      margin-left: 10px;
      text-overflow: ellipsis;
      overflow: hidden;
      display: inline-block;
      max-width: 160px;
    }
  &::-webkit-scrollbar{
    background: #FFFFFF;
  }
}
.dark-popup-menus{
  min-width: 190px;
  max-width: 70vw;
  overflow: hidden;
  white-space: nowrap;
  padding: 0 0 2px 0;
  color: #a9b5c6;
  font-size: 14px;
  background: rgba(23, 34 ,55 , 0.92)!important;
  border-radius: 4px;
  .header{
    background: rgba(23, 34 ,55 , 0.92)!important;
    height: 40px;
    line-height: 40px;
    overflow-x: auto;
    border-bottom: 1px solid rgba(117 ,131, 154 , 0.5);
    writing-mode: horizontal-tb;
    &::-webkit-scrollbar{
      width: 0px;
      height: 0px;
    }
    .header-item{
      cursor: pointer;
      writing-mode:horizontal-tb;
      display: inline-block;
      align-items: center;
      align-content: center;
      padding-left: 20px;
      width:190px;
      color: #a9b5c6;
      background: rgba(23, 34 ,55 , 0.92)!important;
    }
  }
  .sub-menus{
    display: inline-block;
    color: #a9b5c6;
    padding: 0;
    margin: 0;
    vertical-align: top;
    flex: 0 0 190px;
    display: flex;
    align-items: stretch;
    max-height: 70vh;
    overflow-y:hidden;
    .list{
      padding: 0;
      margin: 0;
      border-right: 1px solid rgba(117, 131, 154, 0.5);
      flex:0 0 190px;
      overflow-y: auto;
      .item{
        padding: 0 15px ;
        height: 56px;
        line-height: 56px;
        cursor: pointer;
        &:hover{
          border-radius: 4px;
          color: #333 !important;
          background: #F5F8FC !important;
        }
      }
      &::-webkit-scrollbar{
        width: 0;
        height: 0;
      }
    }

  }
  .sub-sec-menus{
    max-height: 504px;
    max-width: 70vw;
    writing-mode: vertical-lr;
    white-space: initial;
    padding-bottom: 2px;
    color: #a9b5c6;
    background: #232c3d;
    .item{
      writing-mode:horizontal-tb;
      display: inline-block;
      height: 56px;
      line-height: 56px;
      width: 198px;
      cursor: pointer;
      padding-left:20px;
      &:hover{
          border-radius: 4px;
          color: #333 !important;
          background: #F5F8FC !important;
      }
    }
  }
  .lable,.icon {
      display: inline-block;
      vertical-align: middle !important;
    }
    .lable{
      margin-left: 10px;
      text-overflow: ellipsis;
      overflow: hidden;
      display: inline-block;
      max-width: 160px;
    }
  &::-webkit-scrollbar{
    background: #172237;
  }
}
</style>
