<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <div class="theme-drawer" :style="styleObj" v-click-outside="close">
    <div class="header">
      <en-button style="margin-left:20px;" class="theme" icon="iconzhuti-shixin" icon-color="#9367EB" type="text"
        icon-size="22"></en-button>
      <span class="setting">主题设置</span>
    </div>
    <div class="tab">
      <div :class="{ 'active': current === '1' }" @click="tabCurrent('1')">布局</div>
      <div :class="{ 'active': current === '2' }" @click="tabCurrent('2')">风格</div>
      <div :class="{ 'active': current === '3' }" @click="tabCurrent('3')">子菜单</div>
    </div>
    <div class="container">
      <div class="layout" v-show="current === '1'">
        <div class="styles">
          <span class="label">导航布局</span>
          <div class="style-list">
            <div v-for="item in navStyles" :key="item.style">
              <div class="style-item"
                :style="{ backgroundPosition: item.style === themeSetting.navStyle ? item.activeIconPosition : item.iconPosition }"
                @click="changeNavStyle(item)">
              </div>
              <div class="item-text">{{ item.text }}</div>
            </div>
          </div>
        </div>
        <div class="styles">
          <span class="label">首页页签</span>
          <div class="style-list">
            <el-switch v-model="themeSetting.openIndexTab">
            </el-switch>
          </div>
        </div>
      </div>
      <div class="layout" v-show="current === '2'">
        <div class="styles">
          <span class="label">推荐样式</span>
          <div class="style-list">
            <!-- v-if比v-for提前  -->
            <div v-for="item in RecommendedStyles" :key="item.style" v-if="themeSetting.navStyle === 1 && item.style !== 3 && item.style !== 4 && item.style !== 5 && item.style !== 6
              && item.style !== 7 && item.style !== 8 || themeSetting.navStyle === 2 && item.style !== 1 && item.style !== 2 && item.style !== 5 && item.style !== 6
              && item.style !== 7 && item.style !== 8 || themeSetting.navStyle === 3 && item.style !== 1 && item.style !== 2
              && item.style !== 3 && item.style !== 4">
              <div class="style-item3" style="margin-bottom: 20px;"
                :style="{ backgroundPosition: item.style === themeSetting.RecommendedStyles ? item.activeIconPosition : item.iconPosition }"
                @click="changeRecommendedStyles(item)">
              </div>
              <div class="item-text">{{ item.text }}</div>
            </div>
          </div>
        </div>
        <div class="setting-item">
          <span class="label">主题风格</span>
          <div class="color-list">
            <span class="color-item" @click="changeThemeColor(item)" v-for="item in colorList"
              :style="{ background: item.bgColor }" :key="item.type">
              <en-icon name="iconyixuan" size="small" v-show="item.type === themeSetting.themeColor"
                :color="item.type === 'white' ? '#000' : '#fff'"></en-icon>
            </span>
          </div>
        </div>
        <!-- <div class="setting-item">
          <span class="label" style="margin-right: 12px;">自定义</span>
          <el-switch style="margin-left:20px" v-model="themeSetting.openCustomColor" @change="openCustomColorChange">
          </el-switch>
        </div> -->
        <!-- 自定义功能延后 -->
        <div class="setting-items" v-show="false">
        <el-collapse class="collapse" style="margin:0 20px;" accordion >
          <el-collapse-item class="collapse-item" title="顶部设置">
              <div class="setting-item-unFlex" v-show="themeSetting.openCustomColor">
                <span class="label">顶部图标</span>
                <el-radio style="color:#FFFFFF" v-model="themeSetting.TopIcon" label="1">默认彩色</el-radio>
                <el-radio style="color:#FFFFFF" v-model="themeSetting.TopIcon" label="2">纯色</el-radio>
                <div class="setting-components">
                  <my-color-picker v-if="themeSetting.TopIcon === '2'" v-model="themeSetting.TopIconColor"
                    :typeSelections="1" />
                  <!-- <el-color-picker  v-if="themeSetting.TopIcon === '2'" v-model="themeSetting.TopIconColor" size="small"></el-color-picker> -->
                </div>
              </div>
              <div class="setting-item-unFlex" v-show="themeSetting.openCustomColor">
                <span class="label">顶部底色</span>
                <el-radio style="color:#FFFFFF" v-model="themeSetting.topSettingRadio" label="1">设置颜色</el-radio>
                <!-- <el-radio style="color:#FFFFFF" v-model="themeSetting.topSettingRadio" label="2">上传图片</el-radio> -->
                <div class="setting-components">
                  <my-color-picker v-if="themeSetting.topSettingRadio == '1'" v-model="themeSetting.topColor"
                    :typeSelections="2" />
                  <!-- <el-color-picker  v-if="themeSetting.topSettingRadio == '1'" v-model="themeSetting.topColor" size="small"></el-color-picker> -->
                  <!-- <colorPicker v-model="themeSetting.topColor" @change="headleChangeColor" /> -->
                  <div v-else class="Upload-components">
                    <en-upload class="foot-upload" :on-change="topChangeFile" limitNum="9" limitType="3">
                      <!-- <en-button icon="iconfujianhetupian-kongjian" type="text" > 图片  </en-button> -->
                      <div class="icon">+</div>
                    </en-upload>
                  </div>
                </div>
              </div>
          </el-collapse-item>
        </el-collapse>
        <div class="divider" style="margin-top:10px"></div>
        <el-collapse class="collapse" style="margin:0 20px;" accordion >
          <el-collapse-item class="collapse-item" title="导航设置">
            <div class="setting-item">
              <span class="label">标签样式</span>
              <el-select v-model="themeSetting.themeStyle" size="small" style="color:#FFFFFF" class="selectAll"
                placeholder="请选择样式">
                <template slot="prefix">
                  <div class="iconTargetOne" v-if="themeSetting.themeStyle === 1">
                    <div class="iconLeftOne"></div>
                    <div class="iconCenterOne">
                      <svg class="en-icon" aria-hidden="true"
                        style="color:#3e90fe;width:12px;height:12px;vertical-align: middle;">
                        <use xlink:href="#iconshouye"></use>
                      </svg>
                    </div>
                    <div class="iconTextOne">首页</div>
                  </div>
                  <div class="iconTargetTwo" v-if="themeSetting.themeStyle === 2">
                    <div class="iconLeftTwo"></div>
                    <div class="iconCenterTwo">
                      <svg class="en-icon" aria-hidden="true"
                        style="color:#FFFFFF;width:12px;height:12px;vertical-align: middle;">
                        <use xlink:href="#iconshouye"></use>
                      </svg>
                    </div>
                    <div class="iconTextTwo">首页</div>
                  </div>
                  <div class="iconTargetThere" v-if="themeSetting.themeStyle === 3">
                    <div class="iconCenterThere">
                      <svg class="en-icon" aria-hidden="true"
                        style="color:#FFFFFF;width:12px;height:12px;vertical-align: middle;">
                        <use xlink:href="#iconshouye"></use>
                      </svg>
                    </div>
                    <div class="iconTextThere">首页</div>
                  </div>
                  <div class="iconTargetFour" v-if="themeSetting.themeStyle === 4">
                    <div class="iconCenterFour">
                      <svg class="en-icon" aria-hidden="true"
                        style="color:#FFFFFF;width:12px;height:12px;vertical-align: middle;">
                        <use xlink:href="#iconshouye"></use>
                      </svg>
                    </div>
                    <div class="iconTextFour">首页</div>
                  </div>
                  <div class="iconTargetFive" v-if="themeSetting.themeStyle === 5">
                    <div class="iconCenterFive">
                      <svg class="en-icon" aria-hidden="true"
                        style="color:#FFFFFF;width:12px;height:12px;vertical-align: middle;">
                        <use xlink:href="#iconshouye"></use>
                      </svg>
                    </div>
                    <div class="iconTextFive">首页</div>
                  </div>
                  <div class="iconTargetSix" v-if="themeSetting.themeStyle === 6">
                    <div class="iconCenterSix">
                      <svg class="en-icon" aria-hidden="true"
                        style="color:#FFFFFF;width:12px;height:12px;vertical-align: middle;">
                        <use xlink:href="#iconshouye"></use>
                      </svg>
                    </div>
                    <div class="iconTextSix">首页</div>
                  </div>
                  <!-- <div class="iconTargetSeven" v-if="themeSetting.themeStyle === 7">
                    <div class="iconCenterSeven">
                      <svg class="en-icon" aria-hidden="true"
                        style="color:#FFFFFF;width:12px;height:12px;vertical-align: middle;">
                        <use xlink:href="#iconshouye"></use>
                      </svg>
                    </div>
                    <div class="iconTextSeven">首页</div>
                  </div> -->
                </template>
                <el-option label="样式一" :value="1">
                  <span style="float: left" class="el-input__prefix">
                    <div class="iconTargetOne">
                      <div class="iconLeftOne"></div>
                      <div class="iconCenterOne">
                        <svg class="en-icon" aria-hidden="true"
                          style="color:#3e90fe;width:12px;height:12px;vertical-align: middle;">
                          <use xlink:href="#iconshouye"></use>
                        </svg>
                      </div>
                      <div class="iconTextOne">首页</div>
                    </div>
                  </span>
                  <span style="font-size: 13px;margin-left:75px">样式一</span>
                </el-option>
                <el-option label="样式二" :value="2">
                  <span style="float: left" class="el-input__prefix">
                    <div class="iconTargetTwo">
                      <div class="iconLeftTwo"></div>
                      <div class="iconCenterTwo">
                        <svg class="en-icon" aria-hidden="true"
                          style="color:#FFFFFF;width:12px;height:12px;vertical-align: middle;">
                          <use xlink:href="#iconshouye"></use>
                        </svg>
                      </div>
                      <div class="iconTextTwo">首页</div>
                    </div>
                  </span>
                  <span style="font-size: 13px;margin-left:75px">样式二</span>
                </el-option>
                <el-option label="样式三" :value="3">
                  <span style="float: left" class="el-input__prefix">
                    <div class="iconTargetThere">
                      <div class="iconCenterThere">
                        <svg class="en-icon" aria-hidden="true"
                          style="color:#FFFFFF;width:12px;height:12px;vertical-align: middle;">
                          <use xlink:href="#iconshouye"></use>
                        </svg>
                      </div>
                      <div class="iconTextThere">首页</div>
                    </div>
                  </span>
                  <span style="font-size: 13px;margin-left:75px">样式三</span>
                </el-option>
                <el-option label="样式四" :value="4">
                  <span style="float: left" class="el-input__prefix">
                    <div class="iconTargetFour">
                      <div class="iconCenterFour">
                        <svg class="en-icon" aria-hidden="true"
                          style="color:#FFFFFF;width:12px;height:12px;vertical-align: middle;">
                          <use xlink:href="#iconshouye"></use>
                        </svg>
                      </div>
                      <div class="iconTextFour">首页</div>
                    </div>
                  </span>
                  <span style="font-size: 13px;margin-left:75px">样式四</span>
                </el-option>
                <el-option label="样式五" :value="5">
                  <span style="float: left" class="el-input__prefix">
                    <div class="iconTargetFive">
                      <div class="iconCenterFive">
                        <svg class="en-icon" aria-hidden="true"
                          style="color:#FFFFFF;width:12px;height:12px;vertical-align: middle;">
                          <use xlink:href="#iconshouye"></use>
                        </svg>
                      </div>
                      <div class="iconTextFive">首页</div>
                    </div>
                  </span>
                  <span style="font-size: 13px;margin-left:75px">样式五</span>
                </el-option>
                <el-option label="样式六" :value="6">
                  <span style="float: left" class="el-input__prefix">
                    <div class="iconTargetSix">
                      <div class="iconCenterSix">
                        <svg class="en-icon" aria-hidden="true"
                          style="color:#FFFFFF;width:12px;height:12px;vertical-align: middle;">
                          <use xlink:href="#iconshouye"></use>
                        </svg>
                      </div>
                      <div class="iconTextSix">首页</div>
                    </div>
                  </span>
                  <span style="font-size: 13px;margin-left:75px">样式六</span>
                </el-option>
                <!-- 样式七不太好做 先去掉 -->
                <!-- <el-option label="样式七" :value="7" v-if="themeSetting.navStyle === 1">
                  <span style="float: left" class="el-input__prefix">
                    <div class="iconTargetSeven">
                      <div class="iconCenterSeven">
                        <svg class="en-icon" aria-hidden="true"
                          style="color:#FFFFFF;width:12px;height:12px;vertical-align: middle;">
                          <use xlink:href="#iconshouye"></use>
                        </svg>
                      </div>
                      <div class="iconTextSeven">首页</div>
                    </div>
                  </span>
                  <span style="font-size: 13px;margin-left:75px">样式七</span>
                </el-option> -->
              </el-select>
              <my-color-picker v-model="themeSetting.SelectLabelColor" :typeSelections="1" />
            </div>
            <div class="setting-item">
              <span class="label">选中文字</span>
              <my-color-picker v-model="themeSetting.HaverColor" :typeSelections="1" />
              <!-- <el-color-picker v-model="themeSetting.HaverColor" size="small"></el-color-picker> -->
            </div>
            <div class="setting-item">
              <span class="label" style="margin-right:7px">未选中文字</span>
              <my-color-picker v-model="themeSetting.NoHaverColor" :typeSelections="1" />
              <!-- <el-color-picker v-model="themeSetting.NoHaverColor" size="small"></el-color-picker> -->
            </div>
            <div class="setting-item-unFlex" v-show="themeSetting.openCustomColor">
              <span class="label">导航底色</span>
              <el-radio style="color:#FFFFFF" v-model="themeSetting.navColorRadio" label="1">设置颜色</el-radio>
              <!-- <el-radio style="color:#FFFFFF;margin-right:0px" v-model="themeSetting.navColorRadio" label="2">上传图片</el-radio> -->
              <el-tooltip content="最佳尺寸：190*840px" placement="top">
                <i class="el-icon-info" ></i>
              </el-tooltip>
              <div class="setting-components">
                <my-color-picker v-if="themeSetting.navColorRadio == '1'" v-model="themeSetting.navColor"
                  :typeSelections="2" />
                <!-- <el-color-picker :predefine="['#d24d57', '#fc9d99', '#aedd81', '#00ccff', '#26a65b', '#eb7347']"
                  v-if="themeSetting.navColorRadio == '1'" v-model="themeSetting.navColor" size="small"></el-color-picker> -->
                <div v-else class="Upload-components">
                  <en-upload class="foot-upload" :on-change="navChangeFile" limitNum="9" limitType="3">
                    <!-- <en-button icon="iconfujianhetupian-kongjian" type="text" > 图片  </en-button> -->
                    <div class="icon">+</div>
                  </en-upload>

                </div>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
        <div class="divider" style="margin-top:10px" ></div>
        <div class="setting-item-unFlex" style="margin:15px 20px 0 20px;padding-bottom:20px;">
          <span class="label">背景颜色</span>
          <el-radio style="color:#FFFFFF" v-model="themeSetting.bgColorRadio" label="1">设置颜色</el-radio>
          <!-- <el-radio style="color:#FFFFFF" v-model="themeSetting.bgColorRadio" label="2">上传图片</el-radio> -->
          <div class="setting-components">
            <my-color-picker v-if="themeSetting.bgColorRadio == '1'" v-model="themeSetting.bgColor" :typeSelections="2" />
            <!-- <el-color-picker :predefine="['#d24d57', '#fc9d99', '#aedd81', '#00ccff', '#26a65b', '#eb7347']"
              v-if="themeSetting.bgColorRadio == '1'" v-model="themeSetting.bgColor" size="small"></el-color-picker> -->
            <div v-else class="Upload-components">
              <en-upload class="foot-upload" :on-change="bgChangeFile" limitNum="9" limitType="3">
                <!-- <en-button icon="iconfujianhetupian-kongjian" type="text" > 图片  </en-button> -->
                <div class="icon">+</div>
              </en-upload>

            </div>
          </div>
        </div>
        <!-- <div class="setting-item-unFlex" style="margin:15px 20px 0 20px;padding-bottom:20px;">
          <span class="label">主要颜色</span> 暂时不开放
          <span class="text">例按钮底色、选中文字颜色都为主要颜色</span>
          <div class="setting-components">
            <my-color-picker v-model="themeSetting.mainColor" :typeSelections="1" />
            <el-color-picker :predefine="['#d24d57', '#fc9d99', '#aedd81', '#00ccff', '#26a65b', '#eb7347']"
              v-model="themeSetting.mainColor" size="small"></el-color-picker>
          </div>
        </div> -->
        </div>
      </div>
      <div class="layout" v-show="current === '3'">
        <div class="styles">
          <span class="label">推荐样式</span>
          <div class="style-list">
            <div v-for="item in SubmenuStyles" :key="item.style">
              <div v-if="themeSetting.navStyle === 1 && item.style !== 4 || themeSetting.navStyle === 3 && item.style !== 3
                || themeSetting.navStyle === 2 && item.style !== 3 && item.style !== 4">
                <div class="style-item3"
                  :style="{ backgroundPosition: item.style === themeSetting.SubmenuStyles ? item.activeIconPosition : item.iconPosition }"
                  @click="themeSetting.SubmenuStyles = item.style">
                </div>
                <div class="item-text">{{ item.text }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapActions, mapGetters, mapState, mapMutations
} from "vuex";
import { Message } from "element-ui";
import { enService } from "@/api/en/index";
import { fileService } from "@/api/businessModel";
import MyColorPicker from "../my-color-picker";

import Index from "./data/utils";
// import { mapGetters, mapState, mapMutations } from "vuex";
export default {
  name: "ThemeSetting",
  components: {
    MyColorPicker
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      current: "1",
      SubmenuStyles: [
        {
          style: 1,
          text: "移入悬浮",
          iconPosition: "-210px -755px",
          activeIconPosition: "-60px -755px"
        }
        // {
        //   style: 2,
        //   text: "移入固定",
        //   iconPosition: "-530px -755px",
        //   activeIconPosition: "-380px -755px"
        // },
        // {
        //   style: 3,
        //   text: "点击展开",
        //   iconPosition: "-850px  -755px",
        //   activeIconPosition: "-700px  -755px"
        // }
      ],
      navStyles: [
        {
          style: 1,
          text: "常规导航：适用文字长",
          iconPosition: "-210px  -60px",
          activeIconPosition: "-60px -60px"
        },
        {
          style: 2,
          text: "精简导航：适用文字短",
          iconPosition: "-530px -60px",
          activeIconPosition: "-380px -60px"
        },
        {
          style: 3,
          text: "顶部导航：适用内容多",
          iconPosition: "-850px -60px",
          activeIconPosition: "-700px -60px"
        }
      ],
      RecommendedStyles: [
        {
          style: 1,
          text: "顶部栏通屏",
          iconPosition: "-211px -212px",
          activeIconPosition: "-61px -212px"
        },
        {
          style: 2,
          text: "导航栏通屏",
          iconPosition: "-211px -392px",
          activeIconPosition: "-61px -392px"
        },
        {
          style: 3,
          text: "顶部栏通屏",
          iconPosition: "-530px -212px",
          activeIconPosition: "-380px -212px"
        },
        {
          style: 4,
          text: "导航栏通屏",
          iconPosition: "-530px -392px",
          activeIconPosition: "-380px -392px"
        },
        {
          style: 5,
          text: "样式一",
          iconPosition: "-210px -572px",
          activeIconPosition: "-60px -572px"
        },
        {
          style: 6,
          text: "样式二",
          iconPosition: "-530px -572px",
          activeIconPosition: "-380px -572px"
        },
        {
          style: 7,
          text: "样式三",
          iconPosition: "-850px -572px",
          activeIconPosition: "-700px -572px"
        }
        // {
        //   style: 8,
        //   text: "样式四",
        //   iconPosition: "-1172px -572px",
        //   activeIconPosition: "-1022px -572px"
        // }
      ],
      themeStyles: [
        {
          style: 1,
          text: "顶部栏通屏",
          iconPosition: "-188px -165px",
          activeIconPosition: "-41px -165px"
        },
        {
          style: 2,
          text: "导航栏通屏",
          iconPosition: "-482px -165px",
          activeIconPosition: "-335px -165px"
        },
        {
          style: 3,
          text: "导航栏通屏",
          iconPosition: "-188px -289px",
          activeIconPosition: "-41px -289px"
        },
        {
          style: 4,
          text: "导航栏通屏",
          iconPosition: "-482px -289px",
          activeIconPosition: "-335px -289px"
        }
      ],
      colorList: [
        {
          type: "dark",
          bgColor: "#242D3C"
        },
        {
          type: "white",
          bgColor: "#FFFFFF"
        },
        {
          type: "blue",
          bgColor: "#3e90fe"
        },
        {
          type: "green",
          bgColor: "#26C393"
        },
        {
          type: "yellow",
          bgColor: "#FFAD2C"
        },
        {
          type: "red",
          bgColor: "#B60005"
        }
      ],
      themeSetting: {
        navStyle: 1,
        themeStyle: 1,
        SubmenuStyles: 1,
        RecommendedStyles: 1,
        themeColor: "dark",
        openCustomColor: false,
        isCollapse: false,
        NoHaverColor: "#000000",
        SelectLabelColor: "#3E90FE",
        mainColor: "blue",
        HaverColor: "#000000",
        navColor: "#232c3d",
        bgColor: "#1d2532",
        topColor: "#232c3d",
        TopIconColor: "#FFFFFF",
        topSettingRadio: "1",
        TopIcon: "1",
        navColorRadio: "1",
        bgColorRadio: "1",
        openIndexTab: true,
        openRouterTab: true,
        navColorImg: "",
        bgColorImg: "",
        topColorImg: ""
      },
      // headleChangeFile: false,
      fileOption: {
        url: "", // 上传的url路径 可不传
        types: ["jpg", "png", "doc"], // 不传或[]表示不限制
        limit: 10, // 每次最多上传数量
        size: 2, // 单位Mb，单个上传文件大小
        multiple: false, // 默认多选
        data: {}, // 上传额外带的参数
        onSuccess: "onSuccess", // 上传成功回调方法 可不传
        onError: "onError"
      }
    };
  },
  computed: {
    ...mapGetters(["urlParam"]),
    styleObj() {
      return {
        right: this.visible ? 0 : "",
        top: "0px"
      };
    }
  },
  watch: {
    themeSetting: {
      deep: true,
      handler(newVal) {
        // console.log(newVal);
        // console.log(newVal);
        this.setThemeInfo(newVal);
      }
    },
    visible(val) {
      if (!val) {
        this.saveUserConfig();
      }
    }
  },
  mounted() {
    this.queryUserConfig();
    this.fileOption.url = this.urlParam.baseUrl;
  },
  methods: {
    openCustomColorChange() {
      if (this.themeSetting.navStyle !== 3) {
      if (this.themeSetting.openCustomColor) {
          this.themeSetting.SelectLabelColor = "#FFFFFF";
          this.themeSetting.NoHaverColor = "#FFFFFF";
          this.themeSetting.bgColor = "#E7EBF2";
          this.themeSetting.topColor = "#FFFFFF";
        if (this.themeSetting.themeColor === "dark") {
          this.themeSetting.navColor = "";
          this.themeSetting.HaverColor = "#232C3D";
          this.themeSetting.bgColor = "#1d2532";
          this.themeSetting.NoHaverColor = "#A9B5C6";
          this.themeSetting.topColor = "#232c3d";
        }
        if (this.themeSetting.themeColor === "white") {
          this.themeSetting.navColor = "#FFFFFF";
          this.themeSetting.SelectLabelColor = "#232C3D";
          this.themeSetting.bgColor = "#FFFFFF";
          this.themeSetting.HaverColor = "#FFFFFF";
          this.themeSetting.NoHaverColor = "#232C3D";
        }
        if (this.themeSetting.themeColor === "blue") {
          this.themeSetting.navColor = "#3E90FE";
          this.themeSetting.HaverColor = "#FFFFFF";
        }
        if (this.themeSetting.themeColor === "green") {
          this.themeSetting.navColor = "#26C393";
          this.themeSetting.HaverColor = "#26C393";
        }
        if (this.themeSetting.themeColor === "yellow") {
          this.themeSetting.navColor = "#FFAD2C";
          this.themeSetting.HaverColor = "#FFAD2C";
        }
        if (this.themeSetting.themeColor === "red") {
          this.themeSetting.navColor = "#B60005";
          this.themeSetting.HaverColor = "#B60005";
        }
        this.themeSetting.themeColor = "";
      } else {
        this.themeSetting.themeColor = "blue";
        this.themeSetting.navColor = "#3E90FE";
        this.themeSetting.HaverColor = "#FFFFFF";
        this.themeSetting.SelectLabelColor = "#FFFFFF";
        this.themeSetting.NoHaverColor = "#FFFFFF";
        this.themeSetting.bgColor = "#E7EBF2";
        this.themeSetting.topColor = "#FFFFFF";
        if (this.themeSetting.navStyle === 1) {
          this.themeSetting.themeStyle = 3;
        } else {
        this.themeSetting.themeStyle = 2;
        }
        this.themeSetting.TopIcon = "1";
      }
    } else {
      // eslint-disable-next-line no-lonely-if
        if (this.themeSetting.openCustomColor) {
          this.themeSetting.SelectLabelColor = "#FFFFFF";
          this.themeSetting.NoHaverColor = "#FFFFFF";
          this.themeSetting.bgColor = "#E7EBF2";
          this.themeSetting.topColor = "#FFFFFF";
        if (this.themeSetting.themeColor === "dark") {
          this.themeSetting.navColor = "#232C3D";
          this.themeSetting.HaverColor = "#232C3D";
          this.themeSetting.bgColor = "#1d2532";
          this.themeSetting.NoHaverColor = "#A9B5C6";
          this.themeSetting.topColor = "#232c3d";
        }
        if (this.themeSetting.themeColor === "white") {
          this.themeSetting.navColor = "#FFFFFF";
          this.themeSetting.SelectLabelColor = "#232C3D";
          this.themeSetting.bgColor = "#FFFFFF";
          this.themeSetting.HaverColor = "#FFFFFF";
          this.themeSetting.NoHaverColor = "#232C3D";
        }
        if (this.themeSetting.themeColor === "blue") {
          this.themeSetting.SelectLabelColor = "#3E90FE";
          this.themeSetting.navColor = "#FFFFFF";
          this.themeSetting.HaverColor = "#FFFFFF";
          this.themeSetting.bgColor = "#F3F6FE";
          this.themeSetting.NoHaverColor = "#333333";
          this.themeSetting.topColor = "#3E90FE";
        }
        if (this.themeSetting.themeColor === "green") {
          this.themeSetting.navColor = "#26C393";
          this.themeSetting.HaverColor = "#26C393";
        }
        if (this.themeSetting.themeColor === "yellow") {
          this.themeSetting.navColor = "#FFAD2C";
          this.themeSetting.HaverColor = "#FFAD2C";
        }
        if (this.themeSetting.themeColor === "red") {
          this.themeSetting.navColor = "#B60005";
          this.themeSetting.HaverColor = "#B60005";
        }
        this.themeSetting.themeColor = "";
        } else {
        this.themeSetting.themeColor = "blue";
        this.themeSetting.RecommendedStyles = 5;
        this.themeSetting.themeStyle = 3;
        // 菜单底色
        this.themeSetting.navColor = "#FFFFFF";
        // 背景底色
        this.themeSetting.bgColor = "#F3F6FE";
        // 顶部底色
        this.themeSetting.topColor = "#3E90FE";
        // 未选中文字
        this.themeSetting.NoHaverColor = "#333333";
        // 选中文字
        this.themeSetting.HaverColor = "#FFFFFF";
        // 选中按钮颜色
        this.themeSetting.SelectLabelColor = "#3E90FE";
        this.themeSetting.TopIcon = "2";
        this.themeSetting.TopIconColor = "#FFFFFF";
        }
    }
    },
    /**
     * @description: 校验上传文件的数量
     * @param {*} len 即将上传的文件数量
     */
    checkLimitNum(name, len) {
      if (len > 1) {
        Message({
          type: "error",
          message: `字段（${name}）上传数量不能超过1`
        });
        return false;
      }
      return true;
    },

    navChangeFile({ fileList, isAllUpload }) {
      // if (this.checkLimitNum("导航图片", fileList.length)) {
      //   return;
      // }
      this.themeSetting.navColor = "unset";
    },
    bgChangeFile({ fileList, isAllUpload }) {
       // if (this.checkLimitNum("背景图片", fileList.length)) {
       //   return;
       // }
       console.log(fileList, "fileListfileList");
    },
    topChangeFile({ fileList, isAllUpload }) {
      // if (this.checkLimitNum("顶部图片", fileList.length)) {

      // }
      // this.handleAddNewFiles(fileList, isAllUpload, "topChangeFile");
    },
    ...mapActions("main", ["setThemeInfo"]),
    // headleChangeColor() {
    //   console.log(111);
    // },
    changeRecommendedStyles(item) {
      const { style } = item;
      this.themeSetting.RecommendedStyles = style;
      // 5 6 7上下布局推荐样式
      if (style === 5) {
        this.themeSetting.themeColor = "blue";
        this.themeSetting.themeStyle = 3;
        // 菜单底色
        this.themeSetting.navColor = "#F6F7FB";
        // 背景底色
        this.themeSetting.bgColor = "#F6F7FB";
        // 顶部底色
        this.themeSetting.topColor = "#024FFD";
        // 未选中文字
        this.themeSetting.NoHaverColor = "#333333";
        // 选中文字
        this.themeSetting.HaverColor = "#FFFFFF";
        // 选中按钮颜色
        this.themeSetting.SelectLabelColor = "#3370FF";
        this.themeSetting.TopIcon = "2";
        this.themeSetting.TopIconColor = "#FFFFFF";
      } else if (style === 6) {
        this.themeSetting.themeColor = "yellow";
        this.themeSetting.themeStyle = 4;
        // 菜单底色
        this.themeSetting.navColor = "#FFA52F";
        // 背景底色
        this.themeSetting.bgColor = "#F2F1E7";
        // 顶部底色
        this.themeSetting.topColor = "#BA9D61";
        // 未选中文字
        this.themeSetting.NoHaverColor = "#FCFDFE";
        // 选中文字
        this.themeSetting.HaverColor = "#FFA52F";
        // 选中按钮颜色
        this.themeSetting.SelectLabelColor = "#FFFFFF";
        this.themeSetting.TopIcon = "1";
      } else if (style === 7) {
        this.themeSetting.themeColor = "red";
        this.themeSetting.themeStyle = 4;
        // 菜单底色
        this.themeSetting.navColor = "#EBF0FC";
        // 背景底色
        this.themeSetting.bgColor = "#F3F6FE";
        // 顶部底色
        this.themeSetting.topColor = "#D3DDF8";
        // 未选中文字
        this.themeSetting.NoHaverColor = "#333333";
        // 选中文字
        this.themeSetting.HaverColor = "#FFFFFF";
        // 选中按钮颜色
        this.themeSetting.SelectLabelColor = "#B60005";
        this.themeSetting.TopIcon = "1";
      }
      // console.log(style);
    },
    changeNavStyle(item) {
      const { style } = item;
      this.themeSetting.navStyle = style;
      this.themeSetting.SubmenuStyles = 1;
      this.themeSetting.openCustomColor = false;
      if (style === 1) {
        this.themeSetting.RecommendedStyles = 1;
        this.themeSetting.themeStyle = 3;
        this.themeSetting.TopIcon = "1";
      } else if (style === 2) {
        this.themeSetting.themeStyle = 2;
        this.themeSetting.RecommendedStyles = 3;
        this.themeSetting.TopIcon = "1";
      } else {
        this.themeSetting.RecommendedStyles = 5;
        this.themeSetting.themeStyle = 3;
        this.themeSetting.TopIcon = "2";
        this.themeSetting.TopIconColor = "#FFFFFF";
      }
    },
    tabCurrent(num) {
      this.current = num;
    },
    deviceSelectChange() {
      // console.log(111);
    },
    changeThemeColor({ type }) {
      const { themeSetting } = this;
      themeSetting.themeColor = type;
      if (themeSetting.themeColor === "dark") {
        this.themeSetting.TopIcon = "2";
        this.themeSetting.TopIconColor = "#FFFFFF";
      } else {
        this.themeSetting.TopIcon = "1";
      }
      if (themeSetting.RecommendedStyles === 5) {
        // 上下布局 样式一 除了白色上面图标都为白色
        if (themeSetting.themeColor !== "white") {
        this.themeSetting.TopIcon = "2";
        this.themeSetting.TopIconColor = "#FFFFFF";
      } else {
        this.themeSetting.TopIcon = "1";
      }
      }
      themeSetting.openCustomColor = false;
      const themeMap = {
        dark: {
          SelectLabelColor: "#FFFFFF",
          NoHaverColor: "#A9B5C6",
          bgColor: "#1d2532",
          topColor: "#232c3d",
          navColor: "#232c3d",
          HaverColor: "#232C3D"
        },
        white: {
          SelectLabelColor: "#3E90FE",
          NoHaverColor: "#232C3D",
          bgColor: "#F1F5F8",
          topColor: "#FFFFFF",
          navColor: "#FFFFFF",
          HaverColor: "#FFFFFF"
        },
        blue: {
          SelectLabelColor: "#FFFFFF",
          NoHaverColor: "#FFFFFF",
          bgColor: "#F1F5F8",
          topColor: "#FFFFFF",
          navColor: "#3E90FE",
          HaverColor: "#3e90fe"
        },
        green: {
          SelectLabelColor: "#FFFFFF",
          NoHaverColor: "#FFFFFF",
          bgColor: "#F1F5F8",
          topColor: "#FFFFFF",
          navColor: "#26C393",
          HaverColor: "#26C393"
        },
        yellow: {
          SelectLabelColor: "#FFFFFF",
          NoHaverColor: "#FFFFFF",
          bgColor: "#F1F5F8",
          topColor: "#FFFFFF",
          navColor: "#FFAD2C",
          HaverColor: "#FFAD2C"
        },
        red: {
          SelectLabelColor: "#FFFFFF",
          NoHaverColor: "#FFFFFF",
          bgColor: "#F1F5F8",
          topColor: "#FFFFFF",
          navColor: "#B60005",
          HaverColor: "#B60005"
        }
      };
      Object.assign(themeSetting, themeMap[type]);
    },
    changeActive({ style }) {
      this.themeSetting.navStyle = style;
    },
    close(target) {
      function checkIsColorPicker(target, className = "el-color-dropdown") {
        if (typeof target.className !== "string") return false;
        while (target.className?.indexOf(className) === -1) {
          target = target.parentNode;
        }
        return target.className && target.className.indexOf(className) !== -1;
      }
      if (checkIsColorPicker(target)) {
        return;
      }
      // 兼容主题下拉框
      function selectIsDropdownItem(target, className = "el-select-dropdown__item") {
        if (typeof target.className !== "string") return false;
        while (target.className?.indexOf(className) === -1) {
          target = target.parentNode;
        }
        return target.className && target.className.indexOf(className) !== -1;
      }
      if (selectIsDropdownItem(target) || !target.className) {
        return;
      }
      this.$emit("close");
    },
    async saveUserConfig() {
      await enService.saveUserConfig({ configKey: "userTheme", configValue: JSON.stringify(this.themeSetting) });
    },
    async queryUserConfig() {
      let data = (await enService.queryUserConfig({ configKey: "userTheme" })) || {
        navStyle: 1,
        themeStyle: 3,
        SubmenuStyles: 1,
        RecommendedStyles: 1,
        themeColor: "blue",
        openCustomColor: false,
        isCollapse: false,
        navColor: "#3E90FE",
        bgColor: "#E7EBF2",
        topColor: "#FFFFFF",
        TopIconColor: "#FFFFFF",
        NoHaverColor: "#FFFFFF",
        SelectLabelColor: "#FFFFFF",
        mainColor: "blue",
        HaverColor: "#3e90fe",
        topSettingRadio: "1",
        TopIcon: "1",
        navColorRadio: "1",
        bgColorRadio: "1",
        openIndexTab: true,
        openRouterTab: true
      };
      if (data.value) {
        data = JSON.parse(data.value);
      }
      // console.log(data);
      this.themeSetting.openRouterTab = true;
      Object.assign(this.themeSetting, data);
    }
  }
};
</script>
<style lang="scss">
.el-tooltip__popper{
  z-index: 100000!important;
}
.el-collapse-item__wrap {
      overflow: visible
    }
.el-input__prefix {
  display: flex;
  align-items: center;
  margin-left: 20px;

  .iconTargetOne {
    width: 56px;
    height: 22px;
    background: #FFFFFF;
    display: flex;
    align-items: center;

    .iconLeftOne {
      width: 2px;
      height: 22px;
      background: #3e90fe;
    }

    .iconCenterOne {
      margin: 0 4px 0 6px;
    }

    .iconTextOne {
      font-size: 10px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #3e90fe;
    }
  }

  .iconTargetTwo {
    width: 56px;
    height: 22px;
    background: #3e90fe;
    display: flex;
    align-items: center;

    .iconLeftTwo {
      width: 2px;
      height: 22px;
      background: #3e90fe;
    }

    .iconCenterTwo {
      margin: 0 4px 0 6px;
    }

    .iconTextTwo {
      font-size: 10px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
    }
  }

  .iconTargetThere {
    width: 56px;
    height: 22px;
    background: #3e90fe;
    display: flex;
    align-items: center;
    border-radius: 11px;

    .iconCenterThere {
      margin: 0 4px 0 8px;
    }

    .iconTextThere {
      font-size: 10px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
    }
  }

  .iconTargetFour {
    width: 56px;
    height: 22px;
    background: #3e90fe;
    display: flex;
    align-items: center;
    border-radius: 4px;

    .iconCenterFour {
      margin: 0 4px 0 8px;
    }

    .iconTextFour {
      font-size: 10px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
    }
  }

  .iconTargetFive {
    width: 56px;
    height: 22px;
    background: #3e90fe;
    display: flex;
    align-items: center;
    border-radius: 11px 0px 0px 11px;

    .iconCenterFive {
      margin: 0 4px 0 8px;
    }

    .iconTextFive {
      font-size: 10px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
    }
  }

  .iconTargetSix {
    width: 56px;
    height: 22px;
    background: #3e90fe;
    display: flex;
    align-items: center;
    border-radius: 11px 11px 0px 11px;

    .iconCenterSix {
      margin: 0 4px 0 8px;
    }

    .iconTextSix {
      font-size: 10px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
    }
  }

  .iconTargetSeven {
    width: 56px;
    height: 22px;
    display: flex;
    align-items: center;

    .iconCenterSeven {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      background: #3e90fe;
      border-radius: 7px;
      margin: 0 4px 0 8px;
    }

    .iconTextSeven {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 33px;
      height: 22px;
      background: #3e90fe;
      border-radius: 7px;
      font-size: 10px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
    }
  }
}

.selectAll .el-input__prefix {
  left: -10px
}
.el-select-dropdown{
  z-index: 10000 !important;
}
.selectAll .el-input input {
  color: #FFFFFF
}

.selectAll .el-input .el-input__inner {
  background-color: #23243d
}

.collapse {
  background: rgba(35, 44, 61, 0) !important;
  border: none !important;
  font-size: 14px;

  .el-collapse-item__header {
    color: #FFFFFF !important;
    font-size: 12px;
    background: rgba(35, 44, 61, 0) !important;
  }

  .collapse-item:last-child {
    .el-collapse-item__header {
      border-bottom: none;
    }

    .el-collapse-item__wrap {
      border: none;
      background: rgba(35, 44, 61, 0) !important;
    }
  }

  .el-collapse-item__content {
    padding-bottom: 0px;
  }

  i {
    margin-left: 10px;
  }
}

.theme-drawer {
  position: fixed;
  transition: all .3s;
  right: -396px;
  top: 61px;
  width: 396px;
  bottom: 0;
  background: rgba(35, 44, 61, 0.9);
  font-family: Microsoft YaHei;
  z-index: 10000;

  .header {
    display: flex;
    align-items: center;
    height: 50px;
    line-height: 50px;
    font-weight: bold;
    color: #fff;
    border-bottom: 1px solid rgba(255, 255, 255, .3);
    font-weight: bold;
    font-size: 14px;
    text-indent: 20px;

    .setting {
      margin-left: -10px;
    }
  }

  .tab {
    margin: 20px;
    width: 356px;
    height: 34px;
    background: rgba(51, 112, 255, 0.1);
    border: 1px solid #3e90fe;
    border-radius: 17px;
    display: flex;

    div {
      flex: 1 0 calc(33.333% - 10px);
      text-align: center;
      line-height: 34px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
      cursor: pointer;
      user-select: none;
    }

    .active {
      width: 118px;
      height: 34px;
      background: #3e90fe;
      border-radius: 17px;
      transition: all .3s ease-in-out;
    }
  }

  .container {
    padding: 0 20px;
    color: #fff;
    height: calc(100% - 170px);
    overflow-y: auto;

    /* 设置滚动条的样式 */
    &::-webkit-scrollbar {
      width: 0;
    }

    /* 滚动槽 */
    &::-webkit-scrollbar-track {
      border-radius: 6px;
    }

    /* 滚动条滑块 */
    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background: #bdbdbf;
    }

    &::-webkit-scrollbar-thumb:window-inactive {
      background: #bdbdbf;
    }

    .card-define {
      height: 100%;
      margin-top: 0;

      .card-define-body {
        height: calc(100% - 45px);
        overflow-y: auto;

        /* 设置滚动条的样式 */
        &::-webkit-scrollbar {
          width: 6px;
        }

        /* 滚动槽 */
        &::-webkit-scrollbar-track {
          border-radius: 6px;
        }

        /* 滚动条滑块 */
        &::-webkit-scrollbar-thumb {
          border-radius: 6px;
          background: #bdbdbf;
        }

        &::-webkit-scrollbar-thumb:window-inactive {
          background: #bdbdbf;
        }
      }
    }

    .layout {
      .title {
        font-size: 14px;
        font-weight: bold;
        color: #fff;
        margin: 18px 0;
      }

      .styles {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        color: #fff;
        font-size: 12px;
        padding: 0 8px 0px 0;

        .label {
          display: inline-block;
          margin-right: 22px;
        }

        .style-list {
          display: flex;
          justify-content: space-between;
          flex: 1;
          flex-wrap: wrap;

          .style-item {
            width: 129px;
            height: 95px;
            cursor: pointer;
            background: url("../../assets/images/theme.png") no-repeat -41px -41px;
          }

          .style-item3 {
            width: 130px;
            height: 126px;
            cursor: pointer;
            background: url("../../assets/images/theme.png") no-repeat -41px -41px;
          }

          .item-text {
            width: 129px;
            text-align: center;
            margin: 13px 0 21px 0;
          }
        }
      }
      .setting-items{
        background: rgba($color: #FFFFFF, $alpha: 0.05);
        margin:0 -20px;
      }
      .setting-item {
        color: #FFFFFF !important;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .label {
          display: inline-block;
          margin-right: 20px;
          font-size: 12px;
        }

        .color-list {
          display: flex;

          .color-item {
            display: inline-block;
            width: 30px;
            height: 30px;
            line-height: 30px;
            border-radius: 15px;
            margin-right: 15px;
            cursor: pointer;
            text-align: center;
          }
        }
      }

      .setting-item-unFlex {
        color: #FFFFFF !important;

        .setting-components {
          margin: 15px 0 0 68px;

          .Upload-components {
            width: 67px;
            height: 67px;
            background: #FFFFFF;
            border: 1px solid #E8ECF2;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            user-select: none;

            .icon {
              font-size: 40px;
              color: #A9B5C6;
              margin-top: -8px;
            }
          }
        }

        .label {
          display: inline-block;
          margin-right: 20px;
          font-size: 12px;
        }

        .color-list {
          display: flex;

          .color-item {
            display: inline-block;
            width: 30px;
            height: 30px;
            line-height: 30px;
            border-radius: 15px;
            margin-right: 15px;
            cursor: pointer;
            text-align: center;
          }
        }

        .text {
          width: 220px;
          height: 13px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;
        }
      }

      .divider {
        height: 0.5px;
        background: rgba(255, 255, 255, .8);
        opacity: 0.2;
        margin: 20px 20px 5px 20px;
      }
    }
  }
}
</style>
