/*
 * @Author: zoujp
 * @Date: 2020-11-09 16:56:55
 * @LastEditTime: 2020-11-11 10:16:58
 * @LastEditors: Please set LastEditors
 * @Description: 方案详情
 * @FilePath: \user\src\api\plan\planDetail.js
 */

import BaseService from "../BaseService";

/**
 * 方案销售趋势
 */
class PlanDetailService extends BaseService {
  pk = "";

  constructor(http) {
    super("", http);
  }

  /**
   * @description: 获取方案详情
   * @param {*}
   * @return {*}
   */
  querySchemeApproveDetail(params) {
    const { http } = this;
    return http.post("querySchemeApproveDetail", params);
  }

  /**
   * @description: 获取版本记录
   * @param {*}
   * @return {*}
   */
  querySchemeApproveHistory(params) {
    const { http } = this;
    return http.post("querySchemeApproveHistory", params);
  }

  /**
   * @description: 获取对比日志
   * @param {*}
   * @return {*}
   */
  querySchemeComparisonLogList(params) {
    const { http } = this;
    return http.post("querySchemeComparisonLogList", params);
  }

  /**
   * @description: 获取基础数据字段
   * @param {*}
   * @return {*}
   */
  querySchemeComparisonLog(params) {
    const { http } = this;
    return http.post("querySchemeComparisonLog", params);
  }

  /**
   * @description: 人员登录列表
   * @param {*}
   * @return {*}
   */
  querySchemeUserList(params) {
    const { http } = this;
    return http.post("querySchemeUserList", params);
  }

  /**
   * @description: 人员登录
   * @param {*}
   * @return {*}
   */
  loginAction(params) {
    const { http } = this;
    return http.post("loginAction", params);
  }
}

export default PlanDetailService;
