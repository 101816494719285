/*
 * @Author: pengyu
 * @Date: 2021-02-26 09:26:33
 * @LastEditors: pengyu
 * @LastEditTime: 2021-03-16 17:54:24
 * @Description: 邮箱列表接口合集
 */
import BaseService from "../../BaseService";

class MailBoxService extends BaseService {
  pk = ""

  constructor(http) {
    super("", http);
  }

  // 查询邮件列表
  queryMailList(params) {
    const { http } = this;
    return http.post("/queryMailList", params);
  }

  // 修改邮件已读
  updateMailInfo(params) {
    const { http } = this;
    return http.post("/updateMailInfo", params);
  }

  // 删除邮件
  deleteMailInfo(params) {
    const { http } = this;
    return http.post("/deleteMailInfo", params);
  }

  // 查询邮件 映射按钮
  queryMailBtn(params) {
    const { http } = this;
    return http.post("/queryMailBtn", params);
  }

  // 执行邮件映射按钮
  executeMailBtn(params) {
    const { http } = this;
    return http.post("/executeMailBtn", params);
  }

  // 查询邮件数量
  countMail(params) {
    const { http } = this;
    return http.post("/countMail", params);
  }

  // 查询个人邮箱账户
  getMailAccount(params) {
    const { http } = this;
    return http.post("/getMailAccount", params);
  }

  // 查询单个邮箱 的4个箱子邮件数量
  countMailGroup(params) {
    const { http } = this;
    return http.post("/countMailGroup", params);
  }

  // 移动邮件
  moveMail(params) {
    const { http } = this;
    return http.post("/moveMail", params);
  }

  // 全局 搜索 邮件
  searchMail(params) {
    const { http } = this;
    return http.post("/searchMail", params);
  }
}

export default MailBoxService;
