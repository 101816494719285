// 主页的一些路由
const routes = [
  {
    path: "fullScreen",
    name: "fullScreen",
    component: () => import(/* webpackChunkName: "corporate-credit" */"@/views/corporateCredit/information/enterprise-info-fullScreen.vue"),
    meta: {
      title: "全屏",
      keepAlive: true,
      fullPage: false
    }
  }
];

export default routes;
