/**
 * @Author 李煜
 * @Date 2020/8/18
 * @Description 机票接口雷
 */
import BaseService from "../BaseService";

class FlightService extends BaseService {
  pk = ""

  constructor(http) {
    super("", http);
  }

  /**
   * 获取价格日历
   * @param params
   * @returns {*}
   */
  getPriceCalendar(params) {
    const { http } = this;
    return http.post("getPriceCalendar", params);
  }

  /**
   * 查询列表
   * @param params 查询条件
   * @returns {*}
   */
  getFlightData(params) {
    const { http } = this;
    return http.post("getFlightData", params);
  }

  /**
   * 查询舱位
   * @param params 查询条件
   * @returns {*}
   */
  getCabinData(params) {
    const { http } = this;
    return http.post("getCabinData", params); // 不使用module
  }

  /**
   * 获取客规
   * @param params 查询条件
   * @returns {*}
   */
  getTicketRule(params) {
    const { http } = this;
    return http.post("getTicketRule", params); // 不使用module
  }

  /**
   * 获取座位数
   * @param params 查询条件
   * @returns {*}
   */
  getPlaneSeatNum(params) {
    const { http } = this;
    return http.post("getPlaneSeatNum", params); // 不使用module
  }

  /**
   * 检查重复下单问题
   * @param params 查询条件
   * @returns {*}
   */
  checkFlightNoByPassgener(params) {
    const { http } = this;
    return http.post("checkFlightNoByPassgener", params); // 不使用module
  }

  /**
   * 校验超标 (pc使用)
   * @param params 查询条件
   * @returns {*}
   */
  checkPlaneSupplement(params) {
    const { http } = this;
    return http.post("checkPlaneSupplement", params); // 不使用module
  }
}

export default FlightService;
