<!-- eslint-disable max-len -->
<!-- eslint-disable vue/valid-v-bind -->
<!--
 * @Author: Fenngding
 * @Date: 2019-08-23 10:43:47
 * @LastEditors: linshanfeng
 * @LastEditTime: 2024-02-27 16:22:38
 * @Email: fenngding@live.com
 * @Description:
 -->
<template>
    <div
        class="menu-container"
        :style="[navStyle, BackgroundImage]"
        style="display:flex"
    >
      <div class="menu-container" style='float:left;' :style="navStyle" >
        <el-menu
            v-if='themeInfo.SubmenuStyles == 3 '
            :default-active="activeIndex"
            :style="[navBorderStyle, navStyle]"
            :class="{ collapse: isCollapse , 'menu' : navStyles === 1,'menu-short': navStyles === 2}"
          text-color="#a9b5c6"
          active-text-color="#fff"
          :unique-opened="true"
          collapse-transition
          :collapse="isCollapse"
          @open="handleOpen"
          @close="handleClose">
            <div v-if='themeInfo.SubmenuStyles == 3'>
              <div v-if="navStyles === 1">
                <span v-for="(item, index) in menuList" :key="index" class="en-submenuStyle">
                  <el-submenu
                    v-if="item.children && item.children.length > 0"
                    :classList="item.data.classifyList"
                     :menus="item.children"
                    :index="item.index"
                     :activeId="activeId"
                      class="en-submenu"
                       @click.stop.native="clickMenuItem(item)">
                    <template slot="title">
                      <en-icon  :name="setChildrenIcon(item.icon)" size="16"
                      :class= "{
                        'en-icon':true,
                      }"/>
                      <i v-if="isCollapse" class="el-icon-arrow-right icon"></i>
                      <span
                      :class= "{
                        'en-icon-title':true,
                      }">{{ item.name?.length > 6 ? item.name.slice(0,6)+'...' : item.name }}</span>
                    </template>
                    <template>
                      <div v-for="(subItem) in item.children" :key="subItem.index" class="submenu">
                        <el-submenu v-if="subItem.children && subItem.children.length > 0" :menus="subItem.children" :index="subItem.index" @click.stop.native="clickMenuItem(subItem)">
                          <template slot="title">
                            <en-icon  :name="setChildrenIcon(subItem.icon)" size="16"
                          :class= "{
                            'en-icon':true,
                          }" />
                            <span
                            :class= "{
                              'en-icon-title':true,
                            }">{{ subItem.name.length > 4 ? subItem.name.slice(0,4)+'...' : subItem.name }}</span>
                          </template>
                          <el-menu-item v-for="(lastItem) in subItem.children" :key="lastItem.index" :activeId="activeId" :index="lastItem.index" @click.stop.native="clickMenuItem(lastItem)">
                            <en-icon :name="setChildrenIcon(lastItem.icon)" size="16"
                            :class= "{
                              'en-icon':true,
                            }" />
                            <span
                            :class= "{
                              'en-icon-title':true,
                            }">{{ lastItem.name.length> 4 ?lastItem.name.slice(0,4)+'...' : lastItem.name }}</span>
                          </el-menu-item>
                        </el-submenu>
                        <el-menu-item v-else :activeId="activeId" :index="subItem.index" @click.stop.native="clickMenuItem(subItem)">
                          <en-icon :name="setChildrenIcon(subItem.icon)" size="16"
                            :class= "{
                              'en-icon':true,
                            }" />
                          <span
                            :class= "{
                              'en-icon-title':true,
                            }">{{ subItem.name.length > 6 ? subItem.name.slice(0,6)+'...' : subItem.name }}</span>
                        </el-menu-item>
                      </div>
                    </template>
                  </el-submenu>
                  <el-menu-item v-else :index="item.index" :activeId="activeId" @click.native="clickMenuItem(item)">
                    <en-icon :name="item.icon" size="16"
                    :class= "{
                      'en-icon':true,
                    }" />
                    <span slot="title"
                    :class= "{
                      'en-icon-title':true,
                    }">{{ item.name.length > 6 ? item.name.slice(0,6)+'...' : item.name }}</span>
                  </el-menu-item>

                </span>
              </div>
              <div v-if="navStyles === 2">
                <span  v-for="(item, index) in menuList" :key="index" class="en-submenuStyleTwo">
                  <el-submenu
                    v-if="item.children && item.children.length > 0"
                    :classList="item.data.classifyList"  :menus="item.children"
                    :index="item.index" :activeId="activeId" class="en-submenu" @click.stop.native="clickMenuItem(item)">
                    <template slot="title">
                      <div style="height:40px;text-align: center;">
                       <div class="h18"><en-icon class="en-icon" :name="setChildrenIcon(item.icon)" size="12" /></div>
                       <div class="h18 font12 ml-10" v-if="!isCollapse"><span class="en-icon-title">{{ item.name.length > 4 ?  item.name.slice(0,4)+'...' : item.name }}</span></div>
                       <div class="h18 font12 ml-10" v-if="isCollapse"><span class="en-icon-title">{{ item.name.slice(0,2) }}</span></div>
                      </div>
                    </template>
                    <template>
                      <div v-for="subItem in item.children" :key="subItem.index" class="submenu">
                        <el-submenu v-if="subItem.children && subItem.children.length > 0" :menus="subItem.children" :index="subItem.index" @click.stop.native="clickMenuItem(subItem)">
                          <template slot="title">
                            <div class="textAlign h40">
                              <div class="h18"><en-icon class="en-icon" :name="setChildrenIcon(subItem.icon)" size="12" /></div>
                              <div class="h18 font12 ml-10" v-if="!isCollapse"><span class="en-icon-title">{{ subItem.name > 4 ? subItem.name.slice(0,4)+'...' : subItem.name }}</span></div>
                              <div class="h18 font12 ml-10" v-if="isCollapse"><span class="en-icon-title">{{ subItem.name.slice(0,2)}}</span></div>
                          </div>
                          </template>
                          <el-menu-item v-for="lastItem in subItem.children" :key="lastItem.index"
                          :navStyles="navStyles" :activeId="activeId" :index="lastItem.index" @click.stop.native="clickMenuItem(lastItem)">
                          <div class="textAlign h40">
                          <div class="h18"><en-icon class="en-icon" :name="setChildrenIcon(lastItem.icon)" size="12" /></div>
                            <div class="h18 font12 ml-10" v-if="!isCollapse"> <span class="en-icon-title">{{ lastItem.name> 4 ?lastItem.name.slice(0,4)+'...' : lastItem.name }}</span></div>
                            <div class="h18 font12 ml-10" v-if="isCollapse"> <span class="en-icon-title">{{ lastItem.name.slice(0,2)}}</span></div>
                          </div>
                          </el-menu-item>
                        </el-submenu>
                        <el-menu-item v-else :activeId="activeId"  :navStyles="navStyles" :index="subItem.index" @click.stop.native="clickMenuItem(subItem)">
                          <div class="textAlign h40">
                            <div class="h18"><en-icon class="en-icon" :name="setChildrenIcon(subItem.icon)" size="12" /></div>
                            <div class="h18 font12 ml-10" v-if="!isCollapse"> <span class="en-icon-title">{{ subItem.name > 4 ? subItem.name.slice(0,4)+'...' : subItem.name }}</span></div>
                            <div class="h18 font12 ml-10" v-if="isCollapse"> <span class="en-icon-title">{{ subItem.name.slice(0,2) }}</span></div>
                          </div>
                        </el-menu-item>
                      </div>
                    </template>
                  </el-submenu>
                  <el-menu-item v-else style="text-align: center" :index="item.index"  :navStyles="navStyles" :activeId="activeId" @click.native="clickMenuItem(item)">
                  <div class="textAlign h40">
                    <div class="h18"><en-icon  :name="item.icon" size="12" /></div>
                    <div class="h18 font12 ml-10" v-if="!isCollapse"><span slot="title" >{{ item.name.length > 4 ?  item.name.slice(0,4)+'...' : item.name}}</span></div>
                    <div class="h18 font12 ml-10" v-if="isCollapse"><span slot="title" >{{ item.name.slice(0,2) }}</span></div>
                  </div>
                  </el-menu-item>
                </span>
              </div>
            </div>
        </el-menu>
        <en-menu
          v-if='themeInfo.SubmenuStyles == 1 || themeInfo.SubmenuStyles == 2'
          :default-active="activeIndex"
          :key="updateKey"
          :style="[navBorderStyle, navStyle]"
          :class="{ collapse: isCollapse , 'menu' : navStyles === 1,'menu-short': navStyles === 2}"
          text-color="#a9b5c6"
          active-text-color="#fff"
          :unique-opened="true"
          collapse-transition
          :collapse="isCollapse"
          @open="handleOpen"
          @close="handleClose"

        >
        <div v-if='themeInfo.SubmenuStyles == 2 ' >
            <!-- 长菜单 -->
            <div v-if="navStyles === 1">
              <span v-for="(item ,index) in menuList" :key="index" class="en-submenuStyle">
                <en-submenu
                  v-if="item.children && item.children.length > 0"
                  :classList="item.data.classifyList"  :menus="item.children"
                  popperClass='shortFix'
                  :popperStyle="subStyle"

                  :index="item.index" :activeId="activeId" class="en-submenu" @click.stop.native="clickMenuItem(item)">
                  <template slot="title">
                    <en-icon  :name="setChildrenIcon(item.icon)" size="16"
                        :class= "{
                          'en-icon':true,
                        }"/>
                    <el-tooltip class="item" :open-delay="1000" effect="light" :disabled="item?.name?.length < 6" :content="item.name.length > 6 ? item.name.slice(0,5)+'...' : item.name" placement="right-end">
                      <span v-if="!isCollapse" :class= "{
                            'en-icon-title':true,
                          }">{{ item.name.length > 6 ? item.name.slice(0,5)+'...' : item.name }}</span>
                    </el-tooltip>
                  </template>

                  <template>
                    <div v-for="(subItem) in item.children" :key="subItem.index" class="submenu">
                      <en-submenu v-if="subItem.children && subItem.children.length > 0" :menus="subItem.children" :index="subItem.index" @click.stop.native="clickMenuItem(subItem)">
                        <template slot="title">
                          <en-icon  :name="setChildrenIcon(subItem.icon)" size="16"
                            :class= "{
                              'en-icon':true,
                            }"/>
                              <span :class= "{
                              'en-icon-title':true,
                            }">{{ item.subItem?.length > 6 ? item.subItem.slice(0,5)+'...' : item.subItem }}</span>
                        </template>
                        <en-menu-item v-for="(lastItem) in subItem.children" :key="lastItem.index" :activeId="activeId" :index="lastItem.index" @click.stop.native="clickMenuItem(lastItem)">
                            <en-icon  :name="setChildrenIcon(lastItem.icon)" size="16"
                            :class= "{
                              'en-icon':true,
                            }"/>
                              <span  :class= "{
                              'en-icon-title':true,
                            }">{{item.lastItem?.length > 6 ? item.lastItem.slice(0,6)+'...' : item.lastItem  }}</span>
                        </en-menu-item>
                      </en-submenu>
                      <en-menu-item v-else :activeId="activeId" :index="subItem.index" @click.stop.native="clickMenuItem(subItem)">
                        <en-icon  :name="setChildrenIcon(subItem.icon)" size="16"
                        :class= "{
                          'en-icon':true,
                        }"/>
                        <span :class= "{
                          'en-icon-title':true,
                        }">{{item.subItem?.length > 6 ? item.subItem.slice(0,5)+'...' : item.subItem }}</span>
                      </en-menu-item>
                    </div>
                  </template>
                </en-submenu>
                <en-menu-item v-else :index="item.index" :activeId="activeId" @click.native="clickMenuItem(item)">
                 <el-tooltip class="item" :open-delay="1000" effect="light" :disabled="item?.name?.length < 6" :content="item.name.length > 6 ? item.name.slice(0,5)+'...' : item.name" placement="right-end">
                  <en-icon class="en-icon" :name="item.icon" size="16"
                        :class= "{
                          'en-icon':true,
                        }" />
                  <span slot="title" :class= "{
                          'en-icon-title':true,
                        }">{{  item.name?.length > 6 ? item.name.slice(0,5)+'...' : item.name }}</span>
                    </el-tooltip>
                </en-menu-item>
              </span>
            </div>
            <!-- 短菜单 -->
            <div v-if="navStyles === 2">
              <span v-for="(item,index) in menuList" :key="index" class="en-submenuStyle">
                <en-submenu
                  v-if="item.children && item.children.length > 0"
                  :navStyles="navStyles"
                  :classList="item.data.classifyList"  :menus="item.children"
                  popperClass='shortFix'
                  :popperStyle="subStyle"
                  :index="item.index" :activeId="activeId" class="en-submenu" @click.stop.native="clickMenuItem(item)">
                  <template slot="title">
                    <div style="height:40px;text-align: center;">
                     <div class="h18" style="margin-bottom:3px;"><en-icon class="en-icon" style="margin-right:0px" :name="setChildrenIcon(item.icon)" size="12" /></div>
                     <div class="h18 font12" v-if="!isCollapse"><span>{{ item.name.length > 4 ?  item.name.slice(0,4)+'...' : item.name }}</span></div>
                     <div class="h18 font12" v-if="isCollapse"><span>{{ item.name.slice(0,2) }}</span></div>
                    </div>
                  </template>
                  <template>
                    <div v-for="subItem in item.children" :key="subItem.index" class="submenu">
                      <en-submenu v-if="subItem.children && subItem.children.length > 0" :menus="subItem.children" :navStyles="navStyles" :index="subItem.index" @click.stop.native="clickMenuItem(subItem)">
                        <template slot="title">
                          <div class="textAlign h40">
                            <div class="h18" style="margin-bottom:3px;"><en-icon class="en-icon" style="margin-right:0px" :name="setChildrenIcon(subItem.icon)" size="12" /></div>
                            <div class="h18 font12" v-if="!isCollapse"><span class="en-icon-title">{{ subItem.name > 4 ? subItem.name.slice(0,4)+'...' : subItem.name }}</span></div>
                            <div class="h18 font12" v-if="isCollapse"><span class="en-icon-title">{{ subItem.name.slice(0,2)}}</span></div>
                        </div>
                        </template>
                        <en-menu-item v-for="lastItem in subItem.children" :key="lastItem.index"
                        :navStyles="navStyles" :activeId="activeId" :index="lastItem.index" @click.stop.native="clickMenuItem(lastItem)">
                        <div class="textAlign h40">
                        <div class="h18"><en-icon class="en-icon" style="margin-right:0px" :name="setChildrenIcon(lastItem.icon)" size="12" /></div>
                          <div class="h18 font12" v-if="!isCollapse"> <span class="en-icon-title">{{ lastItem.name> 4 ?lastItem.name.slice(0,4)+'...' : lastItem.name }}</span></div>
                          <div class="h18 font12" v-if="isCollapse"> <span class="en-icon-title">{{ lastItem.name.slice(0,2)}}</span></div>
                        </div>
                        </en-menu-item>
                      </en-submenu>
                      <en-menu-item v-else :activeId="activeId"  :navStyles="navStyles" :index="subItem.index" @click.stop.native="clickMenuItem(subItem)">
                        <div class="textAlign h40">
                      <div class="h18" style="margin-bottom:3px;"><en-icon class="en-icon" style="margin-right:0px" :name="setChildrenIcon(subItem.icon)" size="12" /></div>
                      <div class="h18 font12" v-if="!isCollapse"> <span class="en-icon-title">{{ subItem.name > 4 ? subItem.name.slice(0,4)+'...' : subItem.name }}</span></div>
                      <div class="h18 font12" v-if="isCollapse"> <span class="en-icon-title">{{ subItem.name.slice(0,2) }}</span></div>
                    </div>
                      </en-menu-item>
                    </div>
                  </template>
                </en-submenu>
                <en-menu-item v-else style="text-align: center" :index="item.index"  :navStyles="navStyles" :activeId="activeId" @click.native="clickMenuItem(item)">
                <div class="textAlign h40">
                  <div class="h18" style="margin-bottom:3px;"><en-icon  style="margin-right:0px" :name="item.icon" size="12" /></div>
                  <div class="h18 font12" v-if="!isCollapse"><span slot="title" >{{ item.name.length > 4 ?  item.name.slice(0,4)+'...' : item.name}}</span></div>
                  <div class="h18 font12" v-if="isCollapse"><span slot="title" >{{ item.name.slice(0,2) }}</span></div>
                </div>
                </en-menu-item>
              </span>
            </div>
        </div>
        <div v-if='themeInfo.SubmenuStyles == 1 '>
            <!-- 长菜单 -->
            <div v-if="navStyles === 1">
              <span v-for="(item ,index) in menuList" :key="index" class="en-submenuStyle">
                <en-submenu
                  v-if="item.children && item.children.length > 0"
                  :classList="item.data.classifyList"  :menus="item.children"
                  :index="item.index" :activeId="activeId" class="en-submenu" @click.stop.native="clickMenuItem(item)">
                  <template slot="title">
                    <en-icon  :name="setChildrenIcon(item.icon)" size="16"
                        :class= "{
                          'en-icon':true,
                        }"/>
                    <el-tooltip class="item" :open-delay="1000" :disabled="item?.name?.length < 6" effect="light" :content="item.name" placement="right-end">
                      <span v-if="!isCollapse" :class= "{
                            'en-icon-title':true,
                          }">{{ item.name.length > 6 ? item.name.slice(0,5)+'...' : item.name }}</span>
                    </el-tooltip>
                  </template>
                  <template>
                    <div v-for="(subItem) in item.children" :key="subItem.index" class="submenu">
                      <en-submenu v-if="subItem.children && subItem.children.length > 0" :menus="subItem.children" :index="subItem.index" @click.stop.native="clickMenuItem(subItem)">
                        <template slot="title">
                          <en-icon  :name="setChildrenIcon(subItem.icon)" size="16"
                        :class= "{
                          'en-icon':true,
                        }"/>
                        <el-tooltip class="item" :open-delay="1000" :disabled="item?.subItem?.length < 6" effect="light" :content="item.subItem" placement="right-end">
                              <span :class= "{
                              'en-icon-title':true,
                            }">{{  item.subItem?.length > 6 ? item.subItem.slice(0,5)+'...' : item.subItem  }}</span>
                        </el-tooltip>
                        </template>
                        <en-menu-item v-for="(lastItem) in subItem.children" :key="lastItem.index" :activeId="activeId" :index="lastItem.index" @click.stop.native="clickMenuItem(lastItem)">
                        <en-icon  :name="setChildrenIcon(lastItem.icon)" size="16"
                        :class= "{
                          'en-icon':true,
                        }"/>
                        <el-tooltip class="item" :open-delay="1000" :disabled="item?.lastItem?.length < 6" effect="light" :content="item.lastItem" placement="right-end">
                          <span  :class= "{
                          'en-icon-title':true,
                        }">{{ item.lastItem?.length > 6 ? item.lastItem.slice(0,5)+'...' : item.lastItem }}</span>
                        </el-tooltip>
                        </en-menu-item>
                      </en-submenu>
                      <en-menu-item v-else :activeId="activeId" :index="subItem.index" @click.stop.native="clickMenuItem(subItem)">
                        <en-icon  :name="setChildrenIcon(subItem.icon)" size="16"
                        :class= "{
                          'en-icon':true,
                        }"/>
                        <el-tooltip class="item" :open-delay="1000" :disabled="item?.subItem?.length < 6" effect="light" :content="item.subItem" placement="right-end">
                        <span :class= "{
                          'en-icon-title':true,
                        }">{{ item.subItem?.length > 6 ? item.subItem.slice(0,5)+'...' : item.subItem  }}</span>
                        </el-tooltip>
                      </en-menu-item>
                    </div>
                  </template>
                </en-submenu>
                <en-menu-item v-else :index="item.index" :activeId="activeId" @click.native="clickMenuItem(item)">
                  <en-icon class="en-icon" :name="item.icon" size="16"
                        :class= "{
                          'en-icon':true,
                        }" />
                          <span slot="title" :class= "{
                                  'en-icon-title':true,
                                }">
                                {{ item.name?.length > 6 ? item.name.slice(0,5)+'...' : item.name }}</span>
                </en-menu-item>
              </span>
            </div>
            <!-- 短菜单 -->
            <div v-if="navStyles === 2">
              <span v-for="(item,index) in menuList" :key="index" class="en-submenuStyle">
                <en-submenu
                  v-if="item.children && item.children.length > 0"
                  :navStyles="navStyles"
                  :classList="item.data.classifyList"  :menus="item.children"
                  :index="item.index" :activeId="activeId" class="en-submenu" @click.stop.native="clickMenuItem(item)">
                  <template slot="title">
                    <div style="height:40px;text-align: center;">
                     <div class="h18" style="margin-bottom:3px;"><en-icon class="en-icon" style="margin-right:0px" size="20" :name="setChildrenIcon(item.icon)"  /></div>
                        <el-tooltip class="item" effect="light" :open-delay="1000" :disabled="item?.name?.length < 6" :content="item.name" placement="right-end">
                          <div class="h18 font12" v-if="!isCollapse"><span>{{ item.name.length > 4 ?  item.name.slice(0,4)+'...' : item.name }}</span></div>
                          <div class="h18 font12" v-if="isCollapse"><span>{{ item.name.slice(0,2) }}</span></div>
                        </el-tooltip>
                    </div>
                  </template>
                  <template>
                    <div v-for="subItem in item.children" :key="subItem.index" class="submenu">
                      <en-submenu v-if="subItem.children && subItem.children.length > 0" :menus="subItem.children" :navStyles="navStyles" :index="subItem.index" @click.stop.native="clickMenuItem(subItem)">
                        <template slot="title">
                          <div class="textAlign h40">
                            <div class="h18" style="margin-bottom:3px;"><en-icon class="en-icon" style="margin-right:0px" size="20" :name="setChildrenIcon(subItem.icon)"  /></div>
                            <el-tooltip class="item" effect="light" :open-delay="1000" :disabled="subItem?.length < 6" :content="subItem.name" placement="right-end">
                                <div class="h18 font12" v-if="!isCollapse"><span class="en-icon-title">{{ subItem.name > 4 ? subItem.name.slice(0,4)+'...' : subItem.name }}</span></div>
                                <div class="h18 font12" v-if="isCollapse"><span class="en-icon-title">{{ subItem.name.slice(0,2)}}</span></div>
                            </el-tooltip>
                        </div>
                        </template>
                        <en-menu-item v-for="lastItem in subItem.children" :key="lastItem.index"
                        :navStyles="navStyles" :activeId="activeId" :index="lastItem.index" @click.stop.native="clickMenuItem(lastItem)">
                        <div class="textAlign h40">
                        <div class="h18"><en-icon class="en-icon" size="20" style="margin-right:0px" :name="setChildrenIcon(lastItem.icon)" /></div>
                            <el-tooltip class="item" effect="light" :open-delay="1000" :disabled="lastItem?.length < 6" :content="lastItem.name" placement="right-end">
                              <div class="h18 font12" v-if="!isCollapse"> <span class="en-icon-title">{{ lastItem.name> 4 ?lastItem.name.slice(0,4)+'...' : lastItem.name }}</span></div>
                              <div class="h18 font12" v-if="isCollapse"> <span class="en-icon-title">{{ lastItem.name.slice(0,2)}}</span></div>
                            </el-tooltip>
                        </div>
                        </en-menu-item>
                      </en-submenu>
                      <en-menu-item v-else :activeId="activeId"  :navStyles="navStyles" :index="subItem.index" @click.stop.native="clickMenuItem(subItem)">
                        <div class="textAlign h40">
                      <div class="h18" style="margin-bottom:3px;"><en-icon size="20" class="en-icon" style="margin-right:0px" :name="setChildrenIcon(subItem.icon)" /></div>
                            <el-tooltip class="item" effect="light" :open-delay="1000" :disabled="subItem?.length < 6" :content="subItem.name" placement="right-end">
                              <div class="h18 font12" v-if="!isCollapse"> <span class="en-icon-title">{{ subItem.name > 4 ? subItem.name.slice(0,4)+'...' : subItem.name }}</span></div>
                              <div class="h18 font12" v-if="isCollapse"> <span class="en-icon-title">{{ subItem.name.slice(0,2) }}</span></div>
                            </el-tooltip>
                    </div>
                      </en-menu-item>
                    </div>
                  </template>
                </en-submenu>
                <en-menu-item v-else style="text-align: center" :index="item.index"  :navStyles="navStyles" :activeId="activeId" @click.native="clickMenuItem(item)">
                <div class="textAlign h40">
                  <div class="h18" style="margin-bottom:3px;"><en-icon  style="margin-right:0px" :name="item.icon" size="20" /></div>
                              <div class="h18 font12" v-if="!isCollapse"><span slot="title" >{{ item.name.length > 4 ?  item.name.slice(0,4)+'...' : item.name}}</span></div>
                              <div class="h18 font12" v-if="isCollapse"><span slot="title" >{{ item.name.slice(0,2) }}</span></div>
                </div>
                </en-menu-item>
              </span>
            </div>
        </div>

        </en-menu>
        <div class="custom">
            <div
              class="custom-homepage"
              :style="leftIconStyle"
              @click="gotoCustomHomePage"
            >
            <i class="el-icon-setting icon"></i>
        </div>
        <div :class="{'custom-line' : true,
                      'custom-true':isCollapse}" :style="lineStyle"></div>
        <div class="toggle-collapse"  @click="changeIsCollapseTrue">
          <div v-if="isCollapse" :style="rightIconStyle"
          class="item1">
            <en-icon class="en-icon" style="margin-right:0px" name="iconcaidan-zhankai" size="14"></en-icon>
          </div>
          <div v-else :style="rightIconStyle"
          class="item2"><en-icon class="en-icon"  style="margin-right:0px" name="iconcaidan-shouqi" size="14"></en-icon></div>
        </div>
        </div>
        <!-- <PromptIndex :payRollPath="payRollPath" ref="promptIndex"></PromptIndex> -->
      </div>
      <div style='color:#fff;float:left;' v-if='menuChildList.length >= 1 && themeInfo.SubmenuStyles == 2'>
          <el-menu
            :style="[navBorderStyle, navStyle]"
            :class="{ collapse: isCollapse , 'menu' : navStyles === 1,'menu-short': navStyles === 2}"
            text-color="#a9b5c6"
            active-text-color="#fff"
            :unique-opened="true"
            collapse-transition
            :collapse="isCollapse"
            @open="handleOpen"
            @close="handleClose"
            style="width: 320px;padding: 0 14px;"
            >

              <div  class="en-newSubmenuBox">
                <span v-for="(item,index) in menuChildList" :key="index" class="en-newSubmenu"  @click.native="clickMenuItem(item)">
                  <div class="content-item-icon"
                  style="margin-top: 14px;"
                  :style="{backgroundColor: iconColorBg(index)}"
                  >
                   <en-icon  :name="item.icon"></en-icon>
                  </div>
                  <span
                    style="margin-top: 10px;display: block;"
                    slot="title"
                    class="en-icon-title"
                  >
                    {{ item.name }}
                  </span>

                </span>
              </div>
              <!-- <div v-if="navStyles == 2">
                <span v-for="(item,index) in menuChildList" :key="index" class="en-submenu">

                  <el-menu-item  style="text-align: center" :index="item.index"  :navStyles="navStyles" :activeId="activeId" @click.native="clickMenuItem(item)">
                      <div class="textAlign h40">
                        <div class="h18"><en-icon  :name="item.icon" size="12" /></div>
                        <div class="h18 font12 ml-10" v-if="!isCollapse"><span slot="title" >{{ item.name.length > 4 ?  item.name.slice(0,4)+'...' : item.name}}</span></div>
                        <div class="h18 font12 ml-10" v-if="isCollapse"><span slot="title" >{{ item.name.slice(0,2) }}</span></div>
                      </div>
                  </el-menu-item>
                </span>
              </div> -->
          </el-menu>
      </div>
   </div>
</template>
<script>
// import PromptIndex from "@/views/payRoll/user/prompt/index";
import {
  mapMutations,
  createNamespacedHelpers
} from "vuex";
import { menuRouterData, menuClassifyTypeEnum, menuDataTypeEnum } from "@/api/homeMenu/handleMenu";
import { enChart } from "@/api/businessChart";

import enMenuItem from "./src/menu-item.vue";
import enSubmenu from "./src/submenu.vue";
import enMenu from "./src/menu.vue";

const { mapState: mapStateTheme } = createNamespacedHelpers("main");
export default {
  name: "LeftNavMenu",
  components: {
    enMenu,
    enSubmenu,
    enMenuItem
    // PromptIndex
  },
  props: {
    activeId: {
      type: Number,
      default: 1
    },
    navStyles: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      activeIndex: "0",
      menuList: [],
      updateKey: 0,
      // 工资条的路径
      payRollPath: {},
      menuChildList: [],
      chartBgColorList: ["#3E90FE", "#46D48A", "#FFA32C", "#FD635B", "#48BAFF", "#A971F6"]
    };
  },
  watch: {
    dataList: {
      immediate: true,
      handler() {
        this.updateKey++;
        this.updateData(this.dataList);
      }
    },
    $route: {
      handler(val) {
        this.updateActiveMenu(val);
        if (val.path === "/chat") {
          this.isCollapse = true;
        }
      },
      deep: true,
      immediate: true
    },
    SubmenuStyles(newValue, oldValue) {
        // console.log(this.SubmenuStyles);
        // this.menuChildList = [];
    }
  },
  computed: {
    ...mapStateTheme(["themeInfo"]),
    isCollapse() {
        // SubmenuStyles
        // console.log(this.themeInfo, "this.themeInfo.isCollapse");
      return this.themeInfo.isCollapse;
    },
    SubmenuStyles() {
        console.log(this.navStyles, this.themeInfo.SubmenuStyles, "this.themeInfo");
      return this.themeInfo.SubmenuStyles;
    },
    // eslint-disable-next-line vue/return-in-computed-property, consistent-return
    lineStyle() {
      const { NoHaverColor, openCustomColor, themeColor } = this.themeInfo;
        if (openCustomColor) {
          return {
          backgroundColor: `${NoHaverColor }!important`
          };
        }
        if (themeColor === "white") {
          return {
          backgroundColor: "#A9B5C6!important"
          };
        }
          return {
          backgroundColor: `${NoHaverColor }!important`
          };
    },
    leftIconStyle() {
      const { NoHaverColor, openCustomColor, themeColor } = this.themeInfo;
        if (openCustomColor) {
          return {
          color: `${NoHaverColor }!important`
          };
        }
        if (themeColor === "white") {
          return {
            color: "#A9B5C6!important"
          };
        }
          return {
          color: `${NoHaverColor }!important`
          };
    },
    rightIconStyle() {
      const { NoHaverColor, openCustomColor, themeColor } = this.themeInfo;
        if (openCustomColor) {
          return {
          color: `${NoHaverColor }!important`
          };
        }
        if (themeColor === "white") {
          return {
            color: "#A9B5C6!important"
          };
        }
          return {
          color: `${NoHaverColor }!important`
          };
    },
    // eslint-disable-next-line consistent-return, vue/return-in-computed-property
    BackgroundImage() {
      if (this.navStyles === 2) {
        return {
          backgroundColor: "rgb(255, 255, 255) !important;"
          // backgroundImage: "url('../../assets/images/BackgroundImageStyleTwo.png')"
        };
      }
    },
    subStyle() {
      // openCustomColor 自定义
      const {
        themeColor
          } = this.themeInfo;
      return {
          background: themeColor === "write" ? "#FFFFFF!important" : "#232C3D !important"
        };
    },
    navStyle() {
      // openCustomColor 自定义
      const {
          openCustomColor, HaverColor, NoHaverColor, themeStyle, navColor, SelectLabelColor, navStyles
          } = this.themeInfo;
      if (openCustomColor) {
        return {
          // backgroundColor: "none",
          // background: this.active ? themeStyle === 1 ? `rgba($color: ${SelectLabelColor}, $alpha: 0.2) !important` : `${SelectLabelColor } !important` : `${navColor}!important`,
          // color: this.active ? `${HaverColor} !important` : `${NoHaverColor }!important`,
          borderLeftColor: this.active ? `${SelectLabelColor } !important` : ""
        };
      }
      return {
          // background: this.active ? themeStyle === 1 ? `rgba($color: ${SelectLabelColor}, $alpha: 0.2) !important` : `${SelectLabelColor } !important` : `${navColor}!important`,
          borderLeftColor: this.active ? `${SelectLabelColor } !important` : ""
        };
    },
    navBorderStyle() {
      const { openCustomColor, navColor } = this.themeInfo;
      // navStyles
      if (openCustomColor) {
        return {
          borderRightColor: navColor
          // width: this.navStyles === 2 ? "188px" : "124px"
        };
      }
      return {};
    },
    dataList() {
      return this.$store.getters["main/menuList"];
    }
  },

  mounted() {
    this.updateData(this.dataList);
  },
  methods: {
    ...mapMutations("main", ["setMenuData"]),
    iconColorBg(index) {
      if (index <= 6) {
        return this.chartBgColorList[index];
      }
        return this.chartBgColorList[index % 6];

      // return this.chartBgColorList[index];
    },
    changeIsCollapseTrue() {
      this.themeInfo.isCollapse = !this.themeInfo.isCollapse;
    },
    showMenuChildList(item) {
        console.log(item, "item");
        if (item.children) {
            // this.clickMenuItem(item)
            this.menuChildList = item.children;
            // console.log(this.menuChildList.length, "menuChildListmenuChildList");
        } else {
            this.menuChildList = [];
            this.clickMenuItem(item);
        }
    },
    updateActiveMenu(val) {
      let activeIndex = null;
      // eslint-disable-next-line func-names
      const activeFun = function(menu, parentMenu) {
        const data = menu.data;
        if (String(data.refId) === String(val.query.templateId) && val.query.templateId && data.refId) {
          activeIndex = menu.index;
          if (parentMenu) {
            parentMenu.open = true;
          }
        }
        const { path, query } = menuRouterData(data);
        if (path !== val.path) {
          return;
        }
        if (query && val.query) {
          if (query.templateId && query.templateId !== val.query.templateId) {
            return;
          }
        }
        activeIndex = menu.index;
        if (parentMenu) {
          parentMenu.open = true;
        }
      };
      this.menuList.forEach((item1) => {
        if (activeIndex) {
          return;
        }
        activeFun(item1);
        if (!item1.children) {
          return;
        }
        item1.children.forEach((item2) => {
          if (activeIndex) {
            return;
          }
          activeFun(item2, item1);
        });
      });
      if (activeIndex) {
        this.activeIndex = activeIndex;
      } else if (this.activeIndex === "0") {
        this.activeIndex = "";
      }
    },
    // 设置图标
    setChildrenIcon(icon) {
      if (icon.indexOf("bus-board-icon") > -1 || icon.indexOf("bus-ywmb-icon") > -1) {
        return `${icon}_1`;
      }
      return icon;
    },
    /**
     * 点击菜单处理
     */
    async clickMenuItem(item) {
      console.log(item);
      // menuFixedTypeEnum.businessClassify
      const itemData = item.data || item;
      if (itemData?.type === 3 && item.children) {
        if (item.data.refData.hasBoard === "0") {
          const res = await enChart.queryUserConfig({ configKey: `picList-${item.data.id}` });
          if (!res) {
            this.$router.push({
              name: "boardList",
              query: { id: item.data.id }
            });

            // this.$router.push({ path: "/toModelChartUser", query: { templateId: item.data.id, type: 1 } });
            this.$emit("menuClick", itemData);
          } else if (res.value === "1") {
            // 进图表
            this.$router.push({ path: "/toModelChartUser", query: { templateId: item.data.id, type: 1 } });
            this.$emit("menuClick", itemData);
          } else {
            // 2 进列表
            this.$router.push({
              name: "boardList",
              query: { id: item.data.id }
            });
            this.$emit("menuClick", itemData);
          }
        } else {
          this.$router.push({
            name: "boardList",
            query: { id: item.data.id }
          });
          this.$emit("menuClick", itemData);
        }
      }
      if (!item.children) {
        // if (item.data.code === menuFixedTypeEnum.mySalarySheet) {
        //   this.payRollPath = menuRouterData(item.data);
        //   this.$refs.promptIndex.handlePayRoll();
        // } else {
        const { path, query } = menuRouterData(itemData || item);

        if (itemData.icon === "2_18" || itemData.code === "073") {
          // 法律知识库and邮箱
          const routeData = this.$router.resolve({
            path
          });
          window.open(routeData.href, "_blank");
        } else if (itemData.code === "075") {
          // 外部链接 1-在新窗口显示，2-在当前窗口显示
          if (itemData.outType === 1) {
              window.open(itemData.outUrl);
          } else {
              window.location.href = itemData.outUrl;
          }
        } else if (path.length > 0) {
          this.$router.push({
            path,
            query
          });
          this.$emit("menuClick", itemData);
        }
        // }
      }
    },
    /**
     * 定义菜单和首页布局
     */
    gotoCustomHomePage() {
      this.$router.push({ path: "/home/define" });
    },
    handleOpen(e) {
      console.log(e);
    },
    handleClose(e) {
      console.log(e);
    },
    /**
     * 处理菜单数据
     * @param menus
     */
    updateData(menus) {
      const menuList = [];
      menus.forEach((item, index) => {
        if (item.isShow !== 0) {
          return;
        }
        const data = {
          data: item,
          name: item.name,
          open: false
        };
        data.icon = item.customIcon;
        data.iconColor = item.customColor;
        data.index = `${index}`;
        // 首页、看板、我的事项、帮助中心,报表查询不展示子项特殊处理
        if (
          // eslint-disable-next-line no-undef
          [menuClassifyTypeEnum.home, menuClassifyTypeEnum.kanBan, menuClassifyTypeEnum.myMatter, menuClassifyTypeEnum.helpCenter, menuClassifyTypeEnum.report, menuClassifyTypeEnum.lawAngel].indexOf(
            item.customType
          ) === -1
        ) {
          // 隐藏没子数据的分类
          if (!item.dataList || item?.dataList?.length === 0) {
            return;
          }
          // 隐藏设置了不展示的分类
          if (item?.isShow === 1) {
            return;
          }
          const children = [];
          item?.dataList?.forEach((item2, index2) => {
            if (item2.dataType === menuDataTypeEnum.kanBanClassify) {
              return;
            }
            // 隐藏设置了不展示的子类
            if (item2?.isShow === 1) {
              return;
            }
            const data1 = {
              data: item2,
              name: item2.name
            };
            data1.index = `${index}-${index2}`;
            data1.icon = item2.customIcon;
            data1.iconColor = item2.customColor;
            children.push(data1);
          });
          data.children = children;
          menuList.push(data);
        } else {
          menuList.push(data);
        }
      });
      this.menuList = menuList;
    }
  }
};
</script>
<style lang="scss">
// 原来是向右侧方向的箭头
//  默认方向
.el-icon-arrow-down {
  transform: rotate(-90deg);
}
// 点击后方向
.el-submenu.is-opened>.el-submenu__title .el-submenu__icon-arrow {
  transform: rotate(0deg);
}
.el-menu-item:focus{
  background-color: rgba($color: #ffffff, $alpha: 0.2)
}
.el-menu-item:hover{
  background-color: rgba($color: #ffffff, $alpha: 0.2)
}
.el-submenu__title i{
  // color:#d0d2d4 !important;
}
.el-menu.el-menu--popup {
  // background: #232c3d;
}
.shortFix{
    top:61px !important;
    height:100% ;
}
.shortFix .popup-menus{
    background-color:unset;
    box-shadow: none;
}
</style>
<style lang="scss" scoped>
.el-menu-item{
  height: 48px;
  line-height: 48px;
  position: relative;
  -webkit-box-sizing: border-box;
  white-space: nowrap;
  list-style: none;
}
.en-submenuStyle{
  margin-top:14px;
  display: block;
}
.en-submenuStyleTwo{
  margin-top:8px;
  display: block;
}
.en-newSubmenuBox {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  text-align: center;
}
.en-newSubmenu {
  width: 92px;
  min-height: 86px;
  display: block;
  margin: 0 5px 5px 0;
  // flex: 1 1 9%;
}
.content-item-icon {
  background-color: #f76b6b;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 17px; */
}
.content-item-icon .en-icon {
  width: 18px;
  height: 18px;
  color: white;
  border-radius: 3px;
  margin-right: 0px;
}

.h18{
  height: 18px !important;
}
.textAlign{
  text-align: center !important;
}
.h40{
  height:40px !important;
}
.ml-10{
  margin-left: -10px !important;
}
.font12{
  font-size: 12px !important;
}
/deep/ .el-submenu .el-menu-item {
  width: 188px;
  line-height: 40px;
  height: 40px;
  :hover{
    background-color: rgba($color: #ffffff, $alpha: 0.2)
  }
  &.is-active {
    // background-color: rgba(117, 131, 154, 0.2) !important;
    &.is-opened {
      background: #454f64 !important;
    }
  }
}

/deep/ .el-menu--vertical {
  top: 0 !important;
  background: #232c3d !important;
}

/deep/ .el-icon-arrow-right.icon + .en-icon-title + .el-submenu__icon-arrow.el-icon-arrow-right {
  display: none;
}
.en-iconNew {
  margin-right: 12px;
  display: inline-block;
  vertical-align: middle;
}

.en-icon {
  display: inline-block;
  vertical-align: middle;
}
.margin-isCollapse{
  margin-right: 0px;
}
.en-icon-title {
  margin-left: 12px;
  display: inline-block;
  vertical-align: middle !important;
}

.menu-container {
  position: relative;
  height: 100%;
  transition: all 0.2s;
  // background-image: url('../../assets/images/BackgroundImageStyleTwo.png');
  // 长菜单
  /deep/ .el-menu{
    background-color:unset;
    border:none
  }
  .menu {
    // background: #232c3d;
    height: calc(100% - 40px);
    font-weight: 400;
    user-select: none;
    width: 188px;
    // display: inline-block;
    text-align: left;
    position: relative;
    transition: all 0.1s linear;
    overflow: auto;

    &.collapse {
      width: 80px;
      transition: all 0.1s linear;
    }

    &::-webkit-scrollbar {
      /*隐藏滚轮*/
      display: none;
    }

    /deep/ .el-submenu {
      // background: #232c3d;

      .el-submenu__title{
        height: 48px;
        line-height: 48px;
        &:hover {
            &.is-1 {
            border-left: 5px solid #3e90fe;
          }
          &.is-2 {
          margin:0 6px;
          }
          &.is-3 {
            margin:0 6px;
            height: 40px;
            line-height: 40px;
            border-radius: 20px;
          }
          &.is-4 {
            margin:0 6px;
            height: 40px;
            line-height: 40px;
            border-radius: 4px;
          }
          &.is-5 {
            margin-left:8px;
            height: 40px;
            line-height: 40px;
            border-radius: 20px 0 0 20px;
          }
          &.is-6 {
            margin-left:8px;
            height: 40px;
            line-height: 40px;
            border-radius: 20px 20px 0 20px;
          }
        }
      }
      &.is-opened {
        background: #2a3345;

        .el-menu-item {
          background: #2a3345;
          &:hover {
              background: #394356;
              &.is-1 {
                border-left: 5px solid #3e90fe;
              }
              &.is-2 {
              margin:0 6px;
              }
              &.is-3 {
                margin:0 6px;
                height: 40px;
                line-height: 40px;
                border-radius: 20px;
              }
              &.is-4 {
                margin:0 6px;
                height: 40px;
                line-height: 40px;
                border-radius: 4px;
              }
              &.is-5 {
                margin-left:8px;
                height: 40px;
                line-height: 40px;
                border-radius: 20px 0 0 20px;
              }
              &.is-6 {
                margin-left:8px;
                height: 40px;
                line-height: 40px;
                border-radius: 20px 20px 0 20px;
              }
            }
          &.is-active {
            background: #394356;
            color:#A9B5C6
          }
        }
      }
    }

    /deep/ .el-menu-item {
      // background: #232c3d;

      // &.is-active {
      //   background: #394356;
      //   border-left: 2px solid #3e90fe;
      // }
      &.is-active-1 {
        background: #fff !important;
        border-left: 5px solid #3e90fe;
      }
      &.is-active-2 {
        margin: 0 6px;
        background: #fff !important;
      }
      &.is-active-3 {
        margin:0 6px;
        height: 40px;
        line-height: 40px;
        background: #fff !important;
        border-radius: 20px;
      }
      &.is-active-4 {
        margin:0 6px;
        // background: #fff !important;
        height: 40px;
        line-height: 40px;
        border-radius: 4px;
      }
      &.is-active-5 {
        margin-left: 8px;
        background: #fff !important;
        height: 40px;
        line-height: 40px;
        border-radius: 20px 0 0 20px;
      }
      &.is-active-6 {
        margin-left: 8px;
        background: #fff !important;
        height: 40px;
        line-height: 40px;
        border-radius: 20px 20px 0 20px;
      }
      &.is-active-1-two {
        background: #fff !important;
        border-left: 5px solid #3e90fe;
      }
      &.is-active-2-two {
        margin: 0 6px;
        background: #fff !important;
      }
      &.is-active-3-two {
        margin: 0 6px;
        height: 40px;
        line-height: 40px;
        background: #fff !important;
        border-radius: 20px;
      }
      &.is-active-4-two {
        margin: 0 6px;
        background: #fff !important;
        height: 40px;
        line-height: 40px;
        border-radius: 4px;
      }
      &.is-active-5-two {
        margin-left: 8px;
        background: #fff !important;
        height: 40px;
        line-height: 40px;
        border-radius: 20px 0 0 20px;
      }
      &.is-active-6-two {
        margin-left:  8px;
        background: #fff !important;
        height: 40px;
        line-height: 40px;
        border-radius: 20px 20px 0 20px;
      }
      &:hover {
        &.is-1 {
        border-left: 5px solid #3e90fe;
      }
      &.is-2 {
        margin:0 6px;
      }
      &.is-3 {
        margin:0 6px;
        height: 40px;
        line-height: 40px;
        border-radius: 20px;
      }
      &.is-4 {
        margin:0 6px;
        height: 40px;
        line-height: 40px;
        border-radius: 4px;
      }
      &.is-5 {
        margin-left: 8px;
        height: 40px;
        line-height: 40px;
        border-radius: 20px 0 0 20px;
      }
      &.is-6 {
        margin-left: 8px;
        height: 40px;
        line-height: 40px;
        border-radius: 20px 20px 0 20px;
      }
      &.is-1-two {
        border-left: 5px solid #3e90fe;
        line-height:34px !important;
      }
      &.is-2-two {
        margin: 0 6px;
        line-height:34px !important;
      }
      &.is-3-two {
        margin: 0 6px;
        height: 40px;
        line-height:34px !important;
        border-radius: 20px;
      }
      &.is-4-two {
        margin: 0 6px;
        height: 40px;
        line-height:34px !important;
        border-radius: 4px;
      }
      &.is-5-two {
        margin-left: 8px;
        height: 40px;
        line-height:34px !important;
        border-radius: 20px 0 0 20px;
      }
      &.is-6-two {
        margin-left: 8px;
        height: 40px;
        line-height:34px !important;
        border-radius: 20px 20px 0 20px;
      }
     }
    }
  }
  // 短菜单
  .menu-short {
    // background: #232c3d;
    height: calc(100% - 40px);
    font-weight: 400;
    user-select: none;
    width: 124px;
    // display: inline-block;
    position: relative;
    transition: all 0.1s linear;
    overflow: auto;

    &.collapse {
      width: 80px;
      transition: all 0.1s linear;
    }

    &::-webkit-scrollbar {
      /*隐藏滚轮*/
      display: none;
    }

    /deep/ .el-submenu {
      // background: #232c3d;

  .el-submenu__title{
    &:hover {
        &.is-1 {
        border-left: 5px solid #3e90fe;
      }
      &.is-2 {
      }
      &.is-3 {
        margin:0 6px;
        height: 40px;
        line-height: 40px;
        border-radius: 20px;
      }
      &.is-4 {
        margin:0 6px;
        height: 40px;
        line-height: 40px;
        border-radius: 4px;
      }
      &.is-5 {
        margin-left: 8px;
        height: 40px;
        line-height: 40px;
        border-radius: 20px 0 0 20px;
      }
      &.is-6 {
        margin-left: 8px;
        height: 40px;
        line-height: 40px;
        border-radius: 20px 20px 0 20px;
      }
     }
  }

      &.is-opened {
        background: #2a3345;

        .el-menu-item {
          background: #2a3345;

          &.is-active {
            background: #394356;
          }
        }
      }
    }

    /deep/ .el-menu-item {
    //  background: #232c3d;

      // &.is-active {
      //   background: #394356;
      //   border-left: 2px solid #3e90fe;
      // }
      &.is-active-1 {
        background: #fff !important;
        border-left: 5px solid #3e90fe;
      }
      &.is-active-2 {
        margin: 0 6px;
        background: #fff !important;
      }
      &.is-active-3 {
        margin: 0 6px;
        height: 40px;
        line-height: 40px;
        background: #fff !important;
        border-radius: 20px;
      }
      &.is-active-4 {
        margin: 0 6px;
        // background: #fff !important;
        height: 40px;
        line-height: 40px;
        border-radius: 4px;
      }
      &.is-active-5 {
        margin-left: 8px;
        background: #fff !important;
        height: 40px;
        line-height: 40px;
        border-radius: 20px 0 0 20px;
      }
      &.is-active-6 {
        margin-left: 8px;
        background: #fff !important;
        height: 40px;
        line-height: 40px;
        border-radius: 20px 20px 0 20px;
      }
      &.is-active-1-two {
        background: #fff !important;
        border-left: 5px solid #3e90fe;
      }
      &.is-active-2-two {
        margin: 0 6px;
        background: #fff !important;
      }
      &.is-active-3-two {
        margin: 0 6px;
        height: 40px;
        line-height: 40px;
        background: #fff !important;
        border-radius: 20px;
      }
      &.is-active-4-two {
        margin: 0 6px;
        background: #fff !important;
        height: 40px;
        line-height: 40px;
        border-radius: 4px;
      }
      &.is-active-5-two {
        margin-left: 8px;
        background: #fff !important;
        height: 40px;
        line-height: 40px;
        border-radius: 20px 0 0 20px;
      }
      &.is-active-6-two {
        margin-left: 8px;
        background: #fff !important;
        height: 40px;
        line-height: 40px;
        border-radius: 20px 20px 0 20px;
      }
      &:hover {
        &.is-1-two {
        border-left: 5px solid #3e90fe;
      }
      &.is-2-two {
        margin: 0 6px;
      }
      &.is-3-two{
        margin: 0 6px;
        height: 40px;
        line-height: 40px;
        border-radius: 20px;
      }
      &.is-4-two {
        margin: 0 6px;
        height: 40px;
        line-height: 40px;
        border-radius: 4px;
      }
      &.is-5-two {
        margin-left: 8px;
        height: 40px;
        line-height: 40px;
        border-radius: 20px 0 0 20px;
      }
      &.is-6-two {
        margin-left: 8px;
        height: 40px;
        line-height: 40px;
        border-radius: 20px 20px 0 20px;
      }
     }
    }
  }
  .custom{
    display:flex;
    justify-content:space-between;
    background:rgba(255,255,255,0.1);
    align-items:center;
    height: 40px !important;
    line-height:40px;
    .custom-homepage {
    flex:1;
    display:flex;
    justify-content:center;
    align-items:center;
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    cursor: pointer;
    .icon {
      height: 17px;
      width: 17px;
      font-size: 16px;
      vertical-align: middle;
    }

    .desc {
      margin-left: 10px;
      display: inline-block;
    }
  }
  .custom-line{
    width: 1px;
    height: 16px;
    background: #283245;
  }
  .custom-true{
    width: 2px;
    height: 16px;
    transform: scaleX(0.5);
    background: #283245;
  }
  .toggle-collapse {
    flex:1;
    height: 40px;
    text-align: center;
    cursor: pointer;
    display:flex;
    justify-content:center;
    align-items:center;
    .item1 {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      margin-top:-5px;
      color: rgba(255, 255, 255, 1);
      line-height: 40px;
    }
    .item2 {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      margin-top:-5px;
      color: rgba(255, 255, 255, 1);
      line-height: 40px;
    }
  }
  }
}
</style>
