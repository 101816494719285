<!-- eslint-disable vue/no-parsing-error -->
<template>
  <li
    class="el-menu-item"
    role="menuitem"
    tabindex="-1"
    :class="{
      'is-active-1': active&&activeId==1&&navStyles!= 2,
      'is-active-2': active&&activeId==2&&navStyles!= 2,
      'is-active-3': active&&activeId==3&&navStyles!= 2,
      'is-active-4': active&&activeId==4&&navStyles!= 2,
      'is-active-5': active&&activeId==5&&navStyles!= 2,
      'is-active-6': active&&activeId==6&&navStyles!= 2,
      'is-1': activeId==1&&navStyles!= 2,
      'is-2': activeId==2&&navStyles!= 2,
      'is-3': activeId==3&&navStyles!= 2,
      'is-4': activeId==4&&navStyles!= 2,
      'is-5': activeId==5&&navStyles!= 2,
      'is-6': activeId==6&&navStyles!= 2,
      // 布局二 额外处理
      'is-active-1-two': active&&activeId==1&&navStyles== 2,
      'is-active-2-two': active&&activeId==2&&navStyles== 2,
      'is-active-3-two': active&&activeId==3&&navStyles== 2,
      'is-active-4-two': active&&activeId==4&&navStyles== 2,
      'is-active-5-two': active&&activeId==5&&navStyles== 2,
      'is-active-6-two': active&&activeId==6&&navStyles== 2,
      'is-1-two': activeId==1&&navStyles== 2,
      'is-2-two': activeId==2&&navStyles== 2,
      'is-3-two': activeId==3&&navStyles== 2,
      'is-4-two': activeId==4&&navStyles== 2,
      'is-5-two': activeId==5&&navStyles== 2,
      'is-6-two': activeId==6&&navStyles== 2,
      'is-disabled': disabled,
      'is-collapseStyle': themeInfo.isCollapse,
      'is-active-custom': active && activeId!==7 || active && navStyles== 2
    }"
    :style="[paddingStyle, itemStyle, { backgroundColor, ...navStyle }]"
    @click="handleClick"
    @mouseenter="onMouseEnter"
    @focus="onMouseEnter"
    @blur="onMouseLeave"
    @mouseleave="onMouseLeave"
  >
  <div>
    <el-tooltip
      v-if="parentMenu.$options.componentName === 'ElMenu' && rootMenu.collapse && $slots.title"
      effect="dark"
      placement="right"
    >
      <div slot="content">
        <slot name="title"></slot>
      </div>
      <div style="{itemOpenStyle}">
        <slot></slot>
      </div>
    </el-tooltip>
    <template v-else>
      <slot></slot>
      <slot name="title"></slot>
    </template>
  </div>
  </li>
</template>
<script>
import Emitter from "element-ui/src/mixins/emitter";
import {
  createNamespacedHelpers
} from "vuex";
import Menu from "./menu-mixin";

const { mapState: mapStateTheme } = createNamespacedHelpers("main");

export default {
  name: "ElMenuItem",
  componentName: "ElMenuItem",
  mixins: [Menu, Emitter],
  props: {
    index: {
      default: null,
      validator: (val) => typeof val === "string" || val === null
    },
    navStyles: {
      type: Number,
      default: 1
    },
    activeId: Number,
    route: [String, Object],
    disabled: Boolean
  },
  computed: {
    ...mapStateTheme(["themeInfo"]),
    navStyle() {
      const {
          openCustomColor, navColor, themeColor, NoHaverColor, HaverColor, isCollapse, SelectLabelColor, themeStyle
      } = this.themeInfo;
      console.log("navStyles", this.navStyles);
      if (openCustomColor) {
        // console.log("this.rgbaToHex(SelectLabelColor)", this.rgbaToHex(SelectLabelColor));
        return {
          // eslint-disable-next-line no-undef
          // backgroundColor: this.active ? themeStyle === 1 ? `${SelectLabelColor } !important` : `${SelectLabelColor } !important` : `${navColor}!important`,
          // color: this.active ? `${HaverColor} !important` : `${NoHaverColor }!important`,
          lineHeight: this.navStyles === 2 ? isCollapse ? "20px" : "40px" : "40px",
          height: this.navStyles === 2 ? "64px" : "40px",
          fontSize: "14px",
          // display: "flex",
          // alignItems: "center",
          // justifyContent: this.navStyles === 2 ? "center !important" : "",
          borderLeftColor: this.active ? `${SelectLabelColor } !important` : "#FFFFFF"
          // eslint-disable-next-line no-dupe-keys
        };
      }
      return {
          // backgroundColor: this.active ? themeStyle === 1 ? `${SelectLabelColor } !important` : `${SelectLabelColor } !important` : `${navColor}!important`,
          borderLeftColor: this.active ? `${SelectLabelColor } !important` : "#FFFFFF",
          fontSize: "14px",
          lineHeight: this.navStyles === 2 ? isCollapse ? "20px" : "40px" : "40px",
          height: this.navStyles === 2 ? "64px" : "40px"
          // color: this.active ? `${HaverColor} !important` : `${NoHaverColor }!important`
          // display: "flex",
          // alignItems: "center",
          // justifyContent: this.navStyles === 2 ? "center !important" : ""
        };
    },
    active() {
      return this.index === this.rootMenu.activeIndex;
    },
    hoverBackground() {
      return this.rootMenu.hoverBackground;
    },
    backgroundColor() {
      return this.rootMenu.backgroundColor || "";
    },
    activeTextColor() {
      return this.rootMenu.activeTextColor || "";
    },
    textColor() {
      return this.rootMenu.textColor || "";
    },
    mode() {
      return this.rootMenu.mode;
    },
    itemOpenStyle() {
      if (!this.themeInfo.isCollapse) {
        return `
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 0 20px;
        `;
      }
      return `
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      display: inline-block;
      box-sizing: border-box;
      padding: 0 20px;
      `;
    },
    itemStyle() {
      if (this.active) return;
      if (this.activeId === 2 || this.activeId === 3 || this.activeId === 4) {
        // eslint-disable-next-line consistent-return
        return {
          margin: "0 6px"
        };
      } if (this.activeId === 5 || this.activeId === 6) {
        // eslint-disable-next-line consistent-return
        return {
          marginLeft: "8px"
        };
      }
      // eslint-disable-next-line consistent-return
      return { margin: "0 4px" };
    },
    isNested() {
      return this.parentMenu !== this.rootMenu;
    }
  },
  mounted() {
    this.parentMenu.addItem(this);
    this.rootMenu.addItem(this);
  },
  beforeDestroy() {
    this.parentMenu.removeItem(this);
    this.rootMenu.removeItem(this);
  },
  methods: {
    onMouseEnter() {
      if ((this.mode === "horizontal" && !this.rootMenu.backgroundColor) || this.themeInfo.openCustomColor) {
        return;
      }
      this.$el.style.backgroundColor = this.hoverBackground;
    },
    onMouseLeave() {
      if ((this.mode === "horizontal" && !this.rootMenu.backgroundColor) || this.themeInfo.openCustomColor) {
        return;
      }
      this.$el.style.backgroundColor = this.backgroundColor;
    },
    handleClick() {
      if (!this.disabled) {
        this.dispatch("ElMenu", "item-click", this);
        this.$emit("click", this);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.el-menu-item{
  .el-submenu__title {
  position: relative;
  white-space: nowrap;
  list-style: none;
}
}
.is-collapseStyle{
  display:flex;
  justify-content: center;
  align-items: center;
}
</style>
