export default {
  // 保存业务建模数据
  saveBusinessData(state, payload) {
    state.businessData = payload;
  },
  // 删除业务建模数据
  deleteBusinessData(state) {
    state.businessData = null;
  },
  //  保存待提交的业务建模数据
  saveSkyEyeBusinessData(state, payload) {
    state.skyEyeBusinessData = payload;
  },
  // 缓存pageOffice数据
  savePageOfficeData(state, payload) {
    state.pageOfficeData = payload;
  },
  // 保存日期全局变量
  saveDateVariables(state, payload) {
    state.dateVariables = payload;
  }
};
