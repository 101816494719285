import BaseService from "../BaseService";

class PublicService extends BaseService {
  pk = ""

  // cache = new Map()

  constructor(http) {
    super("", http);
  }

  /**
   * 获取urlParam参数
   * @returns {Promise<*|{}>}
   */
  async getUploadUrl() {
    // if (this.cache.get("uploadUrl")) {
    //   return this.cache.get("uploadUrl");
    // }
    const { http } = this;
    const res = await http.post("/queryUpladUrlParam");
    const data = res || {};
    data.baseUrl = data.server.substring(0, data.server.lastIndexOf("/"));
    // this.cache.set("uploadUrl", data);
    return data;
  }
}

export default PublicService;
