/*
 * @Author: zhulin
 * @Date: 2020-07-24 14:38:17
 * @LastEditors: zhulin
 * @LastEditTime: 2021-05-14 14:35:55
 * @Description: 业务建模流程和日志相关接口
 * @FilePath: \user\src\api\businessModel\flowAndLog.js
 */

import BaseService from "../BaseService";

class FlowAndLogService extends BaseService {
  /**
   * @description 查询查看流程数据
   * @param params
   */
  queryFlowDetail(params) {
    const { http } = this;
    return http.post("wfQueryFlowDetail", params);
  }

  /**
   * @description 查询查看流程分支数据
   * @param params
   */
  queryFlowBranchDetail(params) {
    const { http } = this;
    return http.post("wfQueryFlowBranchDetail", params);
  }

  /**
   * @description 提交补充意见
   * @param params
   */
  addSuppleApproval(params) {
    const { http } = this;
    return http.post("flAddSuppleApproval", params);
  }

  /**
   * @description 查询查看日志数据
   * @param params
   */
  querymdLogList(params) {
    const { http } = this;
    return http.post("mdLogList", params);
  }

  /**
   * @description 查询通知回执数据
   * @param params
   */
  queryReceiptList(params) {
    const { http } = this;
    return http.post("queryReceiptList", params);
  }

  /**
   * @description 查询规则用户端日志
   * @param params
   */
  queryRuleUserLog(params) {
    const { http } = this;
    return http.post("arRuleUserLog", params);
  }
}

export default FlowAndLogService;
