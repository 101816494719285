/*
 * @Description:
 * @Author: 梁平贤
 * @Date: 2020-06-20 11:57:40
 * @LastEditors: 彭博
 * @LastEditTime: 2020-08-17 14:50:24
 */

const routes = [
  {
    path: "/example",
    redirect: "/example/normal"
  },
  {
    path: "/example/demo1",
    component: () => import(/* webpackChunkName: "example" */ "@/views/example/Demo1"),
    meta: {
      title: "例子1",
      fullPage: false,
      keepAlive: true
    }
  },
  {
    path: "/example/entry",
    name: "exampleEntry",
    component: () => import(/* webpackChunkName: "example" */ "@/views/example/TestEntry.vue"),
    meta: {
      title: "例子1",
      fullPage: false,
      keepAlive: false
    }
  },
  {
    path: "/example/select",
    name: "exampleSelect",
    component: () => import(/* webpackChunkName: "example" */ "@/views/example/selectDemo.vue"),
    meta: {
      title: "单选、多选",
      fullPage: false,
      keepAlive: false
    }
  },
  {
    path: "/example/table",
    name: "exampleTable",
    component: () => import(/* webpackChunkName: "example" */ "@/views/example/tableDemo.vue"),
    meta: {
      title: "基础列表",
      fullPage: false,
      keepAlive: false
    }
  }
];

export default routes;
