const routes = [
  {
    path: "/",
    redirect: "/home"
  },
  {
    path: "/home",
    name: "homeCommon",
    component: () => import(/* webpackChunkName: "main" */ "@/views/main/home/homeCommon"),
    meta: {
      title: "home",
      keepAlive: true,
      fullPage: false
    }
  }
];

export default routes;
