import BaseService from "../BaseService";

/**
 * login相关
 */
class HotelService extends BaseService {
  pk = ""

  constructor(http) {
    super("", http);
  }

  /**
   * 查询酒店数据
   * @param params 参数
   */
  queryTripHotelList(params) {
    // const { http } = this;
    return this.http.post("tripHotelList.app", params);
  }

  /**
   * 查询地点
   * @param params 参数
   */
  queryLocationTips(params) {
    // const { http } = this;
    return this.http.post("searchTips.app", params);
  }

  /**
   * 查询商圈
   * @param params 参数
   */
  queryTripGetPosition(params) {
    // const { http } = this;
    return this.http.post("tripGetPosition.app", params);
  }

  /**
   * 查询品牌酒店
   * @param params 参数
   */
  queryTripGetBrand(params) {
    // const { http } = this;
    return this.http.post("tripGetBrand.app", params);
  }

  /**
   * 查询酒店详细信息
   * @param params 参数
   */
  queryHotelDetail(params) {
    // const { http } = this;
    return this.http.post("tripHotelDetail.app", params);
  }

  /**
   * 查询酒店房型详细信息
   * @param params 参数
   */
  queryHotelRatePlan(params) {
    // const { http } = this;
    return this.http.post("tripHotelRatePlan.app", params);
  }

  /**
   * 超标检测
   * @param params 参数
   */
  queryCheckSupplement(params) {
    // const { http } = this;
    return this.http.post("checkSupplement.app", params);
  }

  /**
   * 酒店新增节点
   * @param params 参数
   */
  querySaveTripNode(params) {
    // const { http } = this;
    return this.http.post("saveTripNode.app", params);
  }
}

export default HotelService;
