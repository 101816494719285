var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "en-loading",
          rawName: "v-en-loading",
          value: _vm.loadingConfig,
          expression: "loadingConfig",
        },
      ],
      staticClass: "micro-full-page-container",
    },
    [
      _c("div", {
        staticClass: "container",
        domProps: { innerHTML: _vm._s(_vm.content) },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }