const routes = [
  {
    path: "/payroll/paystub",
    name: "paystub",
    component: () => import(/* webpackChunkName:"payroll" */ "@/views/payRoll/user/paystub/index"),
    meta: {
      title: "工资条",
      keepAlive: false,
      fullPage: false
    }
  }
];

export default routes;
