/**
 * project: user
 * fileName: methods
 * Created by 徐俊 on 2020/08/25
 * Copyright © 2020 Enfry Ltd. All rights reserved.
 */
/* eslint-disable */
import { initNimSDK } from "./actions/initNimSDK"; // 循环引用
import { insertLocalSession, deleteSession, setCurrSession, resetCurrSession,onUpdateSession,setFilterSessions } from "./actions/session";
import { resetSearchResult, searchUsers, searchTeam } from "./actions/search";
import { sendMsg, resendMsg, sendFileMsg,getHistoryMsgs } from './actions/msg'
import {onTeamNotificationMsg,delegateTeamFunction } from './actions/team'
export default {

  // 连接sdk请求，false表示强制重连
  connect(store, userInfo) {
    if (window.nim) {
      return;
    }
    const userId = userInfo?.imUserId;
    if(userId!==store.state.currSessionLastMsg?.imUserId){
      store.commit("updateCurrSessionMsgs", {
        type: "destroy"
      });
    }
    console.log(userId)
    if (!userId) {
      console.log("跳转到登录页面");
      return;
    }
    initNimSDK(store, userId);
  },

  // 用户触发的登出逻辑
  disconnect({ commit }) {
    
    if (window.nim) {
      window.nim.disconnect();
    }
    commit("updateUserId", null);
    commit('deleteAllSessions');
    
    
  },

  insertLocalSession,
  deleteSession,
  onUpdateSession,
  setCurrSession,
  setFilterSessions,
  resetCurrSession({ commit }) {
    
    if (window.nim) {
      window.nim.disconnect();
    }
    commit("updateUserId", null);
    commit('deleteAllSessions');
    
  },

  resetSearchResult,
  searchUsers,
  searchTeam,
  onTeamNotificationMsg,
  delegateTeamFunction,
  //消息
  sendMsg,
  resendMsg,
  sendFileMsg,
  getHistoryMsgs,
};
