<template>
  <div class="prompt-dialog">
    <changePassword   :userInfo='userInfo' :visible.sync="showChangePassword" @closeChangePassword="getCloseChangePassword"></changePassword>

    <div >
      <div  class="header-title">
        <span class="prompt-title">提示</span>
        <span class="prompt-info">（工资条涉及个人隐私，请验证身份）</span>
      </div>
      <div class="dialog_content">
        <el-form label-width="45px" :rules="rules" ref="ruleForm" :model="form" @submit.native.prevent>
          <div class="pwd_input">
            <el-form-item label="密码" prop="input" >
              <el-input v-model="form.input" placeholder="请输入密码" autofocus :type="inputtype" @keyup.enter.native="inputEnter"> </el-input>
              <en-icon class="eyes" :name="iconImg" style="color:#A8B6C6" size="small" @click.native="changeState"> </en-icon>
            </el-form-item>
          </div>
        </el-form>
        <a href="javascript:void(0)" class="forgot_pwd" @click="changePassword">忘记密码？</a>
      </div>
      <span  class="dialog-footer">
        <en-button type="primary" @click="confirm('ruleForm')">确 定</en-button>
      </span>
    </div>
    <!-- <el-dialog class="prompt-dialog" title="" :visible.sync="showPrompt" width="700px" :before-close="handleClose" :close-on-click-modal="false"> -->

    <!-- </el-dialog> -->
  </div>
</template>

<script>
import { payRollApi } from "@/api/payRoll";
import ChangePassword from "./changePassword";

export default {
  name: "Prompt",
  components: { ChangePassword },
  props: ["showPrompt", "payRollId", "userInfo"],
  data() {
    const input = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    return {
      inputtype: "password",
      iconImg: "bi-denglu",
      form: {
        input: ""
      },
      rules: {
        input: [
          {
            required: true,
            validator: input,
            trigger: "blur"
          }
        ]
      },
      btnIndex: 0,
      showChangePassword: false
    };
  },
  computed: {},
  methods: {
    handleClose() {
      this.$emit("closePrompt", false);
    },
    inputEnter() {
      this.confirm("ruleForm");
    },
    confirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.checkPayPwd();
        }
      });
    },
    async checkPayPwd() {
      const resp = await payRollApi.checkPaySlip({ checkValue: this.form.input });
      if (resp.result === 0) {
        // 身份校验成功
        // this
        this.$emit("promptCallBack", true);
      } else {
        // 身份校验失败
        this.btnIndex++;
        if (this.btnIndex >= 3) {
          if (this.payRollId) {
            // 锁定对应的工资条
            // await payRollApi.updatePaySlip(this.payRollId);
          }
          this.$emit("promptCallBack", false);
        } else {
          this.$message({
            showClose: false,
            message: "连续输入错误密码三次后将自动退出登录",
            type: "error"
          });
        }
      }
    },
    changeState() {
      if (this.iconImg === "bi-denglu") {
        this.iconImg = "kai-denglu";
        this.inputtype = "text";
      } else {
        this.iconImg = "bi-denglu";
        this.inputtype = "password";
      }
    },
    changePassword() {
      this.showChangePassword = true;
    },
    getCloseChangePassword() {
      this.showChangePassword = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.prompt-dialog .el-dialog /deep/ .el-dialog__body {
  text-align: left;
}
.el-form-item__error {
  line-height: 30px !important;
  z-index: 101;
}
.dialog_content {
  overflow: hidden;
  padding: 0 20px;
  & /deep/ .el-form-item__label{
    padding-right: 10px;
  }
}
.header-title {
    color: #1A1C1E;
    font-size:14px;
    line-height: 24px;
    font-size: 14px;
    font-weight: 700;
    padding: 20px 20px 0;
  .prompt-title {
    font-size: 14px;
    color: #333333;
  }
  .prompt-info {
    color: #b9c4d2;
    font-size: 12px;
  }
}
.pwd_input {
  position: relative;
  .el-input {
    width: 100%;
  }
  .eyes {
    cursor: pointer;
    position: absolute;
    top: 7px;
    right: 10px;
  }
}
.forgot_pwd {
  text-decoration: none;
  color: #3e90fe;
  float: right;
  margin: 0 0 20px 0;
  overflow: hidden;
  font-size: 12px;
}
.dialog-footer{
  padding-right: 20px;
  text-align: right;
  display: inline-block;
  width: 100%;
}
</style>
