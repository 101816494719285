const routes = [
  {
    path: "/setting",
    redirect: "/setting/basic",
    component: () => import(/* webpackChunkName:"setting" */ "@/views/setting/index"),
    children: [
      {
        path: "/setting/basic",
        name: "basicSetting",
        component: () => import(/* webpackChunkName:"setting" */ "@/views/setting/basicSetting"),
        meta: {
          title: "基本信息",
          keepAlive: true,
          pageLayout: 1,
          fullPage: false
        }
      },
      {
        path: "/setting/preference",
        component: () => import(/* webpackChunkName:"setting" */ "@/views/setting/preferenceSetting"),
        meta: {
          title: "偏好管理",
          keepAlive: true,
          pageLayout: 1,
          fullPage: false
        }
      },
      {
        path: "/setting/authorize",
        component: () => import(/* webpackChunkName:"setting" */ "@/views/setting/authorizeSetting"),
        meta: {
          title: "授权管理",
          keepAlive: true,
          pageLayout: 1,
          fullPage: false
        }
      },
      {
        path: "/setting/security",
        component: () => import(/* webpackChunkName:"setting" */ "@/views/setting/securitySetting"),
        meta: {
          title: "安全设置",
          keepAlive: true,
          pageLayout: 1,
          fullPage: false
        }
      },
      {
        path: "/setting/organization",
        component: () => import(/* webpackChunkName:"setting" */ "@/views/setting/organization"),
        meta: {
          title: "组织机构",
          keepAlive: true,
          pageLayout: 1,
          fullPage: false
        }
      },
      {
        path: "/setting/organization/organizationDetail",
        component: () => import(/* webpackChunkName:"setting" */ "@/views/setting/organization/organizationDetail"),
        meta: {
          title: "组织机构",
          keepAlive: true,
          pageLayout: 1,
          fullPage: false
        }
      }
    ]
  },
  {
    path: "/recharge",
    component: () => import(/* webpackChunkName:"recharge" */ "@/views/setting/recharge"),
    meta: {
      title: "充值中心",
      keepAlive: false,
      fullPage: false
    }
  }
];

export default routes;
