// 司法风险
const routes = [
  {
    path: "manageRisk",
    name: "manageRisk",
    component: () => import(/* webpackChunkName: "corporate-credit" */"@/views/corporateCredit/manageRisk"),
    meta: {
      title: "司法风险",
      keepAlive: true,
      fullPage: false
    }
  }
];

export default routes;
