/*
 * @Author: 梁平贤
 * @Date: 2020-07-23 15:30:12
 * @LastEditors: 梁平贤
 * @LastEditTime: 2020-07-23 16:30:12
 * @Description: 业务建模vuex
 */
export default {
  // 业务建模实体数据 暂时无用
  businessData: null,
  // 待提交的业务建模数据,包含接口id, 目前只有企业征信用到
  skyEyeBusinessData: null,
  // pageOffice数据(退出登录需要清空)
  pageOfficeData: null,
  // 日期全局变量
  dateVariables: []

};
