/*
 * @Author: Fenngding
 * @Date: 2019-09-04 09:13:28
 * @LastEditors: wuqi
 * @LastEditTime: 2020-12-02 11:37:49
 * @Email: fenngding@live.com
 * @Description:
 */
const routes = [
  {
    path: "/company-circle",
    redirect: "/company-circle/home"
  },
  {
    path: "/company-circle/home",
    name: "companycirclehome",
    component: () => import(/* webpackChunkName: "company-circle" */"@/views/companyCircle/home.vue"),
    meta: {
      title: "home",
      keepAlive: true,
      fullPage: false
    }
  },
  {
    path: "/company-circle/newthemelist",
    name: "newthemelist",
    component: () => import(/* webpackChunkName: "company-circle" */"@/views/companyCircle/NewList.vue"),
    meta: {
      title: "查看消息",
      keepAlive: false,
      fullPage: false
    }
  },
  {
    path: "/company-circle/upload",
    name: "upload",
    component: () => import(/* webpackChunkName: "company-circle" */"@/views/companyCircle/upload.vue"),
    meta: {
      title: "upload",
      keepAlive: true,
      fullPage: false
    }
  }
];

export default routes;
