/*
 * @Author: zhulin
 * @Date: 2020-06-16 17:12:51
 * @LastEditors: zhulin
 * @LastEditTime: 2020-09-25 10:05:07
 * @Description: file content
 */
import ThemeCommonService from "./themeCommonService";
import DefaultListService from "./defaultListService";
import GroupListService from "./groupListService";
import CalendarListService from "./calendarListService";

export const themeCommonService = new ThemeCommonService();
export const defaultListService = new DefaultListService();
export const groupListService = new GroupListService();
export const calendarListService = new CalendarListService();
