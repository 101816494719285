var render, staticRenderFns
import script from "./submenu.vue?vue&type=script&lang=js"
export * from "./submenu.vue?vue&type=script&lang=js"
import style0 from "./submenu.vue?vue&type=style&index=0&id=15f08d68&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15f08d68",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/dev_en-user/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('15f08d68')) {
      api.createRecord('15f08d68', component.options)
    } else {
      api.reload('15f08d68', component.options)
    }
    
  }
}
component.options.__file = "src/components/top-menu/src/submenu.vue"
export default component.exports