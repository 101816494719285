// 收到群列表及更新群列表接口
export function onTeams(store, teams) {
  const { commit } = store;
  console.log("hook:=======onTeams收到群列表及更新群列表接口", teams);
  if (!Array.isArray(teams)) {
    teams = [teams];
  }
  teams = teams.filter((item) => !!item);
  teams.forEach((team) => {
    if (team.validToCurrentUser === undefined) {
      team.validToCurrentUser = true;
    }
    if (team.avatar && team.avatar.indexOf("chat.nosdn.127") > 0 && team.avatar.indexOf("?imageView") === -1) {
      team.avatar += "?imageView&thumbnail=300y300";
    }
  });
  commit("updateTeamList", teams);
}

// 收到群成员及更新群成员接口
export function onTeamMembers(store, obj) {
  console.log("hook:=======onTeamMembers收到群成员及更新群成员接口", obj);
  store.commit("updateTeamMembers", obj);
}

export function onCreateTeam(store, team) {
  console.log("hook:=======onCreateTeam");
  onTeams(store, team);
  onTeamMembers(store, {
    teamId: team.teamId,
    members: []
    // members: [team.owner]
  });
}

export function onSynCreateTeam(store, team) {
  console.log("hook:=======onSynCreateTeam", team);

  onTeams(store, team);
}

export function onDismissTeam(store, obj) {
  console.log("hook:=======onDismissTeam", obj);
  store.commit("updateTeamList", { invalid: { teamId: obj.teamId } });
}

export function onUpdateTeam(store, team) {
  console.log("hook:=======onUpdateTeam", team);
  onTeams(store, team);
}

export function onTeamNotificationMsg(store, msg) {
  console.log("hook:=======onTeamNotificationMsg", msg);
  if (msg.attach.type === "updateTeam" && msg.attach.team) {
    store.commit("updateTeamInfo", msg.attach.team);
  }
  if (msg.attach.type === "transferTeam") {
    onTeamMembers(store, {
      teamId: msg.attach.team.teamId,
      members: msg.attach.members
    });
  }
}

export function onAddTeamMembers(store, obj) {
  console.log("hook:=======onAddTeamMembers", obj);
  obj.accounts.forEach((account) => {
    // 自己被拉入群时更新群列表
    if (account === store.state.userId) {
      const team = [obj.team];
      onTeams(store, team);
    }
  });
  onTeamMembers(store, {
    teamId: obj.team.teamId,
    members: obj.members
  });
}

export function onRemoveTeamMembers(store, obj) {
  console.log("hook:=======onRemoveTeamMembers", obj);
  obj.accounts.forEach((account) => {
    // 自己被移出群时，更新群列表
    if (account === store.state.userId) {
      obj.team.validToCurrentUser = false;
      const team = [obj.team];
      onTeams(store, team);
    }
  });
  store.commit("removeTeamMembersByAccounts", {
    teamId: obj.team.teamId,
    accounts: obj.accounts
  });
}

export function onUpdateTeamMember(store, teamMember) {
  console.log("hook:=======onUpdateTeamMember", teamMember);
  onTeamMembers(store, {
    teamId: teamMember.teamId,
    members: teamMember
  });
}

export function onUpdateTeamMembersMute(store, obj) {
  console.log("hook:=======onUpdateTeamMembersMute", obj);
  onTeamMembers(store, {
    teamId: obj.team.teamId,
    members: obj.members
  });
}

export function onUpdateTeamManagers(store, obj) {
  console.log("hook:=======onUpdateTeamManagers", obj);
  onTeamMembers(store, {
    teamId: obj.team.teamId,
    members: obj.members
  });
}

/*
* 代理nim sdk中对群组的操作方法
* @functionName  chat sdk中的方法名
* @options 传递给sdk方法的参数
*/
export function delegateTeamFunction(store, { functionName, options }) {
  const chat = window.nim;
  if (functionName && chat[functionName] && typeof chat[functionName] === "function") {
    chat[functionName](options);
  } else {
    throw Error(`There is not property of '${functionName}' in chat or '${functionName}' is not a function`);
  }
}

export function getTeamMembers(store, teamId) {
  console.log("hook:=======getTeamMembers", teamId);
  const nim = window.nim;
  if (!nim) {
    // 防止nim未初始化
    setTimeout(() => {
      getTeamMembers(store, teamId);
    }, 200);
    return;
  }
  nim.getTeamMembers({
    teamId,
    done: (err, obj) => {
      if (obj.members) {
        onTeamMembers(store, {
          teamId: obj.teamId,
          members: obj.members
        });
      } else {
        setTimeout(() => {
          getTeamMembers(store, teamId);
        }, 200);
      }
    }
  });
}
