/*
 * @Author: Fenngding
 * @Date: 2019-09-04 09:13:28
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-13 10:50:16
 * @Email: fenngding@live.com
 * @Description:
 */
/* eslint-disable no-unused-vars */
const routes = [
  {
    path: "/hr",
    redirect: "/hr/info"
  },
  {
    path: "/hr/info",
    name: "info",
    component: () => import(/* webpackChunkName: "hr" */ "@/views/hr/entry/index.vue"),
    meta: {
      title: "人事信息",
      keepAlive: true,
      fullPage: false
    }
  },
  {
    path: "/hr/myHoliday",
    name: "myHoliday",
    component: () => import(/* webpackChunkName: "hr" */ "@/views/hr/hr/myHoliday.vue"),
    meta: {
      title: "我的假期",
      keepAlive: true,
      fullPage: false
    }
  },
  {
    path: "/hr/myOvertime",
    name: "myOvertime",
    component: () => import(/* webpackChunkName: "hr" */ "@/views/hr/hr/myOvertime.vue"),
    meta: {
      title: "我的加班",
      keepAlive: true,
      fullPage: false
    }
  },
  {
    path: "/hr/attendance",
    component: () => import(/* webpackChunkName: "hr" */ "@/views/hr/attendance"),
    meta: {
      title: "考勤",
      keepAlive: true,
      fullPage: false
    }
  },
  {
    path: "/hr/attendance/chart",
    name: "myAttendanceChart",
    component: () => import(/* webpackChunkName: "hr" */ "@/views/hr/attendance/chart"),
    meta: {
      title: "考勤汇总",
      keepAlive: true,
      fullPage: false
    }
  },
  {
    path: "/hr/attendance/list",
    component: () => import(/* webpackChunkName: "hr" */ "@/views/hr/attendance/list"),
    meta: {
      title: "考勤列表",
      keepAlive: true
    }
  },
  {
    path: "/hr/chartDetail",
    component: () => import(/* webpackChunkName: "hr" */ "@/views/hr/hr/chartDetail.vue"),
    meta: {
      title: "报表",
      keepAlive: true
    }
  },
  {
    path: "/hr/reportAttend",
    component: () => import(/* webpackChunkName: "hr" */ "@/views/hr/report/index.vue"),
    meta: {
      title: "考勤查询",
      fullPage: false,
      keepAlive: true

    }
  }
];

export default routes;
