const routes = [
  {
    path: "/payroll/graph",
    name: "graph",
    component: () => import(/* webpackChunkName:"payroll" */ "@/views/payRoll/hr/graph/index"),
    meta: {
      title: "统计图",
      keepAlive: true,
      fullPage: false
    }
  }
];

export default routes;
