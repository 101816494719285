/**
 * @Author en-cli v0.1.0
 * @Date 2020/05/20 17:48
 * @Description 路径配置
 */
const microName = process.env.VUE_APP_MICRO_NAME;
const baseUrl = process.env.VUE_APP_BASE_URL; // 微服务使用时的路由基址地址(开发和生成均是该基地址)
const port = process.env.VUE_APP_PORT; // 端口
const apiBaseUrl = process.env.VUE_APP_API_BASE_URL; // 接口的基址
const domain = process.env.VUE_APP_DOMAIN; // 需要代理到的域名
const enUrl = process.env.VUE_APP_EN_URL; // en-cli服务url
module.exports = {
  microName,
  baseUrl,
  port,
  apiBaseUrl,
  domain,
  enUrl
};
