/*
 * @Author: zoujp
 * @Date: 2020-07-15 18:08:27
 * @LastEditTime: 2020-10-12 15:06:12
 * @LastEditors: Please set LastEditors
 * @Description: 评论点赞接口
 * @FilePath: \user\src\api\businessModel\comment.js
 */

import BaseService from "../BaseService";

class CommentService extends BaseService {
  pk = ""

  constructor(http) {
    super("", http);
  }

  /**
 * @description: 获取评论列表
 * @param params 查询参数
 */
  queryCommentList(params) {
    const { http } = this;
    return http.post("commentList", params);
  }

  /**
 * @description: 删除评论
 * @param params 查询参数
 */
  deleteMdThemeReply(params) {
    const { http } = this;
    return http.post("deleteMdThemeReply", params);
  }

  /**
 * @description: 点赞
 * @param params 查询参数
 */
  mdLikeTheme(params) {
    const { http } = this;
    return http.post("mdLikeTheme", params);
  }

  /**
 * @description: 评论发布
 * @param params 查询参数
 */
  replyMd(params) {
    const { http } = this;
    return http.post("replyMd", params);
  }
}

export default CommentService;
