const routes = [
  {
    path: "/payroll/payroll",
    name: "payroll",
    component: () => import(/* webpackChunkName:"payroll" */ "@/views/payRoll/hr/payroll/index"),
    meta: {
      title: "工资条管理",
      keepAlive: true,
      fullPage: false
    }
  }
];

export default routes;
