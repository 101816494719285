export default {
  // 是否触发退出/解散群聊
  logoutGroup: (state) => state._logoutGroup,
  // 获取当前聊天对象的信息
  getChatsInfo: (state) => state._chatsInfo,
  // 需要塞入到会话列表的新消息
  getNewMsg: (state) => state._newMsg,
  // 更改群聊信息时触发
  getGroupInfo: (state) => state._updateGroupInfo,
  // // 更改群聊信息时触发
  sessionlist: (state) => state.sessionlist
};
