const routes = [
  {
    path: "/",
    redirect: "/home"
  }, {
    path: "/user/apiPlat",
    name: "apiPlat",
    component: () => import("@/views/apiPlat/index.vue"),
    meta: {
      title: "接口平台",
      fullPage: true,
      keepAlive: true
    },
    children: [{
      path: "/user/apiPlat/api/classify/:id",
      name: "classify",
      component: () => import("@/views/apiPlat/api/classify.vue"),
      meta: {
        title: "接口分类",
        fullPage: true,
        keepAlive: true
      }
    }, {
      path: "/user/apiPlat/api/list/:id",
      name: "list",
      component: () => import("@/views/apiPlat/api/list.vue"),
      meta: {
        title: "接口分类",
        fullPage: true,
        keepAlive: true
      }
    }, {
      path: "/user/apiPlat/api/details/:id",
      name: "details",
      component: () => import("@/views/apiPlat/api/details.vue"),
      meta: {
        title: "接口详情",
        fullPage: true,
        keepAlive: true
      }
    }, {
      path: "/user/apiPlat/api/addApi",
      name: "addApi",
      component: () => import("@/views/apiPlat/api/addApi.vue"),
      meta: {
        title: "新增接口",
        fullPage: true,
        keepAlive: true
      }
    }, {
      path: "/user/apiPlat/api/myDraft",
      name: "myDraft",
      component: () => import("@/views/apiPlat/api/myDraft.vue"),
      meta: {
        title: "我的草稿",
        fullPage: true,
        keepAlive: true
      }
    }, {
      path: "/user/apiPlat/api/detailsDraft/:id",
      name: "myDraft",
      component: () => import("@/views/apiPlat/api/detailsDraft.vue"),
      meta: {
        title: "草稿详情",
        fullPage: true,
        keepAlive: true
      }
    }, {
      path: "/user/apiPlat/overview",
      name: "overview",
      component: () => import("@/views/apiPlat/common/overview.vue"),
      meta: {
        title: "平台概述",
        fullPage: true,
        keepAlive: true
      }
    }, {
      path: "/user/apiPlat/overviewEdit",
      name: "overviewEdit",
      component: () => import("@/views/apiPlat/common/overviewEdit.vue"),
      meta: {
        title: "编辑平台概述",
        fullPage: true,
        keepAlive: true
      }
    }, {
      path: "/user/apiPlat/globalParameters",
      name: "globalParameters",
      component: () => import("@/views/apiPlat/common/globalParameters.vue"),
      meta: {
        title: "全局参数",
        fullPage: true,
        keepAlive: true
      }
    }, {
      path: "/user/apiPlat/errorCode",
      name: "errorCode",
      component: () => import("@/views/apiPlat/common/errorCode.vue"),
      meta: {
        title: "全局参数",
        fullPage: true,
        keepAlive: true
      }
    }, {
      path: "/user/apiPlat/search/:value",
      name: "search",
      component: () => import("@/views/apiPlat/common/search.vue"),
      meta: {
        title: "搜索结果",
        fullPage: true,
        keepAlive: true
      }
    }]
  }];
export default routes;
