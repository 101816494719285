import BaseService from "../BaseService";

class ReportDetailService extends BaseService {
  constructor(http) {
    super("", http);
  }

  queryCustomReportDataNew(params) {
    return this.http.post("queryCustomReportDataNew", params);
  }

  queryCustomReportData(params) {
    return this.http.post("queryCustomReportData", params);
  }

  queryTotal(params) {
    return this.http.post("queryCustomReportDataNew", params);
  }

  exportDataNew(params) {
    return this.http.post("exportCustomReportDataNew", params, { responseType: "arraybuffer" });
  }

  exportData(params) {
    return this.http.post("exportExcel", params, { responseType: "arraybuffer" });
  }

  /**
   * 查询全局变量
   * @param params 参数
   * @returns {*}
   */
  queryRbacGloableVariableList(param) {
    return this.http.post("queryRbacGloableVariableList", param);
  }

  /**
   * 查询自定义报表参数
   * @param params 参数
   * @returns {*}
   */
  queryCustomReportConditions(param) {
    return this.http.post("queryCustomReportConditions", param);
  }

  queryControlDetailCol(params) {
    return this.http.post("queryControlDetailCol", params);
  }

  queryControlDetail(params) {
    return this.http.post("queryControlDetail", params);
  }
}

export default ReportDetailService;
