/**
 * @Author 李煜
 * @Date 2020/6/5
 * @Description 路由
 */
import Vue from "vue";
import VueRouter from "vue-router";
import page404 from "@/views/common/404";
import permission from "./permission";
import { baseUrl } from "../../baseUrl";
import routes from "./util";

// const requireRouter = require.context(".", true, /.js$/);
// const route = requireRouter.keys().reduce((acc, fileName) => {
//   const routerConfig = requireRouter(fileName);
//   const routerContext = routerConfig.default || routerConfig;
//   if (/^\.\/[a-zA-Z0-9-]+\/index.js$/.test(fileName)) {
//     return acc.concat(routerContext);
//   }
//   return acc;
// }, []);
Vue.use(VueRouter);

routes.push({
  path: "*",
  component: page404,
  meta: {
    title: "404",
    keepAlive: true,
    fullPage: true
  }
});

// 实例化router方法
const router = new VueRouter({
  base: baseUrl,
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return {
      x: 0,
      y: 0
    };
  },
  routes
});

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

// 权限
if (permission.beforeEach) {
  router.beforeEach(permission.beforeEach);
}
if (permission.afterEach) {
  router.afterEach(permission.afterEach);
}
export default router;
