/*
 * @Author: pengyu
 * @Date: 2021-06-21 11:00:59
 * @LastEditors: pengyu
 * @LastEditTime: 2021-06-21 11:04:43
 * @Description: file content
 */
const routes = [
  {
    path: "/office-online",
    component: () => import("@/views/officeOnline"),
    meta: {
      title: "officeOnline",
      keepAlive: true,
      fullPage: true
    }
  }
];

export default routes;
