/*
 * @Author: zhangjiaheng
 * @Date: 2020-06-22 18:31:37
 * @LastEditTime: 2020-07-09 11:27:07
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \user\src\router\en\index.js
 */
const routes = [
  {
    path: "/en",
    component: () => import(/* webpackChunkName: "home-en" */ "@/views/home/myEn/en"),
    meta: {
      title: "我的事项",
      fullPage: false,
      keepAlive: true
    }
  },
  {
    path: "/myBuss",
    component: () => import(/* webpackChunkName: "home-en" */ "@/views/home/myEn/components/myBuss"),
    meta: {
      title: "我的业务",
      fullPage: false,
      keepAlive: false
    }
  },
  {
    path: "/myReceipt",
    component: () => import(/* webpackChunkName: "home-en" */ "@/views/home/myEn/components/receipt"),
    meta: {
      title: "回执列表",
      fullPage: false,
      keepAlive: false
    }
  },
  {
    path: "/myTask",
    component: () => import(/* webpackChunkName: "home-en" */ "@/views/home/myEn/components/myTask"),
    meta: {
      title: "我的任务",
      fullPage: false,
      keepAlive: false
    }
  },
  {
    path: "/myOrder",
    component: () => import(/* webpackChunkName: "home-en" */ "@/views/home/myEn/components/myOrder"),
    meta: {
      title: "我的订单",
      fullPage: false,
      keepAlive: false
    }
  },
  {
    path: "/handle",
    component: () => import(/* webpackChunkName: "home-en" */ "@/views/home/myEn/components/handle"),
    meta: {
      title: "已办事项",
      fullPage: false,
      keepAlive: false
    }
  },

  {
    path: "/myRead",
    component: () => import(/* webpackChunkName: "home-en" */ "@/views/home/myEn/components/myRead"),
    meta: {
      title: "消息中心",
      fullPage: false,
      keepAlive: false
    }
  },
  {
    path: "/waitdo",
    component: () => import(/* webpackChunkName: "home-en" */ "@/views/home/myEn/components/awitDo"),
    meta: {
      title: "待办事项",
      fullPage: false,
      keepAlive: false
    }
  }
];

export default routes;
