/*
 * @Author: wuqi
 * @Date: 2021-03-24 14:48:38
 * @LastEditors: wuqi
 * @LastEditTime: 2021-03-24 14:52:51
 * @Description:
 */
const routes = [
  {
    path: "/businessModel/boardList", // 业务建模分类菜单入口
    name: "boardList",
    component: () => import(/* webpackChunkName: "user-basic-business" */ "@/views/businessModel/board/list.vue"),
    meta: {
      title: "业务建模分类菜单",
      fullPage: false,
      keepAlive: false
    }
  }
];

export default routes;
