<template>
  <transition name="msgbox-fade">
    <div
      class="el-message-box__wrapper message-box__wrapper"
      tabindex="-1"
      v-show="visible"
      @click.self="handleWrapperClick"
      role="dialog"
      aria-modal="true"
      :aria-label="title || 'dialog'">
      <div class="el-message-box el-message-box--center pop-password-wrap" >
          <!--头部关闭按钮-->
          <div class='el-message-box__header close-wrap'>
          <button
            type="button"
            class="el-message-box__headerbtn"
            aria-label="Close"
            v-if="showClose"
            @click="handleAction('close')"
            @keydown.enter="handleAction('close')">
            <i class="el-message-box__close el-icon-close"></i>
          </button>
        </div>
        <prompt :show-prompt="showPrompt" v-if="showPrompt" :payRollId="payRollId" :userInfo='userInfo' @closePrompt="getClosePrompt" @promptCallBack="payRollVerfyCallBack"></prompt>
        <setPassword :show-set-password="showSetPassword" v-if="showSetPassword" :userInfo='userInfo' @closeSetPassword="getCloseSetPassword"></setPassword>
      </div>
    </div>
  </transition>
</template>

<script type="text/babel">
import Popup from "element-ui/src/utils/popup";
// import Dialog from "element-ui/src/utils/aria-dialog";

import Prompt from "./prompt/prompt";
import SetPassword from "./prompt/setPassword";

// let messageBox;
export default {
  data() {
    return {
      userInfo: {},
      uid: 1,
      title: undefined,
      message: "",
      customClass: "",
      action: "",
      callback: null,
      focusAfterClosed: null,
      showPrompt: false,
      showSetPassword: false,
      isPayPassword: "0",
      // 工资条id
      payRollId: ""
    };
  },
  provide() {
    return {
      userInfo: this.userInfo

    };
  },
  mixins: [Popup],
  props: {
    modal: {
      default: true
    },
    lockScroll: {
      default: true
    },
    showClose: {
      type: Boolean,
      default: true
    },
    closeOnClickModal: {
      default: true
    },
    closeOnPressEscape: {
      default: false
    },
    closeOnHashChange: {
      default: true
    }
  },

  components: {
    Prompt,
    SetPassword
  },
  methods: {
    getSafeClose() {
      const currentId = this.uid;
      return () => {
        this.$nextTick(() => {
          if (currentId === this.uid) this.doClose();
        });
      };
    },
    doClose(data) {
      if (!this.visible) return;
      this.visible = false;
      this._closing = true;
      this.onClose && this.onClose();
      // messageBox.closeDialog(); // 解绑
      if (this.lockScroll) {
        setTimeout(this.restoreBodyStyle, 200);
      }
      this.opened = false;
      this.doAfterClose();
      setTimeout(() => {
        if (this.action) this.callback(this.action, this, data);
      });
    },
    payRollVerfyCallBack(val) {
      // 工资条验证密码弹框回调方法
      this.showPrompt = false;
      if (val) {
        // 工资条密码验证成功登录
        // this.payRollPath = menuRouterData(this.menu);
        this.handleAction("confirm", "success");
        // this.$router.push(this.payRollPath);
      } else {
        // 工资条密码验证失败三次返回登录
        // this.$router.push();
        this.handleAction("confirm", "needLogin");
      }
    },
    getClosePrompt(val) {
      // 工资条验证密码弹框关闭
      this.showPrompt = val;
    },
    getCloseSetPassword(val) {
      // 工资条设置密码弹框关闭
      if (val) {
        // 如果是保存密码后关闭则弹出密码验证框
        this.showPrompt = true;
      }
      this.showSetPassword = false;
    },
    handleWrapperClick() {
      if (this.closeOnClickModal) {
        this.handleAction("close");
      }
    },
    getFirstFocus() {
      const btn = this.$el.querySelector(".el-message-box__btns .el-button");
      const title = this.$el.querySelector(".el-message-box__btns .el-message-box__title");
      return btn || title;
    },
    handleAction(action, data) {
      this.action = action;
      this.doClose(data);
    },
    handleClose() {
      this.handleAction("close");
    }
  },

  watch: {
    visible(val) {
      if (val) {
        this.uid++;
        this.focusAfterClosed = document.activeElement;
        if (this.userInfo.isPayPassword === "0") {
          this.showSetPassword = true;
        } else {
          this.showPrompt = true;
        }
        // messageBox = new Dialog(this.$el, this.focusAfterClosed);
      }
    }
  },

  mounted() {
    this.$nextTick(() => {
      if (this.closeOnHashChange) {
        window.addEventListener("hashchange", this.close);
      }
    });
  },

  beforeDestroy() {
    if (this.closeOnHashChange) {
      window.removeEventListener("hashchange", this.close);
    }
    // setTimeout(() => {
    //   messageBox.closeDialog();
    // });
  }

};
</script>
<style lang="scss" scoped>
.message-box__wrapper{
  top: -30vh;
}

.pop-password-wrap{
  width: 40%;
  padding-bottom: 15px;
  .close-wrap{
    padding-top: 0;
  }
}
</style>
