// 经营风险
const routes = [
  {
    path: "manageStatus",
    name: "manageStatus",
    component: () => import(/* webpackChunkName: "corporate-credit" */"@/views/corporateCredit/manageStatus"),
    meta: {
      title: "司法风险-失信人",
      keepAlive: true,
      fullPage: false
    }
  }
];

export default routes;
