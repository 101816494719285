// 充值中心
import BaseService from "../BaseService";

export default class RechargeService extends BaseService {
  queryUserAmount(params) {
    return this.http.post("queryUserAmount", params);
  }

  queryUserOnlineRechargeList(params) {
    return this.http.post("queryUserOnlineRechargeList", params);
  }

  queryUserAccountConsumeList(params) {
    return this.http.post("queryUserAccountConsumeList", params);
  }

  onlineRechargePlaceOrder(params) {
    return this.http.post("onlineRechargePlaceOrder", params);
  }
}
