var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.value,
          expression: "value",
        },
      ],
      ref: "refBox",
      staticClass: "color_picker_wrapper",
    },
    [
      _c(
        "div",
        {
          staticClass: "color_picker_box",
          on: {
            mousedown: function ($event) {
              $event.stopPropagation()
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "color_hd",
              class:
                !(_vm.showClose || _vm.titleConfig.show) &&
                !_vm.titleConfig.text
                  ? "color_hd_0"
                  : "",
            },
            [
              _vm.type === "gradient"
                ? _c("div", { staticClass: "gcolor" }, [
                    _c(
                      "div",
                      { ref: "refColorBar", staticClass: "gcolor_bar" },
                      [
                        _c("div", {
                          staticClass: "gcolor_bar_bg",
                          style: `background:${_vm.barStyle}`,
                          on: { click: _vm.handlePotBar },
                        }),
                        _c(
                          "div",
                          { staticClass: "gcolor_bar_pot_box" },
                          _vm._l(_vm.colors, function (item, index) {
                            return _c("div", {
                              key: `${item.pst}_${index}`,
                              staticClass: "gcolor_bar_pot",
                              class: {
                                on: _vm.selectIndex === index,
                              },
                              style: {
                                left: _vm.getBarPst(item.pst),
                              },
                              on: {
                                mousedown: function ($event) {
                                  return _vm.sliderPotDown(index, $event)
                                },
                                click: function ($event) {
                                  return _vm.clickGColorPot(index)
                                },
                              },
                            })
                          }),
                          0
                        ),
                      ]
                    ),
                    !_vm.disabledColorDeg
                      ? _c(
                          "div",
                          { staticClass: "gcolor_deg" },
                          [
                            _c("div", { staticClass: "gcolor_deg_span" }, [
                              _vm._v("角度"),
                            ]),
                            _c("el-slider", {
                              staticClass: "slider_input",
                              attrs: { min: 0, max: 360 },
                              model: {
                                value: _vm.deg,
                                callback: function ($$v) {
                                  _vm.deg = $$v
                                },
                                expression: "deg",
                              },
                            }),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.deg,
                                  expression: "deg",
                                },
                              ],
                              staticClass: "number_input",
                              domProps: { value: _vm.deg },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.deg = $event.target.value
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]
          ),
          _vm.type === "gradient"
            ? _c(
                "div",
                { staticClass: "gradient_box" },
                [
                  _vm._l(_vm.colors, function (item, index) {
                    return [
                      index === _vm.selectIndex
                        ? _c("ChromeColorPicker", {
                            key: `${item.pst}_${index}`,
                            attrs: {
                              theme: "light",
                              value: item.color,
                              "sucker-hide": true,
                            },
                            on: { input: _vm.changeColor },
                          })
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              )
            : _c(
                "div",
                { staticClass: "linear" },
                [
                  _c("ChromeColorPicker", {
                    attrs: {
                      theme: "light",
                      value: _vm.color.color,
                      "sucker-hide": true,
                    },
                    on: { input: _vm.changeColor },
                  }),
                ],
                1
              ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }