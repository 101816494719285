<!--
 * @Author: Fenngding
 * @Date: 2019-09-19 16:44:29
 * @LastEditors: Fengding
 * @LastEditTime: 2019-09-25 11:21:11
 * @Email: fenngding@live.com
 * @Description: 用于存放微服务的全屏容器
 -->
<template>
  <div
    v-en-loading="loadingConfig"
    class="micro-full-page-container"
  >
    <div
      class="container"
      v-html="content"
    >
    </div>
  </div>
</template>
<script>
export default {
  name: "MicroFullPage",
  components: {},
  props: {
    loading: Boolean, // 微服务的加载动画
    content: [String, Boolean], // 微服务的加载内容
    showMicro: Boolean // 是否显示微服务模板
  },
  data() {
    return { loadingConfig: { type: "loading", subTitle: "微模块加载中,请稍等..." } };
  },
  computed: {},
  watch: {
    loading(val) { // 根据微服务的加载状态切换动画
      this.loadingConfig.type = val ? "loading" : null;
    }
  },
  methods: {}
};
</script>
<style scoped lang="scss">
.micro-full-page-container {
  height: 100%;
  width: 100%;
  .container {
    height: 100%;
    width: 100%;
  }
}
</style>
