/*
 * @Author: zhulin
 * @Date: 2020-06-16 17:37:45
 * @LastEditors: zhulin
 * @LastEditTime: 2021-08-17 10:41:16
 * @Description: file content
 */
import BaseService from "../BaseService";

/**
 * 主题分组视图相关
 */
class GroupListService extends BaseService {
  pk = "";

  constructor(http) {
    super("", http);
  }

  /**
   * @description  检查是否有无配置看板
   * @param params
   * @returns {*}
   */
  mdQueryTotalPicList(params) {
    const { http } = this;
    return http.post("mdQueryTotalPicList", params);
  }

  /**
   * @description  查询视图列表
   * @param params
   * @returns {*}
   */
  queryUserViewList(params) {
    const { http } = this;
    return http.post("ptQueryUserViewList", params);
  }

  /**
   * @description  查询分共享人
   * @param params
   * @returns {*}
   */
  queryShareUser(params) {
    const { http } = this;
    return http.post("ptQueryShareUser", params);
  }

  /**
   * @description  添加常用分共享人
   * @param params
   * @returns {*}
   */
  addFrequent(params) {
    const { http } = this;
    return http.post("addFrequent", params);
  }

  /**
   * @description  删除常用分共享人
   * @param params
   * @returns {*}
   */
   deleteFrequent(params) {
    const { http } = this;
    return http.post("deleteFrequent", params);
  }

  /**
   * @description  导出查询
   * @param params
   * @returns {*}
   */
  queryExportPtDataCount(params) {
    const { http } = this;
    return http.post("queryExportPtDataCount", params);
  }

  /**
   * @description  导出执行
   * @param params
   * @returns {*}
   */
   ptExportTemplateEmptyOrDataList(params) {
    const { http } = this;
    return http.post("ptExportTemplateEmptyOrDataList", params);
  }

  /**
   * @description  查询分共享人
   * @param params
   * @returns {*}
   */
  queryFrequent(params) {
    const { http } = this;
    return http.post("queryFrequent", params);
  }
}

export default GroupListService;
