// import "./init";
import Vue from "vue";
import * as en from "en-js";
import ElementUi from "element-ui";
// import "element-ui/lib/theme-chalk/index.css";
import enfryComponents from "heluo-component";
import eCharts from "echarts";
import VueAMap from "vue-amap";
// import ClickOutSide from "element-ui/src/utils/clickoutside";
import PopupManager from "element-ui/lib/utils/popup/popup-manager";
import VueMeta from "vue-meta";
import VueI18n from "vue-i18n";
import VueJsx from "vue-jsx";

import ClickOutSide from "./directives/clickoutside";

import App from "./App.vue";
import router from "./router";
import store from "./store";
// eslint-disable-next-line import/no-cycle
import commonMixin from "./mixins/common";
// eslint-disable-next-line import/order
import enBusiness from "en-business";
// eslint-disable-next-line import/order
import enReport from "en-report";
// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/order
import enApiPlat from "en-apiplat";
import "@/assets/css/iconfont.css";
import "@/assets/css/elementresult.css";
import "@/styles/theme.scss";
import "@/assets/css/anticonfont.css";
import messages from "./locales";
import "en-business/lib/en-business.css";
import "en-report/lib/en-report.css";

// import './directives/clickoutside'
// Vue.directive('clickoutside', clickoutside)
Vue.use(enBusiness);
Vue.use(enReport);
Vue.use(enApiPlat);
// console.log(VueJsx,'VueJsx')
Vue.use(VueJsx);

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: "zh", // 默认语言为中文
  messages
});

// eslint-disable-next-line no-unused-vars
// import dialogDrag from "./dialogDrag";
// require("./styles/reset.scss");

Vue.use(VueMeta);
require("@/components/vue-virtual-list");

// 修复el，en，组件之间共享zIndex问题
let zIndex = null;
let hasInitZIndex = false;
let curMaxIndex = zIndex;
const enZIndex = {
  configurable: true,
  get() {
    // console.log("user $enZIndex get zIndex", zIndex);
    if (!hasInitZIndex) {
      zIndex = zIndex || (Vue.prototype.$ELEMENT || {}).zIndex || 2000;
      hasInitZIndex = true;
    }
    return zIndex;
  },
  set(value) {
    // console.log("user $enZIndex set zIndex before", zIndex);
    curMaxIndex = value;
    zIndex = value;
    // console.log("user $enZIndex set zIndex after", zIndex);
    // console.log("当前弹框最大zindex", curMaxIndex);
  }
};
// 全局弹窗无法通过 esc 关闭
ElementUi.Dialog.props.closeOnPressEscape.default = false;
// 拦截工程element-ui中的zIndex
// if (Vue.prototype.$enZIndex) {
Object.defineProperty(PopupManager, "zIndex", enZIndex);
// }
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  key: "e9530a1acdfedaf1951916b000921e73",
  plugin: [
    "AMap.Autocomplete",
    "AMap.PlaceSearch",
    "AMap.Scale",
    "AMap.OverView",
    "AMap.ToolBar",
    "AMap.MapType",
    "AMap.PolyEditor",
    "AMap.CircleEditor",
    "AMap.Geocoder",
    "AMap.Geolocation"
  ],
  // 默认高德 sdk 版本为 1.4.4
  v: "1.4.4"
});
window._AMapSecurityConfig = {
  securityJsCode: "dd716e6e5d2c289f25d0f1ae95103ca9"
};
Vue.use(en);

Vue.use(ElementUi);

// 处理组件内的zIndex
// enfryComponents.initZIndex(enZIndex);
Vue.use(enfryComponents);
Vue.config.productionTip = false;
Vue.directive("ClickOutside", ClickOutSide);

Vue.prototype.$charts = eCharts;
Vue.prototype.$eventHub = new Vue();
if (process.env.VUE_APP_MOCK === "1") {
  // eslint-disable-next-line global-require
  // require("./mock");
}

Vue.mixin(commonMixin);

const vue = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount("#app");
window.$vue = vue;
export default vue;
