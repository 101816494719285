/*
 * @Author: your name
 * @Date: 2020-07-30 15:30:43
 * @LastEditTime: 2020-11-18 17:19:06
 * @LastEditors: pengyu
 * @Description: In User Settings Edit
 * @FilePath: 总账-接口
 */
import BaseService from "../BaseService";

class GeneralLedgerService extends BaseService {
  pk = ""

  constructor(http) {
    super("", http);
  }

  /**
   * @description: 查询总账表格数据
   * @param params 参数
   */
  queryAbTotal(params) {
    const { http } = this;
    return http.post("vcQueryAbTotal", params);
  }
}

export default GeneralLedgerService;
