import BaseService from "../BaseService";

class TemplateDataHandle extends BaseService {
  /**
   * @description: 获取关联数据
   * @param params
   */
  queryAssociatedData(params) {
    const { http } = this;

    return http.post("mdQueryNewRelationDataList", params);
  }

  /**
   * 获取关联数据第一条, 关联取值使用
   * @param relationInfo 配置
   * @param relationSet 关联条件
   * @param templateId 业务建模模板
   * @returns {*}
   */
  queryFisrtAssociatedData(relationInfo, relationSet, templateId) {
    const params = {};
    params.templateId = relationInfo.relationData;
    if (relationInfo.relationRange) {
      params.relationRange = relationInfo.relationRange;
    }
    params.relationType = relationInfo.relationType;
    params.pageSize = 1;
    params.pageNo = 1;
    params.controlType = "2";

    if (relationInfo?.dataFiltration?.length > 0) {
      params.dataFiltration = JSON.stringify(relationInfo.dataFiltration);
    }
    const commParams = {};
    if (relationSet) {
      commParams.relationSet = relationSet;
    }
    if (templateId) {
      commParams.templateExtId = templateId;
    }
    commParams.tempExtId = relationInfo.id;
    params.commParams = JSON.stringify(commParams);
    return this.queryAssociatedData(params).then((response) => response.records[0]);
  }

  /**
   * 计算时间差
   * dateType
   *  12-时间差剔除非工作日（天）,
   13-时间差剔除非工作日（分)
   23-时间差剔除非工作日（月)
   8-时间差剔除非工作日（时）
   * @param startTime 开始时间
   * @param endTime 结束时间
   * @param dateType 类型
   * @returns {*}
   */
  calcDateTimeDifference(startTime, endTime, dateType) {
    const { http } = this;
    const params = {};
    params.startTime = startTime;
    params.endTime = endTime;
    if (dateType === "day") {
      params.dateType = 12;
    } else if (dateType === "minute") {
      params.dateType = 13;
    } else if (dateType === "hours") {
      params.dateType = 8;
    } else if (dateType === "month") {
      params.dateType = 23;
    }
    return http.post("mdCalcWeekDayDataByCondition", params);
  }

  /**
   * 获取参与计算的值
   * @param templateId 模板id
   * @param id 数据id
   * @param expressRule 计算公式
   * @returns {Promise<*>}
   */
  queryCalcValue(templateId, id, expressRule) {
    const { http } = this;
    const params = {};
    params.templateId = templateId;
    params.id = id;
    params.expressRule = JSON.stringify(expressRule);
    return http.post("mdCheckRuleSearchDataRange", params).then((response) => response.value);
  }

  /**
   * 查看向上汇总和向下分解明细
   * @param templateId 模板id
   * @param id 数据id
   * @param field 控件字段key
   */
  querySummaryOrResolveDetail(templateId, id, field) {
    const { http } = this;
    const params = {};
    params.templateId = templateId;
    params.id = id;
    params.field = field;
    return http.post("mdQueryCollectDetail", params);
  }
}

export default TemplateDataHandle;
