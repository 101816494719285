<template>
  <div class='change-pwd-dialog2'>
    <div  class="header-title">
      <span class="prompt-title">修改密码</span>
    </div>
    <div class="dialog_content">
      <div class="progress">
        <div class="progres_number progress_dark"><i>1</i><span>验证身份</span></div>
        <div class="progress-icon">
          ——
        </div>
        <div class="progres_number progress_light"><i>2</i><span>修改密码</span></div>
      </div>
      <el-form label-width="100px" :rules="rules" ref="ruleForm" :model="form">
        <el-form-item prop="newPwd"  label="新密码">
          <el-input v-model="form.newPwd" placeholder="请输入新密码，8-20位字母、数字组成"></el-input>
        </el-form-item>
        <el-form-item prop="confirmNewPwd" size="mini" label="确认新密码">
          <el-input v-model="form.confirmNewPwd" placeholder="请确认新密码"></el-input>
        </el-form-item>

      </el-form>
    </div>
    <span class="dialog-footer">
       <en-button type='white'  @click="handleClose">返回</en-button>
      <en-button type="primary" @click="confirm('ruleForm')">确定</en-button>
    </span>
  </div>
</template>

<script>
import { payRollApi } from "@/api/payRoll";

export default {
  name: "ChangePassword2",
  components: {},
  props: ["showChangePasswordTwo"],
  data() {
    const newPwd = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else if (/^([a-zA-Z0-9\u4E00-\u9FA5\uf900-\ufa2d]){8,20}$/.test(this.form.newPwd)) {
        callback();
      } else {
        callback(new Error("密码由8—20位英文字母、数字组成！"));
      }
    };
    const confirmNewPwd = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入确认密码"));
      } else if (value !== this.form.newPwd) {
        callback(new Error("两次输入密码不一致!"));
      } else if (/^([a-zA-Z0-9\u4E00-\u9FA5\uf900-\ufa2d]){8,20}$/.test(this.form.newPwd)) {
        callback();
      } else {
        callback(new Error("密码由8—20位英文字母、数字组成！"));
      }
    };
    return {
      closeChangePasswordTwo: false,
      input: "",
      rules: {
        newPwd: [
          {
            required: true,
            validator: newPwd,
            trigger: "blur"
          }
        ],
        confirmNewPwd: [
          {
            required: true,
            validator: confirmNewPwd,
            trigger: "blur"
          }
        ]
      },
      form: {
        newPwd: "",
        confirmNewPwd: ""
      }
    };
  },
  computed: {},
  methods: {
    handleClose() {
      this.$emit("close");
    },
    confirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.setPassword();
        } else {
          console.log("error submit!!");
        }
      });
    },
    async setPassword() {
      await payRollApi.paySlipsPassword({ checkValue: this.form.newPwd });
      this.$emit("changeSuccess");
      this.$message({
        showClose: false,
        message: "密码设置成功，请重新输入密码登录",
        type: "success"
      });
    }
  }
};
</script>
<style lang="scss">
.change-pwd-dialog2 .el-dialog /deep/ .el-dialog__body {
  text-align: left;
}
</style>
<style lang="scss" scoped>
.dialog_content {
  overflow: hidden;
  text-align: center;
  text-align: left;
  .progress {
    text-align: center;
    margin: 30px 0 30px 0;
    > div {
      display: inline-block;
    }
    .progres_number {
      i {
        display: inline-block;
        width: 25px;
        line-height: 25px;
        height: 25px;
        border-radius: 50%;
        text-align: center;
        font-style: normal;
        color: #fff;
        margin-right: 10px;
      }
      span {
      }
    }
    .progress_light {
      i {
        background-color: #3e90fe;
      }
      span {
        color: #3e90fe;
      }
    }
    .progress_dark {
      i {
        background-color: #a2c9ef;
      }
      span {
        color: #d5dae3;
      }
    }
    .progress-icon {
      margin: 0 10px 0 10px;
      color: #3e90fe;
      font-size: 24px;
    }
  }
  .el-input {
    width: 455px;
  }
}
.header-title {
  text-align: left;
  margin-top: 5px;
  .prompt-title {
    font-size: 16px;
  }
  .prompt-info {
    color: #a9b5c6;
  }
}
.dialog-footer{
    display: inline-block;
    width: 100%;
    text-align: right;
    margin-top: 15px;
    padding-bottom:30px;
}
</style>
