/*
 * @Author: your name
 * @Date: 2020-06-22 09:37:07
 * @LastEditTime: 2020-06-29 15:20:36
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \user\src\api\common\UploadService.js
 */
import BaseService from "../BaseService";

class UploadService extends BaseService {
  pk = "id"

  constructor(http) {
    super("", http);
  }

  // eslint-disable-next-line class-methods-use-this
  read(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let base64 = reader.result;
        if (base64) {
          // eslint-disable-next-line prefer-destructuring
          base64 = base64.split(",")[1];
        }
        resolve(base64);
      };
      reader.onerror = () => {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({
          code: 500,
          msg: "文件转化base64失败"
        });
      };
    });
  }

  /**
   * 上传base64文件
   * @param file
   */
  async uploadFileByBase64(file) {
    try {
      const { http, module } = this;
      const { type } = file;
      const extName = type.substr(type.lastIndexOf("/") + 1);
      const base64 = await this.read(file);
      return http.post(`/${module}/file`, {
        base64,
        extName
      });
    } catch (e) {
      return e;
    }
  }

  uploadFile(file, params) {
    const formData = new FormData();
    formData.append("name", file);
    if (params) {
      Object.entries(params).forEach(([key, value]) => {
        formData.append(key, value);
      });
    }
    console.log(file, "uploadFile进这里了么");
    const { http } = this;
    // https://static.enfry.cn/Storage-api/upload.api  主板烧了 暂时用生产的
    return http.post("https://storage.softheluo.com/Storage-api/upload.api", formData, {
      "Content-Type": "multipart/form-data"
    });
  }

  uploadExcel(file, params) {
    const formData = new FormData();
    formData.append("file1", file);
    if (params) {
      Object.entries(params).forEach(([key, value]) => {
        formData.append(key, value);
      });
    }
    const { http, module } = this;
    return http.post(`/${module}/upload/excel`, formData, {
      "Content-Type": "multipart/form-data"
    });
  }
}
// debugger;
export default UploadService;
// const xxx = new UploadService();
// xxx.upload();
