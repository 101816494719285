/*
 * @Author: zoujp
 * @Date: 2020-07-21 17:34:11
 * @LastEditTime: 2022-01-06 11:07:49
 * @LastEditors: wuqi
 * @Description: 业务建模按钮接口
 * @FilePath: \user\src\api\businessModel\btn.js
 */

import BaseService from "../BaseService";

class BtnService extends BaseService {
  pk = ""

  constructor(http) {
    super("", http);
  }

  /**
 * @description: 查询任务权限
 * @param params 查询参数
 */
  mdSearchPowerInfo(params) {
    const { http } = this;
    return http.post("mdSearchPowerInfo", params);
  }

  /**
 * @description: 编辑判断
 * @param params 查询参数
 */
  mdEditObjectCheck(params) {
    const { http } = this;
    return http.post("mdEditObjectCheck", params);
  }

  // 提交操作时,查询按钮配置的跳转情况.
  mdQueryBtnfunc(params) {
    return this.http.post("mdQueryBtnfunc", params);
  }

  /**
 * @description: 删除
 * @param params 查询参数
 */
  mdDeleteObject(params) {
    const { http } = this;
    return http.post("mdDeleteObject", params);
  }

  /**
 * @description: 发票自动拆分
 * @param params 查询参数
 */
  mdInvoiceAutoResolve(params) {
    const { http } = this;
    return http.post("mdInvoiceAutoResolve", params);
  }

  /**
 * @description: 共享
 * @param params 查询参数
 */
  mdDataShare(params) {
    const { http } = this;
    return http.post("mdDataShare", params);
  }

  // 加签
  mdSign(params) {
    return this.http.post("mdSign", params);
  }

  /**
 * @description: 获取沟通sessionId
 * @param params 查询参数
 */
  getImSession(params) {
    const { http } = this;
    return http.post("getImSession", params);
  }

  /**
 * @description: 高级按钮
 * @param params 查询参数
 */
  mdTopBtn(params) {
    const { http } = this;
    return http.post("mdTopBtn", params);
  }

  /**
 * @description: 自定义按钮
 * @param params 查询参数
 */
  mdCustomBtn(params) {
    const { http } = this;
    return http.post("mdCustomBtn", params);
  }

  /**
 * @description: 查询收藏列表
 * @param params 查询参数
 */
  mdQueryCollectTemplateList(params) {
    const { http } = this;
    return http.post("mdQueryCollectTemplateList", params);
  }

  /**
 * @description: 删除收藏
 * @param params 查询参数
 */
  mdBatchDelCollectTemplate(params) {
    const { http } = this;
    return http.post("mdBatchDelCollectTemplate", params);
  }

  /**
 * @description: 同意
 * @param params 查询参数
 */
  mdAgree(params) {
    const { http } = this;
    return http.post("mdAgree", params);
  }

  chatFlowAgree(params) {
    const { http } = this;
    return http.post("agree", params);
  }

  chatDeagainSubmit(params) {
    const { http } = this;
    return http.post("deagainSubmit", params);
  }

  /**
   * 流程保存
   * @param params
   * @returns {*}
   */
  mdSaveDataInFlow(params) {
    const { http } = this;
    return http.post("mdSaveDataInFlow", params);
  }

  /**
 * @description: 不同意
 * @param params 查询参数
 */
  mdDisAgree(params) {
    const { http } = this;
    return http.post("mdDisagree", params);
  }

  /**
 * @description: 终止
 * @param params 查询参数
 */
  detailStop(params) {
    const { http } = this;
    return http.post("detailStop", params);
  }

  chatFlowStop(params) {
    const { http } = this;
    return http.post("stop", params);
  }

  /**
 * @description: 流程自定义
 * @param params 查询参数
 */
  mdCustom(params) {
    const { http } = this;
    return http.post("mdCustom", params);
  }

  /**
 * @description: 拟办
 * @param params 查询参数
 */
  mdPropose(params) {
    const { http } = this;
    return http.post("mdPropose", params);
  }

  // 再次拟办
  mdProposeAgain(params) {
    const { http } = this;
    return http.post("flProposeAgain", params);
  }

  /**
 * @description: 驳回
 * @param params 查询参数
 */
  mdReject(params) {
    const { http } = this;
    return http.post("mdReject", params);
  }

  /**
 * @description: 收回
 * @param params 查询参数
 */
  mdTakeBack(params) {
    const { http } = this;
    return http.post("mdTakeBack", params);
  }

  chatFlowTakeBack(params) {
    const { http } = this;
    return http.post("takeBack", params);
  }

  /**
 * @description: 提交，继续添加编辑
 * @param params 查询参数
 */
  mdEditDataInfo(params) {
    const { http } = this;
    return http.post("mdEditDataInfo", params);
  }

  /**
 * @description: 校验基础数据人员的手机号码
 * @param params 查询参数
 */
  checkTenantInfoSingleByParams(params) {
    const { http } = this;
    return http.post("checkTenantInfoSingleByParams", params);
  }

  /**
 * @description: 未提交的数据或者新数据
 * @param params 查询参数
 */
  mdSubmitData(params) {
    const { http } = this;
    return http.post("mdSubmitData", params);
  }

  mdCheckDateRangeConflict(params) {
    const { http } = this;
    return http.post("mdCheckDateRangeConflict", params);
  }

  // 保存或提交基础数据
  mdSubmitBasicData(params) {
    return this.http.post("bdAddOrUpdateData", params);
  }

  /**
 * @description: 获取拟办范围备选数据
 * @param params 查询参数
 */
  queryProposeRange(params) {
    const { http } = this;
    return http.post("queryProposeRange", params);
  }

  /**
   * @description: 获取已经拟办的人员数据
   * @param params 查询参数
   */
  queryAlreadyProposeRange(params) {
    const { http } = this;
    return http.post("queryAlreadyProposeRange", params);
  }

  /**
 * @description: 获取流程退回节点
 * @param params 查询参数
 */
  queryFlowNodeInfo(params) {
    const { http } = this;
    return http.post("queryFlowNodeInfo", params);
  }

  // 编辑校验使用范围
  queryEditObjectCheck(params) {
    return this.http.post("mdEditObjectCheck", params);
  }

  mdSaveOrUpdateCollectTemplateInfo(param) {
    return this.http.post("mdSaveOrUpdateCollectTemplateInfo", param);
  }

  mdQueryCollectTemplateInfo(param) {
    return this.http.post("mdQueryCollectTemplateInfo", param);
  }

  // 关联考勤
  attQueryRecordDetail(params) {
    return this.http.post("attQueryRecordDetail", params);
  }

  // 检测是否可以打车
  checkCarAuth() {
    return this.http.post("checkCarAuth");
  }
}

export default BtnService;
