/*
 * @Author: Louis
 * @Date: 2019-09-03 09:50:28
 * @LastEditors: 周晴龙
 * @LastEditTime: 2020-08-11 14:45:33
 * @Description: 订单管理路由文件
 */

const routes = [
  {
    path: "/order",
    name: "orderList",
    component: () => import(/* webpackChunkName: "business-travel-order" */"@/views/businessTravel/order/index"),
    meta: {
      title: "我的订单",
      fullPage: false,
      keepAlive: true
    }
  }, {
    path: "/order/query",
    name: "orderQuery",
    component: () => import(/* webpackChunkName: "business-travel-order" */"@/views/businessTravel/order/query"),
    meta: {
      title: "订单查询",
      fullPage: false,
      keepAlive: true
    }
  }
];

export default routes;
