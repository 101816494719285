/*
 * @Author: liujianjiu
 * @Date: 2020-07-30
 * @Description:下载中心地址配置
 */
const routes = [
  {
    path: "/download-management",
    redirect: "/download-management/home"
  },
  {
    path: "/download-management/home",
    name: "downloadhome",
    component: () => import(/* webpackChunkName: "download-management" */ "@/views/downloadManagement/home"),
    meta: {
      title: "home",
      keepAlive: false,
      fullPage: false
    }
  }
];
export default routes;
