/*
 * @Author: pengyu
 * @Date: 2021-07-05 09:34:45
 * @LastEditors: pengyu
 * @LastEditTime: 2021-07-05 09:35:40
 * @Description: file content
 */
import BaseService from "../BaseService";

class LawAngelService extends BaseService {
  constructor(http) {
    super("", http);
  }

  // 查询订单信息
  queryZjPayOrderInfo(params) {
    return this.http.post("queryZjPayOrderInfo", params);
  }

  // 查询支付状态
  queryZjPayOrderPayStatus(params) {
    return this.http.post("queryZjPayOrderPayStatus", params);
  }

  // 发起微信支付
  postZjPayWeChatPay(params) {
    return this.http.post("postZjPayWeChatPay", params);
  }

  // 发起支付宝支付
  postZjPayAlipay(params) {
    return this.http.post("postZjPayAlipay", params);
  }

  // 发起银联支付
  postZjPayUnionPay(params) {
    return this.http.post("postZjPayUnionPay", params);
  }
}

export default LawAngelService;
