/*
 * @Author: wuqi
 * @Date: 2021-06-09 17:53:36
 * @LastEditors: wuqi
 * @LastEditTime: 2021-08-12 10:03:33
 * @Description:系统异常处理方法
 */
import _ from "lodash";
import { Message } from "element-ui";

export const reLogin = _.throttle((text, callBack, time = 3000) => {
  Message.warning(text);
  setTimeout(() => {
    // 增加删除保存的userInfo appsessionID
    if (callBack) {
      callBack();
    } else {
      localStorage.removeItem("appSessionId");
    }

    // window.open(`/user/login?redirect=${encodeURIComponent(location.href)}`, "_self");
  }, time);
}, 3000);
