/*
 * @Description: 业务建模详情路由
 * @Author: 梁平贤
 * @Date: 2020-06-18 14:57:01
 * @LastEditors: 梁平贤
 * @LastEditTime: 2020-06-18 15:01:13
 */

const routes = [
  {
    path: "/businessModel/detail",
    name: "businessDetail",
    component: () => import(/* webpackChunkName: "user-basic-business" */ "@/components/businessDetail/index.vue"),
    meta: {
      title: "业务建模详情",
      fullPage: false,
      keepAlive: false
    }
  }
];

export default routes;
