<!--
 * @Author: Fenngding
 * @Date: 2019-08-23 10:43:47
 * @LastEditors: linshanfeng
 * @LastEditTime: 2024-02-26 11:51:59
 * @Email: fenngding@live.com
 * @Description:
 -->
<template>
  <div class="menu-container" :style="[navStyle]">
    <en-menu
       v-if='themeInfo.SubmenuStyles == 1 || themeInfo.SubmenuStyles == 2'
      :default-active="activeIndex"
      :key="updateKey"
      class="menu"
      :style="[navBorderStyle, subNavStyle]"
      :class="{ collapse: isCollapse }"
      text-color="#a9b5c6"
      active-text-color="#fff"
      :unique-opened="true"
      collapse-transition
      :collapse="isCollapse"
      mode="horizontal"
      @open="handleOpen"
      @close="handleClose"
    >
      <template v-for="item in menuList" class="en-submenu">
        <en-submenu
          v-if="item.children && item.children.length > 0"
          :classList="item.data.classifyList"  :menus="item.children" :activeId="activeId"
          :index="item.index" class="en-submenu" @click.stop.native="clickMenuItem(item)">
          <template slot="title">
            <en-icon class="en-icon" :name="setChildrenIcon(item.icon)" size="16" />
            <i v-if="isCollapse" class="el-icon-arrow-right icon"></i>
            <span class="en-icon-title">{{ item.name }}</span>
          </template>
          <template>
            <div v-for="subItem in item.children" :key="subItem.index" class="submenu">
              <en-submenu v-if="subItem.children && subItem.children.length > 0"  :activeId="activeId" :menus="subItem.children" :index="subItem.index" @click.stop.native="clickMenuItem(subItem)">
                <template slot="title">
                  <en-icon class="en-icon" :name="setChildrenIcon(subItem.icon)" size="16" />
                  <span class="en-icon-title">{{ subItem.name }}</span>
                </template>
                <en-menu-item v-for="lastItem in subItem.children" :activeId="activeId" :key="lastItem.index" :index="lastItem.index" @click.stop.native="clickMenuItem(lastItem)">
                  <en-icon class="en-icon" :name="setChildrenIcon(lastItem.icon)" size="16" />
                  <span class="en-icon-title">{{ lastItem.name }}</span>
                </en-menu-item>
              </en-submenu>
              <en-menu-item v-else :index="subItem.index" :activeId="activeId"  @click.stop.native="clickMenuItem(subItem)">
                <en-icon class="en-icon" :name="setChildrenIcon(subItem.icon)" size="16" />
                <span class="en-icon-title">{{ subItem.name }}</span>
              </en-menu-item>
            </div>
          </template>
        </en-submenu>
        <en-menu-item v-else :index="item.index" :activeId="activeId" @click.native="clickMenuItem(item)">
          <en-icon class="en-icon" :name="item.icon" size="16" />
          <span slot="title" class="en-icon-title">{{ item.name }}</span>
        </en-menu-item>
      </template>
    </en-menu>
    <!-- v-if='themeInfo.SubmenuStyles == 1' -->
    <en-menu
      v-else
      :default-active="activeIndex"
      :key="updateKey"
      class="menu"
      :style="[navBorderStyle, subNavStyle]"
      :class="{ collapse: isCollapse }"
      text-color="#a9b5c6"
      active-text-color="#fff"
      :unique-opened="true"
      collapse-transition
      :collapse="isCollapse"
      mode="horizontal"
      @open="handleOpen"
      @close="handleClose"
    >
      <template v-for="item in menuList" class="en-submenu">

        <en-menu-item  :index="item.index" :activeId="activeId" @click.native="leftChild(item)">
          <en-icon class="en-icon" :name="item.icon" size="16" />
          <span slot="title" class="en-icon-title">{{ item.name }}</span>
        </en-menu-item>
      </template>
    </en-menu>
    <div class="custom-homepage" @click="gotoCustomHomePage" :style="navBgStyle">
      <i class="el-icon-setting icon"></i>
      <span v-if="!isCollapse" class="desc"></span>
    </div>
    <!-- <div class="toggle-collapse">
      <div v-if="isCollapse" class="item" @click="isCollapse = false">
        <en-icon class="en-icon" name="zuocecaidanlan-zhankai" size="16"></en-icon>
      </div>
      <div v-else class="item" @click="isCollapse = true"><en-icon class="en-icon" name="shouqi-shouyecaidanlan" size="14"></en-icon></div>
    </div> -->
    <!-- <PromptIndex :payRollPath="payRollPath" ref="promptIndex"></PromptIndex> -->
  </div>
</template>
<script>
// import PromptIndex from "@/views/payRoll/user/prompt/index";
import {
 mapMutations,
  createNamespacedHelpers
} from "vuex";
import { menuRouterData, menuClassifyTypeEnum, menuDataTypeEnum } from "@/api/homeMenu/handleMenu";
import { enChart } from "@/api/businessChart";

import enMenuItem from "./src/menu-item.vue";
import enSubmenu from "./src/submenu.vue";
import enMenu from "./src/menu.vue";

const { mapState: mapStateTheme } = createNamespacedHelpers("main");
export default {
  name: "TopNavMenu",
  components: {
    enMenu,
    enSubmenu,
    enMenuItem
    // PromptIndex
  },
  props: {
    activeId: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      activeIndex: "0",
      isCollapse: false,
      menuList: [],
      updateKey: 0,
      // 工资条的路径
      payRollPath: {}
    };
  },
  watch: {
    dataList: {
      immediate: true,
      handler() {
        this.updateKey++;
        this.updateData(this.dataList);
      }
    },
    $route: {
      handler(val) {
        this.updateActiveMenu(val);
        // if (val.path === "/chat") {
        //   this.isCollapse = true;
        // }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapStateTheme(["themeInfo"]),
    // eslint-disable-next-line vue/return-in-computed-property, consistent-return
    navBgStyle() {
      const {
 openCustomColor, navColor, HaverColor, NoHaverColor, SelectLabelColor, themeStyle
} = this.themeInfo;
        console.log("navColor", navColor, this.themeInfo);
        if (this.themeInfo.RecommendedStyles === 5) {
          if (this.themeInfo.themeColor === "dark") {
            return {
            color: "#FFFFFF !important",
              backgroundColor: `${navColor}!important`
          };
          }
          return {
            color: "#1B1C1E !important",
            backgroundColor: "#FFFFFF !important"
          };
        }
        if (this.themeInfo.RecommendedStyles === 7) {
          if (this.themeInfo.themeColor === "red") {
            return {
              color: "#FFFFFF !important",
              backgroundColor: "#B60005 !important"
          };
          }
          return {
            color: "#1B1C1E !important",
            backgroundColor: "#FFFFFF !important"
          };
        }
        return {
          color: NoHaverColor,
          backgroundColor: `${navColor}!important`
        };
    },
    navStyle() {
      console.log("this.themeInfo", this.themeInfo);
      console.log("this.themeInfo.RecommendedStyles", this.themeInfo.RecommendedStyles);
      const { openCustomColor, navColor, themeColor } = this.themeInfo;
      if (!openCustomColor) {
      if (this.themeInfo.navStyle === 3) {
        if (this.themeInfo.RecommendedStyles === 5) {
          if (this.themeInfo.themeColor === "dark") {
            return {
            background: navColor
          };
          }
          return {
            background: "#FFFFFF !important"
          };
        } if (this.themeInfo.RecommendedStyles === 6) {
          return {
            width: "99%",
            background: navColor,
            borderRadius: "25px",
            margin: "0 auto"
          };
        } if (this.themeInfo.RecommendedStyles === 7) {
          return {
            width: "99%",
            background: navColor,
            borderRadius: "16px 16px 0 0",
            margin: "0 auto"
          };
        }
      }
      }
      if (this.themeInfo.navStyle === 3) {
        if (this.themeInfo.RecommendedStyles === 6) {
          return {
            background: navColor,
            width: "99%",
            borderRadius: "25px",
            margin: "0 auto"
          };
        } if (this.themeInfo.RecommendedStyles === 7) {
          return {
            background: navColor,
            width: "99%",
            borderRadius: "16px 16px 0 0",
            margin: "0 auto"
          };
        }
      }
      return {};
    },
    // eslint-disable-next-line vue/return-in-computed-property, consistent-return
    subNavStyle() {
      console.log("this.themeInfo", this.themeInfo);
      const { openCustomColor, navColor } = this.themeInfo;
      if (!openCustomColor) {
      if (this.themeInfo.navStyle === 3) {
        if (this.themeInfo.RecommendedStyles === 6) {
          return {
            width: "99%",
            background: navColor,
            margin: "0 auto"
          };
        } if (this.themeInfo.RecommendedStyles === 7) {
          return {
            width: "99%",
            background: navColor,
            margin: "0 auto"
          };
        }
      }
      }
      if (this.themeInfo.navStyle === 3) {
        if (this.themeInfo.RecommendedStyles === 6) {
          return {
            background: navColor,
            width: "99%",
            margin: "0 auto"
          };
        } if (this.themeInfo.RecommendedStyles === 7) {
          return {
            background: navColor,
            width: "99%",
            margin: "0 auto"
          };
        }
      }
    },
    navBorderStyle() {
      const { openCustomColor, navColor } = this.themeInfo;
      if (openCustomColor) {
        return {
          borderRightColor: navColor
        };
      }
      return {};
    },
    dataList() {
      return this.$store.getters["main/menuList"];
    }
  },
  mounted() {
    this.updateData(this.dataList);
    console.log(this.themeInfo, "themeInfo");
  },
  methods: {
    ...mapMutations("main", ["setMenuData"]),
    leftChild(item) {
      if (item.children) {
          this.$emit("onChildData", item.children);
          // console.log(this.menuChildList, "menuChildListmenuChildList");
      } else {
          this.$emit("onChildData", []);
          this.menuChildList = [];
          this.clickMenuItem(item);
      }
    },
    updateActiveMenu(val) {
      let activeIndex = null;
      // eslint-disable-next-line func-names
      const activeFun = function(menu, parentMenu) {
        const data = menu.data;
        if (String(data.refId) === String(val.query.templateId) && val.query.templateId && data.refId) {
          activeIndex = menu.index;
          if (parentMenu) {
            parentMenu.open = true;
          }
        }
        const { path, query } = menuRouterData(data);
        if (path !== val.path) {
          return;
        }
        if (query && val.query) {
          if (query.templateId && query.templateId !== val.query.templateId) {
            return;
          }
        }
        activeIndex = menu.index;
        if (parentMenu) {
          parentMenu.open = true;
        }
      };
      this.menuList.forEach((item1) => {
        if (activeIndex) {
          return;
        }
        activeFun(item1);
        if (!item1.children) {
          return;
        }
        item1.children.forEach((item2) => {
          if (activeIndex) {
            return;
          }
          activeFun(item2, item1);
        });
      });
      if (activeIndex) {
        this.activeIndex = activeIndex;
      } else if (this.activeIndex === "0") {
        this.activeIndex = "";
      }
    },
    // 设置图标
    setChildrenIcon(icon) {
      if (icon.indexOf("bus-board-icon") > -1 || icon.indexOf("bus-ywmb-icon") > -1) {
        return `${icon}_1`;
      }
      return icon;
    },
    /**
     * 点击菜单处理
     */
    async clickMenuItem(item) {
      // menuFixedTypeEnum.businessClassify
      console.log(item, "333");
      const itemData = item.data || item;
      if (itemData?.type === 3 && item.children) {
        if (item.data.refData.hasBoard === "0") {
          const res = await enChart.queryUserConfig({ configKey: `picList-${item.data.id}` });
          if (!res) {
            this.$router.push({ path: "/toModelChartUser", query: { templateId: item.data.id, type: 1 } });
          } else if (res.value === "1") {
            // 进图表
            this.$router.push({ path: "/toModelChartUser", query: { templateId: item.data.id, type: 1 } });
          } else {
            // 2 进列表
            this.$router.push({
              name: "boardList",
              query: { id: item.data.id }
            });
          }
        } else {
          this.$router.push({
            name: "boardList",
            query: { id: item.data.id }
          });
        }
      }
      if (!item.children) {
        // if (item.data.code === menuFixedTypeEnum.mySalarySheet) {
        //   this.payRollPath = menuRouterData(item.data);
        //   this.$refs.promptIndex.handlePayRoll();
        // } else {
        const { path, query } = menuRouterData(itemData || item);

        if (itemData.icon === "2_18" || itemData.code === "073") {
          // 法律知识库and邮箱
          const routeData = this.$router.resolve({
            path
          });
          window.open(routeData.href, "_blank");
        } else if (itemData.code === "075") {
          // 外部链接 1-在新窗口显示，2-在当前窗口显示
          if (itemData.outType === 1) {
              window.open(itemData.outUrl);
          } else {
              window.location.href = itemData.outUrl;
          }
        } else if (path.length > 0) {
          this.$router.push({
            path,
            query
          });
        }
        // }
      }
    },
    /**
     * 定义菜单和首页布局
     */
    gotoCustomHomePage() {
      this.$router.push({ path: "/home/define" });
    },
    handleOpen(e) {
      console.log(e);
    },
    handleClose(e) {
      console.log(e);
    },
    /**
     * 处理菜单数据
     * @param menus
     */
    updateData(menus) {
      const menuList = [];
      menus.forEach((item, index) => {
        if (item.isShow !== 0) {
          return;
        }
        const data = {
          data: item,
          name: item.name,
          open: false
        };
        data.icon = item.customIcon;
        data.iconColor = item.customColor;
        data.index = `${index}`;
        // 首页、看板、我的事项、帮助中心,报表查询不展示子项特殊处理
        if (
          [menuClassifyTypeEnum.home, menuClassifyTypeEnum.kanBan, menuClassifyTypeEnum.myMatter, menuClassifyTypeEnum.helpCenter, menuClassifyTypeEnum.report, menuClassifyTypeEnum.lawAngel].indexOf(
            item.customType
          ) === -1
        ) {
          // 隐藏没子数据的分类
          if (!item.dataList || item?.dataList?.length === 0) {
            return;
          }
          // 隐藏设置了不展示的分类
          if (item?.isShow === 1) {
            return;
          }
          const children = [];
          item?.dataList?.forEach((item2, index2) => {
            if (item2.dataType === menuDataTypeEnum.kanBanClassify) {
              return;
            }
            // 隐藏设置了不展示的子类
            if (item2?.isShow === 1) {
              return;
            }
            const data1 = {
              data: item2,
              name: item2.name
            };
            data1.index = `${index}-${index2}`;
            data1.icon = item2.customIcon;
            data1.iconColor = item2.customColor;
            children.push(data1);
          });
          data.children = children;
          menuList.push(data);
        } else {
          menuList.push(data);
        }
      });
      this.menuList = menuList;
    }
  }
};
</script>
<style lang="scss">
.el-menu.el-menu--popup {
  // background: #232c3d;

}
</style>
<style lang="scss" scoped>
/deep/ .el-submenu .el-menu-item {
  // width: 188px;
  min-width: 80px;
  line-height: 40px;
  height: 40px;
  display: inline-block;

  &.is-active {
    // background-color: rgba(117, 131, 154, 0.2) !important;
    &.is-opened {
      background: #454f64 !important;
    }
  }
}

/deep/ .el-menu--vertical {
  top: 0 !important;
  background: #232c3d !important;
}

/deep/ .el-icon-arrow-right.icon + .en-icon-title + .el-submenu__icon-arrow.el-icon-arrow-right {
  display: none;
}

.en-icon {
  margin-right: 12px;
  display: inline-block;
  vertical-align: middle;
}

.en-icon-title {
  display: inline-block;
  vertical-align: middle !important;
}

.menu-container {
  position: relative;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  overflow: hidden;
  .menu {
    height:50px ;
    font-weight: 400;
    user-select: none;
    width:calc(100% - 40px);
    display: inline-block;
    text-align: left;
    position: relative;
    transition: all 0.1s linear;
    overflow: auto;
    display: flex;
    border: none;
    &.collapse {
      width: 80px;
      transition: all 0.1s linear;
    }

    &::-webkit-scrollbar {
      /*隐藏滚轮*/
      display: none;
    }

    /deep/ .el-submenu {
      background: #232c3d;

      &.is-opened {
        background: #2a3345;

        .el-menu-item {
          background: #2a3345;

          &.is-active {
            background: #394356;
          }
        }
      }
    }

    /deep/ .el-menu-item {
      background: #232c3d;

      &.is-active-1 {
        background: #fff !important;
        // background: #394356;
        border-left: 5px solid #3e90fe;
      }
      &.is-active-2 {
        background: #fff !important;
      }
      &.is-active-3 {
        margin:0 1px;
        height: 40px;
        line-height: 40px;
        // background: #fff !important;
        border-radius: 20px;
      }
      &.is-active-4 {
        // background: #fff !important;
        height: 40px;
        line-height: 40px;
        border-radius: 4px;
      }
      &.is-active-5 {
        background: #fff !important;
        height: 40px;
        line-height: 40px;
        border-radius: 20px 0 0 20px;
      }
      &.is-active-6 {
        background: #fff !important;
        height: 40px;
        line-height: 40px;
        border-radius: 20px 20px 0 20px;
      }
    }
  }

  .custom-homepage {
    text-align: center;
    line-height: 50px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: white;
    cursor: pointer;
    height: 50px;
    // border-left: 1px solid #C5DDEA;
    // margin-left: 8px;
    width: 50px;
    .icon {
      height: 17px;
      width: 17px;
      font-size: 16px;
      vertical-align: middle;
    }

    .desc {
      margin-left: 10px;
      display: inline-block;
    }
  }

  .toggle-collapse {
    height: 50px;
    line-height: 50px;
    text-align: center;
    cursor: pointer;
    .item {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      line-height:50px;
    }
  }
}
</style>
