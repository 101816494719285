/**
 * @Author 李煜
 * @Date 2020/6/5
 * @Description 路由辅助工具
 */
import {
  curry,
  compose,
  map,
  filter,
  ifElse
} from "ramda";
import { isArray } from "lodash";

/**
 * 追加path
 * @param path 追加路径
 * @param v 路由对象
 */
export const replacePath = (path, v) => {
  if (v.path === "*") {
    return {
      ...v
    };
  }
  v.path = `${path}/${v.path}`.replace("//", "/");
  return {
    ...v
  };
};

/**
 * 追加Redirect
 * @param path 追加路径
 * @param v 路由对象
 */
export const replaceRedirect = (path, v) => {
  if (v.redirect) {
    v.redirect = `${path}${v.redirect}`.replace("//", "/");
  }
  return {
    ...v
  };
};

// 替换加载js文件
const requireRouter = require.context(".", true, /.js$/);

// 正则
const test = curry((what, str) => what.test(str));

// 第二级目录下的index.js
const isSecordLevel = test(/^\.\/[a-zA-Z0-9-]+\/index.js$/);

/**
 * 获取js文件路径
 * @param req requireRouter
 * @returns {*}
 */
const reqKeys = (req) => req.keys();

/**
 * 打印日志
 * @param key
 * @returns {*} 路径
 */
const log = (key) => {
  if (process.env.NODE_ENV === "development") {
    // console.log(`%cloading route ${key}`, "color: blue;");
  }
  return key;
};
/**
 * 打印路由
 * @param d
 * @returns {*}
 */
const logTable = (d) => d;
/**
 * 判断是否存在路由
 */
const hasRoutes = ifElse(
  isArray,
  logTable,
  (v) => {
    console.warn(`${v} 未加载到路由，请检查是否路由有导出`);
    return [];
  }
);

/**
 * 加载路由
 * @param key 路由路径
 * @returns {*} 返回加载路由，没有则继续返回路由路径
 */
const loadRoutes = (key) => requireRouter(key).default || key;

/**
 * 处理每个文件的路由
 */
const doRoute = compose(hasRoutes, loadRoutes, log);

/**
 * flat数组
 * @param v 数组
 * @returns {*|any[]} 数组
 */
const flat = (v) => v.flat(1);

// 自动加载路由方法
const autoLoadRouter = compose(logTable, flat, map(doRoute), filter(isSecordLevel), reqKeys);
// 通过requireRouter加载路由
export default autoLoadRouter(requireRouter);
