/*
 * @Author: 陈炯明
 * @Date: 2020-06-16 17:12:51
 * @LastEditors: 黎加冬
 * @LastEditTime: 2020-07-09 09:35:07
 * @Description: 接口
 */
import ReportService from "./reportService"; // 报表
import TotalReportService from "./TotalReportService"; // 汇总报表
import ReportDetailService from "./reportDetailService"; // 汇总报表明细
import CustomService from "./customService"; // 汇总报表明细

export const totalReportService = new TotalReportService();
export const reportService = new ReportService();
export const reportDetailService = new ReportDetailService();
export const customService = new CustomService();
