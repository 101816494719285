/*
 * @Author: pengyu
 * @Date: 2021-06-16 17:10:00
 * @LastEditors: pengyu
 * @LastEditTime: 2021-06-16 17:10:11
 * @Description: file content
 */
import BaseService from "../BaseService";

class LawAngelService extends BaseService {
  pk = ""

  constructor(http) {
    super("", http);
  }

  // 文档目录
  getTemplateCategory() {
    return this.http.post("listFtsTemplateCategory", {});
  }

  // 搜索模板
  searchTemplates(params) {
    return this.http.post("searchFtsTemplates", params);
  }

  // 查看作者信息
  getAuthor(params) {
    return this.http.post("getFtsTemplateAuthorInfo", params);
  }

  // 查看模板（带正文）
  getTemplate(params) {
    return this.http.post("getFtsTemplate", params);
  }

  // 查看模板（不带正文）
  viewTemplate(params) {
    return this.http.post("viewFtsTemplate", params);
  }

  // 下载模板
  downloadTemplate(params) {
    return this.http.post("downloadFtsTemplate", params);
  }

  // 另存为
  saveTemplateToFolder(params) {
    return this.http.post("saveFtsTemplateToFolder", params);
  }

  // 查询法天使收费方式
  queryFtsChargeMode() {
    return this.http.post("queryFtsChargesMode");
  }
}

export default LawAngelService;
