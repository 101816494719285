var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "set-password-dialog" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "dialog_content" },
      [
        _c(
          "el-form",
          {
            ref: "ruleForm",
            attrs: { "label-width": "70px", rules: _vm.rules, model: _vm.form },
          },
          [
            _c(
              "div",
              { staticClass: "pwd_input" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "新密码", prop: "newPwd" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入密码", type: _vm.inputtype },
                      model: {
                        value: _vm.form.newPwd,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "newPwd", $$v)
                        },
                        expression: "form.newPwd",
                      },
                    }),
                    _c("en-icon", {
                      staticStyle: { color: "#A8B6C6" },
                      attrs: { name: _vm.iconImg, size: "small" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.changeState.apply(null, arguments)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "pwd_input" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "确认密码", prop: "confirmNewPwd" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入密码", type: _vm.inputtype },
                      model: {
                        value: _vm.form.confirmNewPwd,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "confirmNewPwd", $$v)
                        },
                        expression: "form.confirmNewPwd",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
    _c(
      "span",
      { staticClass: "dialog-footer" },
      [
        _c(
          "en-button",
          {
            attrs: { type: "primary" },
            on: {
              click: function ($event) {
                return _vm.confirm("ruleForm")
              },
            },
          },
          [_vm._v("确 定")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header-title" }, [
      _c("span", { staticClass: "prompt-title" }, [_vm._v("设置工资条密码")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }