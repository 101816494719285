/*
 * @Author: pengyu
 * @Date: 2020-06-16 11:39:07
 * @LastEditors: zhulin
 * @LastEditTime: 2021-07-01 15:45:27
 * @Description: file content
 */
// 主页的一些路由
const routes = [
  {
    path: "home",
    name: "home",
    component: () => import(/* webpackChunkName: "corporate-credit" */"@/views/corporateCredit/home"),
    meta: {
      title: "常用设置业务建模分类",
      keepAlive: true,
      fullPage: false
    }
  }
];

export default routes;
